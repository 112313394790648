import { useQueryClient, useMutation } from '@tanstack/react-query';
import { EventContentObject } from 'models';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
// TODO fix this import from different feature, probably target queries should be here instead
import { getEventsQueryKey, getEventQueryKey as getCommunityEventQueryKey } from 'features/communities/queries';
import { type EventContentObjectDataObject } from 'models/service';

import { getEventQueryKey } from '..';
import { type QuickInviteFormData } from '../../components';
import { patchEvent } from '../../services';

export const usePatchEvent = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { eventContentObject: EventContentObject; eventContentDataObject: EventContentObjectDataObject }, // TODO need to return DataObject because redux for now
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    {
      id: string;
      data: QuickInviteFormData;
    }
  >({
    async mutationFn({ id, data: { formValues } }) {
      const result = await patchEvent(id, {
        invitees: [
          ...(formValues.invitees ?? []),
          ...(formValues.moderators ?? []),
          ...(formValues.presenters ?? []),
        ].map((user) => ({
          id: user.id,
          event_role: user.eventRole,
        })),
        email_invitees: [
          ...(formValues.email_invitees ?? []),
          ...(formValues.email_moderators ?? []),
          ...(formValues.email_presenters ?? []),
        ].map((emailInvitee) => ({
          email: emailInvitee.email,
          event_role: emailInvitee.eventRole,
        })),
      });

      return {
        eventContentObject: new EventContentObject(result.data),
        eventContentDataObject: result.data,
      };
    },
    onSuccess(_, variables) {
      queryClient.invalidateQueries(getCommunityEventQueryKey({ eventId: variables.id }));
      queryClient.invalidateQueries(getEventQueryKey({ eventId: variables.id }));
      queryClient.invalidateQueries(getEventsQueryKey({ type: 'upcoming' }));
    },
    onError() {
      queryClient.invalidateQueries(getEventsQueryKey({}));
    },
  });
};
