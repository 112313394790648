import { isBefore, isToday } from 'date-fns';
import { useMemo, type ComponentProps } from 'react';
import { HeartbeatIcon } from 'ui';
import { type ActivityTodo } from '../../../../types';
import { BaseTodoWidgetItem } from '../BaseTodoWidgetItem';

type P = {
  type?: ComponentProps<typeof BaseTodoWidgetItem>['type'];
  todo: ActivityTodo;
  className?: string;
};

const ActivityTodoWidgetItem = ({ type, todo, className }: P) => {
  const { endTime, activity, path, startTime } = todo;
  const { times, periodicityType, name, description } = activity;

  const currentDate = useMemo(() => new Date(), []);

  const mode = useMemo(() => {
    if (periodicityType === 'daily' && times.length > 0) {
      if (isBefore(currentDate, startTime)) {
        return 'at';
      }

      return 'by';
    }

    if (periodicityType === 'one_time' && isToday(endTime)) {
      return 'by';
    }

    return 'hidden';
  }, [currentDate, endTime, periodicityType, startTime, times.length]);

  const time = useMemo(() => {
    if (mode === 'by') {
      return endTime;
    }

    return startTime;
  }, [endTime, mode, startTime]);

  return (
    <BaseTodoWidgetItem
      type={type}
      className={className}
      path={path}
      icon={<HeartbeatIcon />}
      title="Complete activity"
      description={name}
      additionalDescription={description}
      mode={mode}
      time={time}
    />
  );
};

export default ActivityTodoWidgetItem;
