const HEIGHT = 50;

export function FileIcon(props) {
  const { fileType } = props;
  const typing = fileType.split('/');
  return (
    <div className="file">
      {typing[1] === 'pdf' ? (
        <img
          height={HEIGHT}
          alt="file-pdf"
          src="/assets/file-icons/file_pdf.png"
          srcSet="/assets/file-icons/file_pdf@2x.png 2x, /assets/file-icons/file_pdf@3x.png 3x"
        />
      ) : typing[0] === 'video' || typing[0] === 'audio' ? (
        <img
          height={HEIGHT}
          alt="file-video"
          src="/assets/file-icons/file_video.png"
          srcSet="/assets/file-icons/file_video@2x.png 2x, /assets/file-icons/file_video@3x.png 3x"
        />
      ) : typing[0] === 'image' ? (
        <div style={{ position: 'relative' }}>
          <div className="file__image" style={{ backgroundImage: `url(${props.url})` }} />
          <img
            height={HEIGHT}
            alt="file-folder"
            src="/assets/file-icons/file_img.png"
            srcSet="/assets/file-icons/file_img@2x.png 2x, /assets/file-icons/file_img@3x.png 3x"
          />
        </div>
      ) : (
        <img
          height={HEIGHT}
          alt="file-text"
          src="/assets/file-icons/file_text.png"
          srcSet="/assets/file-icons/file_text@2x.png 2x, /assets/file-icons/file_text@3x.png 3x"
        />
      )}
      {props.extension && <div className="file__extension">{props.extension}</div>}
    </div>
  );
}
