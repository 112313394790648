import cs from 'classnames';
import { type ReactNode } from 'react';

type P = {
  children: ReactNode;
  className?: string;
  size?: 'small';
};

const RoundElement = ({ children, className, size }: P) => {
  return (
    <div
      className={cs('round-element', className, {
        'round-element--small': size === 'small',
      })}
    >
      {children}
    </div>
  );
};

export default RoundElement;
