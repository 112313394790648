import { type ReduxUser } from 'models';
import { images } from 'common/utils';
import { CommunitySelect as CommunitySelectBasic, type CommunitySelectP } from 'features/communities';
import { Avatar, Col, Form, Row } from 'ui';

type ItemP = React.ComponentProps<typeof Form.Item>;

type P = {
  item: ItemP;
  viewer: ReduxUser;
  input?: CommunitySelectP;
};

const CommunitySelect = ({ viewer, item, input }: P) => {
  return (
    <Row item={{ className: 'community-select' }}>
      <Col item={{ className: 'community-select__avatar' }}>
        <Avatar size={42} photo={viewer.profile_photo?.url ?? images.default.userProfilePhoto} />
      </Col>
      <Col item={{ className: 'community-select__input' }}>
        <Form.Item name="community" {...item}>
          <CommunitySelectBasic {...input} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CommunitySelect;
