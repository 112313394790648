import { EnterEvent } from 'models';
import { parseBigmarkerErrorMessage } from 'old/utils/big-marker';
import { useMutation } from 'query';
import { handleAxiosError } from 'common/error-handlers';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { enterEvent } from '../../services';

type Variables = {
  eventId: string;
  invitationToken?: string;
  exitUrl?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export const useEnterEvent = () =>
  useMutation<EnterEvent, Variables, ApiClientResponseError<DefaultResponseErrorDataObject>>(
    async ({ eventId, invitationToken, exitUrl, firstName, lastName, email }: Variables) => {
      const { data } = await enterEvent(eventId, {
        exit_uri: exitUrl ?? window.location.href,
        invitation_token: invitationToken,
        first_name: firstName,
        last_name: lastName,
        email,
      });

      return new EnterEvent(data);
    },
    {
      onSuccess(data) {
        // TODO maybe move this to `onSuccess` of mutation if make sense in future
        window.open(data.enterUrl, '_self');
      },
      onError(error) {
        handleAxiosError(error, parseBigmarkerErrorMessage(error.response?.data?.message ?? ''));
      },
    },
  );
