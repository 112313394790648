import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { AppLayout } from '../../layouts/AppLayout';
import { Card } from 'ui';
import { Navigation, NavLink } from '../../atoms/navigation';
import CreateProgram from '../../../features/programs/CreateProgram';
import EditProgram from '../../../features/programs/EditProgram';
import { can } from '../../utils/permissions';
import { routes } from 'routes';

class Renderer extends React.Component {
  onSuccess = (course) => {
    this.props.history.push(`/courses/${course.id}/timeline`);
  };

  render() {
    const { user } = this.props;

    return (
      <AppLayout
        center={
          <Card shrinkedLeft>
            <div className="program-form">
              {/* TODO these buttons don't make sense */}
              <div className="program-form__sidebar">
                <Navigation direction="vertical" compact>
                  {can(undefined, 'Course', 'create') && (
                    <NavLink to={routes.program.create}>
                      <Trans>Create a New Program</Trans>
                    </NavLink>
                  )}
                  <NavLink to={routes.program.joined}>
                    <Trans>Go to Joined Programs</Trans>
                  </NavLink>
                </Navigation>
              </div>
              <div className="program-form__right-content">
                {this.props.match.params.programId ? (
                  <EditProgram
                    program={this.props.match.params.programId}
                    onSuccess={this.onSuccess}
                    currentUser={user}
                  />
                ) : (
                  <CreateProgram onSuccess={this.onSuccess} currentUser={user} organizationId={this.props.organizationId} />
                )}
              </div>
            </div>
          </Card>
        }
      />
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
  organizationId: props.match.params.organizationId
});

export const PageCourseForm = connect(mapState)(Renderer);
