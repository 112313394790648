import { ContentLeft, Footer, Header, LoginSignpost, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnSuccessLogin, useOnboardingNavigation } from '../hooks';
import { type OnboardingLocationState } from '../types';

export type LoginSignpostLocationState = OnboardingLocationState;

const PageLoginSignpost = () => {
  const { onNavigateToSignUp, onNavigateToPasswordlessLogin, onNavigateToPasswordLogin } =
    useOnboardingNavigation<LoginSignpostLocationState>();
  const { onSuccessLogin } = useOnSuccessLogin<LoginSignpostLocationState>();

  const headerButtons: HeaderItem[] = [
    {
      label: 'Sign up',
      type: 'primary',
      onClick() {
        onNavigateToSignUp();
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={
        <LoginSignpost
          onSuccessLogin={onSuccessLogin}
          onNavigateToPasswordlessLogin={onNavigateToPasswordlessLogin}
          onNavigateToPasswordLogin={onNavigateToPasswordLogin}
        />
      }
      footer={<Footer />}
    />
  );
};

export default PageLoginSignpost;
