const serialize = (editor: any) => {
  const html = editor.getData();

  function innerText(element: any) {
    function getTextLoop(element: any) {
      const texts: any[] = [];
      const childNodes = [...element.childNodes];
      for (const node of childNodes) {
        if (node.nodeType === 3) {
          texts.push(node.textContent.trim());
        } else {
          texts.push(...getTextLoop(node));
        }
      }

      return texts;
    }

    return getTextLoop(element).join(' ');
  }

  const div = document.createElement('div');
  div.innerHTML = html;
  const text = innerText(div);

  return {
    html,
    text,
  };
};

export default serialize;
