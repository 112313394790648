import { MinusCircleOutlined, PlusOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import variables from 'common/styles/variables.json';
import { Button, Form, Row, Col, TextAreaInput, TextInput } from 'ui';
import { type Topic } from '../../../../models/program';

type P = {
  name: string;
  modules: Topic[];
};

const ProgramModules: React.FC<P> = ({ name, modules }) => {
  const { t } = useTranslation();

  return (
    <Form.List name={name}>
      {(fields, { add, remove, move }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <Form.Item required={false} key={field.key}>
                <Row item={{ gutter: variables.spaceMd.value }}>
                  <Col item={{ span: 18 }}>
                    <TextInput
                      item={{
                        ...field,
                        name: [field.name, 'name'],
                        label: 'Module',
                        rules: [{ required: true, message: t('Module name is required'), whitespace: true }],
                      }}
                    />
                  </Col>
                  {index === 0 ? null : (
                    <Col item={{ span: 2, className: 'delete-button-wrapper' }}>
                      <ArrowUpOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          move(index, index - 1);
                        }}
                        data-test-id="btn_move-up-module"
                      />
                    </Col>
                  )}
                  {index + 1 < fields.length ? (
                    <Col item={{ span: 2, className: 'delete-button-wrapper' }}>
                      <ArrowDownOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          move(index, index + 1);
                        }}
                        data-test-id="btn_move-down-module"
                      />
                    </Col>
                  ) : null}
                  {fields.length > 1 ? (
                    <Col item={{ span: 2, className: 'delete-button-wrapper' }}>
                      <Button
                        className="dynamic-delete-button"
                        type="ghost"
                        shape="circle"
                        disabled={modules[field.key]?.has_content}
                        onClick={() => {
                          remove(field.name);
                        }}
                        size="large"
                        data-test-id="btn_delete-module"
                      >
                        <MinusCircleOutlined className="dynamic-delete-button__icon" />
                      </Button>
                    </Col>
                  ) : null}
                </Row>
                <TextAreaInput
                  item={{
                    ...field,
                    name: [field.name, 'description'],
                    label: 'Module description',
                  }}
                  input={{
                    'data-test-id': 'program_module-description',
                  }}
                />
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                size="large"
                data-test-id="btn_add-module"
              >
                <PlusOutlined /> {t('Add Module')}
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default ProgramModules;
