import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Form, TextInput, Section, Col, PasswordInput, Row, Link, CheckboxInput, Button, Text } from 'ui';
import { type Login } from '../models';

type P<T> = {
  onSubmit: (data: Login) => void;
  onNavigateToPrimaryLogin: () => void;
  loading: boolean;
  state: T | undefined;
};

const LoginForm = <T,>({ onSubmit, loading, state, onNavigateToPrimaryLogin }: P<T>) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = (data: { email: string; password: string; remember: boolean }) => {
    onSubmit(data);
  };

  return (
    <Form onFinish={onFinish} form={form} initialValues={{ remember: true }}>
      <TextInput
        item={{
          name: 'email',
          label: 'Email',
          rules: [
            {
              type: 'email',
            },
            {
              required: true,
              whitespace: true,
            },
          ],
        }}
        input={{
          autocomplete: 'email',
        }}
      />
      <PasswordInput
        item={{
          name: 'password',
          label: 'Password',
          rules: [
            {
              required: true,
              whitespace: true,
            },
          ],
          className: 'login-form__password',
        }}
      />
      <Row item={{ justify: 'center', align: 'middle' }}>
        <Col item={{ span: 12 }}>
          <CheckboxInput
            item={{
              label: t('Remember me'),
              name: 'remember',
              valuePropName: 'checked',
            }}
          />
        </Col>
        <Col item={{ span: 12, className: 'onboarding-password-login-form__forgot-link' }}>
          {/* TODO type of state should be sent to Link */}
          <Text>
            <Link to={{ pathname: '/forgot-password', state }}>Forgot password?</Link>
          </Text>
        </Col>
      </Row>
      <Section paddingTop paddingBottom={false} lineTop={false} lineBottom={false}>
        <div className="onboarding-password-login-form__login-buttons-section">
          <Button
            className="onboarding-password-login-form__button"
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={loading ? <LoadingOutlined /> : null}
            size="large"
          >
            {t('Log in')}
          </Button>
          <Button
            className="onboarding-password-login-form__button"
            onClick={() => {
              onNavigateToPrimaryLogin();
            }}
            icon={<ArrowLeftOutlined />}
            size="large"
          >
            {t('All log in options')}
          </Button>
        </div>
      </Section>
    </Form>
  );
};

export default LoginForm;
