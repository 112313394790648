import { PlusOutlined } from '@ant-design/icons';
import { type Community, type ReduxUser } from 'models';
import { EventEditor } from 'old/atoms/event-editor';
import { EVENT_DRAFT } from 'old/atoms/event-editor/emptyDraftState';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// TODO import from different feature
import variables from 'common/styles/variables.json';

import { EventFeedback, type EventFeedbackOptions } from 'features/events';
import { TelehealthRequest } from 'features/events/components/Telehealth';
import { useCreateEventPermissions } from 'features/events/hooks';
import { Button, Col, ContentWrapper, Row, Title } from 'ui';

import { useEventsQuery } from '../queries';
import { CommunityEventsData } from './CommunityEventsData';

const EVENTS_PAGE_SIZE = 4;

type P = {
  viewer: ReduxUser;
  community: Community;
  feedbackOptions: EventFeedbackOptions;
};

const CommunityEvents = ({ viewer, community, feedbackOptions }: P) => {
  const { t } = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const {
    data: upcomingEventsDataPages,
    isLoading: isLoadingUpcoming,
    isRefetching: isRefetchingUpcoming,
    fetchNextPage: fetchUpcomingEventsNextPage,
    hasNextPage: hasNextUpcomingEventsPage,
    isFetchingNextPage: isFetchingNextUpcomingEventsPage,
  } = useEventsQuery({
    limit: EVENTS_PAGE_SIZE,
    type: 'upcoming',
    communityId: community.id,
  });

  const {
    data: pastEventsDataPages,
    isLoading: isLoadingPast,
    isRefetching: isRefetchingPast,
    fetchNextPage: fetchPastEventsNextPage,
    hasNextPage: hasNextPastEventsPage,
    isFetchingNextPage: isFetchingNextPastEventsPage,
  } = useEventsQuery({
    limit: EVENTS_PAGE_SIZE,
    type: 'past',
    communityId: community.id,
  });

  const { joinedCommunities, createEventAllowedTypes, isCreateButtonAvailable, isTelehealthButtonAvailable } =
    useCreateEventPermissions({ viewer, community });

  return (
    <div className="community-events">
      <EventFeedback {...feedbackOptions} />

      <ContentWrapper
        headerClassName="community-events__header"
        header={
          <>
            <Title level={4} className="community-events__header__title">
              {t('Upcoming Events')}
            </Title>
            <Row item={{ gutter: [isTelehealthButtonAvailable ? variables.spaceXs.value : 0, 0] }}>
              <Col>
                {isCreateButtonAvailable ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsCreateModalOpen(true);
                    }}
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    {t('Create')}
                  </Button>
                ) : null}
              </Col>
              <Col>
                {isTelehealthButtonAvailable ? (
                  <TelehealthRequest
                    viewer={viewer}
                    communities={joinedCommunities}
                    buttonType="primary"
                    communityId={community.id}
                  />
                ) : null}
              </Col>
            </Row>
          </>
        }
      >
        <CommunityEventsData
          pageSize={EVENTS_PAGE_SIZE}
          community={community}
          viewer={viewer}
          hasNextPage={hasNextUpcomingEventsPage ?? false}
          fetchNextPage={fetchUpcomingEventsNextPage}
          isLoading={isLoadingUpcoming}
          isFetching={isFetchingNextUpcomingEventsPage}
          isRefetching={isRefetchingUpcoming}
          data={upcomingEventsDataPages}
          emptyDescription={t('No upcoming events')}
          className="community-events__load-more"
        />
      </ContentWrapper>

      <ContentWrapper
        headerClassName="community-events__header"
        header={
          <Title level={4} className="community-events__title">
            {t('Past Events')}
          </Title>
        }
      >
        <CommunityEventsData
          pageSize={EVENTS_PAGE_SIZE}
          community={community}
          viewer={viewer}
          hasNextPage={hasNextPastEventsPage ?? false}
          fetchNextPage={fetchPastEventsNextPage}
          isLoading={isLoadingPast}
          isFetching={isFetchingNextPastEventsPage}
          isRefetching={isRefetchingPast}
          data={pastEventsDataPages}
          emptyDescription={t('No past events')}
          className="community-events__load-more"
        />
      </ContentWrapper>

      <EventEditor
        eventTypes={createEventAllowedTypes}
        isEditorOpen={isCreateModalOpen}
        onRequestClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSuccess={() => {
          setIsCreateModalOpen(false);
        }}
        draft={{ ...EVENT_DRAFT, post_in_communities: [community] }}
        communityId={community.id}
      />
    </div>
  );
};

export default CommunityEvents;
