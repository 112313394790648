import { type ApiResponse } from 'apisauce';
import { http } from 'old/services/shared';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { apiClient, isDefaultResponseErrorDataObject } from 'common/services';
import deprecatedApiClient from 'common/services/deprecatedApi';
import { type ConsentsLocationState } from '../PageConsents';
import { type OnboardingTourLocationState } from '../PageOnboardingTour';

export const CONSENTS_ERROR_STATUS_CODE = 412 as const;
export const PRECONDITION_FAILED_ONBOARDING = 'onboarding_required' as const;
export const PRECONDITION_FAILED_CONSENTS = 'consents_required' as const;

const PreconditionFailedGuard = () => {
  const history = useHistory<ConsentsLocationState | OnboardingTourLocationState>();

  const navigateToConsents = useCallback(() => {
    if (history.location.pathname !== routes.consents) {
      history.push(routes.consents, {
        referrer: history.location.pathname + history.location.search,
        originalState: history.location.state,
      });
    }
  }, [history]);

  const navigateToOnboarding = useCallback(() => {
    if (history.location.pathname !== routes.onboardingTour.index) {
      history.push(routes.onboardingTour.index, {
        referrer: history.location.pathname + history.location.search,
        originalState: history.location.state,
      });
    }
  }, [history]);

  const handlePreconditionFailedMessage = useCallback(
    (message?: string) => {
      if (message) {
        const parts = message.split(',');

        if (parts.includes(PRECONDITION_FAILED_ONBOARDING)) {
          navigateToOnboarding();
          return;
        }

        if (parts.includes(PRECONDITION_FAILED_CONSENTS)) {
          navigateToConsents();
          return;
        }
      }

      navigateToConsents();
    },
    [navigateToConsents, navigateToOnboarding],
  );

  const handleApisauceResponse = useCallback(
    (response: ApiResponse<unknown>) => {
      if (response.status === CONSENTS_ERROR_STATUS_CODE) {
        if (isDefaultResponseErrorDataObject(response.data)) {
          handlePreconditionFailedMessage(response.data.message);
          return;
        }

        handlePreconditionFailedMessage();
      }
    },
    [handlePreconditionFailedMessage],
  );

  const handleAxiosResponseOrError = useCallback(
    (responseOrError: any) => {
      if (
        responseOrError?.status === CONSENTS_ERROR_STATUS_CODE ||
        responseOrError?.response?.status === CONSENTS_ERROR_STATUS_CODE
      ) {
        const { data, response } = responseOrError;

        if (response) {
          const { data: responseData } = response;

          if (isDefaultResponseErrorDataObject(responseData)) {
            handlePreconditionFailedMessage(responseData.message);
            return;
          }
        }

        if (isDefaultResponseErrorDataObject(data)) {
          handlePreconditionFailedMessage(data.message);
          return;
        }

        handlePreconditionFailedMessage();
      }
    },
    [handlePreconditionFailedMessage],
  );

  useEffect(() => {
    if (!http.responseTransforms.includes(handleApisauceResponse)) {
      http.addResponseTransform(handleApisauceResponse);
    }

    const interceptorNumber = apiClient.interceptors.response.use(undefined, (error) => {
      handleAxiosResponseOrError(error);
      throw error;
    });

    const interceptorNumberDeprecated = deprecatedApiClient.interceptors.response.use(
      (response) => {
        handleAxiosResponseOrError(response);
        return response;
      },
      (error) => {
        handleAxiosResponseOrError(error);
        throw error;
      },
    );

    return () => {
      apiClient.interceptors.response.eject(interceptorNumber);
      deprecatedApiClient.interceptors.response.eject(interceptorNumberDeprecated);
    };
  }, [handleAxiosResponseOrError, handleApisauceResponse]);

  return null;
};

export default PreconditionFailedGuard;
