import classNames from 'classnames';
import { t } from 'i18next';
import { type ReduxUser } from 'models';
import { AxiosErrorHandler } from 'common/error-handlers';
import { Card, Loading, Text } from 'ui';
import { useTodosQuery } from '../../queries';
import { TodosWidgetEmptyState } from './TodosWidgetEmptyState';
import { TodosWidgetList } from './TodosWidgetList';
import { TodosWidgetListFeatured } from './TodosWidgetListFeatured';
import { TodosWidgetLoadingState } from './TodosWidgetLoadingState';
import { TodosWidgetUpdatedAt } from './TodosWidgetUpdatedAt';

type P = {
  numberOfNotifications: number;
  unreadMessages: number;
  className?: string;
  hideTodosFeedCta?: boolean;
  viewer: ReduxUser;
};

const PAGE_SIZE_FEATURED = 6;
const PAGE_SIZE = 20;

const TodosWidget = ({ unreadMessages, numberOfNotifications, className, hideTodosFeedCta, viewer }: P) => {
  const {
    data: featuredTodos,
    error: featuredError,
    isLoading: isLoadingFeatured,
    isFetching: isFetchingFeatured,
    hasNextPage: hasNextPageFeatured,
    isRefetching: isRefetchingFeatured,
    dataUpdatedAt: dataUpdatedAtFeatured,
    fetchNextPage: fetchNextPageFeatured,
  } = useTodosQuery({ pageSize: PAGE_SIZE_FEATURED, featured: true });

  const {
    data: todos,
    error,
    isLoading,
    isFetching,
    hasNextPage,
    isRefetching,
    dataUpdatedAt,
    fetchNextPage,
  } = useTodosQuery({ pageSize: PAGE_SIZE, featured: false });

  const allFeaturedTodos = featuredTodos?.pages.flatMap((page) => page.data);
  const allTodos = todos?.pages.flatMap((page) => page.data);

  const numberOfFeaturedTodos = featuredTodos?.pages[featuredTodos.pages.length - 1]?.total;
  const numberOfTodos = todos?.pages[todos.pages.length - 1]?.total;

  const numberOfTodosTotal =
    numberOfFeaturedTodos === undefined || numberOfTodos === undefined
      ? undefined
      : numberOfFeaturedTodos + numberOfTodos;

  return (
    <Card
      extra={<Text type="secondary">{t('dateFormatShortMonthDayYear', { date: new Date() })}</Text>}
      title={
        <span>
          {t(`To-Dos for today`, {
            numberOfTodos: numberOfTodosTotal ?? '?',
          })}
        </span>
      }
      className={classNames('todos-widget', className)}
    >
      {isLoading || !allTodos || isLoadingFeatured || !allFeaturedTodos ? (
        <AxiosErrorHandler error={featuredError ?? error} actions={false}>
          <TodosWidgetLoadingState cols={PAGE_SIZE} />
        </AxiosErrorHandler>
      ) : (
        <div className="todos-widget__content">
          {isRefetching || isRefetchingFeatured ? <Loading /> : null}

          {allFeaturedTodos.length === 0 && allTodos.length === 0 ? (
            <TodosWidgetEmptyState
              unreadMessages={unreadMessages}
              newNotifications={numberOfNotifications}
              hideTodosFeedCta={hideTodosFeedCta}
            />
          ) : (
            <>
              {allFeaturedTodos.length > 0 ? (
                <TodosWidgetListFeatured
                  items={allFeaturedTodos}
                  isFetching={isFetchingFeatured}
                  hasMoreData={hasNextPageFeatured}
                  fetchNextPage={fetchNextPageFeatured}
                  viewer={viewer}
                />
              ) : null}

              {allTodos.length > 0 ? (
                <TodosWidgetList
                  items={allTodos}
                  isFetching={isFetching}
                  hasMoreData={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  viewer={viewer}
                />
              ) : null}
            </>
          )}

          <TodosWidgetUpdatedAt updatedAt={[dataUpdatedAtFeatured, dataUpdatedAt]} />
        </div>
      )}
    </Card>
  );
};

export default TodosWidget;
