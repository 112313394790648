import { apiClient, routes } from 'common/services';
import { type ResetPassword } from '../models';

export const resetPassword = async ({
  email,
  currentPassword,
  password,
  passwordConfirmation,
  resetPasswordToken,
}: ResetPassword) => {
  const data = {
    user: {
      email,
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetPasswordToken,
    },
  };

  return apiClient.put(routes.user.password, data, { skipAuth: true });
};
