import { Box } from '../../box';
import { Text } from '../../text';
import { ThemeConsumer } from '../../theme';
import { FileIcon } from '../../file';
import { FileDownloader } from '../../file-downloader';
import { calculateFileSize } from '../../../utils/files';

export function Attachment({ attachment }) {
  const filenames = (attachment.filename || 'Item does not exist.').split(/\.(?=[^.]+$)/);
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box flexDirection="row" className="attachment-row">
          <a
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <FileIcon url={attachment.url} fileType={attachment.content_type || ''} extension={filenames[1]} />
          </a>
          <Box
            flex="1"
            style={{
              marginLeft: 10,
              borderBottom: '1px solid rgba(143, 142, 148, 0.3)',
              minHeight: 48,
              paddingBottom: 10,
            }}
          >
            <a href={attachment.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Text weight="600" lineHeight="24px" component="p" style={{ wordBreak: 'break-all' }}>
                {filenames[0]}
              </Text>
            </a>
            <Box flexDirection="row" alignItems="center">
              {attachment.byte_size && <Text style={{ width: 80 }}>{calculateFileSize(attachment.byte_size)}</Text>}
              {attachment.content_type && (
                <FileDownloader url={attachment.download_url} fileName={attachment.filename} />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </ThemeConsumer>
  );
}
