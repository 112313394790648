import { Component } from 'react';
import { WithingsOrderPostRendererCore } from './WithingsOrderPostRendererCore';
import { CommentsList, CommentForm } from '../comment';
import { withTranslation } from 'react-i18next';
import { can } from '../../utils/permissions';
import { ContentObjectHeader } from 'features/feed';
import { AutocompleteService } from '../../services/AutocompleteService';
import { Loading, ReportIcon, BookmarkIconLine } from 'ui';
import CommentWrapper from './shared/CommentWrapper';
import { images } from 'common/utils';
import { canReportContentObject } from 'permissions';
import { ContentObjectFooter, CommentsEmpty } from 'features/feed';
import { parseISO } from 'date-fns';

export class Renderer extends Component {
  state = {
    loading: false,
  };

  onRequestMentions = async (value) => {
    if (this.props.item.program?.id) {
      const response = await AutocompleteService.getProgramUsers({
        scope: 'programs',
        search: value,
        program_ids: [this.props.item.program.id],
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    return [];
  };

  createActions = () => {
    const { t, isBookmark, viewer, item, onBookmarkObject, onReportObject } = this.props;

    if (isBookmark) {
      return [
        {
          label: t('Unbookmark'),
          key: 'unbookmark',
          onClick: () => onBookmarkObject(item),
          icon: <BookmarkIconLine width={16} height={16} />,
        },
      ];
    }

    const actions = [];

    if (canReportContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Report'),
        key: 'report',
        onClick: () => onReportObject(item),
        icon: <ReportIcon width={16} height={16} />,
      });
    }

    return actions;
  };

  render() {
    const disableComments = this.props.isBookmark;
    const showComments = this.props.showComments;

    const { isOnFeed } = this.props;

    return (
      <>
        <ContentObjectHeader
          title={this.props.authorName}
          subtitle={parseISO(this.props.item.publish_at)}
          avatar={{
            url: this.props.item.author.profile_photo?.url ?? images.default.userProfilePhoto,
            alias: this.props.authorName,
          }}
          bookmark={this.props.bookmark}
          actions={this.createActions()}
          pill={this.props.pill}
          authorId={this.props.item.author.id}
        />
        <WithingsOrderPostRendererCore
          viewer={this.props.viewer}
          item={this.props.item}
          onLikeObject={this.props.onLikeObject}
          onShowComments={this.props.onShowComments}
          onCreateObject={this.props.onCreateObject}
          onRequestEventDetail={this.props.onRequestEventDetail}
          onReloadObject={this.props.onReloadObject}
          isBookmark={this.props.isBookmark}
          isOnFeed={isOnFeed}
        />
        <ContentObjectFooter
          item={this.props.item}
          viewer={this.props.viewer}
          onShowComments={this.props.onShowComments}
          view={this.props.isBookmark ? 'bookmark' : 'other'}
        />
        {!disableComments && showComments && (
          <CommentWrapper>
            {this.props.item.replies.length > 0 ? (
              <div style={{ padding: '10px 0' }}>
                <CommentsList
                  item={this.props.item}
                  onCreateComment={this.props.onCreateComment}
                  onDeleteObject={this.props.onDeleteObject}
                  onEditObject={this.props.onEditComment || this.props.onEditObject}
                  onLikeObject={this.props.onLikeObject}
                  onReportObject={this.props.onReportObject}
                  onSortChange={this.props.onSortChange}
                  onBookmarkObject={this.props.onBookmarkObject}
                  parent="post"
                  viewer={this.props.viewer}
                  originalItem={this.props.item}
                  course={this.props.item.program}
                  scrollToCommentId={this.props.scrollToCommentId}
                  scrollToReplyId={this.props.scrollToReplyId}
                />
              </div>
            ) : (
              <CommentsEmpty />
            )}
            {this.state.loading ? <Loading /> : null}
            {can(this.props.item, 'ContentObject', 'comment') ? (
              <div style={{ padding: '15px 25px' }}>
                <CommentForm
                  viewer={this.props.viewer}
                  onSubmit={async (draft) => {
                    this.setState({ loading: true });
                    await this.props.onCreateComment(draft, this.props.item.id);
                    this.setState({ loading: false });
                  }}
                  onRequestMentions={this.onRequestMentions}
                  suffixId={`withings-${this.props.item.id}`}
                  loading={this.state.loading}
                />
              </div>
            ) : null}
          </CommentWrapper>
        )}
      </>
    );
  }
}

export const WithingsOrderPostRenderer = withTranslation()(Renderer);
