import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { isPasswordValid } from 'common/validators';
import { Form, Section, PasswordInput, TextInput, Button } from 'ui';
import { type ResetPassword } from '../models';

type P = {
  onSubmit: (data: ResetPassword) => void | void;
  onNavigateToPrimaryLogin: () => void;
  token?: string;
  loading: boolean;
  email?: string;
};

const NewPasswordForm = ({ onSubmit, token, loading, email, onNavigateToPrimaryLogin }: P) => {
  const [form] = Form.useForm();
  const isPasswordExpired = Boolean(!token);
  const { t } = useTranslation();
  const initialValues = { email };

  const onFinish = (data: {
    email: string;
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
  }) => {
    onSubmit(data);
  };

  return (
    <Form onFinish={onFinish} initialValues={initialValues} form={form} className="login-form">
      {isPasswordExpired ? (
        <>
          <TextInput
            item={{
              name: 'email',
              label: 'Email',
              rules: [
                {
                  type: 'email',
                },
                {
                  required: isPasswordExpired,
                  whitespace: true,
                },
              ],
            }}
            input={{
              autocomplete: 'email',
            }}
          />
          <PasswordInput
            item={{
              name: 'currentPassword',
              label: 'Current password',
              rules: [
                {
                  required: isPasswordExpired,
                  whitespace: true,
                },
              ],
            }}
          />
        </>
      ) : null}
      <PasswordInput
        item={{
          name: 'password',
          label: 'New password',
          dependencies: ['currentPassword'],
          rules: [
            {
              required: true,
              whitespace: true,
            },
            ({ getFieldValue }) => ({
              message: t('Error: New password must be different from the current password.'),
              async validator(_, value) {
                if (!value || getFieldValue('currentPassword') !== value) {
                  // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
                  return Promise.resolve();
                }

                throw new Error(t('Error: New password must be different from the current password.'));
              },
            }),
            {
              message: '',
              // eslint-disable-next-line @typescript-eslint/promise-function-async
              validator(_, value) {
                if (isPasswordValid(value)) {
                  return Promise.resolve();
                }

                return Promise.reject();
              },
            },
          ],
        }}
        showValidation
      />
      <PasswordInput
        item={{
          name: 'passwordConfirmation',
          label: 'Confirm password',
          dependencies: ['password'],
          rules: [
            {
              required: true,
              whitespace: true,
            },
            ({ getFieldValue }) => ({
              message: t('Error: This field and password fields must match.'),
              async validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
                  return Promise.resolve();
                }

                throw new Error(t('Error: This field and password fields must match.'));
              },
            }),
          ],
        }}
      />
      <Section paddingTop paddingBottom={false} lineTop={false} lineBottom={false}>
        <Button
          className="forgot-password-form__button"
          type="primary"
          htmlType="submit"
          loading={loading}
          icon={loading ? <LoadingOutlined /> : null}
          size="large"
        >
          {t('Reset password')}
        </Button>
        <Button
          className="forgot-password-form__button"
          onClick={() => {
            onNavigateToPrimaryLogin();
          }}
          size="large"
        >
          {t(`Back to Log in`)}
        </Button>
      </Section>
    </Form>
  );
};

export default NewPasswordForm;
