import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponse, type ApiClientResponseError, type ResponseErrorDataObject } from 'common/services/api';
import { type SignUp } from '../../models';
import { signUp } from '../../services';
import { type LoginDataObject } from '../../services/data-objects';

export const useSignUpQuery = () =>
  useMutation<ApiClientResponse<LoginDataObject>, ApiClientResponseError<ResponseErrorDataObject>, SignUp>({
    mutationFn: async (auth: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      passwordConfirmation: string;
      publicCommunityId?: string;
      invitationToken?: string;
    }) => signUp(auth),
  });
