import { type ReactNode } from 'react';
import { routes } from 'routes';
import {
  NavigationHomeIcon,
  NavigationEventsIcon,
  NavigationProgramsIcon,
  NavigationCommunitiesIcon,
  NavigationMessagesIcon,
  NavigationBookmarksIcon,
  NavigationJournalIcon,
  NavigationCampaignsIcon,
  NavigationActiveHomeIcon,
  NavigationActiveEventsIcon,
  NavigationActiveProgramsIcon,
  NavigationActiveCommunitiesIcon,
  NavigationActiveMessagesIcon,
  NavigationActiveBookmarksIcon,
  NavigationActiveJournalIcon,
  NavigationActiveCampaignsIcon,
  CompassIcon,
  CompassFilledIcon,
} from '../../../../Icons';

export type NavigationItem =
  | '/bookmarks'
  | '/campaigns'
  | '/courses'
  | '/events'
  | '/feed'
  | '/journal'
  | '/messages'
  | '/network';

export type AppConfig = { feature_flags: { campaigns: boolean } };

export type NavigationMenuItemConfig = {
  id: string;
  key: string;
  icon: ReactNode;
  activeIcon: ReactNode;
  href: string;
  text: string;
  badge?: NavigationItem;
  alias?: string;
  isHidden?: (appConfig: AppConfig) => boolean;
};

type NavigationMenuConfigSections = {
  name?: string;
  collapsedName?: string;
  section?: NavigationMenuItemConfig[];
};

type NavigationMenuConfig = NavigationMenuConfigSections[];

export const navigationMenuConfig: NavigationMenuConfig = [
  {
    section: [
      {
        id: 'nav-feed',
        key: '/feed',
        icon: <NavigationHomeIcon />,
        activeIcon: <NavigationActiveHomeIcon />,
        href: '/feed',
        text: 'Home',
      },
      {
        id: 'nav-events',
        key: '/events',
        icon: <NavigationEventsIcon />,
        activeIcon: <NavigationActiveEventsIcon />,
        href: '/feed',
        text: 'Events',
      },
      {
        id: 'nav-channels',
        key: '/channels',
        icon: <CompassIcon />,
        activeIcon: <CompassFilledIcon />,
        href: '/channels',
        text: 'Channels',
      },
      {
        id: 'nav-messages',
        key: '/messages',
        icon: <NavigationMessagesIcon />,
        activeIcon: <NavigationActiveMessagesIcon />,
        href: '/messages',
        text: 'Messages',
        badge: '/messages',
      },
    ],
  },
  {
    name: 'MY CONTENT',
    collapsedName: 'MY',
    section: [
      {
        id: 'nav-joined-communities',
        key: routes.community.joined,
        icon: <NavigationCommunitiesIcon />,
        activeIcon: <NavigationActiveCommunitiesIcon />,
        href: routes.community.joined,
        text: 'Joined communities',
      },
      {
        id: 'nav-joined-programs',
        key: routes.program.joined,
        icon: <NavigationProgramsIcon />,
        activeIcon: <NavigationActiveProgramsIcon />,
        href: routes.program.joined,
        text: 'Joined programs',
      },
      {
        id: 'nav-campaigns',
        key: '/campaigns',
        icon: <NavigationCampaignsIcon />,
        activeIcon: <NavigationActiveCampaignsIcon />,
        href: '/campaigns',
        text: 'Campaigns',
        isHidden: (appConfig: AppConfig) => !appConfig.feature_flags.campaigns,
      },
      {
        id: 'nav-bookmarks',
        key: '/bookmarks',
        icon: <NavigationBookmarksIcon />,
        activeIcon: <NavigationActiveBookmarksIcon />,
        href: '/bookmarks',
        text: 'Bookmarks',
      },
      {
        id: 'nav-journal',
        key: '/journal',
        icon: <NavigationJournalIcon />,
        activeIcon: <NavigationActiveJournalIcon />,
        href: '/journal',
        text: 'Journal',
      },
    ],
  },
];
