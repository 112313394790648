import React from 'react';
import { Trans } from 'react-i18next';

import { Icon } from '../icon';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';

import { PostEditor } from './PostEditor';
import { EMPTY_DRAFT } from './emptyDraftState';
import { HeaderWithAccessoryView } from './components';
import { Modal } from 'ui';

export class JournalEditorTrigger extends React.Component {
  state = {
    isCreateModalOpened: false,
  };

  getEmptyDraft = () => ({
    ...EMPTY_DRAFT,
    post_in_communities: [],
  });

  render() {
    const { isCreateModalOpened } = this.state;

    return (
      <React.Fragment>
        {(this.props.draft !== undefined || isCreateModalOpened) && (
          <Modal
            disableBack
            title={
              <React.Fragment>
                <Trans>Create note</Trans>
              </React.Fragment>
            }
            isOpened={this.props.draft !== undefined || isCreateModalOpened}
            onCancel={() => {
              this.setState({ isCreateModalOpened: false });
              this.props.onRequestClose();
            }}
          >
            {this.props.draft || isCreateModalOpened ? (
              <PostEditor
                fields={['html_content', 'attachments', 'emojis', 'whiteboard']}
                draft={isCreateModalOpened ? this.getEmptyDraft() : this.props.draft}
                viewer={this.props.viewer}
                onSubmit={(object) => {
                  this.setState({ isCreateModalOpened: false });
                  this.props.onSubmit(object);
                }}
                maxWidth={this.props.maxWidth}
              />
            ) : null}
          </Modal>
        )}
        <ThemeConsumer>
          {(theme) => (
            <div
              onClick={() => this.setState({ isCreateModalOpened: true })}
              data-test-id="panel_start-conversation"
              style={{ cursor: 'pointer', width: '100%' }}
            >
              <HeaderWithAccessoryView viewer={this.props.viewer}>
                <React.Fragment>
                  <Text size="14px" weight={300} color={theme.color.lightGray} data-test-id="item_start-conversation">
                    <Trans>Create note</Trans>...
                  </Text>
                  <Icon size={28} name="pba-attachment" color={theme.color.lightGray} />
                </React.Fragment>
              </HeaderWithAccessoryView>
            </div>
          )}
        </ThemeConsumer>
      </React.Fragment>
    );
  }
}
