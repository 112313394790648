import { useMutation } from '@tanstack/react-query';
import { changeRequest } from 'common/services';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import { type ContactUsFormValues } from '../../contact-us';

export const useProgramChangeRequest = () =>
  useMutation<
    undefined,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    { data: ContactUsFormValues; programId: string }
  >({
    async mutationFn({ data, programId }) {
      await changeRequest({
        kind: 'program_request_to_contact',
        subject_type: 'Program',
        subject_id: programId,
        meta: {
          invitation_phone: data.invitationPhone,
        },
      });

      return undefined;
    },
  });
