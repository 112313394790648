import { LoadingOutlined } from '@ant-design/icons';
import { type ChangeRequest } from 'models';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import variables from 'common/styles/variables.json';
import { isPasswordValid } from 'common/validators';
import { CheckboxInput, Col, Form, PasswordInput, Row, Skeleton, TextInput, Text, Button, Section, Link } from 'ui';
import { useChangeRequestsQuery } from '../queries';

type P = {
  onSubmit: (data: FormValues) => void;
  loading: boolean;
  changeRequestId?: string;
  invitationToken?: string;
};

const getInitialValues = (changeRequestData?: ChangeRequest): Partial<FormValues> => ({
  firstName: changeRequestData?.meta.firstName,
  lastName: changeRequestData?.meta.lastName,
  email: changeRequestData?.meta.invitationEmail,
});

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const SignUpForm = ({ onSubmit, loading, changeRequestId, invitationToken }: P) => {
  const [form] = Form.useForm<FormValues>();
  const { t } = useTranslation();

  const {
    data: changeRequestData,
    isLoading,
    isFetching,
  } = useChangeRequestsQuery({
    changeRequestId,
    invitationToken,
  });

  const initialValues = getInitialValues(changeRequestData);

  const onFinish = (data: FormValues) => {
    onSubmit(data);
  };

  return (
    <div className="sign-up-form">
      {isLoading && isFetching ? (
        <Skeleton active paragraph={{ rows: 8 }} className="sign-up-form__loading" />
      ) : (
        <Form<FormValues> onFinish={onFinish} form={form} initialValues={initialValues}>
          <Row item={{ gutter: variables.spaceMd.value }}>
            <Col item={{ span: 12 }}>
              <TextInput
                item={{
                  name: 'firstName',
                  label: 'First name',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'given-name',
                }}
              />
            </Col>
            <Col item={{ span: 12 }}>
              <TextInput
                item={{
                  name: 'lastName',
                  label: 'Last name',
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'family-name',
                }}
              />
            </Col>
          </Row>
          <TextInput
            item={{
              name: 'email',
              label: 'Email',
              rules: [
                {
                  type: 'email',
                },
                {
                  required: true,
                  whitespace: true,
                },
              ],
            }}
            input={{
              autocomplete: 'email',
            }}
          />
          <PasswordInput
            item={{
              name: 'password',
              label: 'Password',
              rules: [
                {
                  required: true,
                  whitespace: true,
                },
                () => ({
                  message: '',
                  validator: async (_, value) => (isPasswordValid(value) ? Promise.resolve() : Promise.reject()),
                }),
              ],
            }}
            showValidation
          />
          <CheckboxInput
            item={{
              label: (
                <Text>
                  {t('I agree with')}{' '}
                  <a href={process.env.REACT_APP_TERMS_URL} target="_blank" rel="noopener noreferrer">
                    {t('Terms')}
                  </a>{' '}
                  {t('and')}{' '}
                  <Link to={routes.privacyPolicy} target="_blank" rel="noopener noreferrer">
                    {t('Privacy Policy')}
                  </Link>
                </Text>
              ),
              name: 'termsAgreement',
              valuePropName: 'checked',
              rules: [
                {
                  message: t('Error: Accepting Terms & Conditions is required'),
                  validator: async (_, value) => (value ? Promise.resolve() : Promise.reject()),
                },
              ],
            }}
          />
          <Section paddingTop={false} lineTop={false} lineBottom={false}>
            <Button
              id="create-account"
              block
              loading={loading}
              htmlType="submit"
              icon={loading ? <LoadingOutlined /> : null}
              type="primary"
              size="large"
            >
              Sign up
            </Button>
          </Section>
        </Form>
      )}
    </div>
  );
};

export default SignUpForm;
