import { InboxOutlined, GoogleOutlined, DropboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { type Form, Upload, message, Row, Col } from 'antd';
import { type UploadFile, type UploadChangeParam } from 'antd/lib/upload/interface';
import { type ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type UploadedFileDataObject } from 'models/service';

import { DropboxChooser } from '../../../old/atoms/dropbox-chooser';
import { GooglePicker } from '../../../old/atoms/google-picker';
import { GoogleService } from '../../../old/services/GoogleService';
import { StorageService } from '../../../old/services/StorageService';
import { Item } from '../Item';

const { Dragger } = Upload;

type ItemP = Omit<ComponentProps<typeof Form.Item>, 'children'> & {
  label?: string;
};
type InputP = ComponentProps<typeof Dragger>;

type P = {
  item?: ItemP;
  input?: InputP;
};

export type AttahcmentsInputUploadFile<T = UploadedFileDataObject> = Omit<UploadFile<T>, 'response'> & {
  response: T;
};

export const generateAttachmentsInputInitialValue = (files?: UploadedFileDataObject[]) =>
  files ? { fileList: files.map((file) => generateAttachmetsInputFileListItem(file)) } : { fileList: [] };

export const generateAttachmetsInputFileListItem = (file: UploadedFileDataObject): AttahcmentsInputUploadFile => ({
  url: file.url,
  uid: file.id,
  name: file.filename,
  fileName: file.filename,
  percent: 100,
  status: 'done',
  size: file.byte_size,
  type: file.content_type,
  response: file,
});

const AttachmentsInputDragger = ({
  loading,
  setLoading,
  ...rest
}: InputP & {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const { t } = useTranslation();

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === 'error') {
      message.error(t(info.file.error.message), 1.5);
    }
  };

  const uploadButton = (
    <>
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
      </div>
      <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
    </>
  );

  const onThirdPartyUploadFinish = (newFiles: UploadedFileDataObject[]) => {
    const fileList = [...(rest.fileList ?? []), ...newFiles.map((file) => generateAttachmetsInputFileListItem(file))];
    const file = fileList.at(-1);

    if (file) {
      rest.onChange?.({
        file,
        fileList,
      });
    }
  };

  const onSelectGoogle = async (data: any) => {
    setLoading(true);

    // @ts-expect-error
    if (data.action === 'picked' && window.gapi) {
      const pickerFile = data.docs[0];
      // @ts-expect-error
      const { accessToken } = window.google;

      const response = await (pickerFile.type === 'document'
        ? GoogleService.exportDriveDoc({
            fileId: pickerFile.id,
            mimeType: 'application/pdf',
            accessToken,
          })
        : GoogleService.getDriveFile(pickerFile.id, accessToken));
      // TODO missing type in axios requests this is just hotfix
      const file = new File([response.data], pickerFile.name, { type: pickerFile.mimeType });

      const responseData = await StorageService.send(file);
      onThirdPartyUploadFinish([responseData.data]);
    }

    setLoading(false);
  };

  const onSelectDropbox = async (data: any) => {
    setLoading(true);

    const files = data.map((file: any) => ({
      name: file.name,
      url: file.link,
    }));

    const promises = files.map(async (file: any) => StorageService.send(file));

    const responses = await Promise.all(promises);
    onThirdPartyUploadFinish(responses.map((r: any) => r.data));

    setLoading(false);
  };

  return (
    <Dragger
      listType="picture"
      customRequest={async ({ onError, onSuccess, file, onProgress }) => {
        let target;

        const response = await StorageService.send(file, (value: any) => {
          target = value.target;
          onProgress?.({ percent: 100 * (value.loaded / value.total), ...value });
        });

        if (response.ok && target) {
          onSuccess?.(response.data, target);
        } else {
          onError?.(response.originalError ?? new Error('Failed to upload'));
        }
      }}
      onChange={handleChange}
      multiple
      {...rest}
      disabled={loading}
    >
      <Row gutter={13}>
        <Col span={18}>{uploadButton}</Col>
        <Col span={6} className="attachments" style={{ display: 'flex', flexWrap: 'wrap', paddingRight: 24 }}>
          <Col
            span={24}
            className="attachments__third-party"
            style={{ marginBottom: 5 }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <GooglePicker onSelect={onSelectGoogle}>
              <div className="attachments__third-party__inner">
                <span className="attachments__third-party__inner-text">{t('Google drive')}</span> <GoogleOutlined />
              </div>
            </GooglePicker>
          </Col>
          <Col
            span={24}
            className="attachments__third-party"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <DropboxChooser onSelect={onSelectDropbox}>
              <div className="attachments__third-party__inner">
                <span className="attachments__third-party__inner-text">{t('Dropbox')}</span> <DropboxOutlined />
              </div>
            </DropboxChooser>
          </Col>
        </Col>
      </Row>
    </Dragger>
  );
};

const AttachmentsInput = ({ item, input }: P) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Item label={item?.label}>
      <Item
        valuePropName="fileList"
        getValueProps={(value) => value}
        rules={[
          {
            message: t('Error: {{label}} is still in process of uploading', { label: t(item?.label) }),
            async validator(_, value?: UploadChangeParam) {
              for (const file of value?.fileList ?? []) {
                if (file.status === 'uploading') {
                  throw new Error('Uploading in process');
                }
              }

              if (loading) {
                throw new Error('Uploading in process');
              }
            },
            validateTrigger: 'onSubmit',
          },
        ]}
        {...item}
        noStyle
      >
        <AttachmentsInputDragger loading={loading} setLoading={setLoading} {...input} />
      </Item>
      {loading ? (
        <Col style={{ textAlign: 'center', fontSize: 24, marginTop: 15 }}>
          <LoadingOutlined />
        </Col>
      ) : null}
    </Item>
  );
};

export default AttachmentsInput;

export { type UploadChangeParam } from 'antd/lib/upload/interface';
