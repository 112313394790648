import { PlusOutlined, SearchOutlined, LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Modal } from 'antd';
import { type CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import debounce from 'lodash/debounce';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { images } from 'common/utils';
import { type AutocompleteUser } from 'models/user';
import { Avatar, Card, Col, Form, Row, Space, Tag, Checkbox, Text, Button, Input, RichTextInput, Title } from 'ui';

type P = {
  name: string;
  label: string;
  buttonLabel: string;
  scope: string;
  okText: string;
  modalTitle: string;
  onSubmit: (users: AutocompleteUser[], name: string) => void;
  getUsers: (scope: string, value: string, limit?: number) => Promise<AutocompleteUser[]>;
  chosen: AutocompleteUser[];
  hasUserDescription?: boolean;
};

const ProgramUsersSelect = ({
  name,
  label,
  buttonLabel,
  scope,
  okText,
  modalTitle,
  onSubmit,
  getUsers,
  chosen,
  hasUserDescription = false,
}: P) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchedUsers, setSearchedUsers] = useState<AutocompleteUser[]>([]);
  const [invitees, setInvitees] = useState<AutocompleteUser[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const getDataForValue = useCallback(
    async (value: string) => {
      const users = await getUsers(scope, value);
      setLoading(false);

      setSearchedUsers(users);
    },
    [getUsers, scope],
  );

  const getDataDebounce = useMemo(() => debounce(getDataForValue, 500), [getDataForValue]);

  const onOk = () => {
    onSubmit(invitees, name);
    setIsOpen(false);
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setInvitees(chosen);
  }, [chosen]);

  const removeUser = (user: AutocompleteUser) => {
    setInvitees(invitees.filter((invitee) => invitee.id !== user.id));
  };

  const cardHeader = (user: AutocompleteUser) => {
    return (
      <>
        <Avatar size="large" photo={user?.profile_photo?.url ?? images.default.userProfilePhoto}>
          {user?.first_name?.slice(0, 2).toUpperCase()}
        </Avatar>
        <Text className="program_users_select__card-header-name">
          {user?.first_name} {user?.last_name}
        </Text>
      </>
    );
  };

  return (
    <>
      <Row item={{ className: 'program_users_select' }}>
        <Col item={{ span: 24 }}>
          <Title level={4}>{label}</Title>
        </Col>
        <Col item={{ span: 24 }}>
          <Form.List name={name}>
            {(fields, { remove }) => (
              <div>
                {fields.map((field, index) => (
                  <Card
                    key={`${name}__${index}__${invitees[index]?.id}`}
                    title={cardHeader(invitees[index])}
                    bodyStyle={{ display: hasUserDescription ? 'block' : 'none' }}
                    style={{ marginBottom: 16 }}
                    extra={
                      <Button
                        className="program_users_select__button program_users_select__button--remove"
                        icon={<CloseCircleOutlined />}
                        onClick={() => {
                          remove(field.name);
                          removeUser(invitees[index]);
                        }}
                        size="large"
                      />
                    }
                  >
                    {hasUserDescription ? (
                      <RichTextInput
                        text={{ name: [field.name, 'description'] }}
                        html={{
                          ...field,
                          name: [field.name, 'html_description'],
                          label: 'Description',
                          maxWidth: '594px',
                        }}
                      />
                    ) : null}
                  </Card>
                ))}
              </div>
            )}
          </Form.List>
        </Col>
        <Col item={{ span: 24 }}>
          <Space size={40}>
            <Button
              className="program_users_select__button"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsOpen(true);
              }}
              size="large"
              data-test-id={`btn_invite-${name}`}
            >
              {t(buttonLabel)}
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal title={modalTitle} open={isOpen} onOk={onOk} onCancel={onCancel} okText={t(okText)}>
        <div className="user-list">
          <Col item={{ span: 24, className: 'user-list__selected' }}>
            {invitees?.length === 0 ? (
              <div className="user-list__selected__empty">{t('No selected users.')}</div>
            ) : (
              invitees?.map((user: AutocompleteUser) => (
                <Tag
                  closable
                  onClose={() => {
                    removeUser(user);
                  }}
                  key={`tag_` + user.id}
                >
                  {user.first_name} {user.last_name}
                </Tag>
              ))
            )}
          </Col>
          <Input
            placeholder={t('search...')}
            suffix={<SearchOutlined />}
            onChange={(event) => {
              setLoading(true);
              getDataDebounce.cancel();
              getDataDebounce(event.target.value);
            }}
            data-test-id="program_search-users"
          />

          {loading ? (
            <Col item={{ span: 4, offset: 10, className: 'program_users_select__spinner-wrapper' }}>
              <LoadingOutlined />
            </Col>
          ) : (
            <div className="user-list__list" data-test-id="program_user-list">
              {searchedUsers.map((user) => (
                <label className="user-list__list__item" key={`searched_` + user.id} data-test-id="program_user">
                  <Avatar photo={`${user.profile_photo?.url ?? images.default.userProfilePhoto}`} />
                  <span className="user-list__list__item__name">{`${user.first_name} ${user.last_name}`}</span>
                  <Checkbox
                    onChange={(event: CheckboxChangeEvent) => {
                      if (event.target.checked) {
                        setInvitees([...invitees, user]);
                      } else {
                        removeUser(user);
                      }
                    }}
                    checked={invitees.some((invitee: AutocompleteUser) => invitee.id === user.id)}
                  />
                </label>
              ))}

              {searchedUsers.length === 0 && (
                <div className="user-list__list__empty" data-test-id="program_user-list-empty">
                  {t('No matches.')}
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ProgramUsersSelect;
