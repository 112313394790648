import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import { App } from './App';
import { getAppConfig } from './old/atoms/app-config-gate';

import './common/styles/main.css';
import './index.css';

ReactDOM.render(<App />, document.querySelector('#root'));

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID,
      gaOptions: {
        transport: 'beacon',
        environment: String(process.env.REACT_APP_GOOGLE_TRACKING_ENVIRONMENT),
        wla_name: String(process.env.REACT_APP_TITLE),
        app_version: String(process.env.REACT_APP_VERSION),
      },
    },
  ]);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}

// TODO Change to import brand color directly to css as variable
document.documentElement.style.setProperty('--brand-color', getAppConfig().colors.brand);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
