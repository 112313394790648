import { type ReduxUser, type Community } from 'models';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { canInviteToCommunity, canManageCommunity } from 'permissions';
import { Button, Card, Col, Row, Skeleton, Title } from 'ui';
import { useMembersQuery } from '../../queries';
import { MembersInviteModal } from '../MembersInviteModal';
import { UserList } from '../UserList';
import { useDeleteMember } from './hooks';

type P = {
  community: Community;
  viewer: ReduxUser;
};

const PAGE_SIZE = 20;

const Members = ({ community, viewer }: P) => {
  const { t } = useTranslation();
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const {
    isLoading: isLoadingMembers,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  } = useMembersQuery({
    communityId: community.id,
    pageSize: PAGE_SIZE,
  });

  const onDeleteUser = useDeleteMember({ community });

  const members = useMemo(() => data?.pages.flat().flat(), [data]);

  return (
    <>
      <Card
        className="community-members"
        title={
          <Row item={{ align: 'middle', justify: 'space-between' }}>
            <Title className="community-members__title" level={5}>
              {t('Members ({{count}})', { count: community.membersCount })}
            </Title>
            {canInviteToCommunity({ viewer, community }) ? (
              <Button
                className="community-members__invite"
                type="link"
                onClick={() => {
                  setOpenInviteModal(true);
                }}
                size="large"
              >
                {t('Invite member')}
              </Button>
            ) : null}
          </Row>
        }
      >
        {isLoadingMembers || !members ? (
          <Skeleton active avatar paragraph={{ rows: 18 }} />
        ) : (
          <>
            <Row>
              <Col item={{ span: 24 }}>
                {
                  <UserList
                    users={members?.map((member) => ({
                      id: member.id,
                      photo: {
                        url: member.profilePhoto?.url ?? images.default.userProfilePhoto,
                        alt: t('Member profile photo'),
                      },
                      fullName: member.fullName,
                    }))}
                    viewer={viewer}
                    onDeleteUser={canManageCommunity({ viewer, community }) ? onDeleteUser : undefined}
                  />
                }
              </Col>
            </Row>
            {hasNextPage ? (
              <Row item={{ align: 'middle', justify: 'center', className: 'community-members__show-more' }}>
                <Button
                  type="ghost"
                  onClick={async () => fetchNextPage()}
                  loading={isFetchingNextPage || isFetching}
                  size="large"
                >
                  Show More
                </Button>
              </Row>
            ) : null}
          </>
        )}
      </Card>
      <MembersInviteModal
        communityId={community.id}
        isOpened={openInviteModal}
        onClose={() => {
          setOpenInviteModal(false);
        }}
      />
    </>
  );
};

export default Members;
