import {
  type Event,
  type CommunityListItem,
  type RegisteredEventInvitee,
  type EventEmailInvitee,
  type EventPrivacy,
} from 'models';
import { Form, type FormInstance } from 'ui';
import EventInviteesAlert from './EventInviteesAlert';

type CommonFormValues = {
  invitees?: RegisteredEventInvitee[];
  moderators?: RegisteredEventInvitee[];
  presenters?: RegisteredEventInvitee[];
  email_invitees?: EventEmailInvitee[];
  email_moderators?: EventEmailInvitee[];
  email_presenters?: EventEmailInvitee[];
  communityId?: string;
  viewPermission: EventPrivacy;
};

type P = {
  form: FormInstance<CommonFormValues>;
  communities: CommunityListItem[];
  event?: Event;
};

const EventInviteesExceededLimit = ({ form, communities, event }: P) => {
  return (
    <Form.Item
      noStyle
      dependencies={[
        'communityId',
        'invitees',
        'moderators',
        'presenters',
        'viewPermission',
        'email_invitees',
        'email_moderators',
        'email_presenters',
      ]}
    >
      {() => {
        const communityId: string | undefined = form.getFieldValue('communityId');
        const selectedCommunity = communities.find((community) => communityId === community.id);

        const totalPrivateInvitees =
          (form.getFieldValue('invitees') ?? []).length +
          (form.getFieldValue('moderators') ?? []).length +
          (form.getFieldValue('presenters') ?? []).length +
          (form.getFieldValue('email_invitees') ?? []).length +
          (form.getFieldValue('email_moderators') ?? []).length +
          (form.getFieldValue('email_presenters') ?? []).length;

        const totalPublicInvitees =
          (selectedCommunity?.membersCount ?? 0) +
          (form.getFieldValue('email_moderators') ?? []).length +
          (form.getFieldValue('email_presenters') ?? []).length;

        const totalInvitees =
          form.getFieldValue('viewPermission') === 'private' ? totalPrivateInvitees : totalPublicInvitees;

        const inviteesCount = event?.inviteesCount ?? 0;
        const isLimitAbove250Exceeded = inviteesCount > 25 && inviteesCount <= 250 && totalInvitees > 250;
        const isLimitAbove25Exceeded = inviteesCount <= 25 && totalInvitees > 25;

        const isEventApproved = event?.lastChangeRequestStatus === 'approved';
        const approvedInviteesLimitExceeded = isEventApproved && (isLimitAbove250Exceeded || isLimitAbove25Exceeded);

        const exceededApprovedInviteesLimit = approvedInviteesLimitExceeded
          ? isLimitAbove25Exceeded
            ? 25
            : 250
          : undefined;

        return (
          <EventInviteesAlert
            totalInvitees={totalInvitees}
            isEventApproved={isEventApproved}
            exceededApprovedInviteesLimit={exceededApprovedInviteesLimit}
          />
        );
      }}
    </Form.Item>
  );
};

export default EventInviteesExceededLimit;
