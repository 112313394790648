import { type PluginConstructor } from '@ckeditor/ckeditor5-core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { type FC, memo, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RichTextEditorBase, { type EditorConfig } from 'rich-text-editor/build';
import 'rich-text-editor/build/translations/en';
import 'rich-text-editor/build/translations/es';

import { message } from '../';
import ClipboardImageValidator from './ClipboardImageValidator';
import Emojis from './Emojis';
import ImageUpload from './ImageUpload';
import maxIndent from './maxIndent';
import Mention from './Mention';
import serialize from './serialize';

export type ToolbarOption =
  | 'bold'
  | 'bulletedList'
  | 'embed'
  | 'fontColor'
  | 'heading'
  | 'imageUpload'
  | 'indent'
  | 'italic'
  | 'link'
  | 'mediaEmbed'
  | 'numberedList'
  | 'outdent'
  | 'specialCharacters'
  | 'strikethrough'
  | 'underline'
  | 'wproofreader';

export type AnotationOption = {
  id: string;
  userId: string;
  link: string;
};

export type RichTextData = {
  html: string;
  text: string;
};

export type RichTextMentions = (query: string) => AnotationOption[] | Promise<AnotationOption[]>;

type RichTextEditorProperties = {
  collapsible?: boolean;
  focused?: boolean;
  onChange?: (data: RichTextData) => void;
  onUploading?: (uploading: boolean) => void;
  initialData?: string;
  data?: string;
  editable?: boolean;
  placeholder?: string;
  onRequestUpload?: (uri: string) => any;
  toolbar?: ToolbarOption[];
  mentions?: RichTextMentions;
  maxWidth?: string;
  isFocusedInitially?: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  labelId?: string;
};

const embedHtml = (baseUrl: string, options?: string) =>
  '<div style="position: relative; padding-bottom: 100%; height: 0">' +
  `<iframe src="${baseUrl}" ` +
  'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
  (options ?? '') +
  'allowfullscreen>' +
  '</iframe>' +
  '</div>';

const defaultToolbar: ToolbarOption[] = [
  'heading',
  'bold',
  'italic',
  'underline',
  'fontColor',
  'numberedList',
  'bulletedList',
  'outdent',
  'indent',
  'link',
  'imageUpload',
  'mediaEmbed',
  'strikethrough',
  'wproofreader',
  'specialCharacters',
];

const defaultMentions: never[] | RichTextMentions | undefined = [];

const classes = ['block-indent__1', 'block-indent__2', 'block-indent__3', 'block-indent__4', 'block-indent__5'];

const RichTextEditor: FC<RichTextEditorProperties> = ({
  collapsible = false,
  focused = false,
  onChange,
  onUploading,
  initialData,
  data,
  editable = true,
  placeholder,
  onRequestUpload,
  toolbar = defaultToolbar,
  mentions = defaultMentions,
  maxWidth,
  isFocusedInitially = false,
  isMobile = false,
  disabled = false,
  labelId,
}) => {
  const { t } = useTranslation();
  const originalData: string | undefined = initialData ?? data;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [editorReference, setEditorReference] = useState<RichTextEditorBase | null>(null);
  // eslint-disable-next-line react/hook-use-state
  const [uploadData] = useState<Record<string, boolean>>({});
  const metaData: Record<
    string,
    {
      width: number;
      height: number;
    }
  > = useMemo(() => ({}), []);

  const onChangeUploading = (uploading: boolean) => {
    if (onUploading) {
      onUploading(uploading);
    }
  };

  const onUpdateData = (editor: RichTextEditorBase) => onChange?.(serialize(editor));

  const builtinPlugins = editable
    ? RichTextEditorBase.builtinPlugins
    : RichTextEditorBase.builtinPlugins?.filter((plugin) => plugin.pluginName !== 'WProofreader');

  const plugins = [...(builtinPlugins ?? [])];

  if (editable) {
    plugins.push(
      Emojis as PluginConstructor,
      Mention as PluginConstructor,
      maxIndent(classes.length) as PluginConstructor,
      ClipboardImageValidator as PluginConstructor,
    );
  }

  if (onRequestUpload) {
    plugins.push(
      // eslint-disable-next-line new-cap
      ImageUpload(
        onRequestUpload,
        onChangeUploading,
        onUpdateData,
        message.error,
        uploadData,
        metaData,
      ) as PluginConstructor,
    );
  } else {
    toolbar = toolbar.filter((option: ToolbarOption) => option !== 'imageUpload');
  }

  const language = localStorage.getItem('lang')?.slice(0, 2);

  const editorConfiguration: EditorConfig = {
    language: language && ['en', 'es'].includes(language) ? language : 'en', // TODO this is hack because default language might be en-US
    plugins,
    toolbar: {
      items: toolbar,
      shouldNotGroupWhenFull: true,
    },
    wproofreader: {
      serviceId: 'LhzEvM4RdIyDCUr',
      srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
      actionItems: isMobile ? ['settings', 'toggle'] : ['settings', 'toggle', 'proofreadDialog'],
      autocomplete: !isMobile,
    },
    fontColor: {
      colors: [
        {
          label: t('Orange'),
          color: '#FCA92D',
        },
        {
          label: t('Blue'),
          color: '#16ABEB',
        },
        {
          label: t('Green'),
          color: '#02C19F',
        },
        {
          label: t('Red'),
          color: '#FB4A4A',
        },
        {
          label: t('Gray'),
          color: '#717E94',
        },
        {
          label: t('Black'),
          color: '#263044',
        },
      ],
      colorPicker: false,
    },
    placeholder: placeholder ? t(placeholder) : placeholder,
    mention: {
      feeds: [
        {
          marker: '@',
          feed: mentions,
          minimumCharacters: 1,
        },
      ],
    },

    mediaEmbed: {
      removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
      extraProviders: [
        {
          name: 'googleSlides',
          url: /(((https|http):\/\/|)docs\.google\.com\/presentation\/d\/)(.+?(?=(\/.+|\/|$)))/,

          html(match: string[]) {
            const presentationKey = match[4];

            if (!presentationKey) {
              return '';
            }

            return embedHtml(`https://docs.google.com/presentation/d/${presentationKey}/embed`);
          },
        },
        {
          name: 'youtubeShorts',
          url: /youtube\.com\/shorts\/(\w+\s*\/?)*(\d+)*\??.*$/i,

          html(match: string[]) {
            const shortId = match[1];

            return embedHtml(`https://www.youtube.com/embed/${shortId}`, 'allow="autoplay; encrypted-media" ');
          },
        },
        {
          name: 'youtubeLive',
          url: /youtube\.com\/live\/(.*)$/i,

          html(match: string[]) {
            const liveId = match[1];

            return embedHtml(`https://www.youtube.com/embed/${liveId}`, 'allow="autoplay; encrypted-media" ');
          },
        },
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
    },
    indentBlock: {
      classes,
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'tT', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'tT', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'tT', class: 'ck-heading_heading2' },
      ],
    },
    typing: {
      // @ts-expect-error wrong types, the missing property is not required by documentation
      transformations: {
        extra: [
          {
            from: /([!.?] )([a-z])$/,
            to: (matches: string[]) => [null, matches[1].toUpperCase()],
          },
          {
            from: /^([a-z])$/,
            to: (matches: string[]) => [matches[0].toUpperCase()],
          },
        ],
      },
    },
    list: {
      multiBlock: false,
    },
    ui: {
      poweredBy: {
        side: 'right',
        position: 'border',
        label: null,
        verticalOffset: -12,
        horizontalOffset: 0,
      },
    },
    initialData: originalData,
  };

  let className = 'rich-text';

  className += editable ? ' rich-text__editable' : ' dont-break-out';

  if (collapsible) {
    className += ' rich-text__collapsible';
  }

  if (focused || isFocused) {
    className += ' rich-text__focused';
  }

  useEffect(() => {
    if (isFocusedInitially) {
      setTimeout(() => {
        if (editorReference) {
          editorReference.focus();
        }
      }, 0);
    }
  }, [isFocusedInitially, editorReference]);

  const disableSpellCheck = (inputEditor?: RichTextEditorBase) => {
    if (isMobile) {
      const editor = inputEditor ?? editorReference;

      if (editor) {
        editor.ui.view.editable.element?.setAttribute('autocomplete', 'off');
        editor.ui.view.editable.element?.setAttribute('autocorrect', 'off');
        editor.ui.view.editable.element?.setAttribute('autocapitalize', 'off');
        editor.ui.view.editable.element?.setAttribute('spellcheck', 'false');
      }
    }
  };

  return (
    <div className={className} style={{ maxWidth }}>
      <CKEditor
        editor={RichTextEditorBase}
        config={editorConfiguration}
        data={data}
        onReady={(editor?) => {
          if (editor) {
            disableSpellCheck(editor);
            if (editable) {
              setEditorReference(editor);
              editor.disableReadOnlyMode('editable');
            } else {
              editor.enableReadOnlyMode('editable');
            }

            for (const item of editor.ui.view.toolbar.items) {
              // @ts-expect-error no idea
              if (item?.buttonView?.label === 'Special characters') {
                // @ts-expect-error no idea
                item.buttonView.label = 'Characters';
              }
            }

            if (labelId) {
              editor.editing.view.document.getRoot()?._setAttribute('aria-label', '');
              editor.editing.view.document.getRoot()?._setAttribute('aria-labelledby', labelId);
            }
          }
        }}
        onChange={(_, editor) => {
          disableSpellCheck(editor);
          onUpdateData(editor);
        }}
        onFocus={() => {
          disableSpellCheck();
          setIsFocused(true);
        }}
        onBlur={() => {
          disableSpellCheck();
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default memo(RichTextEditor);
