import { useEffect } from 'react';
import { CommunityCard } from 'features/communities';
import { Slider } from 'ui';
import { CARDS_IN_ROW } from '../..';
import { useIsChannelEmpty, usePageCursor } from '../../hooks';
import { ChannelsEmpty } from '../ChannelsEmpty';
import { useFeaturedCommunities } from './hooks';

type P = {
  getPathToCommunityDetail: (id: string) => string;
  navigateToJoinedCommunities: () => void;
  organizationId?: string;
  title?: string;
  onSeeAll?: () => void;
  view: 'detail' | 'preview';
  onEmptyState?: (isEmpty: boolean) => void;
};

const CommunitiesSlider = ({
  getPathToCommunityDetail,
  navigateToJoinedCommunities,
  organizationId,
  title,
  onSeeAll,
  view,
  onEmptyState,
}: P) => {
  const [pageCursor, setPageCursor] = usePageCursor(organizationId);
  const {
    featured,
    query: { data: featuredCommunitiesData, isFetching: isFetchingFeatured },
  } = useFeaturedCommunities({
    onSeeAll,
    organizationId,
    pagination: { pageSize: CARDS_IN_ROW, position: pageCursor },
  });

  const featuredCommunities = featuredCommunitiesData?.data;
  const showSeeAllButton = onSeeAll && (featuredCommunities?.length ?? 0) > 0;

  const isEmpty = useIsChannelEmpty(isFetchingFeatured, pageCursor, featuredCommunities);

  // TODO fix not working without organizationId, this hack is needed together with useEffect in default channels to set empty state correctly
  useEffect(() => {
    onEmptyState?.(isEmpty);
  }, [isEmpty, onEmptyState, organizationId]);

  if (isEmpty) {
    return null;
  }

  return (
    <Slider
      isLoading={isFetchingFeatured}
      items={featuredCommunities?.map((community) => (
        <CommunityCard
          community={community}
          getPathToCommunityDetail={getPathToCommunityDetail}
          key={`featured-comunity-${community.id}`}
        />
      ))}
      title={title}
      queryData={{
        limit: CARDS_IN_ROW,
        hasNextPage: (pageCursor + 1) * CARDS_IN_ROW < (featuredCommunitiesData?.total ?? 0),
        hasPreviousPage: pageCursor > 0,
      }}
      clickActions={{
        onPrevious() {
          setPageCursor((pageCursor) => Math.max(pageCursor - 1, 0));
        },
        async onNext() {
          setPageCursor((pageCursor) => pageCursor + 1);
        },
        onSeeAll: showSeeAllButton ? onSeeAll : undefined,
      }}
      emptyState={<ChannelsEmpty scope="communities" navigateToJoined={navigateToJoinedCommunities} />}
      hideArrows={!featured}
      view={view}
    />
  );
};

export default CommunitiesSlider;
