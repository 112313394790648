import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Button, Col, Text, Title, Row, Link } from 'ui';

type P = {
  onUserClickBack: () => void;
  onReplaceCheckMagicLink: () => void;
  requestAnotherMagicLink: () => void;
  isRequestLoading: boolean;
};

const LinkWasSent = ({ onUserClickBack, requestAnotherMagicLink, onReplaceCheckMagicLink, isRequestLoading }: P) => {
  const { t } = useTranslation();

  const [timeToExpired, setTimeToExpired] = useState(300);
  const [linkWasResent, setLinkWasResent] = useState(false);

  useEffect(() => {
    if (timeToExpired === 0) {
      onReplaceCheckMagicLink();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeToExpired((t) => t - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [onReplaceCheckMagicLink, timeToExpired]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const formattedMinutes = minutes > 0 ? `${minutes}m` : '';
    const formattedSeconds = seconds > 0 ? `${seconds}s` : '';
    return `${formattedMinutes} ${formattedSeconds}`;
  };

  const resendMail = () => {
    setTimeToExpired(300);
    setLinkWasResent(true);
    requestAnotherMagicLink();
  };

  return (
    <Row>
      <Col item={{ span: 24 }}>
        <Title level={2}>{linkWasResent ? t('Log in link resent') : t('Log in link sent')}</Title>
      </Col>
      <Col item={{ span: 24, className: 'onboarding-link-was-sent__was-sent-text' }}>
        <Row item={{ gutter: [0, variables.spaceMd.value] }}>
          <Col item={{ span: 24 }}>
            <Text>
              {t(
                'We have sent you an email with a log in link. Go to your inbox, check your email, and click the button with the log in link. It will redirect you back to this app and automatically log you in.',
              )}
            </Text>
          </Col>
          <Col item={{ span: 24 }}>
            <Text>
              <Trans i18nKey="loginHelpText">
                If you didn&apos;t receive the log in link, please try again or <Link to="/sign-up">sign up.</Link>
              </Trans>
            </Text>
          </Col>

          <Col item={{ span: 24 }}>
            <Row item={{ justify: 'center', align: 'middle' }}>
              <Col item={{ span: 12 }}>
                <Text>
                  {t('Link expires in')}:{' '}
                  <Text type="secondary" strong>
                    {formatTime(timeToExpired)}
                  </Text>
                </Text>
              </Col>
              <Col item={{ span: 12, className: 'onboarding-password-login-form__forgot-link' }}>
                <Button
                  type="link"
                  className="onboarding-link-was-sent__request-new-link-button"
                  onClick={resendMail}
                  disabled={isRequestLoading}
                  icon={isRequestLoading ? <LoadingOutlined /> : null}
                  size="large"
                >
                  {t('Request new link')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col item={{ className: 'onboarding-link-was-sent__buttons-section', span: 24 }}>
        <Button
          type="default"
          className="onboarding-link-was-sent__link-sent-buttons"
          onClick={() => {
            onUserClickBack();
          }}
          size="large"
        >
          {t(`Back to Log in`)}
        </Button>
      </Col>
    </Row>
  );
};

export default LinkWasSent;
