import * as React from 'react';
import { Count } from '../../count';
import { ThemeConsumer } from '../../theme';
import { CommentForm, CommentsList } from '../../comment';
import { can } from '../../../utils/permissions';
import { AutocompleteService } from '../../../services/AutocompleteService';
import Rating from '../../../../features/programs/feed/Rating';
import { Loading } from 'ui';

export class Footer extends React.Component {
  state = {
    loading: false,
  };

  onRequestMentions = async (value) => {
    if (this.props.item.post_in_communities?.length) {
      const response = await AutocompleteService.getCommunityUsers({
        scope: 'communities',
        search: value,
        community_ids: this.props.item.post_in_communities.map((c) => c.id),
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    if (this.props.item.program?.id) {
      const response = await AutocompleteService.getProgramUsers({
        scope: 'programs',
        search: value,
        program_ids: [this.props.item.program.id],
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    return [];
  };

  render() {
    const { actions, item, isProgramPublished, program } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className={`shared-footer ${this.props.padding ? 'shared-footer--padding' : ''}`}>
            {!item.noReplies ? (
              <div className="shared-footer__likes">
                <div onClick={() => this.props.onOpenDetail()}>
                  <Count iconName="notification--comment" style={{ cursor: 'pointer' }}>
                    {item.comments_count > 0 ? item.comments_count : undefined}
                  </Count>
                </div>
                {isProgramPublished && (
                  <div className="shared-footer__rating">
                    <Rating
                      onRate={() => actions.reloadObject(this.props.item.id)}
                      contentObjectId={item.id}
                      rating={item.evaluation}
                      ratingAverage={item.evaluation_rating_average}
                      canRate={can(program, 'Course', 'rateProgramObject')}
                      canViewRating={can(program, 'Course', 'seeFuturePosts')}
                    />
                  </div>
                )}
              </div>
            ) : null}

            {!item.noReplies ? (
              <div style={{ width: '100%' }}>
                {this.props.item.replies.length > 0 ? (
                  <div style={{ padding: '10px 0' }}>
                    <CommentsList
                      item={this.props.item}
                      onCreateComment={actions.createComment}
                      onDeleteObject={actions.deleteObject}
                      onEditObject={actions.updateObject}
                      onLikeObject={actions.likeObject}
                      onReportObject={actions.reportObject}
                      onRequestUserProfile={actions.requestUserProfile}
                      onSortChange={actions.sortChange}
                      onBookmarkObject={actions.bookmarkObject}
                      parent={this.props.item.type}
                      viewer={this.props.viewer}
                      originalItem={this.props.item}
                      course={program ?? item.program}
                    />
                  </div>
                ) : null}
                {this.state.loading ? <Loading /> : null}
                {can(this.props.item, 'ContentObject', 'comment') ? (
                  <div style={{ padding: '15px 25px' }}>
                    <CommentForm
                      viewer={this.props.viewer}
                      onSubmit={async (draft) => {
                        this.setState({ loading: true });
                        const response = await actions.createComment(draft, this.props.item.id);
                        this.setState({ loading: false });
                        return response;
                      }}
                      onRequestMentions={this.onRequestMentions}
                      suffixId={`footer-${this.props.item.id}`}
                      loading={this.state.loading}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </ThemeConsumer>
    );
  }
}
