import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { getCurrentUserTimezone } from 'common/utils/timezones';
import { type LoginDataObject } from './data-objects';

// TODO: types are not correct, return type is user profile not LoginDataObject
export const signUp = async ({
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
  publicCommunityId,
  invitationToken,
}: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  publicCommunityId?: string;
  invitationToken?: string;
}) => {
  const data = {
    user: {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirmation: passwordConfirmation,
      join_community_id: publicCommunityId,
      time_zone: getCurrentUserTimezone(),
    },
  };

  return apiClient.post<LoginDataObject, ApiClientResponse<LoginDataObject>>(routes.signup, data, {
    skipAuth: true,
    params: {
      invitation_token: invitationToken,
    },
  });
};
