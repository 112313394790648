import { getAppConfig } from 'old/atoms/app-config-gate';
import { GoogleLogin } from 'old/pages/page-landing/GoogleLogin';
import { dispatch } from 'old/store';
import variables from 'common/styles/variables.json';
import { Row, Col } from 'ui';

type P = {
  onSuccessLogin: () => void;
};

export const SocialButtons = ({ onSuccessLogin }: P) => {
  const { constants } = getAppConfig();

  const onSocialLoginSuccess = async (data: any) => {
    await dispatch.session.attemptWithSocial(data);
    onSuccessLogin();
  };

  return (
    <Row item={{ gutter: variables.spaceMd.value, className: 'onboarding__social-buttons' }}>
      {constants.GOOGLE_ID ? (
        <Col item={{ span: 24, className: 'onboarding__social-buttons__col' }}>
          <GoogleLogin className="onboarding__social-button" onSuccess={onSocialLoginSuccess} />
        </Col>
      ) : null}
    </Row>
  );
};
