import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type Activity, type Reminder } from 'models/activity';
import type Program from 'models/program';
import { Col, Form, Stepper } from 'ui';

import ActivityFormStep1 from './features/ActivityFormStep1';
import ActivityFormStep2 from './features/ActivityFormStep2';
import ActivityFormStep3 from './features/ActivityFormStep3';
import ActivityFormStep4 from './features/ActivityFormStep4';
import { type ActivityOption, type TaskKindOption } from './features/ActivityTypes';

type P = {
  onSubmit: (activity: Activity) => void;
  program: Program;
  activity: Activity;
  loading: boolean;
  edit?: boolean;
  onChangeStep?: (step: number) => void;
};

const defaultTextTask = (name?: string) => ({ input: 'text', name: name ?? '', kind: 'default' });
const defaultNumberTask = (name?: string, kind?: TaskKindOption) => ({
  input: 'number',
  name: name ?? '',
  kind: kind ?? 'default',
});
const defaultSelectTask = (name?: string) => ({ input: 'select', name: name ?? '', kind: 'default', bulk: [''] });

const getInitialValues = (activity: Activity, edit: boolean) => {
  const timezoneTimes = activity.times?.map((item: any) => {
    return { ...item, time: moment(item.time, 'HH:mm:ssZ') };
  });

  return {
    ...activity,
    tasks: edit
      ? activity.tasks.map((task) => ({
          ...task,
          bulk: task.options?.length
            ? [
                task.options
                  ?.reduce((accumulator: string[], option) => {
                    accumulator.push(option.name);
                    return accumulator;
                  }, [])
                  .join(','),
              ]
            : undefined,
        }))
      : [defaultTextTask()],
    times: timezoneTimes,
    reminders: edit
      ? activity.reminders?.map((reminder: Reminder) => {
          if (reminder.minutes % 1440 === 0) {
            return { ...reminder, minutes: reminder.minutes / 1440, type: 1440 };
          }

          if (reminder.minutes % 60 === 0) {
            return { ...reminder, minutes: reminder.minutes / 60, type: 60 };
          }

          return { ...reminder, type: 1 };
        })
      : [
          { minutes: 7, type: 60 },
          { minutes: 14, type: 60 },
        ],
  };
};

const ActivityForm = ({ onSubmit, program, activity, loading, edit, onChangeStep }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const initialValues = getInitialValues(activity, edit ?? false);
  const [isWithings, setIsWithings] = useState<boolean>(Boolean(initialValues.is_withings));

  const [isDexcom, setIsDexcom] = useState<boolean>(Boolean(initialValues.is_dexcom));
  const disabledEditWithings = Boolean(initialValues.is_withings && edit);
  const disabledEdit = Boolean(program?.status !== 'draft' && edit);

  const [step, setStep] = useState(0);

  const isSelectFeedback = activity?.tasks.length > 1;
  const isNumberFeedback = activity?.tasks.find((task) => task.input === 'number');

  const [activityType, setActivityType] = useState<ActivityOption>(
    activity.isDexcom
      ? 'dexcom-glucose'
      : activity.isBloodPressureType
      ? 'withings-blood-pressure'
      : isSelectFeedback
      ? 'select-feedback'
      : isNumberFeedback
      ? 'numeric-feedback'
      : 'extended-feedback',
  );

  const onSelectActivityType = (type: ActivityOption) => {
    setActivityType(type);
    onSelectWithings(false);

    let tasks: any[];
    switch (type) {
      case 'extended-feedback':
        tasks = [defaultTextTask()];
        break;
      case 'numeric-feedback':
        tasks = [defaultNumberTask()];
        break;
      case 'select-feedback':
        tasks = [defaultSelectTask(), defaultSelectTask(t('Unit')), defaultNumberTask(t('Numeric value'))];
        break;
      case 'withings-blood-pressure':
        tasks = [
          defaultNumberTask('Diastolic Blood Pressure (in mmHg)', 'withings_blood_pressure_diastolic'),
          defaultNumberTask('Systolic Blood Pressure (in mmHg)', 'withings_blood_pressure_systolic'),
        ];
        break;
      case 'dexcom-glucose':
        tasks = [defaultNumberTask('Blood glucose (in mg/dL)', 'dexcom_glucose')];
        break;
    }

    form.setFieldsValue({
      tasks,
      periodicity_type: undefined,
    });
  };

  const onSelectTaskKind = (kind: TaskKindOption) => {
    const isWithings = kind !== 'default' && kind !== 'dexcom_glucose';
    onSelectWithings(isWithings);

    const isDexcom = kind === 'dexcom_glucose';
    onSelectDexcom(isDexcom);
  };

  const onSelectWithings = (isWithings: boolean) => {
    setIsWithings(isWithings);
    form.setFieldsValue({
      is_withings: isWithings,
    });
  };

  const onSelectDexcom = (isDexcom: boolean) => {
    setIsDexcom(isDexcom);
    form.setFieldsValue({
      is_dexcom: isDexcom,
    });
  };

  const isDexcomEnabledProgram = () => {
    return program.withings_devices?.includes(String(process.env.REACT_APP_WITHINGS_EAN_RPM_PROGRAM));
  };

  const getStep = (step: number) => {
    switch (step + 1) {
      case 1:
        return (
          <ActivityFormStep1
            form={form}
            topics={program.topics}
            startTime={program.start_time}
            endTime={program.end_time}
          />
        );
      case 2:
        return (
          <ActivityFormStep2
            disabledEdit={disabledEdit || disabledEditWithings}
            selected={activityType}
            isWithingsEnabled={program?.withings_devices.length > 0}
            isDexcomEnabledProgram={isDexcomEnabledProgram()}
            onSelect={onSelectActivityType}
          />
        );
      case 3:
        return (
          <ActivityFormStep3
            type={activityType}
            disabledEdit={disabledEdit}
            disabledEditWithings={disabledEditWithings}
            onSelectTaskKind={onSelectTaskKind}
          />
        );
      default:
        return <ActivityFormStep4 disabledEdit={disabledEdit} isDexcom={isDexcom} />;
    }
  };

  const onNavigateTo = (step: number) => {
    if ((activityType === 'withings-blood-pressure' || activityType === 'dexcom-glucose') && step === 2) {
      onChangeStep?.(step - 1);
      setStep(step - 1);
      return;
    }

    onChangeStep?.(step);
    setStep(step);
  };

  const onSubmitStep = () => {
    form
      .validateFields()
      .then(() => {
        if (step === 1 && activityType === 'withings-blood-pressure') {
          onSelectWithings(true);
          onChangeStep?.(step + 2);
          setStep(step + 2);
        } else if (step === 1 && activityType === 'dexcom-glucose') {
          onSelectDexcom(true);
          onChangeStep?.(step + 2);
          setStep(step + 2);
        } else if (step < 3) {
          onChangeStep?.(step + 1);
          setStep(step + 1);
        } else {
          onFinish(form.getFieldsValue());
          // TODO Make button continue shadowed when not filled data
        }

        return null;
      })
      .catch((error) => {
        if (error?.errorFields?.[0]?.name) {
          form?.scrollToField(error.errorFields[0].name, {
            behavior: 'smooth',
            block: 'center',
          });
        }
      });
  };

  const onFinish = (data: any) => {
    const cleanedTasks = data.tasks?.map((task: any, index: number) => {
      if (task.input === 'select') {
        const bulkOptions = (task.bulk?.[0] || '')
          .split(',')
          .filter(Boolean)
          .map((option: string, indexOption: number) => ({
            ...activity.tasks?.[index]?.options?.[indexOption],
            name: option,
          }));
        return {
          ...activity.tasks?.[index],
          input: task.input,
          name: task.name,
          kind: task.kind,
          options: bulkOptions,
        };
      }

      return {
        ...activity.tasks?.[index],
        ...task,
      };
    });

    const cleanedActivity = {
      ...activity,
      ...data,
      is_withings: isWithings,
      is_dexcom: isDexcom,
      start_time: data.start_time.utc().format(),
      end_time: data.end_time.utc().format(),
      tasks: program.status === 'published' && edit ? activity.tasks : cleanedTasks,
      times:
        program.status === 'published' && edit
          ? activity.times
          : data.times?.map((item: any, index: number) => ({
              ...activity.times?.[index],
              time: item.time.format('HH:mm:ssZ'),
            })),
      reminders:
        program.status === 'published' && edit
          ? activity.reminders
          : data.reminders?.map((reminder: Reminder, index: number) => ({
              ...activity.reminders?.[index],
              minutes: reminder.minutes * (reminder.type ?? 1),
            })),
      multiple_submissions: activityType === 'select-feedback' || activityType === 'dexcom-glucose' || isWithings,
    };

    onSubmit(cleanedActivity);
  };

  return loading ? (
    <Col item={{ style: { textAlign: 'center', fontSize: 24 } }}>
      <LoadingOutlined />
    </Col>
  ) : (
    <Form initialValues={initialValues} form={form}>
      <Stepper
        currentStep={step}
        numberOfSteps={4}
        getStep={getStep}
        onNavigateTo={onNavigateTo}
        onSubmit={onSubmitStep}
      />
    </Form>
  );
};

export default ActivityForm;
