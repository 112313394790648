import React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { Button, Link } from '../../../ui';
import { AzureService } from '../../services/AzureService';
import { Box } from '../../atoms/box';
import { GoogleService } from '../../services/GoogleService';
import { Icon } from '../../atoms/icon';
import { ProfileSideNav } from '../../pages/page-profile/ProfileSideNav';
import { showAlert } from '../../atoms/alert';
import { store } from '../../store';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { TABS, UserZoneLayout } from '../../layouts/UserZoneLayout';

class PageCalendarSyncRenderer extends React.Component {
  state = {
    loading: null,
    calendars: [],
  };

  async componentDidMount() {
    this.setState({
      calendars: (await store.dispatch.session.getCalendars()).data,
    });
  }

  get redirectUrl() {
    const query = new URLSearchParams(this.props.location.search);

    return query.get('r');
  }

  linkedCalendar = (type) => {
    return this.state.calendars.find((calendar) => calendar.provider === type);
  };

  onOpenDialog(type) {
    return async () => {
      let data = null;

      this.setState({ loading: type });

      switch (type) {
        case 'google':
          data = await GoogleService.login({
            scope: ['https://www.googleapis.com/auth/calendar', 'https://www.googleapis.com/auth/calendar.events'],
            access_type: 'offline',
          });
          break;
        case 'azure':
          data = await AzureService.login({ scope: ['Calendars.ReadWrite', 'offline_access'] });
          break;
        default:
      }

      this.setState({ loading: null });

      if (data) {
        const response = await store.dispatch.session.addCalendar(data);

        if (!response.ok) {
          showAlert({
            type: 'error',
            message: <Trans>Something went wrong, please try again later.</Trans>,
          });
        } else {
          this.setState({
            calendars: this.state.calendars.concat([{ provider: type }]),
          });
        }
      }
    };
  }

  onRemoveAccount(type) {
    return async () => {
      const response = await store.dispatch.session.removeCalendar({ provider: type });

      if (!response.ok) {
        showAlert({
          type: 'error',
          message: <Trans>Something went wrong, please try again later.</Trans>,
        });
      } else {
        this.setState({
          calendars: this.state.calendars.filter((c) => c.provider !== type),
        });
      }
    };
  }

  renderLabel = (text) => {
    return (
      <Text
        style={{
          lineHeight: '1.2',
          color: '#90a4ae',
          fontSize: 13,
          fontWeight: 'bold',
          marginBottom: 15,
          marginTop: 10,
        }}
        component="p"
      >
        {text}
      </Text>
    );
  };

  renderLinkedContent = (key, text, unlinkText) => {
    return (
      <div style={{ borderBottom: '1px solid #ECEFF1', paddingBottom: 20, marginBottom: 34 }}>
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box flexDirection="row" alignItems="center">
            <Icon name="check-full" color="#00E676" size="17px" />
            <Text style={{ lineHeight: '1.2', fontSize: 15, color: '#455a64', marginLeft: 12 }}>{text}</Text>
          </Box>

          <Button onClick={this.onRemoveAccount(key)} danger type="primary">
            Unlink
          </Button>
        </Box>

        {unlinkText ? (
          <span style={{ marginTop: 6, maxWidth: '70%', display: 'inline-block' }}>{unlinkText}</span>
        ) : null}
      </div>
    );
  };

  renderUnlinkedContent = (text, key) => {
    return (
      <Button
        onClick={this.onOpenDialog(key)}
        style={{ borderColor: '#FC612D', color: '#FC612D' }}
        id={`btn-link-${key}`}
      >
        {text}
      </Button>
    );
  };

  renderBackLink = () => {
    return (
      <ThemeConsumer>
        {(theme) =>
          this.redirectUrl ? (
            <Link
              to={this.redirectUrl}
              style={{
                lineHeight: '20px',
                color: theme.color.brand,
                fontSize: 13,
                fontWeight: 'bold',
                textDecoration: 'none',
                marginBottom: 20,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon
                name="pba-arrow-left"
                color="inherit"
                size={25}
                style={{ display: 'inline-block', lineHeight: 1, marginRight: 5 }}
              />
              <Trans>GO BACK TO EVENT DETAIL</Trans>
            </Link>
          ) : null
        }
      </ThemeConsumer>
    );
  };

  render() {
    return (
      <UserZoneLayout navigation={<ProfileSideNav user={this.props.user} />} contentId={TABS.profile}>
        {this.renderBackLink()}

        {this.renderLabel(<Trans>GOOGLE CALENDAR SYNC</Trans>)}

        {this.linkedCalendar('google')
          ? this.renderLinkedContent('google', <Trans>Google Calendar linked</Trans>)
          : this.renderUnlinkedContent(<Trans>Link with my Google Calendar</Trans>, 'google')}

        {this.renderLabel(<Trans>OUTLOOK CALENDAR SYNC</Trans>)}

        {this.linkedCalendar('azure')
          ? this.renderLinkedContent('azure', <Trans>Outlook Calendar linked</Trans>)
          : this.renderUnlinkedContent(<Trans>Link with my Outlook Calendar</Trans>, 'azure')}
      </UserZoneLayout>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const PageCalendarSync = connect(mapState)(PageCalendarSyncRenderer);
