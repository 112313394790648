import { FC } from 'react';
import { User } from '../../common/models';
import { routes } from '../../common/services';
import { Avatar } from '../../ui';

type P = {
  user: User;
};

const UserCard: FC<P> = ({ user }: P) => {
  return (
    <div className="user-card">
      <Avatar
        size={24}
        src={
          new URL(
            routes.chatrooms.media.getAvatarFile(user._id, 'user_avatar', 'medium'),
            process.env.REACT_APP_MEDIA_MODULE_URL,
          ).href
        }
        alt={`${user.getFullName()} Profile photo`}
        className="user-card__avatar"
      >
        {user.getInitials()}
      </Avatar>
      <h3 className="user-card__name">{user.getFullName()}</h3>
    </div>
  );
};

export default UserCard;
