import { type ReduxUser, type ContentObjectRedux } from 'models';
import { ActionBar } from './ActionBar';
import { StatusBar } from './StatusBar';

type P = {
  item: ContentObjectRedux;
  onShowComments?: () => void;
  viewer: ReduxUser;
  view?: 'bookmark' | 'other'; // TODO move to some `type`
};

const ContentObjectFooter = ({ item, viewer, onShowComments, view = 'other' }: P) => {
  return (
    <>
      <StatusBar viewer={viewer} item={item} onShowComments={onShowComments} />
      <ActionBar item={item} viewer={viewer} onShowComments={onShowComments} view={view} />
    </>
  );
};

export default ContentObjectFooter;
