import moment, { type Moment } from 'moment';
import { type UploadedFile } from './uploaded-file';

export type PostInCommunity = {
  id: string;
  name: string;
  organization_id: string;
};

export type Choice = {
  id: string;
  name: string;
  voted: boolean;
  votes_count: number;
};

export type Question = {
  id: string;
  question: string;
  multiple: boolean;
  choices: Choice[];
  question_votes: number;
};

export default class Poll {
  id?: string;
  pinned = false;
  post_in_communities: PostInCommunity[] = [];
  start_time?: Moment;
  end_time?: Moment;
  programs_topic_id?: string;
  title = '';
  description = '';
  html_description = '';
  questions: Question[] = [];
  attachments?: UploadedFile[];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);

      if (data.start_time) {
        this.start_time = moment(data.start_time);
      }

      if (data.end_time) {
        this.end_time = moment(data.end_time);
      }

      this.programs_topic_id = data.programs_topic?.id;
    }

    if (!this.start_time) {
      this.start_time = moment().add(10, 'minutes');
    }

    if (!this.end_time) {
      this.end_time = this.start_time.clone().add(60, 'minutes');
    }
  }
}
