import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { default as PageSharedEvent } from './PageSharedEvent';

const PageSharedEventReduxConnect = ({ viewer }) => <PageSharedEvent viewer={viewer} />;

const mapState = (state) => ({
  viewer: select.session.user(state),
});

export default connect(mapState)(PageSharedEventReduxConnect);
