import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type EventFeedback } from 'models';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
// TODO fix this import from different feature, probably target queries should be here instead
import { getEventsQueryKey, getEventQueryKey as getCommunityEventQueryKey } from 'features/communities/queries';
import { getEventQueryKey } from '..';
import { createEventFeedback } from '../../services';

export const useCreateEventFeedaback = () => {
  const queryClient = useQueryClient();

  return useMutation<
    undefined,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    { id: string; data: EventFeedback }
  >({
    async mutationFn({ id, data }) {
      const response = await createEventFeedback(id, createRequestData(data));

      return response.data;
    },
    onSuccess(_, variables) {
      queryClient.invalidateQueries(getCommunityEventQueryKey({ eventId: variables.id }));
      queryClient.invalidateQueries(getEventQueryKey({ eventId: variables.id }));
      queryClient.invalidateQueries(getEventsQueryKey({ type: 'upcoming' }));
    },
    onError() {
      queryClient.invalidateQueries(getEventsQueryKey({}));
    },
  });
};

const createRequestData = (data: EventFeedback) => {
  const common = {
    audio_rating: data.isAudioOk,
    audio_text: data.audioText,
    video_rating: data.isVideoOk,
    video_text: data.videoText,
  };

  if ('isHelpful' in data) {
    return { ...common, helpful_rating: data.isHelpful, helpful_text: data.helpfulText };
  }

  return {
    ...common,
    recommend_rating: data.recommendationLikelihood,
    provider_rating: data.providerRating,
    recommend_text: data.recommendationLikelihoodText,
    provider_text: data.providerRatingText,
  };
};
