import axios from 'axios';

import { getAppConfig } from '../atoms/app-config-gate';
import { polling, openPopup } from '../utils/socialAuthHelpers';

/**
 * Implementation
 */
export const GoogleService = {
  getDriveFile: async (fileId, accessToken) =>
    axios({
      url: `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${accessToken}` },
    }),

  exportDriveDoc: (params) =>
    axios({
      url: `https://www.googleapis.com/drive/v3/files/${params.fileId}/export?mimeType=${params.mimeType}`,
      method: 'GET',
      responseType: 'blob',
      data: { fileId: params.fileId, mimeType: params.mimeType },
      headers: { Authorization: `Bearer ${params.accessToken}` },
    }),

  login: async (params = {}) => {
    const REDIRECT_URI = params.redirectUri ?? window.location.href;
    const GOOGLE_CLIENT_ID = getAppConfig().constants.GOOGLE_ID;
    const SCOPE = ['profile', 'email', ...(params.scope || [])].join('%20');

    return await new Promise((resolve, reject) => {
      polling(
        openPopup(
          `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&response_type=code${
            params.access_type ? `&access_type=${params.access_type}` : ''
          }`,
          'google-login-popup',
        ),
        (query) => {
          if (query) {
            resolve({
              provider: 'google',
              grant_type: 'authorization_code',
              code: query.get('code'),
              redirect_uri: REDIRECT_URI, // TODO test on dev, there will be probably needed refferrer with invitation_token/community_id
            });
          } else {
            reject(new Error('Failed to authorize with Google'));
          }
        },
        'accounts.google.com',
      );
    });
  },
};
