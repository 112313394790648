import { Button, CompassIcon, Empty } from 'ui';

type P = {
  scope: 'communities' | 'programs'; // TODO replace this with entity type
  navigateToJoined?: () => void;
};

const ChannelsEmpty = ({ scope, navigateToJoined }: P) => (
  <Empty
    image={<CompassIcon />}
    description={{
      title: 'This channel is empty',
      subtitle: 'There is nothing available for this channel yet or you’ve joined all the content already',
    }}
  >
    {/* TODO maybe move to `Empty` as new option */}
    <Button type="link" onClick={navigateToJoined} size="large">
      {scope === 'communities' ? 'Go to Joined communities' : 'Go to Joined programs'}
    </Button>
  </Empty>
);

export default ChannelsEmpty;
