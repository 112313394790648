import withAntIconWrapper from '../icon-wrapper/withAntIconWrapper';
import { ReactComponent as DocumentIconImport } from './doc.svg';
import { ReactComponent as DefaultFileIconImport } from './file.svg';
import { ReactComponent as FolderIconImport } from './folder.svg';
import { ReactComponent as ImgIconImport } from './img.svg';
import { ReactComponent as PdfIconImport } from './pdf.svg';
import { ReactComponent as XlsIconImport } from './xls.svg';

export const DocumentIcon = withAntIconWrapper(DocumentIconImport);
export const DefaultFileIcon = withAntIconWrapper(DefaultFileIconImport);
export const FolderIcon = withAntIconWrapper(FolderIconImport);
export const ImgIcon = withAntIconWrapper(ImgIconImport);
export const PdfIcon = withAntIconWrapper(PdfIconImport);
export const XlsIcon = withAntIconWrapper(XlsIconImport);
