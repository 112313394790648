import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countries, states } from 'common/utils';
import { Section, Title, Form, Row, Col, TextInput, SelectInput, PhoneInput, ModalFormActions } from 'ui';

import { type Option, type DeliveryData } from '../../types';

export type DeliveryFormData = DeliveryData;

type P = {
  user: {
    firstName?: string;
    lastName?: string;
    email?: string;
    country?: string;
    state?: string;
    phone?: string;
    street?: string;
    apt?: string;
    city?: string;
    zip?: string;
  };
  initialData?: DeliveryFormData;
  onBack: () => void;
  onSubmit: (values: DeliveryFormData) => void;
};

const DeliveryInfo = ({ user, onBack, onSubmit, initialData }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<DeliveryFormData>();

  const initialValues: Partial<DeliveryFormData> = initialData ?? {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    country: user.country,
    state: user.state,
    phone: user.phone,
    address1: user.street,
    address2: user.apt,
    city: user.city,
    zip: user.zip,
  };

  const [isStateEnabled, setIsStateEnabled] = useState(initialValues.country === 'US');

  const countryOptions: Option[] = useMemo(
    () => countries.map((country) => ({ label: country.name, value: country.code })),
    [],
  );

  const stateOptions: Option[] = useMemo(() => states.map((state) => ({ label: state.name, value: state.code })), []);

  const handleValuesChange = (_: any, values: DeliveryFormData) => {
    setIsStateEnabled(values.country === 'US');
  };

  const handleFinish = (values: DeliveryFormData) => {
    onSubmit(values);
  };

  return (
    <Form<DeliveryFormData>
      form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      onFinish={handleFinish}
    >
      <Section>
        <fieldset>
          <legend>
            <Title level={4}>{t('Delivery information')}</Title>
          </legend>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'firstName',
                  label: 'First name',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'given-name',
                }}
              />
            </Col>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'lastName',
                  label: 'Last name',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'family-name',
                }}
              />
            </Col>
          </Row>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'email',
                  label: 'Email',
                  rules: [
                    {
                      type: 'email',
                    },
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'email',
                }}
              />
            </Col>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <PhoneInput
                item={{
                  name: 'phone',
                  label: 'Telephone number',
                  rules: [
                    {
                      required: true,
                      message: t(
                        'Error: Phone number is required. Please enter a valid phone number (e.g., 201 555 0123)',
                      ),
                    },
                  ],
                }}
                input={{
                  autocomplete: 'tel',
                }}
              />
            </Col>
          </Row>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <SelectInput
                item={{
                  label: 'Country',
                  name: 'country',
                  'data-test-id': 'select-country',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  showSearch: true,
                  placeholder: t('Country'),
                  options: countryOptions,
                  getOptionLabel: (option) => option.label,
                  getOptionValue: (option) => option.value,
                }}
              />
            </Col>
            {isStateEnabled ? (
              <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
                <SelectInput
                  item={{
                    label: 'State',
                    name: 'state',
                    'data-test-id': 'select-state',
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  input={{
                    showSearch: true,
                    options: stateOptions,
                    getOptionLabel: (option) => option.label,
                    getOptionValue: (option) => option.value,
                  }}
                />
              </Col>
            ) : null}
          </Row>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'address1',
                  label: 'Address 1 (Street with number)',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'address-line1',
                }}
              />
            </Col>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'address2',
                  label: 'Address 2 (Apartment or suite number)',
                }}
                input={{
                  autocomplete: 'address-line2',
                }}
              />
            </Col>
          </Row>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'city',
                  label: 'City',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'address-level2',
                }}
              />
            </Col>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'zip',
                  label: 'Zip code',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'postal-code',
                }}
              />
            </Col>
          </Row>
        </fieldset>
      </Section>
      <ModalFormActions left={{ onClick: onBack }} submit={{ children: 'Continue' }} />
    </Form>
  );
};

export default DeliveryInfo;
