import { LoadingOutlined } from '@ant-design/icons';
import { type WithingsOrderPost } from 'models';
import { useTranslation } from 'react-i18next';

import { type Social } from 'models/content-object';
import type Program from 'models/program';
import { Form, SelectInput, RichTextInput, DateTimeInput, Section, Col, ModalFormActions, TextInput } from 'ui';

type P = {
  submitText?: string;
  onSubmit: (post: WithingsOrderPost<Social>) => void | void;
  program: Program;
  post: WithingsOrderPost<Social>;
  loading: boolean;
};

export type ProgramTopic = {
  id: string;
  name: string;
};

export const WithingsOrderPostForm = ({ onSubmit, post, program, loading, submitText }: P) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = (data: any) => {
    const cleanedPost = {
      ...post,
      ...data,
    };

    onSubmit(cleanedPost);
  };

  return loading ? (
    <Col item={{ style: { textAlign: 'center', fontSize: 24 } }}>
      <LoadingOutlined />
    </Col>
  ) : (
    <Form onFinish={onFinish} initialValues={post} form={form}>
      <Section paddingTop={false} paddingBottom={false}>
        <SelectInput<any>
          item={{
            name: 'program_topic_id',
            label: 'Program module',
            rules: [
              {
                required: true,
                message: t('Please select the module'),
              },
            ],
            'data-test-id': 'select_course-topic',
          }}
          input={{
            placeholder: t('Select module'),
            options: program.topics,
            getOptionLabel: (option: ProgramTopic) => option.name,
            // TODO this is returning undefined potentionally what is not according to getOptionValue, there is now `any` for this case
            getOptionValue: (option: ProgramTopic) => option.id,
          }}
        />
        <DateTimeInput
          item={{
            name: 'publish_at',
            label: 'Publish date',
            rules: [
              {
                required: true,
              },
              () => ({
                async validator(rule, value) {
                  if (value.isBefore(program.start_time)) {
                    throw 'Error: Post publish date must be set after Program start time';
                  }

                  if (value.isAfter(program.end_time)) {
                    throw 'Error: Post publish date must be set before Program end time';
                  }
                },
              }),
            ],
          }}
          input={{
            format: 'MMM D, YYYY [at] h:mm A',
            showTime: { format: 'h:mm A' },
          }}
        />
        <DateTimeInput
          item={{
            name: 'todo_due_date',
            label: 'To-Do due date',
            dependencies: ['publish_at'],
            rules: [
              {
                required: true,
              },
              () => ({
                async validator(rule, value) {
                  if (program) {
                    if (value.isBefore(program.start_time)) {
                      throw 'Error: To-Do due date must be set after Program start time';
                    }

                    if (value.isAfter(program.end_time)) {
                      throw 'Error: To-Do due date must be set before Program end time';
                    }
                  }

                  const publishAt = form.getFieldValue('publish_at');
                  if (value.isBefore(publishAt)) {
                    throw 'Error: Due to date must be set after the publish date';
                  }
                },
              }),
            ],
          }}
          input={{
            format: 'MMM D, YYYY [at] h:mm A',
            showTime: { format: 'h:mm A' },
          }}
        />
        <TextInput
          item={{
            name: 'title',
            label: 'Title',
            rules: [{ required: true }],
          }}
        />
        <RichTextInput
          text={{ name: 'text_content' }}
          html={{
            name: 'html_content',
            label: t('Content'),
            maxWidth: '100%',
            rules: [{ required: true, message: t('This field is required') }],
          }}
        />
      </Section>
      <ModalFormActions submit={{ children: submitText }} />
    </Form>
  );
};
