import { type ContentObjectRedux } from 'models';
import { dispatch } from 'old/store';
import { useMutation } from 'query';
import { likeObject } from '../../services';

export const useLikeObjectMutation = () => {
  return useMutation<unknown, { id: string; item: ContentObjectRedux }>(async ({ id }) => likeObject(id), {
    async onSuccess(_, params) {
      dispatch.feed.storeContentObjectLike({ object: params.item }); // TODO remove this from redux
    },
  });
};
