import { type ContentObjectRedux } from 'models';
import { useHistory } from 'react-router-dom';
import { generateContentObjectPath } from 'common/utils';
import { Button, EyeLineIcon } from 'ui';

type P = {
  item: ContentObjectRedux;
};

const ViewButton = ({ item }: P) => {
  const { push } = useHistory();

  return (
    <div className="view-button">
      <Button
        type="text"
        icon={<EyeLineIcon className="view-button__icon" />}
        className="view-button__button"
        // TODO fix accessibility
        onClick={() => {
          push(generateContentObjectPath(item));
        }}
        size="large"
      >
        View
      </Button>
    </div>
  );
};

export default ViewButton;
