import React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { TranslationService } from '../../services/TranslationService';
import { ThemeConsumer } from '../theme';

class TranslationRenderer extends React.Component {
  state = {
    translatedText: '',
    loading: false,
  };

  get lang() {
    return navigator.language || navigator.userLanguage;
  }

  get settings() {
    return (
      this.props.authUser.settings || {
        translations: {
          language: {
            value: this.lang,
          },
          enable_translations: {
            value: true,
          },
        },
      }
    );
  }

  onTranslate = async () => {
    this.setState({ loading: true });
    const response = await TranslationService.translate({
      id: this.props.id,
      language: this.settings.translations.language.value || 'en',
      text: this.props.text,
      type: this.props.type,
    });

    if (response.ok) {
      this.setState({ translatedText: response.data.translated_text_content });
    }

    this.setState({ loading: false });
  };

  onRemove = () => {
    this.setState({ translatedText: '' });
  };

  render() {
    if (!this.props.authUser) {
      return null;
    }

    return this.settings.translations?.enable_translations?.value ? (
      <ThemeConsumer>
        {(theme) => (
          <div style={{ marginTop: 10 }}>
            {this.state.translatedText ? (
              <p
                style={{
                  color: '#777777',
                  fontSize: 13,
                  padding: 5,
                  borderTop: '1px solid #cccccc',
                  fontWeight: 400,
                  wordBreak: 'break-all',
                }}
              >
                {this.state.translatedText}
              </p>
            ) : null}

            {this.state.translatedText ? (
              <span
                style={{ cursor: 'pointer', color: theme.color.link, fontSize: 13, fontWeight: 400 }}
                onClick={this.onRemove}
              >
                <Trans>Remove Translation</Trans>
              </span>
            ) : (
              <span
                style={{ cursor: 'pointer', color: theme.color.link, fontSize: 13, fontWeight: 400 }}
                onClick={this.onTranslate}
              >
                <Trans>See Translation</Trans> {this.state.loading ? <i className="icon-spin5 animate-spin" /> : null}
              </span>
            )}
          </div>
        )}
      </ThemeConsumer>
    ) : null;
  }
}

const mapState = (state) => ({
  authUser: select.session.user(state),
});

export const Translation = connect(mapState)(TranslationRenderer);
