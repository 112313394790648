import moment, { type Moment } from 'moment';
import { type ContentObjectDataObjectDeprecated } from './service/content-object-deprecated';

export class ContentObjectDeprecated<U> {
  id = '';
  publish_at?: Moment;
  shareIn: U[] = [];
  program_topic_id?: string; // TODO change module id to Social, as Object will be shareable in multiple modules
  constructor(data: ContentObjectDataObjectDeprecated, shareIn: U[]) {
    Object.assign(this, data);
    if (data.publish_at) {
      this.publish_at = moment(data.publish_at);
    }

    this.shareIn = shareIn;
    this.program_topic_id = data?.programs_topic?.id;
  }
}
