import variables from 'common/styles/variables.json';
import { countries, states } from 'common/utils';
import { Col, Fieldset, Form, Row, SelectInput, TextInput } from 'ui';

type AdressFormValues = {
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
  street?: string;
  apt?: string;
};

type P = {
  layout?: 'modal' | 'page';
  isRequired?: boolean;
};

const AdressFieldset = <FormValues extends AdressFormValues>({ layout = 'page', isRequired }: P) => {
  return (
    <Fieldset legend={{ title: 'Address' }}>
      <Row item={{ gutter: variables.spaceXs.value }}>
        <Col item={{ span: 24 }}>
          <SelectInput
            item={{
              name: 'country',
              label: 'Country',
              rules: [
                {
                  required: isRequired,
                },
              ],
            }}
            input={{
              options: countries,
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.code,
            }}
          />
        </Col>
        <Form.Item<FormValues> dependencies={['country']} noStyle>
          {({ getFieldValue }) => {
            return getFieldValue('country') === 'US' ? (
              <Col item={{ span: 24 }}>
                <SelectInput
                  item={{
                    name: 'state',
                    label: 'State',
                    rules: [
                      {
                        required: isRequired,
                      },
                    ],
                  }}
                  input={{
                    options: states,
                    getOptionLabel: (option) => option.name,
                    getOptionValue: (option) => option.code,
                  }}
                />
              </Col>
            ) : null;
          }}
        </Form.Item>
        <Col item={layout === 'page' ? { xs: { span: 24 }, lg: { span: 12 } } : { span: 12 }}>
          <TextInput
            item={{
              name: 'city',
              label: 'City',
              rules: [
                {
                  required: isRequired,
                },
              ],
            }}
            input={{
              autocomplete: 'address-level2',
            }}
          />
        </Col>
        <Col item={layout === 'page' ? { xs: { span: 24 }, lg: { span: 12 } } : { span: 12 }}>
          <TextInput
            item={{
              name: 'zip',
              label: 'ZIP code',
              rules: [
                {
                  required: isRequired,
                },
              ],
            }}
            input={{
              autocomplete: 'postal-code',
            }}
          />
        </Col>
        <Col item={layout === 'page' ? { xs: { span: 24 }, lg: { span: 12 } } : { span: 12 }}>
          <TextInput
            item={{
              name: 'street',
              label: 'Street',
              rules: [
                {
                  required: isRequired,
                },
              ],
            }}
            input={{
              autocomplete: 'address-line1',
            }}
          />
        </Col>
        <Col item={layout === 'page' ? { xs: { span: 24 }, lg: { span: 12 } } : { span: 12 }}>
          <TextInput
            item={{
              name: 'apt',
              label: 'Apartment',
            }}
            input={{
              autocomplete: 'address-line2',
            }}
          />
        </Col>
      </Row>
    </Fieldset>
  );
};

export default AdressFieldset;
