import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';

import { type Login, OauthToken } from '../../models';
import { loginWithPassword } from '../../services';

export const useLoginQuery = () =>
  useMutation<OauthToken, ApiClientResponseError<DefaultResponseErrorDataObject>, Login>({
    async mutationFn(auth: { email: string; password: string; publicCommunityId?: string }) {
      const oauthResponse = await loginWithPassword(auth);

      return new OauthToken({
        accessToken: oauthResponse.data.access_token,
        tokenType: oauthResponse.data.token_type,
        refreshToken: oauthResponse.data.refresh_token,
        expiresIn: oauthResponse.data.expires_in,
        createdAt: oauthResponse.data.created_at,
      });
    },
  });
