import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Button, Col, message, RichTextEditor, Row, Title } from 'ui';
import { useAcceptConsents } from '../../../queries';
import { type Consent } from '../../../types';
import { ContinueReading } from './ContinueReading';

type P = {
  consent: Consent;
  onAccept: () => void;
  onCancel: () => void;
};

const ConsentDetail = ({ consent, onAccept, onCancel }: P) => {
  const { t } = useTranslation();
  const { mutate: acceptConsents, isLoading: isAcceptingConsents } = useAcceptConsents();

  const acceptConsent = () => {
    acceptConsents(
      { consentIds: [consent.id] },
      {
        onSuccess() {
          onAccept();
        },
        onError() {
          message.error(t('Failed to confirm consent: {{name}}', { name: consent.name }));
        },
      },
    );
  };

  return (
    <Row item={{ gutter: [variables.spaceMd.value, variables.spaceMd.value] }}>
      <Col item={{ span: 24 }}>
        <Title level={2}>{consent.name}</Title>
        <RichTextEditor editable={false} data={consent.htmlContent} />
        <ContinueReading />
      </Col>
      <Col item={{ span: consent.consented ? 24 : 12 }}>
        <Button size="large" type="default" onClick={onCancel} block>
          Back
        </Button>
      </Col>
      {consent.consented ? null : (
        <Col item={{ span: 12 }}>
          <Button size="large" type="primary" onClick={acceptConsent} loading={isAcceptingConsents} block>
            Confirm
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default ConsentDetail;
