import { Image } from 'models';
import { uploadFile as uploadFileApi } from '../services';

export const uploadImage = async (
  file: Blob | File | string,
  onProgress: (event: { target: XMLHttpRequest; loaded: number; total: number }) => void,
) => {
  const response = await uploadFileApi(file, onProgress);
  return new Image(response.data);
};
