import { type Form } from 'antd';
import { type NamePath } from 'antd/lib/form/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RichTextEditor, type RichTextData, type RichTextMentions } from '../';
import { StorageService } from '../../old/services/StorageService';

import EmptyInput, { type EmptyInputContentProperties } from './EmptyInput';
import { Item } from './Item';

type ItemProperties = Omit<React.ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  placeholder?: string;
  mentions?: RichTextMentions;
  maxWidth?: string;
  'data-test-id'?: string;
};

type RichTextInputProperties = {
  text: EmptyInputContentProperties<string>;
  html: ItemProperties;
};

type EditorProperties = {
  placeholder?: string;
  value?: any;
  onChange?: any;
  onUploading?: (uploading: boolean) => void;
  onChangeText?: any;
  mentions?: RichTextMentions;
  maxWidth?: string;
  label?: string;
  id?: string;
};

const Editor = ({
  placeholder,
  onChange,
  onUploading,
  value,
  onChangeText,
  mentions,
  maxWidth,
  label,
  id,
}: EditorProperties) => {
  const handleChange = (value: RichTextData) => {
    onChange(value.html);

    if (onChangeText) {
      onChangeText(value.text);
    }
  };

  return (
    <>
      {label ? (
        <div className="ant-col ant-form-item-label">
          <label id={id}>{label}</label>
        </div>
      ) : null}
      <div className="rich-text-input__border">
        <RichTextEditor
          placeholder={placeholder ?? 'Description'}
          data={value}
          // eslint-disable-next-line react/jsx-handler-names
          onRequestUpload={StorageService.send}
          onChange={handleChange}
          onUploading={onUploading}
          mentions={mentions}
          maxWidth={maxWidth}
          labelId={id}
        />
      </div>
    </>
  );
};

const RichTextInput = ({
  text,
  html: { placeholder, mentions, maxWidth, rules = [], label, ...htmlRest },
}: RichTextInputProperties) => {
  const { t } = useTranslation();
  const [oldTextName, setOldTextName] = useState<NamePath | undefined>();
  const [onChangeText, setOnChangeText] = useState<(value: any) => void>();
  const [uploading, setUploading] = useState<boolean>(false);

  const translatedLabel = label ? t(label) : label;

  const onUploading = (uploading: boolean) => {
    setUploading(uploading);
  };

  const isSameTextName = () => {
    if (Array.isArray(oldTextName) && Array.isArray(text.name) && oldTextName.length === text.name.length) {
      return oldTextName.every((item, index) => item === text.name[index]);
    }

    return oldTextName === text.name;
  };

  return (
    <>
      <EmptyInput<string>
        {...text}
        onSetOnChange={(value: any) => {
          if (!onChangeText || !isSameTextName()) {
            setOldTextName(text.name);
            setOnChangeText(() => value);
          }
        }}
      />
      <Item
        {...htmlRest}
        rules={[
          ...rules,
          {
            validator(_rule, _value, callback) {
              if (uploading) {
                callback(t('Please wait for image upload'));
              }

              callback();
            },
          },
        ]}
        style={{ flexWrap: 'nowrap' }}
        data-test-id={htmlRest?.['data-test-id']}
      >
        <Editor
          placeholder={placeholder ? t(placeholder) : placeholder}
          onUploading={onUploading}
          onChangeText={onChangeText}
          mentions={mentions}
          maxWidth={maxWidth}
          label={translatedLabel}
        />
      </Item>
    </>
  );
};

export default RichTextInput;
