import * as React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Prompt } from 'react-router-dom';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import { Box } from '../box';
import { Button } from '../button';
import { RequestError } from '../error';
import { store } from '../../store';
import { withTranslation } from 'react-i18next';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { validator } from '../../utils/validator';
import { TextField, RadioGroup, SelectField, CheckboxGroup, CropperEditorField, AdminsField, TagsField } from '../form';
import { RichTextEditor } from '../rich-text';
import { StorageService } from '../../services/StorageService';
import { FieldError } from '../form';
import { dispatch } from '../../store';
import { notifications, RULES, patientTypes, serviceTypes, telehealthVisitDurations } from './helpers';
import { canManageShowInChannels } from 'permissions';
import { CheckboxWithLabel } from '../form/base-atoms/CheckboxWithLabel';
import { Tooltip, HelpIcon, Section, Alert, Text as TextAD } from 'ui';

class CommunityFormRenderer extends React.Component {
  state = {
    response: null,
  };

  organization = (values, onChangeId) => {
    const organizationId = onChangeId ?? values?.organization_id;

    return (
      this.props.organizationData ||
      this.props.organizationsFromRedux.find((organization) => organization.id === organizationId) ||
      this.props.organizations?.find((organization) => organization.id === organizationId) ||
      this.props.organization ||
      this.props.organizationsFromRedux[0] ||
      this.props.organizations?.[0] ||
      {}
    );
  };

  componentDidMount() {
    if (!this.organization().privacy && this.organization().id) {
      store.dispatch.organizations.findAsync(this.organization().id);
    }
  }

  mapperToArray(obj) {
    return Object.keys(obj).map((key) => (obj[key].value === true ? key : null));
  }

  mapperToObject = (settings, arr) => {
    return settings.reduce((acc, curr) => {
      acc[curr.value] = arr.includes(curr.value);
      return acc;
    }, {});
  };

  buildPushNotificationsValues(settings, values) {
    if (Object.keys(settings).length !== 0) {
      return notifications.map((notification) => {
        const disabling = settings[notification.value];
        return {
          ...notification,
          disabled: !disabling.change_allowed,
          value: values.includes(notification.value) ? notification.value : '',
          data: notification.value,
        };
      });
    }
    return notifications;
  }

  buildEmailNotificationsValues(settings, values) {
    if (Object.keys(settings).length !== 0) {
      return notifications.map((notification) => {
        const disabling = settings[notification.value];
        return {
          ...notification,
          disabled: !disabling.change_allowed,
          value: values.includes(notification.value) ? notification.value : '',
          data: notification.value,
        };
      });
    }
    return notifications;
  }

  deleteCommunity = async () => {
    if (
      await window.confirm(
        this.props.t(
          `You are about to delete this community. By deleting this community, you will also delete all of the community's associated content. Are you sure you want to delete this community?`,
        ),
      )
    ) {
      if (
        await window.confirm(
          `${this.props.t(
            'Are you sure you want to delete this community? If you continue, the community will be permanently deleted. No content related to the community can be recovered.',
          )}`,
        )
      ) {
        this.props.onDelete();
      }
    }
  };

  onValidate = (values) => {
    return validator.validateAll(values, this.props.isDefaultCommunity ? RULES.default : RULES.extended);
  };

  handleDescriptionChange = (formik) => (value) => {
    this.setState(
      {
        html_description: value.html,
        description: value.text,
      },
      () => {
        formik.setFieldValue('html_description', value.html);
        formik.setFieldValue('description', value.text);
      },
    );
  };

  render() {
    const community = this.props.community || {};
    const push_notifications_settings = community.push_notifications_settings || {};
    const email_notifications_settings = community.email_notifications_settings || {};
    const settings = community.settings || {};

    const isEdit = Boolean(this.props.community);

    return (
      <Formik
        enableReinitialize
        initialValues={{
          show_in_channels: community.show_in_channels ?? false,
          featured_in_channels: community.featured_in_channels ?? false,
          name: community.name,
          html_description: community.html_description || '',
          description: community.description || '',
          privacy: community.privacy
            ? community.privacy
            : this.props.organizationData
            ? this.props.organizationData.privacy
            : this.organization().settings?.allow_medical_community || this.organization().privacy === 'secret'
            ? 'secret'
            : 'private',
          cover_photo: community.cover_photo,
          organization_id: community.organization_id || this.props.organization?.id || '',
          tag_ids: community.tags?.map((tag) => tag.id) || [],
          administrators: community.administrators ? community.administrators : [this.props.currentUser],
          push_notifications_settings:
            this.mapperToArray(push_notifications_settings).length !== 0
              ? this.mapperToArray(push_notifications_settings)
              : notifications.map((setting) => setting.value),
          email_notifications_settings:
            this.mapperToArray(email_notifications_settings).length !== 0
              ? this.mapperToArray(email_notifications_settings)
              : notifications.map((setting) => setting.value),
          allow_comments:
            this.props.isMedicalOrganization || this.organization().settings?.allow_medical_community
              ? false
              : settings.allow_comments ?? true,
          medical_community:
            community.settings?.allow_unsubscribe !== undefined
              ? !community.settings.allow_unsubscribe
              : this.props.isMedicalOrganization !== undefined
              ? this.props.isMedicalOrganization
              : this.organization().settings?.allow_medical_community ?? false,
        }}
        validate={this.onValidate}
        onSubmit={async (values, formik) => {
          const push_notifications_settings = this.mapperToObject(notifications, values.push_notifications_settings);
          const email_notifications_settings = this.mapperToObject(notifications, values.email_notifications_settings);

          const result = {
            ...values,
            push_notifications_settings: push_notifications_settings,
            email_notifications_settings: email_notifications_settings,
            admin_ids: values.administrators.map((admin) => admin.id),
            administrators: values.administrators.map((administrator) => ({
              id: administrator.id,
              first_name: administrator.first_name,
              last_name: administrator.last_name,
            })),
            settings: {
              ...(values.settings || {}),
              allow_comments: values.allow_comments && !values.medical_community,
              allow_posts: values.allow_comments && !values.medical_community,
              allow_invites: !values.medical_community,
              allow_unsubscribe: !values.medical_community,
              view_members: !values.medical_community,
              allowed_events_admin: !values.medical_community
                ? ['conference', 'webinar']
                : ['conference', 'webinar', 'telehealth_visit'],
              allowed_events_member: !values.medical_community ? ['conference', 'webinar'] : ['telehealth_visit'],
            },
            meta: values.medical_community
              ? {
                  patient_types: patientTypes,
                  service_types: serviceTypes,
                  telehealth_visit_durations: telehealthVisitDurations,
                }
              : undefined,
          };

          if (this.organization(values).privacy === 'secret') {
            result.privacy = 'secret';
          }

          formik.setSubmitting(true);

          this.setState({ response: null });
          formik.resetForm(values);
          const response = await this.props.onSubmit(result);
          this.setState({ response: response });

          if (response?.ok) {
            this.props.onSuccess(response);
            await dispatch.session.fetchUser();
          } else {
            this.props.onError(response);
          }

          formik.setSubmitting(false);
        }}
      >
        {(formik) => (
          <ThemeConsumer>
            {(theme) => (
              <React.Fragment>
                <Prompt
                  when={Object.keys(formik.touched).length > 0}
                  message={() => this.props.t('Do you wish to discard changes?')}
                />
                <div style={{ marginTop: 20 }}>
                  <CropperEditorField name="cover_photo" label={<Trans>Hero Image</Trans>} id="cover-photo" />
                </div>

                <TextField
                  name="name"
                  placeholder={this.props.t('Name of the community')}
                  label={<Trans>Name of the community</Trans>}
                  id={'title'}
                />
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    borderBottom: '1px solid rgb(200, 199, 204)',
                    paddingBottom: 10,
                  }}
                >
                  <Text
                    weight="100"
                    size="12px"
                    component="p"
                    color={theme.color.lightGray}
                    style={{ padding: '0 5px' }}
                  >
                    <Trans>Description</Trans>
                  </Text>
                  <RichTextEditor
                    placeholder={this.props.t('Description')}
                    data={community.html_description || ''}
                    onRequestUpload={StorageService.send}
                    onChange={this.handleDescriptionChange(formik)}
                    maxWidth={'645px'}
                  />
                  <FieldError name="html_description" />
                </div>
                <div>
                  <AdminsField
                    name="administrators"
                    currentUser={this.props.currentUser}
                    removeAdmin={(admin) => {
                      const admins = formik.values.administrators.filter((item) => admin.id !== item.id);
                      formik.setFieldValue('administrators', admins);
                      formik.setFieldTouched('administrators', true);
                    }}
                    placeholder={this.props.t('Search User')}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <SelectField
                    name="organization_id"
                    disabled={this.props.isDefaultCommunity || isEdit}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    label={<Trans>Organization</Trans>}
                    options={(this.props.organizationsFromRedux.length > 0
                      ? this.props.organizationsFromRedux
                      : this.props.organizations
                    ).map((option) => ({
                      label: option.name,
                      value: option.id,
                    }))}
                    id={'select-organization'}
                    placeholder={
                      (this.props.isDefaultCommunity ? this.organization()?.name : null) ??
                      this.props.t('Please, select an organization')
                    }
                    mergeOnChange={true}
                    onChange={(event) => {
                      const organization = this.organization(undefined, event.target.value);
                      const isMedicalNewOrganization = Boolean(organization.settings?.allow_medical_community);
                      const isOrganizationSecret = Boolean(organization.privacy === 'secret');

                      formik.setFieldValue('allow_comments', !isMedicalNewOrganization);
                      formik.setFieldValue(
                        'privacy',
                        isMedicalNewOrganization || isOrganizationSecret ? 'secret' : 'private',
                      );
                      formik.setFieldValue('medical_community', isMedicalNewOrganization);
                      formik.setFieldValue('tag_ids', []);
                      formik.setFieldValue('show_in_channels', false);
                    }}
                  />
                </div>
                {this.buildPushNotificationsValues(
                  push_notifications_settings,
                  formik.values.push_notifications_settings,
                ).every((setting) => setting.disabled) &&
                this.buildPushNotificationsValues(
                  push_notifications_settings,
                  formik.values.push_notifications_settings,
                ).every((setting) => setting.disabled) ? null : (
                  <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-xs-6 community-form__email-settings">
                      <Text
                        weight="600"
                        size="14px"
                        style={{ marginTop: 20, paddingBottom: 20 }}
                        component="p"
                        color={theme.color.lightGray}
                      >
                        <Trans>Email Notifications</Trans>
                      </Text>
                      <CheckboxGroup
                        name="email_notifications_settings"
                        values={this.buildEmailNotificationsValues(
                          email_notifications_settings,
                          formik.values.email_notifications_settings,
                        )}
                      />
                    </div>

                    <div className="col-xs-6 community-form__push-settings">
                      <Text
                        weight="600"
                        size="14px"
                        style={{ marginTop: 20, paddingBottom: 20 }}
                        component="p"
                        color={theme.color.lightGray}
                      >
                        <Trans>Push Notifications</Trans>
                      </Text>
                      <CheckboxGroup
                        name="push_notifications_settings"
                        values={this.buildPushNotificationsValues(
                          push_notifications_settings,
                          formik.values.push_notifications_settings,
                        )}
                      />
                    </div>
                  </div>
                )}
                <Text
                  weight="600"
                  size={14}
                  style={{ marginTop: 30, paddingBottom: 10 }}
                  component="p"
                  color={theme.color.lightGray}
                >
                  <Trans>Community Member Permissions</Trans>
                </Text>
                <RadioGroup
                  name="allow_comments"
                  values={[
                    {
                      value: true,
                      label: <Trans>Enable Posts, Comments and Replies</Trans>,
                      disabled:
                        (this.props.isDefaultCommunity && this.props.isMedicalOrganization) ||
                        formik.values.medical_community ||
                        (this.props.organization
                          ? this.props.organization.settings?.allow_medical_community
                            ? formik.values.medical_community
                            : false
                          : this.organization(formik.values).settings?.allow_medical_community) ||
                        (isEdit && !this.props.currentUser.superadmin),
                    },
                    {
                      value: false,
                      label: <Trans>Disable Posts, Comments and Replies</Trans>,
                      disabled:
                        (this.props.isDefaultCommunity && this.props.isMedicalOrganization) ||
                        formik.values.medical_community ||
                        (this.props.organization
                          ? this.props.organization.settings?.allow_medical_community
                            ? formik.values.medical_community
                            : false
                          : this.organization(formik.values).settings?.allow_medical_community) ||
                        (isEdit && !this.props.currentUser.superadmin),
                    },
                  ]}
                />
                <React.Fragment>
                  <Text
                    weight="600"
                    size={14}
                    style={{ marginTop: 30, paddingBottom: 10 }}
                    component="p"
                    color={theme.color.lightGray}
                  >
                    <Trans>Privacy</Trans>
                  </Text>

                  <RadioGroup
                    inline
                    name="privacy"
                    values={[
                      {
                        value: 'public',
                        label: (
                          <>
                            <Trans>Public</Trans>
                            <Tooltip
                              item={{
                                title: (
                                  <>
                                    <Trans>Anyone in the BigHeart ecosystem can explore and join public communities.</Trans>
                                    {!this.props.currentUser.superadmin ? (
                                      <>
                                        <br />
                                        <br />
                                        <Trans>This option is available only for super admins. If you need to set this community to Public, please contact a super admin for assistance.</Trans>
                                      </>
                                    ) : null}
                                  </>
                                )
                              }}
                            >
                              <span style={{ paddingLeft: 8 }}>
                                <HelpIcon style={{ width: '17px', height: 'auto' }} />
                              </span>
                            </Tooltip>
                          </>
                        ),
                        disabled:
                          !this.props.currentUser.superadmin ||
                          this.props.isDefaultCommunity ||
                          this.organization(formik.values).privacy === 'secret' ||
                          this.organization(formik.values).settings?.allow_medical_community,
                      },
                      {
                        value: 'private',
                        label: (
                          <>
                            <Trans>Private</Trans>{' '}
                            <Tooltip
                              item={{
                                title:
                                  'Anyone in the BigHeart ecosystem can explore and request to join private communities. The community admin approves or denies the request to join.',
                              }}
                            >
                              <span style={{ paddingLeft: 8 }}>
                                <HelpIcon style={{ width: '17px', height: 'auto' }} />
                              </span>
                            </Tooltip>
                          </>
                        ),
                        disabled:
                          this.props.isDefaultCommunity ||
                          this.organization(formik.values).privacy === 'secret' ||
                          this.organization(formik.values).settings?.allow_medical_community ||
                          (isEdit && !this.props.currentUser.superadmin),
                      },
                      {
                        value: 'secret',
                        label: (
                          <>
                            <Trans>Secret</Trans>
                            <Tooltip
                              item={{
                                title:
                                  'Members join a secret community by invitation only. It is not searchable on the platform.',
                              }}
                            >
                              <span style={{ paddingLeft: 8 }}>
                                <HelpIcon style={{ width: '17px', height: 'auto' }} />
                              </span>
                            </Tooltip>
                          </>
                        ),
                        disabled:
                          this.props.isDefaultCommunity ||
                          this.organization(formik.values).privacy === 'secret' ||
                          this.organization(formik.values).settings?.allow_medical_community ||
                          (isEdit && !this.props.currentUser.superadmin),
                      },
                    ]}
                  />
                  {this.props.isDefaultCommunity ? (
                    <Text>
                      {formik.values.privacy === 'public' ? (
                        <Trans>Default community always needs to be public.</Trans>
                      ) : formik.values.privacy === 'secret' ? (
                        <Trans>Default community always needs to be secret.</Trans>
                      ) : null}
                    </Text>
                  ) : null}
                </React.Fragment>
                {canManageShowInChannels({
                  viewer: this.props.currentUser,
                  organizationId: this.organization(formik.values)?.id,
                }) && formik.values.privacy !== 'secret' ? (
                  <>
                    <Text
                      weight="600"
                      size={14}
                      style={{ marginTop: 30, paddingBottom: 10 }}
                      component="p"
                      color={theme.color.lightGray}
                    >
                      <Trans>Channels</Trans>
                    </Text>
                    <Section lineBottom={false}>
                      <Alert
                        message={
                          <>
                            <TextAD>{this.props.t('The selected organization is using')}</TextAD>{' '}
                            <TextAD strong>
                              {this.props.t(this.organization(formik.values)?.custom_channels ? 'custom' : 'default')}
                            </TextAD>{' '}
                            <TextAD>{this.props.t('channels')}</TextAD>
                          </>
                        }
                        type="info"
                        showIcon
                      />
                    </Section>
                    <TagsField
                      options={
                        this.organization(formik.values)?.tags?.map((tagItem) => ({
                          value: tagItem.id,
                          label: tagItem.name,
                        })) ?? []
                      }
                      name="tag_ids"
                      label={<Trans>Tags</Trans>}
                    />
                    <CheckboxWithLabel
                      label={
                        <>
                          <Trans>Show community in Channels</Trans>
                          <Tooltip
                            item={{
                              title:
                                'Community will display in your organization’s channels. Everyone in your organization will be able join this community.',
                            }}
                          >
                            <span style={{ paddingLeft: 8 }}>
                              <HelpIcon />
                            </span>
                          </Tooltip>
                        </>
                      }
                      name="show_in_channels"
                      inputProps={{
                        onChange: () => {
                          formik.setFieldValue('show_in_channels', !formik.values.show_in_channels);
                          if (!formik.values.show_in_channels) {
                            formik.setFieldValue('featured_in_channels', false);
                          }
                        },
                        value: formik.values.show_in_channels,
                        checked: formik.values.show_in_channels,
                      }}
                    />
                    {formik.values.show_in_channels && (
                      <>
                        <CheckboxWithLabel
                          label={
                            <>
                              <Trans>Pin Community as featured in Channels</Trans>
                              <Tooltip
                                item={{
                                  title: 'Community will appear as featured in the Channels',
                                }}
                              >
                                <span style={{ paddingLeft: 8 }}>
                                  <HelpIcon />
                                </span>
                              </Tooltip>
                            </>
                          }
                          name="featured_in_channels"
                          inputProps={{
                            onChange: () =>
                              formik.setFieldValue('featured_in_channels', !formik.values.featured_in_channels),
                            value: formik.values.featured_in_channels,
                            checked: formik.values.featured_in_channels,
                          }}
                        />
                      </>
                    )}
                  </>
                ) : null}
                <Text
                  weight="600"
                  size={14}
                  style={{ marginTop: 30, paddingBottom: 10 }}
                  component="p"
                  color={theme.color.lightGray}
                >
                  <Trans>Community type</Trans>
                </Text>
                <RadioGroup
                  name="medical_community"
                  values={[
                    {
                      value: true,
                      label: <Trans>Medical</Trans>,
                      disabled:
                        isEdit ||
                        (this.props.isDefaultCommunity && !this.props.isMedicalOrganization) ||
                        !this.organization(formik.values).settings?.allow_medical_community,
                      onChange: () => formik.setFieldValue('allow_comments', false),
                    },
                    {
                      value: false,
                      label: <Trans>Non-medical</Trans>,
                      disabled:
                        isEdit ||
                        (this.props.isDefaultCommunity && !this.props.isMedicalOrganization) ||
                        !this.organization(formik.values).settings?.allow_medical_community,
                    },
                  ]}
                />
                <RequestError response={this.state.response} />
                <Box flex="1" flexDirection="row" justifyContent="space-between" style={{ margin: '30px 0' }}>
                  <Button
                    size="lg"
                    title={isEdit ? <Trans>Update</Trans> : <Trans>Submit</Trans>}
                    type="submit"
                    color={theme.color.brand}
                    onClick={() => {
                      if (Object.keys(formik.errors).length > 0) {
                        window.scrollTo(0, 0);
                      }
                      formik.submitForm();
                    }}
                    textColor="white"
                    disabled={formik.isSubmitting}
                    style={{ padding: '0 40px' }}
                    id="btn_submit-community"
                  />
                  {isEdit && !community.default && (
                    <Button
                      size="lg"
                      title={this.props.t('Delete')}
                      type="submit"
                      color={theme.color.red}
                      onClick={this.deleteCommunity}
                      textColor="white"
                      style={{ padding: '0 40px' }}
                      id="btn_delete-community"
                    />
                  )}
                </Box>
              </React.Fragment>
            )}
          </ThemeConsumer>
        )}
      </Formik>
    );
  }
}

const mapState = (state, props) => ({
  organizationsFromRedux: select.adminOrganizations.get(state),
  organization: select.organizations.find(state, props.match.params.organizationId),
});

export const CommunityForm = withTranslation()(withRouter(connect(mapState)(CommunityFormRenderer)));
