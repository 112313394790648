import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type RequestMagicLink } from '../models';

export const requestMagicLink = async ({ email, redirectUrl }: RequestMagicLink) => {
  const data = {
    email,
    redirect_url: redirectUrl,
  };

  return apiClient.post<undefined, ApiClientResponse<undefined>>(routes.oauth.magicLink, data, { skipAuth: true });
};
