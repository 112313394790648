import { type AssetsDataObject, type FileAssetsDataObject, type FolderAssetDataObject } from 'models/service';

export const FileType = 'file';
export const FolderType = 'folder';

export class Asset {
  id: string;
  name: string;
  updatedAt?: Date;
  parentId: string | null;

  constructor(data: AssetsDataObject) {
    this.id = data.id;
    this.name = data.name;
    this.updatedAt = new Date(data.updated_at);
    this.parentId = data.parent_id;
  }
}

export class FileAsset extends Asset {
  type: typeof FileType;
  extension: string;
  downloadUrl: string;
  size: number;
  contentType: string;

  constructor(data: FileAssetsDataObject) {
    super(data);

    this.type = data.type;
    this.extension = data.extension;
    this.downloadUrl = data.metadata.download_url;
    this.size = data.metadata.byte_size;
    this.contentType = data.metadata.content_type;
  }
}

export class FolderAsset extends Asset {
  type: typeof FolderType;

  constructor(data: FolderAssetDataObject) {
    super(data);

    this.type = data.type;
  }
}
