import { LoadingOutlined } from '@ant-design/icons';

import { type Activity, type Log } from 'models/activity';
import { Col, Form, Button, Section } from 'ui';
import { ActivityTasksFormInput } from './features';

type P = {
  onSubmit: (taskLogs: Log[]) => Promise<void> | void;
  activity: Activity;
  loading: boolean;
  isProgramPublished: boolean;
  isBookmark: boolean;
};

export type ProgramTopic = {
  id: string;
  name: string;
};

const ActivityTasksForm = ({ onSubmit, activity, loading, isProgramPublished, isBookmark }: P) => {
  const [form] = Form.useForm();

  const onFinish = async (data: any) => {
    const cleanedTasks = data.tasks.map((task: any) => {
      return Object.entries(task).map(([key, value]) => {
        if (activity.tasks.some((task) => task.id === key && task.input === 'select')) {
          return { activity_task_log: { activity_task_id: key, activity_task_option_id: String(value) } };
        }

        return { activity_task_log: { activity_task_id: key, value } };
      }) as Log[];
    });

    await onSubmit(cleanedTasks);
  };

  return loading ? (
    <Col item={{ className: 'activity-task-form__loading_wrapper' }}>
      <LoadingOutlined />
    </Col>
  ) : (
    <Form onFinish={onFinish} form={form} initialValues={{ tasks: [{}] }}>
      <ActivityTasksFormInput
        name="tasks"
        tasks={activity.tasks}
        isBookmark={isBookmark}
        isMultipleSubmissionsAllowed={activity.multiple_submissions}
      />

      {isProgramPublished ? (
        <Section paddingTop={false} paddingBottom={false} lineTop={false} lineBottom={false}>
          <Button disabled={isBookmark} htmlType="submit" type="primary">
            Submit activity
          </Button>
        </Section>
      ) : null}
    </Form>
  );
};

export default ActivityTasksForm;
