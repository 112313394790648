import * as React from 'react';
import { Trans } from 'react-i18next';

import { Text } from 'old/atoms/text';
import { Attachment } from './Attachment';

export class Renderer extends React.Component {
  render() {
    const { item, style } = this.props;

    return item.attachments?.length ? (
      <div className="renderer-core__attachments" style={style}>
        <Text style={{ textTransform: 'uppercase' }} size="11px" lineHeight="36px" weight="bold">
          <Trans>Attachments</Trans>
        </Text>
        <div className="renderer-core__attachments-wrapper">
          {item.attachments.map((attachment) => (
            <Attachment key={attachment.id} attachment={attachment} />
          ))}
        </div>
      </div>
    ) : null;
  }
}

export const Attachments = Renderer;
