import cn from 'classnames';
import { endOfDay, isAfter } from 'date-fns';
import { type TimeTagMode } from 'features/todos/types';
import { Tag, TimeLineIcon, RadarLineIcon } from 'ui';
import { TodoWidgetItemTimeText } from './TodoWidgetItemTimeText';

type P = {
  mode: TimeTagMode;
  time: Date;
};

const TodoWidgetItemTimeTag = ({ time, mode }: P) => {
  if (mode === 'hidden' || isAfter(time, endOfDay(new Date()))) {
    return null;
  }

  const isLive = mode === 'live';

  return (
    <Tag
      className={cn('todo-widget-time-tag', {
        'todo-widget-time-tag--live': isLive,
      })}
    >
      {isLive ? <RadarLineIcon className="todo-widget-time-tag__icon--live" /> : <TimeLineIcon />}
      <TodoWidgetItemTimeText
        className={cn('todo-widget-time-tag__text', { 'todo-widget-time-tag__text--live': isLive })}
        time={time}
        mode={mode}
      />
    </Tag>
  );
};

export default TodoWidgetItemTimeTag;
