import { type Gender, type ReduxUser } from 'models';
import variables from 'common/styles/variables.json';
import { AdressFieldset, NameFieldset } from 'features/user-profile';
import {
  BirthDateInput,
  Col,
  Form,
  getBirthDateTypeValue,
  ModalFormActions,
  Paragraph,
  PhoneInput,
  Row,
  SelectInput,
  Space,
  type BirthDateType,
} from 'ui';

export type PersonalInfoFormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  birthDate: BirthDateType;
  gender: string;
  country: string;
  state: string;
  city: string;
  zip: string;
  street: string;
  apt: string;
};

const getInitialValues = ({ viewer }: { viewer: ReduxUser }): Partial<PersonalInfoFormValues> => ({
  firstName: viewer.first_name,
  lastName: viewer.last_name,
  email: viewer.email,
  phone: viewer.phone,
  birthDate: getBirthDateTypeValue(viewer.birthdate),
  gender: viewer.gender?.id,
  country: viewer.country,
  state: viewer.state,
  city: viewer.city,
  zip: viewer.zip,
  street: viewer.street,
  apt: viewer.apt,
});

type P = {
  viewer: ReduxUser;
  genders: Gender[];
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: PersonalInfoFormValues) => void;
};

const PersonalInfoForm = ({ viewer, genders, isLoading, onCancel, onSubmit }: P) => {
  const [form] = Form.useForm<PersonalInfoFormValues>();

  // TODO missing `language`
  return (
    <Form<PersonalInfoFormValues> form={form} initialValues={getInitialValues({ viewer })} onFinish={onSubmit}>
      <Space size="middle" direction="vertical">
        <Paragraph>
          Please confirm you are using your legal name and the information below is correct. After confirmation, any
          changes will be reflected in your profile
        </Paragraph>
        <Row item={{ gutter: variables.spaceXs.value }}>
          <Col item={{ span: 24 }}>
            <NameFieldset<PersonalInfoFormValues> withMiddleName={false} />
          </Col>
          <Col item={{ span: 24 }}>
            <PhoneInput
              item={{
                name: 'phone',
                label: 'Phone number',
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
            />
          </Col>
          <Col item={{ span: 24 }}>
            <BirthDateInput item={{ label: 'Date of birth', name: 'birthDate' }} />
          </Col>
          <Col item={{ span: 24 }}>
            <SelectInput
              item={{
                name: 'gender',
                label: 'Gender',
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              input={{
                options: genders,
                getOptionLabel: (option) => option.name,
                getOptionValue: (option) => option.id,
              }}
            />
          </Col>
          <Col item={{ span: 24 }}>
            <AdressFieldset<PersonalInfoFormValues> layout="modal" isRequired />
          </Col>
          <Col item={{ span: 24 }}>
            <ModalFormActions
              right={{ onClick: onCancel, children: 'Back' }}
              submit={{
                children: 'Confirm',
                loading: isLoading,
              }}
            />
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

export default PersonalInfoForm;
