import React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

// Layout
import { AppLayout } from '../../layouts/AppLayout';
import { RegularFeed } from '../../atoms/feed';
import { JournalEditorTrigger } from '../../atoms/post-editor';
import { Sidebar } from '../../atoms/sidebar';

import { getFeedKey } from '../../models/feed';
import { Card, Title } from 'ui';
import { useFeedbackOptions } from 'pages/hooks';

class Renderer extends React.Component {
  static defaultProps = {
    community: null,
  };

  state = {
    draft: undefined,
  };

  componentDidMount() {
    this.props.resetFeed();
  }

  navigateToEvents = () => {
    this.props.history.push(`/events`);
  };

  initiatePostEditing = async (object) => {
    this.setState({ draft: object });
  };

  render() {
    return (
      <AppLayout
        center={
          <RegularFeed
            community={this.props.community}
            limit={7}
            viewer={this.props.viewer}
            onInitiatePostEditing={this.initiatePostEditing}
            reloadingPost={this.props.reloadingPost}
            type="journal"
            render={(feedNode, actions) => (
              <React.Fragment>
                <Title level={1}>Journal</Title>
                <Card shrinked>
                  <JournalEditorTrigger
                    draft={this.state.draft}
                    viewer={this.props.viewer}
                    onRequestClose={() => {
                      this.setState({ draft: undefined });
                    }}
                    onSubmit={async (object) => {
                      if (object.id !== undefined) {
                        await actions.updateObject(object);
                      } else {
                        await actions.createObject(object);
                      }
                      this.setState({ draft: undefined });
                    }}
                  />
                </Card>
                <div>{feedNode}</div>
              </React.Fragment>
            )}
          />
        }
        right={
          <Sidebar
            viewer={this.props.viewer}
            navigateToEvents={this.navigateToEvents}
            numberOfNotifications={this.props.badges.total}
            unreadMessages={this.props.unreadMessages}
            feedbackOptions={this.props.feedbackOptions}
          />
        }
      />
    );
  }
}

const mapDispatch = (dispatch, props) => {
  const feedName = getFeedKey(props);
  return {
    resetFeed: () => {
      dispatch.feed.reset();
    },
    reloadContentObject: (object) => {
      dispatch.feed.reloadAsync({ feed: feedName, postId: object.id });
    },
  };
};

const mapState = (state, props) => {
  return {
    viewer: select.session.user(state),
    reloadingPost: state.loading.effects.feed.reloadAsync,
    badges: select.notifications.badges(state),
    unreadMessages: select.session.unreadMessages(state),
  };
};

const PageJournalFunctional = (props) => {
  const feedbackOptions = useFeedbackOptions();
  return <Renderer {...props} feedbackOptions={feedbackOptions} />;
};

export const PageJournal = connect(mapState, mapDispatch)(PageJournalFunctional);
