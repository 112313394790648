import { isAfter } from 'date-fns';
import { dispatch } from 'old/store';
import { type OauthDataObject } from 'models/service';

import apiClient, { type ApiClientResponse } from './api';
import { routes } from './routes';

type TokenType = 'accessToken' | 'accessTokenCreatedAt' | 'accessTokenExpiresIn' | 'refreshToken';

type QueueItem<T = undefined> = {
  resolve: (result: T) => void;
  reject: (error: Error | null) => void;
};

let isRefreshingToken = false;
let tokenWaitingQueue: Array<QueueItem<OauthDataObject>> = [];

const processRefreshignQueue = (data: OauthDataObject | null, error: Error | null = null) => {
  for (const prom of tokenWaitingQueue) {
    data ? prom.resolve(data) : prom.reject(error);
  }

  tokenWaitingQueue = [];
};

const pushToGetTokenQueue = async () => {
  const data = await new Promise<OauthDataObject>((resolve, reject) => {
    tokenWaitingQueue.push({ resolve, reject });
  });

  if (!data) {
    throw new Error('No token data');
  }

  return data;
};

export const getNewTokenOnce = async (refreshToken: string) => {
  if (isRefreshingToken) {
    return pushToGetTokenQueue();
  }

  try {
    isRefreshingToken = true;
    const response = await getFreshAccessToken(refreshToken);
    processRefreshignQueue(response.data);
    return response.data;
  } catch (error: any) {
    processRefreshignQueue(null, error);
    throw error;
  } finally {
    isRefreshingToken = false;
  }
};

const getToken = (type: TokenType) => window.localStorage.getItem(type);
const setToken = (type: TokenType, token = '') => {
  window.localStorage.setItem(type, token);
};

export const clearTokens = () => {
  setToken('accessToken', '');
  setToken('refreshToken', '');
  setToken('accessTokenExpiresIn', '');
  setToken('accessTokenCreatedAt', '');
};

export const storeTokensData = (data: OauthDataObject) => {
  setToken('accessToken', data.access_token);
  setToken('refreshToken', data.refresh_token);
  setToken('accessTokenExpiresIn', String(data.expires_in));
  setToken('accessTokenCreatedAt', String(data.created_at));
};

export const getTokensData = () => {
  const accessToken = getToken('accessToken');
  const refreshToken = getToken('refreshToken');
  const accessTokenExpiresIn = getToken('accessTokenExpiresIn');
  const accessTokenCreatedAt = getToken('accessTokenCreatedAt');

  if (accessToken && refreshToken && accessTokenExpiresIn && accessTokenCreatedAt) {
    return {
      accessToken,
      refreshToken,
      accessTokenExpiresIn: Number(accessTokenExpiresIn),
      accessTokenCreatedAt: Number(accessTokenCreatedAt),
    };
  }

  return null;
};

export const getAccessToken = async () => {
  const oauthData = getTokensData();
  if (!oauthData) {
    return null;
  }

  const expiration = new Date((oauthData.accessTokenCreatedAt + oauthData.accessTokenExpiresIn - 60) * 1000);
  const now = new Date(Date.now());

  if (isAfter(now, expiration)) {
    try {
      const tokenData = await getNewTokenOnce(oauthData.refreshToken);
      dispatch.session.setOauthResponse(tokenData);

      return tokenData.access_token;
    } catch (error) {
      // Refresh was not success, pass old token and continue
      console.error(error);
    }
  }

  return oauthData.accessToken;
};

export const getFreshAccessToken = async (refreshToken: string) =>
  apiClient.post<OauthDataObject, ApiClientResponse<OauthDataObject>>(
    routes.oauth.token,
    {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    },
    {
      skipAuth: true,
    },
  );
