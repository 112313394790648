import deprecatedApiClient, {
  type ApiClientResponse as DeprecatedApiClientResponse,
} from 'common/services/deprecatedApi';
import { type LoginTokenDataObject } from 'models/service';

export const generateWithingsCodeRequestUrl = () => {
  const query = new URLSearchParams({
    response_type: 'code',
    state: 'something',
    client_id: `${process.env.REACT_APP_WITHINGS_CLIENT_ID}`,
    scope: 'user.activity,user.metrics',
    redirect_uri: `${window.location.origin}/profile/withings-sync`,
  });

  return `https://account.withings.com/oauth2_user/authorize2?${query.toString()}`;
};

export const linkWithings = async (code: string) => {
  return deprecatedApiClient.post<any, DeprecatedApiClientResponse<any>>(`/withings/access_token`, {
    code,
    redirect_uri: `${window.location.origin}/profile/withings-sync`,
  });
};

export const unlinkWithings = async (withingsUserId: string) => {
  return deprecatedApiClient.delete<any, DeprecatedApiClientResponse<any>>(`/withings/access_token`, {
    data: { withings_user_id: withingsUserId },
  });
};

export const getLoginToken = async () => {
  return deprecatedApiClient.get<LoginTokenDataObject, DeprecatedApiClientResponse<LoginTokenDataObject>>(
    `/users/login_token`,
  );
};
