import { useTranslation } from 'react-i18next';
import { Text } from 'ui';

type P = {
  text?: string;
};

const Separator = ({ text }: P) => {
  const { t } = useTranslation();

  return (
    <div className="form-separator">
      {text ? (
        <Text size="small" className="form-separator__text">
          {t(text)}
        </Text>
      ) : null}
    </div>
  );
};

export default Separator;
