import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type BaseUserDataObject } from 'models/service';

type GetUsersParams = {
  search?: string;
  limit?: number;
  scope?: 'all';
  exclude_community_id?: string;
};

export const getUsers = async ({ search, limit = 10, scope = 'all', exclude_community_id }: GetUsersParams) => {
  return apiClient.get<BaseUserDataObject[], ApiClientResponse<BaseUserDataObject[]>>(routes.user.autocomplete, {
    params: { scope, search, limit, exclude_community_id },
  });
};
