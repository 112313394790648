import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { updateNotificationsSettings } from '../../services';

type NotificationSettings = {
  badgeNotificationsSettings: {
    enableNotifications: boolean;
  };
  emailNotificationsSettings: {
    enableNotifications: boolean;
    newsfeed: boolean;
    events: boolean;
    donations: boolean;
    program: boolean;
  };
  pushNotificationsSettings: {
    enableNotifications: boolean;
    newsfeed: boolean;
    events: boolean;
    messaging: boolean;
    donations: boolean;
    program: boolean;
  };
};

export const useUpdateNotificationsSettings = () => {
  return useMutation<
    void,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    { userId: string; notifications: NotificationSettings }
  >({
    async mutationFn({ userId, notifications }) {
      await updateNotificationsSettings({
        userId,
        notifications: {
          badge_notifications_settings: {
            enable_notifications: notifications.badgeNotificationsSettings.enableNotifications,
          },
          email_notifications_settings: {
            enable_notifications: notifications.emailNotificationsSettings.enableNotifications,
            newsfeed: notifications.emailNotificationsSettings.newsfeed,
            events: notifications.emailNotificationsSettings.events,
            donations: notifications.emailNotificationsSettings.donations,
            program: notifications.emailNotificationsSettings.program,
          },
          push_notifications_settings: {
            enable_notifications: notifications.pushNotificationsSettings.enableNotifications,
            newsfeed: notifications.pushNotificationsSettings.newsfeed,
            events: notifications.pushNotificationsSettings.events,
            messaging: notifications.pushNotificationsSettings.messaging,
            donations: notifications.pushNotificationsSettings.donations,
            program: notifications.pushNotificationsSettings.program,
          },
        },
      });
    },
  });
};
