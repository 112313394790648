import { Col, Form, Input, Row } from 'antd';
import { type NamePath } from 'antd/lib/form/interface';
import classnames from 'classnames';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { passwordRules, validatePasswordConditionSeparatly } from 'common/validators';
import { Text, Item } from 'ui';
import { CheckPasswordIcon, ClosePasswordIcon, EyeCloseIcon, EyeOpenIcon } from '../../Icons';

type ItemP = Omit<React.ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
};

type InputP = React.ComponentProps<typeof Input.Password>;

type P = {
  item: ItemP;
  input?: InputP;
  showValidation?: boolean;
};

const ValidationInfo = ({
  valid,
  showErrorState,
  title,
}: {
  valid: boolean;
  showErrorState: boolean;
  title: string;
}) => {
  const { t } = useTranslation();

  return (
    <li
      className={classnames({
        // TODO this is too jumping in UI
        'password-input__validated': showErrorState || valid,
      })}
    >
      <Text
        className={'password-input__text'}
        type={showErrorState || valid ? (valid ? 'success' : 'danger') : undefined}
        aria-live="polite"
      >
        {showErrorState || valid ? (
          valid ? (
            // eslint-disable-next-line jsx-a11y/prefer-tag-over-role
            <CheckPasswordIcon aria-label="requirement completed" role="img" />
          ) : (
            // eslint-disable-next-line jsx-a11y/prefer-tag-over-role
            <ClosePasswordIcon aria-label="password requires" role="img" />
          )
        ) : null}
        {t(title)}
      </Text>
    </li>
  );
};

const PasswordValidationInfo = ({ name, idPrefix = 'passwordValidation' }: { name?: NamePath; idPrefix?: string }) => {
  const { t } = useTranslation();

  if (!name) {
    return null;
  }

  return (
    <Form.Item shouldUpdate>
      {({ getFieldValue, getFieldError }) => {
        const password: string | undefined = getFieldValue(name);
        const hasValidationErrors = Boolean(password && getFieldError(name)?.length > 0);
        const passwordCheck = validatePasswordConditionSeparatly(password ?? '');

        return (
          <Row
            id={`${idPrefix}_password_help`}
            aria-atomic
            gutter={[0, variables.spaceMd.value]}
            className="password-input__validation"
          >
            <Col span={24}>
              <Text>{t('The password must contain following: ')}</Text>
            </Col>
            <Col span={24}>
              <ul className="password-input__validation__grid-container">
                {passwordRules.map((rule) => (
                  <ValidationInfo
                    key={rule.key}
                    valid={passwordCheck[rule.key]}
                    showErrorState={hasValidationErrors}
                    title={rule.title}
                  />
                ))}
              </ul>
            </Col>
            <Text aria-live="polite" className="visualy-hidden">
              {`${passwordCheck.numberOfFullfilledConditions} of ${passwordRules.length} requirements completed`}
            </Text>
          </Row>
        );
      }}
    </Form.Item>
  );
};

const PasswordInput = ({ item, input, showValidation }: P) => {
  return (
    <>
      <Item {...item}>
        <Input.Password
          {...input}
          size="large"
          iconRender={(visible) => (visible ? <EyeOpenIcon cursor="pointer" /> : <EyeCloseIcon cursor="pointer" />)}
          aria-labelledby="passwordValidation_password_help"
        />
      </Item>

      {showValidation ? <PasswordValidationInfo name={item?.name} /> : null}
    </>
  );
};

export default PasswordInput;
