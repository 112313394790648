/* eslint-disable import/order */
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from '@tanstack/react-query';
// Store and persistor
import { store, persistor } from './old/store';
// Router
import { MainRouter } from './old/routers';
// Other
import { AppConfigGate } from './old/atoms/app-config-gate';
import { ThemeProvider } from './old/atoms/theme';
import { queryClient } from 'query';
import './i18n';
import { RecoilRoot } from 'recoil';
import { ConnectionSocket } from './common/services';

export function App() {
  return (
    <RecoilRoot>
      <Provider store={store}>
        <PersistGate loading="Loading" persistor={persistor}>
          <AppConfigGate>
            <ThemeProvider>
              <QueryClientProvider client={queryClient}>
                <ConnectionSocket />
                <MainRouter />
              </QueryClientProvider>
            </ThemeProvider>
          </AppConfigGate>
        </PersistGate>
      </Provider>
    </RecoilRoot>
  );
}
/* eslint-enable import/order */
