import { Button, CommentIcon } from 'ui';

type P = {
  onShowComments?: () => void;
};

const CommentButton = ({ onShowComments }: P) => {
  return (
    <div className="comment-button">
      <Button
        type="text"
        icon={<CommentIcon className="comment-button__icon" />}
        className="comment-button__button"
        onClick={onShowComments}
        size="large"
      >
        Comment
      </Button>
    </div>
  );
};

export default CommentButton;
