import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type P = {
  legend: {
    title: string;
    visible?: boolean;
  };
  children: ReactNode;
};

// TODO legend visible is not properly styled
const Fieldset = ({ legend, children }: P) => {
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend className={legend.visible ? '' : 'visualy-hidden'}>{t(legend.title)}</legend>
      {children}
    </fieldset>
  );
};

export default Fieldset;
