import { type ReduxUser } from 'models';
import { NavLink } from 'react-router-dom';
import { IconDirectMessageButton } from 'features/chat'; // TODO how to solve import from different feature
import { Avatar, Col, Empty, Row, Text, Button, DeleteIcon } from 'ui';

type P = {
  users?: User[];
  viewer: ReduxUser;
  onDeleteUser?: (user: User) => void;
};

export type User = {
  id: string;
  photo: { url: string; alt: string };
  fullName: string;
};

const UserList = ({ users, viewer, onDeleteUser }: P) => {
  return (
    <div className="community-user-list">
      {!users || users.length === 0 ? (
        <Empty />
      ) : (
        users.map((user) => (
          <Row key={user.id} item={{ className: 'community-user-list__row', align: 'middle' }}>
            <Col item={{ className: 'community-user-list__avatar' }}>
              <NavLink to={`/members/${user.id}`}>
                <Avatar photo={user.photo.url} size={40} alt={user.photo.alt} />
              </NavLink>
            </Col>
            <Col item={{ className: 'community-user-list__label community-user-list__right-stretch' }}>
              <Row
                item={{
                  className: 'community-user-list__label__content',
                  align: 'middle',
                }}
              >
                <Col item={{ className: 'community-user-list__right-stretch' }}>
                  <NavLink to={`/members/${user.id}`}>
                    <Text strong type="secondary" ellipsis={{ tooltip: user.fullName }}>
                      {user.fullName}
                    </Text>
                  </NavLink>
                </Col>
                <Col>
                  <Row item={{ align: 'middle' }}>
                    <IconDirectMessageButton legacyUserId={user.id} viewer={viewer} />
                    {onDeleteUser ? (
                      <Button
                        onClick={() => {
                          onDeleteUser(user);
                        }}
                        icon={<DeleteIcon />}
                        danger
                        type="link"
                      />
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default UserList;
