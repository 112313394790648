import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type LoginDataObject } from './data-objects';

type TokenLoginRequest = {
  login_token: string;
  login_token_type?: 'magic_token';
  grant_type: 'password';
};

export const loginWithToken = async (data: TokenLoginRequest) =>
  apiClient.post<LoginDataObject, ApiClientResponse<LoginDataObject>>(routes.oauth.token, data, {
    skipAuth: true,
  });
