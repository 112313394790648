import { dispatch } from 'old/store';
import { useCallback } from 'react';
import { OnboardingTourImage, OnboardingTour, OnboardingTourLayout } from 'features/onboarding';
import { type OnboardingTourStepOption } from 'features/onboarding/models';
import { useOnSuccessLogin } from '../hooks';
import { useOnboardingTourStep } from './hooks';

const STEP_KEY = 'step';
const CALENDAR_STEP_VALUE: OnboardingTourStepOption = 'calendar';

export const CALENDAR_PARAMS = { [STEP_KEY]: CALENDAR_STEP_VALUE };

export type OnboardingTourLocationState = {
  referrer?: string;
  originalState?: unknown;
};

const PageOnboardingTour = () => {
  const { onSuccessOnboardingTour } = useOnSuccessLogin();
  const { step, onChangeStep } = useOnboardingTourStep({ stepKey: STEP_KEY });

  const onLogout = useCallback(async () => {
    await dispatch.session.logOut();
  }, []);

  return (
    <OnboardingTourLayout
      centeredContent={step === 'intro' || step === 'finish'}
      contentLeft={<OnboardingTourImage step={step} />}
      contentRight={({ steps, viewer }) => (
        <OnboardingTour
          viewer={viewer}
          steps={steps}
          step={step}
          onChangeStep={onChangeStep}
          onLogout={onLogout}
          onFinish={onSuccessOnboardingTour}
        />
      )}
    />
  );
};

export default PageOnboardingTour;
