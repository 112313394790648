import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { deleteChatroom, manageChatroomMembers, patchChatroom } from '../../../actions';
import { Profile } from '../../../common/models';
import { routes } from '../../../common/services';
import { ID } from '../../../common/types';
import { canDeleteChatroom, canManageChatroomMembers, canRenameChatroom } from '../../../permissions';
import { activeChatroom } from '../../../recoil/atoms';
import {
  activeChatroomState,
  chatroomsArchivedState,
  chatroomsLastUpdatedState,
  usersInChatroomState,
} from '../../../recoil/selectors';
import { Avatar, Button, Col, Dropdown, type MenuProps, message, ModalConfirmDelete, Row } from '../../../ui';
import { DotsIcon } from '../../../ui/Icons';
import ModalManageMembers from './ModalManageMembers';
import ModalRenameChat from './ModalRenameChat';
import ModalViewMembers from './ModalViewMembers';
import { ChatroomState } from '../../../../index';
import { Participant, getFullName, getParticipantInitials } from '../../../models';
import { ParticipantBase } from '../../../types';

type P = {
  currentUserProfile: Profile;
  handleChatState: (chatStateToApply: ChatroomState, id?: ID) => void;
};

const Header = ({ currentUserProfile, handleChatState }: P) => {
  const { t } = useTranslation(['translationChat']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalDeleteChatOpen, setIsModalDeleteChatOpen] = useState(false);
  const [isModalRenameChatOpen, setIsModalRenameChatOpen] = useState(false);
  const [isModalManageMembersOpen, setIsModalManageMembersOpen] = useState(false);
  const [isModalViewMembersOpen, setIsModalViewMembersOpen] = useState(false);
  const chatroomUsers = useRecoilValue(usersInChatroomState);
  const activeChatroomId = useRecoilValue(activeChatroom);
  const activeChatroomData = useRecoilValue(activeChatroomState(activeChatroomId));
  const setLastUpdatedChatroom = useSetRecoilState(chatroomsLastUpdatedState);
  const setArchivedChatroom = useSetRecoilState(chatroomsArchivedState);

  const moreButtonDropdownElements: MenuProps['items'] = useMemo(() => {
    const menuItems: MenuProps['items'] = [];
    if (canRenameChatroom(currentUserProfile, activeChatroomData.author)) {
      menuItems.push({
        label: t('chat.chatroomDetail.header.moreButtonDropdown.renameChat'),
        key: '0',
      });
    }
    if (canManageChatroomMembers(currentUserProfile, activeChatroomData)) {
      menuItems.push({
        label: t('chat.chatroomDetail.header.moreButtonDropdown.manageMembers'),
        key: '1',
      });
    }
    if (!canManageChatroomMembers(currentUserProfile, activeChatroomData)) {
      menuItems.push({
        label: t('chat.chatroomDetail.header.moreButtonDropdown.viewMembers'),
        key: '2',
      });
    }
    if (canDeleteChatroom(currentUserProfile, activeChatroomData.author)) {
      menuItems.push({
        label: (
          <span className="menu-item--danger">{t('chat.chatroomDetail.header.moreButtonDropdown.deleteChat')}</span>
        ),
        key: '3',
        danger: true,
      });
    }

    return menuItems;
  }, [
    t,
    currentUserProfile,
    activeChatroomData,
    setIsModalRenameChatOpen,
    setIsModalManageMembersOpen,
    setIsModalViewMembersOpen,
    setIsModalDeleteChatOpen,
  ]);

  const onMenuClick = useCallback(
    (key: string) => {
      switch (key) {
        case '0':
          setIsModalRenameChatOpen(true);
          break;
        case '1':
          setIsModalManageMembersOpen(true);
          break;
        case '2':
          setIsModalViewMembersOpen(true);
          break;
        case '3':
          setIsModalDeleteChatOpen(true);
          break;
        default:
          break;
      }
    },
    [setIsModalRenameChatOpen, setIsModalManageMembersOpen, setIsModalViewMembersOpen, setIsModalDeleteChatOpen],
  );

  const handleRenameChat = useCallback(
    async (name: string) => {
      setIsLoading(true);
      const response = await patchChatroom(activeChatroomData._id, {
        name,
      });

      setIsLoading(false);

      if (response.error) {
        return message.error('chat.chatroomDetail.messages.chatroomRenameError');
      }

      setIsModalRenameChatOpen(false);
      setLastUpdatedChatroom(response.result);
    },
    [activeChatroomData._id, setLastUpdatedChatroom],
  );

  const handleManageMembers = async (membersToAdd: Participant[], membersToRemove: Participant[]) => {

    let participantsToAdd: { _id: string; participant: ParticipantBase }[] = [];

    membersToAdd.forEach((member) => {
      participantsToAdd.push({
        _id: member._id,
        participant: {
          firstName: member.firstName,
          lastName: member.lastName,
        }
      })
    });
    if (membersToAdd.length > 0 || membersToRemove.length > 0) {
      setIsLoading(true);
      try {
        const responses = await manageChatroomMembers(activeChatroomId as ID, participantsToAdd, membersToRemove);
        setIsLoading(false);

        if (responses.length > 0) {
          const lastResponse = responses[responses.length - 1];

          setLastUpdatedChatroom(lastResponse.result);
        }
      } catch {
        return message.error('chat.chatroomDetail.messages.chatroomUserDeleteError');
      }
    }

    setIsModalManageMembersOpen(false);
  };

  const handleDeleteChat = useCallback(async () => {
    setIsLoading(true);
    const response = await deleteChatroom(activeChatroomData._id);
    setIsLoading(false);

    if (response.error) {
      return message.error('chat.chatroomDetail.messages.chatroomDeleteError');
    }

    setIsModalDeleteChatOpen(false);
    setArchivedChatroom(response.result);
    handleChatState('default');
  }, [activeChatroomData._id, setArchivedChatroom]);

  const { _id: currentUserId } = currentUserProfile;

  const getChatroomName = (): string => activeChatroomData.getName(currentUserId, activeChatroomData.participants);

  const renderUsersAvatars = () =>
    Object.keys(chatroomUsers)
      .filter((id) => !(Object.keys(chatroomUsers).length === 2 && id === currentUserId))
      .map((id) => (
        <div key={id} title={getFullName(chatroomUsers[id])} className="main-avatar">
          <Avatar
            src={
              new URL(
                routes.chatrooms.media.getAvatarFile(id, 'user_avatar', 'medium'),
                process.env.REACT_APP_MEDIA_MODULE_URL,
              ).href
            }
            size={56}
            alt={`${getFullName(chatroomUsers[id])} Profile photo`}
          >
            {getParticipantInitials(chatroomUsers[id])}
          </Avatar>
        </div>
      ));

  return (
    <div className="chatroom-detail__header">
      <Row align={'middle'}>
        <Col flex={'0 0 auto'} className="col--avatars">
          <Avatar.Group maxCount={1} maxPopoverPlacement="bottom" className="chatroom-detail__header__avatars">
            {renderUsersAvatars()}
          </Avatar.Group>
        </Col>

        <Col flex={'1'} className="col--name">
          <span className="label--large">{getChatroomName()}</span>
        </Col>

        <Col flex={'0 0 auto'}>
          <Dropdown
            menu={{
              items: moreButtonDropdownElements,
              onClick: (event) => {
                onMenuClick(event.key);
              },
            }}
            trigger={['click']}
          >
            <Button type="link" className="icon icon--dots">
              <DotsIcon />
            </Button>
          </Dropdown>
        </Col>

        <div onClick={(event) => event.stopPropagation()}>
          <ModalRenameChat
            isOpen={isModalRenameChatOpen}
            isLoading={isLoading}
            currentName={activeChatroomData.name}
            onOkClick={handleRenameChat}
            onCancelClick={() => setIsModalRenameChatOpen(false)}
          />

          {isModalManageMembersOpen ? (
            <ModalManageMembers
              currentUserProfile={currentUserProfile}
              isOpen={isModalManageMembersOpen}
              isLoading={isLoading}
              onOkClick={handleManageMembers}
              onCancelClick={() => setIsModalManageMembersOpen(false)}
            />
          ) : null}

          <ModalViewMembers
            currentUserProfile={currentUserProfile}
            isOpen={isModalViewMembersOpen}
            onCancelClick={() => setIsModalViewMembersOpen(false)}
          />

          <ModalConfirmDelete
            title={t('chat.chatroomDetail.header.modalDeleteChat.title')}
            isOpen={isModalDeleteChatOpen}
            isLoading={isLoading}
            onOkClick={handleDeleteChat}
            onCancelClick={() => setIsModalDeleteChatOpen(false)}
          >
            <p className="p--large">{t('chat.chatroomDetail.header.modalDeleteChat.content')}</p>
          </ModalConfirmDelete>
        </div>
      </Row>
    </div>
  );
};

export default Header;
