import { type AxiosRequestConfig } from 'axios';
import { getAccessToken } from '../auth';

export const defaultRailsInterceptor = async (config: AxiosRequestConfig) => {
  if (config.headers && !config.skipAuth) {
    // TODO one day this will be used from new
    const accessToken = await getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config.headers.Accept = 'application/json';
    config.headers['Cache-Control'] = 'no-cache';
  }

  return config;
};
