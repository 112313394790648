import { type BaseOptionType } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { useDebounce } from 'common/hooks';
import { Empty } from '../Empty';
import { Select, type SelectProperties } from '../Select';
import { Spin } from '../Spin';

const INITIAL_STATE = { delay: 0, value: '' };

export type DebounceSelectProperties<ValueType, OptionType extends BaseOptionType> = SelectProperties<
  ValueType,
  OptionType
> & {
  debounceTime?: number;
};

const DebounceSelect = <ValueType, OptionType extends BaseOptionType>({
  debounceTime = 500,
  onSearch,
  ...restProperties
}: DebounceSelectProperties<ValueType, OptionType>) => {
  const [search, setSearch] = useState<{ delay: number; value: string }>(INITIAL_STATE);
  const { value, isDebouncing } = useDebounce(search.value, search.delay);

  useEffect(() => {
    onSearch?.(value);
  }, [value, onSearch]);

  return (
    <Select<ValueType, OptionType>
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      notFoundContent={restProperties.loading || isDebouncing ? <Spin /> : <Empty />}
      onSearch={(value) => {
        setSearch({ delay: debounceTime, value });
      }}
      onDropdownVisibleChange={() => {
        setSearch(INITIAL_STATE);
      }}
      {...restProperties}
    />
  );
};

export default DebounceSelect;
