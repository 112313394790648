import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Trans } from 'react-i18next';
import { startOfYear, endOfYear } from 'date-fns';

import { Loader } from '../../atoms/loader';
import { CampaignsLayout } from '../../layouts/CampaignsLayout';
import { CampaignsSideNav } from '../admin-page-campaigns/CampaignsSideNav';
import { ThemeConsumer } from '../../atoms/theme';
import { select } from '@rematch/select';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { Icon } from '../../atoms/icon';
import { SearchInput } from '../../atoms/search-input';
import { Button } from '../../atoms/button';
import { showAlert } from '../../atoms/alert';
import { Avatar } from 'ui';
import { images } from 'common/utils';

class Renderer extends React.Component {
  state = {
    search: '',
    donations: [],
    campaigns: [],
    chosen: [],
    chosenDonors: [],
    donors: [],
    response: null,
  };

  async componentDidMount() {
    const campaigns = (await this.props.getCampaigns()).data;

    this.setState(
      {
        campaigns: campaigns,
        allCampaigns: campaigns,
      },
      () => this.onSearch(this.state.search),
    );
  }

  onSearch = (value) => {
    value = typeof value === 'string' ? value : value.target.value ?? '';
    this.setState({
      search: value,
      campaigns: value
        ? this.state.campaigns.filter((campaign) => campaign.name.search(new RegExp(value, 'i')) > -1)
        : this.state.allCampaigns,
    });
  };

  onCheck = (campaign) => {
    if (this.getCheckedCampaign(campaign.id)) {
      this.setState(
        { chosen: this.state.chosen.filter((innerCampaign) => innerCampaign.id !== campaign.id) },
        this.getDonors,
      );
    } else {
      this.setState({ chosen: this.state.chosen.concat([campaign]) }, this.getDonors);
    }
  };

  onCheckDonor = (donor) => {
    if (this.getCheckedDonor(donor.id)) {
      this.setState({ chosenDonors: this.state.chosenDonors.filter((innerDonor) => innerDonor.id !== donor.id) });
    } else {
      this.setState({ chosenDonors: this.state.chosenDonors.concat([donor]) });
    }
  };

  getCheckedCampaign = (id) => this.state.chosen.find((campaign) => campaign.id === id);
  getCheckedDonor = (id) => this.state.chosenDonors.find((donor) => donor.id === id);

  checkAllCampaigns = () => {
    this.setState(
      {
        chosen: this.state.campaigns === this.state.chosen ? [] : this.state.campaigns,
      },
      this.getDonors,
    );
  };

  checkAllDonors = () => {
    this.setState({
      chosenDonors: this.state.donors === this.state.chosenDonors ? [] : this.state.donors,
    });
  };

  async getDonors() {
    const donors = await this.props.getDonors({ campaigns: this.state.chosen.map((campaign) => campaign.id) });
    this.setState({
      donors: donors,
      chosenDonors: this.state.chosenDonors.filter((chosenDonor) =>
        donors.some((donor) => chosenDonor.id === donor.id),
      ),
    });
  }

  async sendReport() {
    const response = await this.props.sendReport({
      from: startOfYear(new Date()),
      to: endOfYear(new Date()),
      users: this.state.chosenDonors.map((donor) => donor.id),
    });

    if (response.ok) {
      showAlert({ type: 'success', message: <Trans>Report was successfully sent.</Trans> });
    } else {
      showAlert({
        type: 'error',
        message: <Trans>Something went wrong, please try again later!</Trans>,
      });
    }
  }

  render() {
    return (
      <CampaignsLayout navigation={<CampaignsSideNav />} campaignsByOrganization={this.props.campaignsByOrganization}>
        <ThemeConsumer>
          {(theme) => (
            <div>
              <Box flexDirection="row">
                <Text size={20} weight="600">
                  <Trans>Send donation receipts</Trans>
                </Text>
              </Box>
              <div className="two-columns">
                <div className="two-columns__left-menu">
                  <React.Fragment>
                    <Box flexDirection="row" alignItems="stretch">
                      <Text size={12} weight="600" color={theme.color.brand} style={{ textTransform: 'uppercase' }}>
                        <Trans>Campaigns</Trans>
                      </Text>
                    </Box>
                    <SearchInput
                      placeholder={'Search campaign'}
                      outerDivCss={{
                        height: 32,
                        backgroundColor: '#F4F4F4',
                        position: 'relative',
                        overflow: 'hidden',
                        margin: '20px 0 16px 0',
                      }}
                      iconSize={14}
                      iconColor={'#90A4AE'}
                      inputCss={{
                        height: 32,
                        color: '#90A4AE',
                        position: 'relative',
                        width: '100%',
                        background: 'none',
                        border: 0,
                        outline: 'none',
                        fontSize: 14,
                        paddingLeft: 34,
                        paddingRight: 10,
                      }}
                      onKeyUp={this.onSearch}
                      id={'search-campaign'}
                    />
                    <div style={{ maxHeight: 500, overflow: 'auto' }}>
                      {this.props.loadingCampaigns ? (
                        <Loader />
                      ) : (
                        this.state.campaigns.map((campaign) => (
                          <div key={campaign.id} className="two-columns__left-menu-campaign">
                            <div className="two-columns__left-menu-campaign__header">
                              <input
                                name={campaign.id}
                                id={`checkbox-${campaign.id}`}
                                type="checkbox"
                                onChange={() => this.onCheck(campaign)}
                                hidden
                              />
                              <label htmlFor={`checkbox-${campaign.id}`}>
                                <i
                                  className="checkbox"
                                  style={{
                                    backgroundColor: this.getCheckedCampaign(campaign.id)
                                      ? theme.color.brand
                                      : 'transparent',
                                  }}
                                >
                                  {this.getCheckedCampaign(campaign.id) ? (
                                    <Icon name="check" color="white" size={10} />
                                  ) : null}
                                </i>
                              </label>
                            </div>

                            <div>
                              <div style={{ color: theme.color.brand, paddingBottom: 5 }}>{campaign.name}</div>
                              <div style={{ color: '#90a4ae', fontSize: 11 }}>
                                {campaign.organization.name}, {campaign.community.name}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </React.Fragment>
                </div>
                <div className="two-columns__right-content">
                  <Box flexDirection="row" alignItems="stretch">
                    <Text size={12} weight="600" color={theme.color.brand} style={{ textTransform: 'uppercase' }}>
                      <Trans>Donors</Trans>
                      <span style={{ color: '#a2abbb' }}> ({this.state.donors.length})</span>
                    </Text>
                    <div
                      className="select-all"
                      onClick={() => {
                        this.checkAllDonors();
                      }}
                    >
                      <Trans>Select all</Trans>
                    </div>
                  </Box>
                  <Box flexDirection="column" style={{ maxHeight: 550, overflow: 'auto' }}>
                    {this.state.donors.length ? (
                      this.state.donors.map((donor) => (
                        <div key={donor.id} className="donor">
                          <div className="donor__detail">
                            <Avatar photo={donor.profile_photo?.url ?? images.default.userProfilePhoto} size={40} />
                            <span style={{ color: theme.color.brand }}>
                              {donor.first_name} {donor.last_name}
                            </span>
                          </div>
                          <React.Fragment>
                            <input
                              name={donor.id}
                              id={`checkbox-donor-${donor.id}`}
                              type="checkbox"
                              onChange={() => this.onCheckDonor(donor)}
                              hidden
                            />
                            <label htmlFor={`checkbox-donor-${donor.id}`}>
                              <i
                                className="checkbox"
                                style={{
                                  backgroundColor: this.getCheckedDonor(donor.id) ? theme.color.brand : 'transparent',
                                }}
                              >
                                {this.getCheckedDonor(donor.id) ? <Icon name="check" color="white" size={10} /> : null}
                              </i>
                            </label>
                          </React.Fragment>
                        </div>
                      ))
                    ) : (
                      <div style={{ marginTop: 20, color: '#a2abbb' }}>
                        <Trans>Select campaign</Trans>
                      </div>
                    )}
                  </Box>
                </div>
              </div>
              <div className="two-columns two-columns--footer">
                <div className="two-columns__left-menu two-columns__left-menu__footer">
                  {this.state.chosenDonors.length} <Trans>donors selected</Trans>
                </div>
                <div className="two-columns__right-content two-columns__right-content__footer">
                  {this.props.loading ? (
                    <Loader />
                  ) : (
                    <Button
                      type="button"
                      onClick={() => this.sendReport()}
                      textColor={theme.color.white}
                      color={theme.color.green}
                      size="md"
                      title={<Trans>Send donation receipts</Trans>}
                      disabled={!this.state.chosenDonors.length}
                      id={'send-receipts'}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </ThemeConsumer>
      </CampaignsLayout>
    );
  }
}

const mapState = (state, props) => ({
  loading: state.loading.effects.donations.sendReport,
  loadingCampaigns: state.loading.effects.campaigns.getAsync,
  campaignsByOrganization: select.campaigns.getBy(state, 'organization'),
});

const mapDispatch = (dispatch, props) => ({
  getCampaigns: () => dispatch.campaigns.getAsync(),
  getDonors: (campaigns) => dispatch.campaigns.getDonors(campaigns),
  sendReport: (report) => dispatch.donations.sendReport(report),
});

export const SendDonationReport = connect(mapState, mapDispatch)(Renderer);
