import { type ReduxUser } from 'models';
import { useMemo, type ComponentProps } from 'react';
import { CalendarIcon, InviteIcon } from 'ui';
import { type EventTodo } from '../../../../types';
import { BaseTodoWidgetItem } from '../BaseTodoWidgetItem';
import EventItems from './EventTodoWidgetItems/EventItems';
import { useIsLiveStatus } from './hooks';

type P = {
  type?: ComponentProps<typeof BaseTodoWidgetItem>['type'];
  todo: EventTodo;
  className?: string;
  viewer: ReduxUser;
};

const EventTodoWidgetItem = ({ type, todo, className, viewer }: P) => {
  const { currentUserRsvp, startTime, endTime, textContent, name } = todo.event;
  const isPending = currentUserRsvp === 'pending';
  const isGoing = currentUserRsvp === 'going';

  const isLive = useIsLiveStatus({ startTime, endTime, currentUserRsvp });

  const mode = useMemo(() => {
    if (isLive) {
      return 'live';
    }

    if (isGoing) {
      return 'at';
    }

    return 'by';
  }, [isGoing, isLive]);

  switch (currentUserRsvp) {
    case 'pending':
      return (
        <BaseTodoWidgetItem
          type={type}
          className={className}
          path={todo.path}
          icon={<InviteIcon />}
          title="Respond to invitation"
          description={name}
          additionalDescription={textContent}
          item={<EventItems event={todo.event} viewer={viewer} isPending={isPending} />}
          mode={mode}
          time={startTime}
          additionalDescriptionRows={1}
        />
      );
    case 'going':
      return (
        <BaseTodoWidgetItem
          type={type}
          className={className}
          path={todo.path}
          icon={<CalendarIcon />}
          title="Join event"
          description={name}
          additionalDescription={textContent}
          item={<EventItems event={todo.event} viewer={viewer} isPending={isPending} />}
          mode={mode}
          time={startTime}
          additionalDescriptionRows={1}
        />
      );
    case 'not_going':
      console.error('Error: The "not_going" case was reached. This is an invalid state and should never occur.');
      return null;
  }
};

export default EventTodoWidgetItem;
