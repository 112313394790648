import { useDesyncAccount } from '../../queries';
import { type StatusType } from '../DexcomSync';

const useDexcomDesync = (onFinished: (result: { status: StatusType; messageText: string }) => void) => {
  const { mutate: desyncAccount, isLoading: isDesyncing } = useDesyncAccount();

  const desyncDexcom = async () => {
    desyncAccount(undefined, {
      onSuccess() {
        onFinished({ status: 'success', messageText: 'Dexcom account unlinked' });
      },
      onError() {
        onFinished({ status: 'error', messageText: 'Dexcom account unlink failed' });
      },
    });
  };

  return { desyncDexcom, isDesyncing };
};

export default useDexcomDesync;
