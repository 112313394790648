import { Col, Form, Row, TextInput, Title } from 'ui';
import { JoinedCommunitiesList } from './JoinedCommunitiesList';

type FormValues = {
  search?: string;
};

type P = { getPathToCommunityDetail: (id: string) => string };

const JoinedCommunities = ({ getPathToCommunityDetail }: P) => {
  return (
    <Form<FormValues> className="joined-programs" noOptional>
      <Title level={1}>Joined Communities</Title>
      <Row>
        <Col>
          <TextInput item={{ name: 'search', label: 'Search' }} />
        </Col>
      </Row>
      <Form.Item<FormValues> noStyle dependencies={['search']}>
        {({ getFieldsValue }) => {
          const { search = '' } = getFieldsValue();

          return <JoinedCommunitiesList getPathToCommunityDetail={getPathToCommunityDetail} search={search} />;
        }}
      </Form.Item>
    </Form>
  );
};

export default JoinedCommunities;
