import { copyToClipboard } from './copyToClipboard';
import { generateContentObjectPath } from './generateContentObjectPath';

const generateContentObjectURL = (item: any) => {
  const contentObjectPath = generateContentObjectPath(item);

  const fullHostname = window.location.origin;

  return `${fullHostname}${contentObjectPath}`;
};

export const copyLinkToClipBoard = async (item: any) => copyToClipboard(async () => generateContentObjectURL(item));
