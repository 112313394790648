import * as React from 'react';
import { parseISO } from 'date-fns';
import { PostRendererCore } from './PostRendererCore';
import { CommentsList, CommentForm } from '../comment';
import { can } from '../../utils/permissions';
import { AutocompleteService } from '../../services/AutocompleteService';
import { Loading, EditIcon, ReportIcon, DeleteIcon, BookmarkIconLine } from 'ui';
import CommentWrapper from './shared/CommentWrapper';
import { canDeleteContentObject, canEditContentObject } from 'permissions';
import { withTranslation } from 'react-i18next';

import { ContentObjectHeader, ContentObjectFooter, CommentsEmpty } from 'features/feed';
import { canReportContentObject } from 'permissions';
import { images } from 'common/utils';

export class Renderer extends React.Component {
  state = {
    loading: false,
  };

  onRequestMentions = async (value) => {
    if (this.props.item.post_in_communities?.length) {
      const response = await AutocompleteService.getCommunityUsers({
        scope: 'communities',
        search: value,
        community_ids: this.props.item.post_in_communities.map((c) => c.id),
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    if (this.props.item.program?.id) {
      const response = await AutocompleteService.getProgramUsers({
        scope: 'programs',
        search: value,
        program_ids: [this.props.item.program.id],
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    return [];
  };

  createActions = () => {
    const { t, isBookmark, onBookmarkObject, viewer, item, onInitiatePostEditing, onDeleteObject, onReportObject } =
      this.props;

    if (isBookmark) {
      return [
        {
          label: t('Unbookmark'),
          key: 'unbookmark',
          onClick: () => onBookmarkObject(item),
          icon: <BookmarkIconLine width={16} height={16} />,
        },
      ];
    }

    const actions = [];

    if (canReportContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Report'),
        key: 'report',
        onClick: () => onReportObject(item),
        icon: <ReportIcon width={16} height={16} />,
      });
    }

    if (canEditContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Edit'),
        key: 'update',
        onClick: () => onInitiatePostEditing(item),
        icon: <EditIcon width={16} height={16} />,
      });
    }

    if (canDeleteContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Delete'),
        key: 'delete',
        onClick: () => onDeleteObject({ object: item }),
        danger: true,
        // TODO replace width and heigth with props size in every renderer and with every action
        icon: <DeleteIcon width={16} height={16} />,
      });
    }

    return actions;
  };

  render() {
    const disableComments = this.props.isBookmark || this.props.isJournal;
    const showComments = this.props.showComments;

    return (
      <>
        {!this.props.isJournal ? (
          <ContentObjectHeader
            title={this.props.authorName}
            subtitle={parseISO(this.props.item.publish_at)}
            avatar={{
              url: this.props.item.author.profile_photo?.url ?? images.default.userProfilePhoto,
              name: this.props.authorName,
            }}
            bookmark={this.props.bookmark}
            actions={this.props.hideActions ? undefined : this.createActions()}
            pill={this.props.pill}
            authorId={this.props.item.author.id}
          />
        ) : (
          <ContentObjectHeader subtitle={parseISO(this.props.item.publish_at)} actions={this.createActions()} />
        )}
        <PostRendererCore
          viewer={this.props.viewer}
          item={this.props.item}
          onLikeObject={this.props.onLikeObject}
          showComments={showComments}
          onCreateObject={this.props.onCreateObject}
          onReloadObject={this.props.onReloadObject}
          isBookmark={this.props.isBookmark}
          isJournal={this.props.isJournal}
          isOnFeed={this.props.isOnFeed}
        />
        {!this.props.isJournal && !this.props.isRepost ? ( // TODO there should be some view type prop instead of this
          <ContentObjectFooter
            item={this.props.item}
            viewer={this.props.viewer}
            onShowComments={this.props.onShowComments}
            view={this.props.isBookmark ? 'bookmark' : 'other'}
          />
        ) : null}
        {!disableComments && showComments && (
          <CommentWrapper>
            {this.props.item.replies.length > 0 ? (
              <div style={{ padding: '10px 0' }}>
                <CommentsList
                  item={this.props.item}
                  scrollToCommentId={this.props.scrollToCommentId}
                  scrollToReplyId={this.props.scrollToReplyId}
                  onCreateComment={this.props.onCreateComment}
                  onDeleteObject={this.props.onDeleteObject}
                  onEditObject={this.props.onEditComment || this.props.onEditObject}
                  onLikeObject={this.props.onLikeObject}
                  onReportObject={this.props.onReportObject}
                  onSortChange={this.props.onSortChange}
                  onBookmarkObject={this.props.onBookmarkObject}
                  parent="post"
                  viewer={this.props.viewer}
                  originalItem={this.props.item}
                  course={this.props.item.program}
                />
              </div>
            ) : (
              <CommentsEmpty />
            )}
            {this.state.loading ? <Loading /> : null}
            {can(this.props.item, 'ContentObject', 'comment') ? (
              <div style={{ padding: '15px 25px' }}>
                <CommentForm
                  viewer={this.props.viewer}
                  onSubmit={async (draft) => {
                    this.setState({ loading: true });
                    const response = await this.props.onCreateComment(draft, this.props.item.id);
                    this.setState({ loading: false });
                    return response;
                  }}
                  onRequestMentions={this.onRequestMentions}
                  suffixId={`post-${this.props.item.id}`}
                  loading={this.state.loading}
                />
              </div>
            ) : null}
          </CommentWrapper>
        )}
      </>
    );
  }
}

export const PostRenderer = withTranslation()(Renderer);
