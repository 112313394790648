import { FileAsset, FolderAsset, FolderType } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { type FileAssetsDataObject, type FolderAssetDataObject } from 'models/service';
import { getAssets } from '../services';
import { type FileSorter } from '../types';
import { getAssetsQueryKey } from './';

type AssetsQueryParams = FileSorter & {
  communityId: string;
  parentId?: string;
  pageSize: number;
};

export const useAssetsQuery = (params: AssetsQueryParams) =>
  useInfiniteQuery<Array<FileAsset | FolderAsset>, ApiClientResponseError<DefaultResponseErrorDataObject>>(
    getAssetsQueryKey({ id: params.communityId, params }),
    async ({ pageParam: pageParameter = 0 }: QueryFunctionContext<QueryKey, number>) => {
      const { data: files } = await getAssets({
        community_id: params.communityId,
        parent_id: params.parentId,
        limit: params.pageSize,
        offset: pageParameter,
        sort: params.sort === 'updatedAt' ? 'updated_at' : params.sort,
        order: params.order,
      });

      return files.map((item: FileAssetsDataObject | FolderAssetDataObject) =>
        item.type === FolderType ? new FolderAsset(item) : new FileAsset(item),
      );
    },
    {
      getNextPageParam(lastPage, allPages) {
        return lastPage.length < params.pageSize ? undefined : allPages.length * params.pageSize;
      },
      useErrorBoundary: true,
    },
  );
