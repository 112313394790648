import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type LoginDataObject } from './data-objects';

export const loginWithPassword = async ({
  email,
  password,
  publicCommunityId,
}: {
  email: string;
  password: string;
  publicCommunityId?: string;
}) => {
  const data = {
    username: email,
    password,
    provider: 'local',
    grant_type: 'password',
    join_community_id: publicCommunityId,
  };

  return apiClient.post<LoginDataObject, ApiClientResponse<LoginDataObject>>(routes.oauth.token, data, {
    skipAuth: true,
  });
};
