import { apiClient, type ApiClientResponse, routes } from 'common/services';

type ChangeRoleRequestData = {
  user_id: string;
  role: 'none';
  entity_ids: string[];
};

export const changeRoleInComunity = async (params: ChangeRoleRequestData) => {
  return apiClient.post<undefined, ApiClientResponse<undefined>>(routes.community.changeRole, {
    ...params,
    entity_type: 'community',
  });
};
