import { LoadingOutlined } from '@ant-design/icons';
import { type Program, type Post } from 'models';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { removeCircularReference } from 'common/utils';
import { type Social } from 'models/content-object';
import {
  Form,
  TextInput,
  SelectInput,
  RichTextInput,
  DateTimeInput,
  Col,
  AttachmentsInput,
  ModalFormActions,
  generateAttachmentsInputInitialValue,
} from 'ui';

type P = {
  onSubmit: (post: Post<Social>) => void;
  program: Program;
  post: Post<Social>;
  loading: boolean;
};

export type ProgramTopic = {
  id: string;
  name: string;
};

const getInitialValues = (post: Post<Social>, program: Program) => {
  // TODO `removeCircularReference` this is fixing circural reference
  const data = removeCircularReference({
    ...post,
    program_id: program.id,
  });

  return {
    ...data,
    publish_at: data.publish_at ? moment(data.publish_at) : undefined,
    todo_due_date: data.todo_due_date ? moment(data.todo_due_date) : undefined,
    attachments: generateAttachmentsInputInitialValue(data.attachments),
  };
};

export const PostForm = ({ onSubmit, post, program, loading }: P) => {
  const [form] = Form.useForm();
  const isEditPost = Boolean(post.id);
  const initialValues = getInitialValues(post, program);
  const { t } = useTranslation();

  const onFinish = (data: any) => {
    const cleanedPost = {
      ...post,
      ...data,
    };

    if (data.attachments) {
      const fileList = Array.isArray(data.attachments) ? data.attachments : data.attachments.fileList;
      cleanedPost.attachments = fileList.map((file: any) => file.response || file);
    }

    onSubmit(cleanedPost);
  };

  return loading ? (
    <Col item={{ style: { textAlign: 'center', fontSize: 24 } }}>
      <LoadingOutlined />
    </Col>
  ) : (
    <Form initialValues={initialValues} onFinish={onFinish} form={form}>
      <TextInput item={{ name: 'program_id', hidden: true }} />
      <SelectInput<any>
        item={{
          name: 'program_topic_id',
          label: 'Program module',
          rules: [
            {
              required: true,
            },
          ],
          'data-test-id': 'select_course-topic',
        }}
        input={{
          placeholder: t('Please select the module the post belongs to'),
          // TODO these are not same types! there is any now for this case
          options: program.topics,
          getOptionLabel: (option: ProgramTopic) => option.name,
          getOptionValue: (option: ProgramTopic) => option.id,
        }}
      />
      <DateTimeInput
        item={{
          name: 'publish_at',
          label: 'Publish date',
          rules: [
            {
              required: true,
              message: t('Error: Please provide publish date'),
            },
            () => ({
              async validator(rule, value) {
                if (program) {
                  if (value.isBefore(program.start_time)) {
                    throw 'Error: Post publish date must be set after Program start time';
                  }

                  if (value.isAfter(program.end_time)) {
                    throw 'Error: Post publish date must be set before Program end time';
                  }
                }
              },
            }),
          ],
        }}
        input={{
          format: 'MMM D, YYYY [at] h:mm A',
          showTime: { format: 'h:mm A' },
        }}
      />
      <DateTimeInput
        item={{
          name: 'todo_due_date',
          label: 'To-Do due date',
          dependencies: ['publish_at'],
          rules: [
            {
              required: true,
            },
            () => ({
              async validator(rule, value) {
                if (program) {
                  if (value.isBefore(program.start_time)) {
                    throw 'Error: To-Do due date must be set after Program start time';
                  }

                  if (value.isAfter(program.end_time)) {
                    throw 'Error: To-Do due date must be set before Program end time';
                  }
                }

                const publishAt = form.getFieldValue('publish_at');
                if (value.isBefore(publishAt)) {
                  throw 'Error: Due to date must be set after the publish date';
                }
              },
            }),
          ],
        }}
        input={{
          format: 'MMM D, YYYY [at] h:mm A',
          showTime: { format: 'h:mm A' },
        }}
      />
      <TextInput
        item={{
          name: 'title',
          label: 'Title',
          rules: [{ required: true }],
        }}
      />
      <RichTextInput
        text={{ name: 'text_content' }}
        html={{
          name: 'html_content',
          label: 'Content',
          maxWidth: '100%',
          // TODO remove this message if it is possible in #62581
          rules: [{ required: true, message: 'This field is required' }],
        }}
      />
      <AttachmentsInput item={{ name: 'attachments', label: 'Attachments' }} />
      <ModalFormActions submit={{ children: `${isEditPost ? 'Edit' : 'Create'} Post` }} />
    </Form>
  );
};
