import { useMutation } from '@tanstack/react-query';

import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import { OauthToken } from '../../models';
import { loginWithToken } from '../../services';

export const useLoginWithTokenQuery = () =>
  useMutation<
    OauthToken,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    { token: string; type?: 'magic_token' }
  >({
    async mutationFn({ token, type }) {
      const oauthResponse = await loginWithToken({
        login_token: token,
        login_token_type: type,
        grant_type: 'password',
      });

      return new OauthToken({
        accessToken: oauthResponse.data.access_token,
        tokenType: oauthResponse.data.token_type,
        refreshToken: oauthResponse.data.refresh_token,
        expiresIn: oauthResponse.data.expires_in,
        createdAt: oauthResponse.data.created_at,
      });
    },
  });
