import { addMinutes, isAfter, isBefore, isPast, isWithinInterval, parseISO } from 'date-fns';
import moment, { type Moment } from 'moment/moment';
import { getLastChangeRequestStatus } from 'features/events/utils';
import { ChangeRequest } from './change-request';
import { Entity } from './entity';
import { Image } from './image';
import { type EventPrivacy } from './privacy';
import {
  type BaseEventDataObject,
  type RegisteredEventInviteeDataObject,
  type ListEventDataObject,
  type EventDataObject,
  type EventMetaItemDataObject,
  type EventMetaDataObject,
  type EmailEventInviteeDataObject,
  type ChangeRequestStatus,
  type AbstractEventDataObject,
  type PublicEventDataObject,
  type EnterEventDataObject,
  type EventSlimDataObject,
} from './service';
import { type Tag } from './tag';

export type DialInInformation = {
  id: string;
  number: string;
  passcode: string;
};

export type EventStatus = 'draft' | 'published';

export type EventInviteeRole = 'attendee' | 'moderator' | 'presenter';

export type RecordingStatus = 'not_available' | 'pending' | 'recorded' | null;

export type UserRSVP = 'going' | 'not_going' | 'pending';

export type EventType = 'conference' | 'telehealth_visit' | 'webinar';

export type EventFile = {
  bigMarkerId: string;
  byteSize: number;
  checksum: string;
  contentType: string;
  createdAt: string;
  downloadUrl: string;
  filename: string;
  id: string;
  url: string;
};

export enum InviteType {
  empty = '',
  invitees = 'invitees',
  moderators = 'moderators',
  presenters = 'presenters',
}
export type PostInCommunity = {
  id: string;
  name: string;
  organizationId: string;
};

export type EventEmailInvitee = {
  email: string;
  eventRole: EventInviteeRole;
};

export class EnterEvent {
  enterUrl: string;

  constructor(data: EnterEventDataObject) {
    this.enterUrl = data.enter_uri;
  }
}

export class RegisteredEventInvitee {
  id: string;
  firstName?: string;
  lastName?: string;
  profilePhoto?: Image;
  eventRole: EventInviteeRole;

  constructor(data: RegisteredEventInviteeDataObject) {
    this.id = data.id;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.profilePhoto = data.profile_photo ? new Image(data.profile_photo) : undefined;
    this.eventRole = data.event_role;
  }
}

export class EmailEventInvitee {
  email: string;
  eventRole: EventInviteeRole;

  constructor(data: EmailEventInviteeDataObject) {
    this.email = data.email;
    this.eventRole = data.event_role;
  }
}

export abstract class AbstractEvent extends Entity {
  static leaveOffset = 58;
  static enterOffset: Record<EventInviteeRole, number> = {
    moderator: -60,
    presenter: -60,
    attendee: -15,
  };

  static getEnterOffset(role: EventInviteeRole) {
    return AbstractEvent.enterOffset[role];
  }

  coverPhoto?: Image;
  bmgId: string | null;
  endTime: Date;
  startTime: Date;
  recordingStatus: RecordingStatus;
  recordingUrl: string | null;
  status: EventStatus;

  constructor(data: AbstractEventDataObject) {
    super(data);
    this.coverPhoto = data.cover_photo ? new Image(data.cover_photo) : undefined;
    this.bmgId = data.bmg_id;
    this.endTime = new Date(data.end_time);
    this.startTime = new Date(data.start_time);
    this.recordingStatus = data.recording_status;
    this.recordingUrl = data.recording_url;
    this.status = data.status;
  }

  isBeforeOpenToJoin(role: EventInviteeRole) {
    const startTime = addMinutes(this.startTime, AbstractEvent.getEnterOffset(role));
    return isBefore(new Date(), startTime);
  }

  get isAfterOpenToJoin() {
    const endTime = addMinutes(this.endTime, AbstractEvent.leaveOffset);
    return isAfter(new Date(), endTime);
  }

  get isPublished() {
    return this.status === 'published';
  }

  get isLive() {
    return this.isPublished && isEventLive({ startTime: this.startTime, endTime: this.endTime });
  }
}

export class PublicEvent extends AbstractEvent {
  administrators: Array<{ id: string }>;
  dialInInformation: DialInInformation;
  duration: number;
  eventType: EventType;
  htmlContent: string | null;
  textContent: string;
  viewPermission: EventPrivacy;
  meta?: EventMeta;

  constructor(data: PublicEventDataObject) {
    super(data);
    this.administrators = data.administrators;
    this.dialInInformation = data.dial_in_information;
    this.duration = data.duration;
    this.eventType = data.event_type;
    this.htmlContent = data.html_content;
    this.textContent = data.text_content;
    this.viewPermission = data.view_permission;
    this.meta = data.meta ? new EventMeta(data.meta) : undefined;
  }
}

export abstract class BaseEvent extends AbstractEvent {
  static generatePublishAt({
    isDuplicating,
    isProgram,
    publishAt,
  }: {
    isDuplicating?: boolean;
    isProgram?: boolean;
    publishAt?: Moment;
  }) {
    if (isProgram && publishAt && !isDuplicating) {
      return moment(publishAt);
    }

    return undefined;
  }

  static generateStartDate({
    startTime,
    isDuplicating,
    publishAt,
  }: {
    startTime?: Date;
    isDuplicating?: boolean;
    publishAt?: Moment;
  }) {
    if (startTime) {
      if (isDuplicating) {
        const tomorrow = moment().add(1, 'day');
        tomorrow.set('hours', startTime.getHours());
        tomorrow.set('minutes', startTime.getMinutes());
        tomorrow.set('seconds', 0);
        tomorrow.set('millisecond', 0);
        return tomorrow;
      }

      return moment(startTime);
    }

    if (publishAt) {
      return publishAt.clone().add(24, 'hours');
    }

    const start = moment().add(5, 'minutes');
    const remainder = 5 - (start.minute() % 5);
    return moment(start).add(remainder, 'minutes').startOf('minute');
  }

  static getEndDate({
    startTime,
    duration,
    endTime,
    isDuplicating,
  }: {
    startTime: Moment;
    duration?: number;
    endTime?: Date;
    isDuplicating?: boolean;
  }) {
    if (endTime && !isDuplicating) {
      return moment(endTime);
    }

    if (!duration) {
      return undefined;
    }

    return startTime.clone().add(duration, 'minutes');
  }

  currentUserRole: EventInviteeRole;
  currentUserRsvp: UserRSVP;
  invitees: RegisteredEventInvitee[];
  inviteesCount: number;
  changeRequests?: ChangeRequest[];

  constructor(data: BaseEventDataObject) {
    super(data);
    this.currentUserRole = data.current_user_role;
    this.currentUserRsvp = data.current_user_rsvp;
    this.invitees = data.invitees.map((invitee) => new RegisteredEventInvitee(invitee));
    this.inviteesCount = data.invitees_count;
    this.changeRequests = data.change_requests?.map((changeRequest) => new ChangeRequest(changeRequest));
  }

  // TODO START - this is something what some part should be permissions logic because it's affected by user logged
  override isBeforeOpenToJoin(role?: EventInviteeRole) {
    return super.isBeforeOpenToJoin(role ?? this.currentUserRole);
  }

  get isPast(): boolean {
    return isPast(this.endTime);
  }

  // TODO possible refactor - current states of event (upcoming vs past vs "joinable") are not accurate
  get isOpenToJoin(): boolean {
    if (this.bmgId && this.currentUserRsvp === 'going') {
      return !(this.isBeforeOpenToJoin() || this.isAfterOpenToJoin);
    }

    return false;
  }

  get isRecordingAvailable(): boolean {
    return this.recordingStatus === 'recorded' && this.recordingUrl !== null;
  }

  get isOpenToResponse(): boolean {
    return !this.isPast;
  }
  // TODO END - this is something what some part should be permissions logic because it's affected by user logged

  get isStarted(): boolean {
    return moment().isAfter(this.startTime);
  }

  get isInProgress(): boolean {
    return moment().isBetween(this.startTime, this.endTime);
  }

  get isFinished(): boolean {
    return moment().isAfter(this.endTime);
  }

  get lastChangeRequestStatus(): ChangeRequestStatus | undefined {
    return getLastChangeRequestStatus(this);
  }
}

export class ListEvent extends BaseEvent {
  // TODO use User model when it is available
  author: { id: string };
  postInCommunities: PostInCommunity[];

  constructor(data: ListEventDataObject) {
    super(data);
    this.author = data.author;
    this.postInCommunities = data.post_in_communities.map((postInCommunity) => ({
      ...postInCommunity,
      organizationId: postInCommunity.organization_id,
    }));
    this.status = data.status;
  }

  // TODO there are not comming invitees in List
  // Original was logic can(event, 'Event', 'isEmailInvitee')
  isEmailInvitee = () => false;
}

class EventMetaItem {
  name: string;
  translations: {
    en: string;
    es: string;
  };

  constructor(data: EventMetaItemDataObject) {
    this.name = data.name;
    this.translations = data.translations;
  }
}

class EventMeta {
  patientType?: EventMetaItem;
  serviceType?: EventMetaItem[];

  constructor(data: EventMetaDataObject) {
    this.patientType = data.patient_type ? new EventMetaItem(data.patient_type) : undefined;
    this.serviceType = data.service_type?.map((serviceType) => new EventMetaItem(serviceType));
  }
}

export class Event extends BaseEvent {
  // TODO use UserDataObject when available
  administrators: Array<{ id: string }>;
  dialInInformation: DialInInformation;
  duration: number;
  emailInvitees: EventEmailInvitee[];
  eventType: EventType;
  eventsFiles: EventFile[];
  feedbacked: boolean;
  goingInviteesCount: number;
  htmlContent: string | null;
  notGoingInviteesCount: number;
  pendingInviteesCount: number;
  textContent: string;
  viewPermission: EventPrivacy;
  meta?: EventMeta;
  tags?: Tag[];
  showInChannels?: boolean;
  featuredInChannels?: boolean;

  constructor(data: EventDataObject) {
    super(data);
    this.administrators = data.administrators;
    this.dialInInformation = data.dial_in_information;
    this.duration = data.duration;
    this.emailInvitees = data.email_invitees.map((invitee) => ({
      email: invitee.email,
      eventRole: invitee.event_role,
    }));
    this.eventType = data.event_type;
    this.eventsFiles = data.events_files.map((file) => ({
      bigMarkerId: file.big_marker_id,
      byteSize: file.byte_size,
      checksum: file.checksum,
      contentType: file.content_type,
      createdAt: file.created_at,
      downloadUrl: file.download_url,
      filename: file.filename,
      id: file.id,
      url: file.url,
    }));
    this.feedbacked = data.feedbacked;
    this.goingInviteesCount = data.going_invitees_count;
    this.htmlContent = data.html_content;
    this.notGoingInviteesCount = data.not_going_invitees_count;
    this.pendingInviteesCount = data.pending_invitees_count;
    this.textContent = data.text_content;
    this.viewPermission = data.view_permission;
    this.meta = data.meta ? new EventMeta(data.meta) : undefined;
    this.tags = data.tags;
    this.showInChannels = data.show_in_channels;
    this.featuredInChannels = data.featured_in_channels;
  }
}

type ConferenceOrWebinarFeedback = {
  isHelpful: number;
  isAudioOk: number;
  isVideoOk: number;
  helpfulText?: string;
  audioText?: string;
  videoText?: string;
};

type TelehealthFeedback = {
  isAudioOk: number;
  isVideoOk: number;
  audioText?: string;
  videoText?: string;
  recommendationLikelihood: number;
  recommendationLikelihoodText?: string;
  providerRating: number;
  providerRatingText?: string;
};

export type EventFeedback = ConferenceOrWebinarFeedback | TelehealthFeedback;

export type EventSlim = Entity & {
  coverPhoto?: Image;
  endTime: Date;
  startTime: Date;
  place?: string;
};

export const getEventSlimData = (data: EventSlimDataObject): EventSlim => ({
  id: data.id,
  name: data.name,
  coverPhoto: data.cover_photo ? new Image(data.cover_photo) : undefined,
  endTime: new Date(data.end_time),
  startTime: new Date(data.start_time),
  place: data.place,
});

export const isEventInProgress = ({
  startTime,
  endTime,
  endDifferenceInMinutes = 58,
  startDifferenceInMinutes = 0,
}: {
  startTime: Date | string;
  endTime: Date | string;
  endDifferenceInMinutes?: number;
  startDifferenceInMinutes?: number;
}) => {
  return isWithinInterval(new Date(), {
    start: addMinutes(typeof startTime === 'string' ? parseISO(startTime) : startTime, startDifferenceInMinutes),
    end: addMinutes(typeof endTime === 'string' ? parseISO(endTime) : endTime, endDifferenceInMinutes),
  });
};

export const isEventLive = ({ startTime, endTime }: { startTime: Date | string; endTime: Date | string }) =>
  isEventInProgress({ startTime, endTime });
