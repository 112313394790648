import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from 'query';
import { useTranslation } from 'react-i18next';
import { message } from 'ui';
import { getCommunityMembersKey, getCommunityQueryKey } from '..';
import { inviteUsersToCommunity } from '../../services/inviteUsersToCommunity';

export const useInviteUsers = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<undefined, { id: string; invitees: { emails: string[]; phones: string[]; userIds: string[] } }>(
    async ({ id, invitees }) => {
      const changeRequests = [];

      for (const email of invitees.emails) {
        changeRequests.push({
          change_request: {
            kind: 'community_invitation',
            subject_type: 'Community',
            subject_id: id,
            meta: { invitation_email: email },
          },
        });
      }

      for (const phone of invitees.phones) {
        changeRequests.push({
          change_request: {
            kind: 'community_invitation',
            subject_type: 'Community',
            subject_id: id,
            meta: { invitation_phone: phone },
          },
        });
      }

      for (const userId of invitees.userIds) {
        changeRequests.push({
          change_request: {
            kind: 'community_invitation',
            subject_type: 'Community',
            subject_id: id,
            subject_user_id: userId,
          },
        });
      }

      const response = await inviteUsersToCommunity({ changeRequests });
      return response.data;
    },
    {
      onSuccess(_, params: { id: string }) {
        queryClient.invalidateQueries(getCommunityQueryKey({ id: params.id }));
        queryClient.invalidateQueries(getCommunityMembersKey({ communityId: params.id }));

        queryClient.invalidateQueries({
          predicate(query) {
            if (Array.isArray(query.queryKey)) {
              const [firstElement, secondElement] = query.queryKey;
              return firstElement === 'communities' && secondElement === 'user';
            }

            return false;
          },
        });
      },
      onError(error) {
        const errorMessage = error.response?.status === 422 ? 'errorUserAlreadyInvited' : 'Failed to invite users.';

        message.error(t(errorMessage));
      },
    },
  );
};
