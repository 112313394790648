import { type EventFeedback } from 'models';
import { Form, Section, RateInput, ModalFormActions, Title, TextAreaInput } from 'ui';

export type EventFeedbackConfig = {
  key: string;
  title: string;
  starLabel: string;
  textAreaLabel: string;
  textKey: string;
};

type P = {
  onSubmit: (data: EventFeedback) => void;
  onCancel: () => void;
  loading: boolean;
  config: EventFeedbackConfig[];
};

const RateEventFeedbackForm = ({ onSubmit, onCancel, loading, config }: P) => {
  const [form] = Form.useForm();

  const onFinish = (data: EventFeedback) => {
    onSubmit(data);
  };

  return (
    <Form<EventFeedback> onFinish={onFinish} form={form}>
      {config.map((section, index) => (
        <Section key={section.key} paddingTop={index > 0} paddingBottom={false}>
          <Title level={5}>{section.title}</Title>
          <RateInput
            item={{
              name: section.key,
              label: section.starLabel,
              rules: [
                {
                  required: true,
                },
              ],
            }}
          />
          <Form.Item noStyle dependencies={[section.key]}>
            {({ getFieldValue }) =>
              getFieldValue(section.key) < 5 ? (
                <TextAreaInput
                  item={{
                    name: section.textKey,
                    label: section.textAreaLabel,
                  }}
                />
              ) : null
            }
          </Form.Item>
        </Section>
      ))}
      <ModalFormActions right={{ onClick: onCancel, children: 'Skip' }} submit={{ children: 'Send', loading }} />
    </Form>
  );
};

export default RateEventFeedbackForm;
