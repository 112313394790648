import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { Attachment } from '../feed-object/shared/Attachment';
import { Box } from '../box';
import { CommentForm } from './CommentForm';
import { CommentsList } from './CommentsList';
import { Icon } from '../icon';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { Translation } from '../translation';
import { RichTextRenderer } from '../rich-text';
import { isVisualFile } from '../../utils/files';
import { can } from '../../utils/permissions';
import { AutocompleteService } from '../../services/AutocompleteService';
import { ChatToButton } from '../../widgets/chat-to-button';
import { CommentBookmark } from 'features/feed';
import {
  Avatar,
  Image,
  Loading,
  BookmarkIconLine,
  Dropdown,
  LinkLine,
  Button,
  DotsIcon,
  ReportIcon,
  EditIcon,
  DeleteIcon,
  message,
  Link,
} from 'ui';
import { Count } from '../count';
import { canReportContentObject, canLikeContentObject } from 'permissions';
import { copyLinkToClipBoard } from 'common/utils';
import { images } from 'common/utils';

export class Renderer extends React.Component {
  onRequestMentions = async (value) => {
    if (this.props.originalItem?.post_in_communities.length) {
      const response = await AutocompleteService.getCommunityUsers({
        scope: 'communities',
        search: value,
        community_ids: this.props.originalItem.post_in_communities.map((c) => c.id),
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    if (this.props.course?.id) {
      const response = await AutocompleteService.getProgramUsers({
        scope: 'programs',
        search: value,
        program_ids: [this.props.course.id],
        limit: 5,
      });

      if (response.ok) {
        return response.data;
      }
    }

    return [];
  };

  state = {
    edit: false,
    reply: false,
    loading: false,
  };

  handleAdminCommentDeletionRights = () =>
    this.props.viewer.admin_organizations.some(
      (organization) =>
        this.props.originalItem?.post_in_communities.some(
          (postInCommunity) => postInCommunity.organization_id === organization,
        ) || organization === this.props.course?.organization.id,
    );

  menu = () => {
    const actions = [
      {
        label: this.props.t('Copy link'),
        key: 'link',
        icon: <LinkLine width={16} height={16} />, // TODO some size prop should handle icon size
      },
    ];

    if (this.props.comment.author.id === this.props.viewer.id) {
      actions.push({
        label: this.props.t('Edit'),
        key: 'edit',
        icon: <EditIcon width={16} height={16} />, // TODO some size prop should handle icon size
      });
    }

    if (canReportContentObject({ viewer: this.props.viewer, contentObject: this.props.comment })) {
      actions.push({
        label: this.props.t('Report'),
        key: 'report',
        icon: <ReportIcon width={16} height={16} />, // TODO some size prop should handle icon size
      });
    }

    if (
      this.props.comment.author?.id === this.props.viewer.id ||
      this.props.viewer.superadmin ||
      this.handleAdminCommentDeletionRights()
    ) {
      actions.push({
        label: this.props.t('Delete'),
        key: 'delete',
        danger: true,
        icon: <DeleteIcon width={16} height={16} />, // TODO some size prop should handle icon size
      });
    }

    return actions;
  };

  copyLink = async () => {
    try {
      await copyLinkToClipBoard(this.props.comment);
      message.success('Link copied to clipboard');
    } catch {
      message.error('Sorry, something went wrong.');
    }
  };

  onSelectKey = ({ key }) => {
    switch (key) {
      case 'link':
        this.copyLink();
        break;
      case 'edit':
        this.setState({ edit: !this.state.edit });
        break;
      case 'report':
        this.props.onReportObject(this.props.comment);
        break;
      case 'delete':
        this.props.onDeleteObject({ object: this.props.comment, originalItem: this.props.originalItem });
        break;
    }
  };

  renderAvatar = () => {
    const { comment } = this.props;
    return (
      <Link to={`/members/${comment.author.id}`} className="comment__avatar">
        <Avatar size={45} photo={comment.author.profile_photo ?? images.default.userProfilePhoto} />
      </Link>
    );
  };

  renderMetaInfoLine = () => {
    const { comment } = this.props;
    return (
      <Box flexDirection="row" alignItems="center" justifyContent="space-between" className="comment__meta-info">
        <Box flexDirection="row" alignItems="center">
          <Link to={`/members/${comment.author.id}`}>
            <Text size={14} weight="600" className="comment__meta-info__author">
              {`${comment.author.first_name} ${comment.author.last_name}`}
            </Text>
          </Link>
          <div className="comment__meta-info__message">
            <ChatToButton legacyUserId={comment.author.id} />
          </div>
          <Text size={11} style={{ marginLeft: 7 }}>
            {this.props.t('dateFormatShortMonthDayYearTime', { date: new Date(comment.created_at) })}
          </Text>
        </Box>
        <div className="comment__bookmark-icon">
          <Icon
            name={`pba-bookmark${comment.bookmarked ? '-filled' : '-empty'}`}
            size={25}
            onClick={() => this.props.onBookmarkObject(comment)}
          />
        </div>
        <Dropdown
          className="comment__menu"
          menu={{
            items: this.menu(),
            onClick: this.onSelectKey,
          }}
          trigger={['click']}
        >
          <Button icon={<DotsIcon />} type="link" />
        </Dropdown>
      </Box>
    );
  };

  renderVisualAttachments() {
    const imageAttachments = this.props.comment.attachments.filter(isVisualFile);
    return imageAttachments.length ? (
      <div className="comment__visual-attachments">
        <Image.PreviewGroup>
          {imageAttachments.map((image) => (
            <Image height={100} width={120} src={image.url} alt={image.filename} />
          ))}
        </Image.PreviewGroup>
      </div>
    ) : null;
  }

  renderAttachments(bookmark) {
    return this.props.comment.attachments.length && !this.state.edit ? (
      <div className={`${bookmark ? 'comment__attachments-wrapper' : ''}`}>
        {this.renderVisualAttachments()}
        <div className="comment__attachments">
          <Text className="comment__attachments__title" size="11px" lineHeight="36px" weight="bold">
            <Trans>Attachments</Trans>
          </Text>
          <div className="comment__attachments__wrapper">
            {this.props.comment.attachments.map((attachment) => (
              <Attachment key={attachment.id} attachment={attachment} />
            ))}
          </div>
        </div>
      </div>
    ) : null;
  }

  renderMessageLine = () => {
    const { comment } = this.props;
    const index = this.props.index || 0;

    return (
      <div style={{ marginBottom: 5 }} className="dont-break-out" id="comment-message-line">
        {this.state.edit ? (
          <CommentForm
            index={index}
            focused={true}
            viewer={this.props.viewer}
            onSubmit={this.onEditObject}
            comment={comment}
            onRequestMentions={this.onRequestMentions}
            suffixId={`${index}-${comment.id}`}
            loading={this.state.loading}
          />
        ) : comment.html_content ? (
          <React.Fragment>
            <Text size={14} color="#455A64">
              <RichTextRenderer data={comment.html_content} />
            </Text>
            {comment.text_content ? <Translation id={comment.id} type={comment.type} /> : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  };

  renderActionsLine = () => {
    const { props } = this;

    return (
      <ThemeConsumer>
        {() => (
          <Box flexDirection="row" className="comment__actions-line" style={{ alignItems: 'center' }}>
            <Box flexDirection="row" style={{ alignItems: 'center' }}>
              {canLikeContentObject({ contentObject: this.props.originalItem }) ? (
                <Count
                  liked={props.comment.liked}
                  iconName="like"
                  onClick={() => props.onLikeObject(this.props.comment)}
                  style={{ paddingRight: 9 }}
                  id={`like-${props.comment.id}`}
                >
                  {props.comment.likes_count > 0 ? props.comment.likes_count : undefined}
                </Count>
              ) : null}
            </Box>
            {this.props.originalItem && can(this.props.originalItem, 'ContentObject', 'comment') && (
              <Text
                onClick={() => this.setState({ reply: !this.state.reply })}
                size="13px"
                lineHeight="15px"
                style={{ cursor: 'pointer' }}
              >
                <Trans>Reply</Trans>
              </Text>
            )}
          </Box>
        )}
      </ThemeConsumer>
    );
  };

  createBookMarkAction = () => {
    return [
      {
        label: this.props.t('Unbookmark'),
        key: 'unbookmark',
        onClick: () => this.props.onBookmarkObject(this.props.comment),
        icon: <BookmarkIconLine width={16} height={16} />,
      },
    ];
  };

  onEditObject = (comment) => {
    this.setState({ edit: false });
    this.props.onEditObject({ comment });
  };

  render() {
    const { comment } = this.props;
    const index = this.props.index || 0;
    const nextIndex = index + 1;
    return this.props.isBookmark ? (
      <CommentBookmark
        parentId={comment.top_parent_shareable_id}
        parentType={comment.top_parent_type}
        renderMessageLine={this.renderMessageLine()}
        author={comment.author}
        timestamp={parseISO(comment.created_at)}
        renderAttachments={() => this.renderAttachments(true)}
        isOnFeed={true}
        actions={this.createBookMarkAction()}
        pill={this.props.pill}
        item={this.props.comment}
        viewer={this.props.viewer}
      />
    ) : (
      <ThemeConsumer>
        {() => (
          <div
            className="reply comment"
            ref={this.props.scrollRef}
            style={{ padding: this.props.parent === 'comment' ? '0' : '15px 25px 0 25px' }}
          >
            {this.renderAvatar()}
            <div style={{ width: 'calc(100% - 60px)' }}>
              {this.renderMetaInfoLine()}
              {this.renderMessageLine()}
              {this.renderAttachments()}
              {this.renderActionsLine()}
              {this.state.loading ? <Loading /> : null}
              {this.state.reply &&
                this.props.originalItem &&
                can(this.props.originalItem, 'ContentObject', 'comment') && (
                  <div style={{ paddingBottom: '15px' }}>
                    <CommentForm
                      index={index}
                      viewer={this.props.viewer}
                      onSubmit={async (draft) => {
                        this.setState({ loading: true });
                        const response = await this.props.onCreateComment(
                          draft,
                          this.props.parent !== 'comment' ? comment.id : comment.parent_id,
                        );
                        this.setState({ loading: false, reply: false });
                        return response;
                      }}
                      onRequestMentions={this.onRequestMentions}
                      focused={this.props.focused}
                      suffixId={`reply-${index}-${comment.id}`}
                      loading={this.state.loading}
                    />
                  </div>
                )}
              {comment.replies?.length > 0 ? (
                <CommentsList
                  item={comment}
                  index={nextIndex}
                  scrollToCommentId={this.props.scrollToCommentId}
                  scrollToReplyId={this.props.scrollToReplyId}
                  onCreateComment={this.props.onCreateComment}
                  onDeleteObject={this.props.onDeleteObject}
                  onEditObject={this.props.onEditObject}
                  onLikeObject={this.props.onLikeObject}
                  onReportObject={this.props.onReportObject}
                  onSortChange={this.props.onSortChange}
                  onBookmarkObject={this.props.onBookmarkObject}
                  parent="comment"
                  showSortFilter={false}
                  viewer={this.props.viewer}
                  originalItem={this.props.originalItem}
                  course={this.props.course}
                />
              ) : null}
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

export const Comment = withTranslation(undefined, { withRef: true })(Renderer);
