import { ArrowLeftOutlined } from '@ant-design/icons';
import { Modal as ModalAD, Spin } from 'antd';
import cs from 'classnames';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Title } from 'ui';

type P = {
  isOpened: boolean;
  onCancel?: () => void;
  title?: React.ReactNode | string;
  loading?: boolean;
  confirmLoading?: boolean;
  step?: number;
  disableBack?: boolean;
  steps?: React.ReactNode[];
  closable?: boolean;
  onBackClick?: () => void;
  error?: Error | null;
  'data-test-id'?: string;
  footer?: React.ReactNode;
  onOk?: () => void;
  width?: number | 'large' | 'small';
  maskClosable?: boolean;
  destroyOnClose?: boolean;
  className?: string;
  zIndex?: number;
};

const Modal: React.FC<P> = ({
  isOpened,
  onCancel,
  title,
  loading = false,
  confirmLoading = false,
  step = 0,
  disableBack = false,
  steps,
  closable = true,
  onBackClick,
  error,
  children,
  footer = false,
  onOk,
  width = 'large',
  maskClosable = true,
  destroyOnClose = false,
  className,
  zIndex,
}) => {
  const { t } = useTranslation();
  const content = children ?? steps?.[step];

  return (
    <ModalAD
      onCancel={onCancel}
      title={
        <div className="modal__header">
          {!disableBack && (
            <Button
              className="modal__back"
              type="link-secondary"
              icon={<ArrowLeftOutlined />}
              onClick={onBackClick}
              aria-label="Go to previous step"
            />
          )}
          {title ? (
            <div className="modal__title-container">
              <Title level={2} className="modal__title">
                {title}
              </Title>
            </div>
          ) : null}
        </div>
      }
      open={isOpened}
      closable={closable}
      confirmLoading={confirmLoading}
      width={width}
      footer={footer}
      onOk={onOk}
      className={cs(`modal__size--${width}`, className)}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
      zIndex={zIndex}
    >
      <>
        <div className="modal__space" style={loading ? {} : { display: 'none' }}>
          <Spin size="large" />
        </div>
        <div style={loading ? { display: 'none' } : {}}>
          {error ? (
            <div className="modal__space modal__error">
              <label className="modal__error">{t(error.message)}</label>
            </div>
          ) : (
            content
          )}
        </div>
      </>
    </ModalAD>
  );
};

export default Modal;
