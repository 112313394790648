import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from 'query';
import { getCommunityMembersKey, getCommunityQueryKey } from '..';
import { changeRoleInComunity } from '../../services';

export const useDeleteUserFromComunity = () => {
  const queryClient = useQueryClient();

  return useMutation<undefined, { userId: string; communityId: string }>(
    async ({ userId, communityId }) => {
      const result = await changeRoleInComunity({
        user_id: userId,
        entity_ids: [communityId],
        role: 'none',
      });
      return result.data;
    },
    {
      async onSuccess(_, params: { communityId: string }) {
        queryClient.invalidateQueries(getCommunityQueryKey({ id: params.communityId }));
        queryClient.invalidateQueries(getCommunityMembersKey({ communityId: params.communityId }));
      },
    },
  );
};
