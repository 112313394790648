import { FC } from 'react';
import { User } from '../../../../common/models';
import { routes } from '../../../../common/services';
import { Avatar, Button } from '../../../../ui';
import { ReactComponent as CloseIcon } from './close.svg';
import { Participant, getParticipantInitials } from '../../../../models';

type P = {
  user: Participant;
  name: string;
  onCloseClick?: (user: Participant) => void;
};

const UserCardTag: FC<P> = ({ user, name, onCloseClick }: P) => {
  const handleCloseClick = onCloseClick;

  return (
    <div className="user-card-tag">
      <Avatar
        size={24}
        alt={`${name} Profile photo`}
        className="user-card-tag__avatar"
        src={
          new URL(
            routes.chatrooms.media.getAvatarFile(user._id, 'user_avatar', 'medium'),
            process.env.REACT_APP_MEDIA_MODULE_URL,
          ).href
        }
      >
        {getParticipantInitials(user)}
      </Avatar>
      <h3 className="user-card-tag__name">{name}</h3>

      {handleCloseClick && (
        <Button type="link" className="icon icon--send" onClick={() => handleCloseClick(user)}>
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};

export default UserCardTag;
