import * as React from 'react';

import { getAppConfig } from '../app-config-gate';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';
const GOOGLE_AUTH_URL = 'https://accounts.google.com/gsi/client';
const DEVELOPER_KEY = process.env.REACT_APP_GOOGLE_DEVELOPER_KEY;
const SCOPE = 'https://www.googleapis.com/auth/drive.readonly';

export class GooglePicker extends React.Component {
  componentDidMount() {
    this.injectGoogleScripts(GOOGLE_SDK_URL, 'googlejs', this.onApiLoad);
    this.injectGoogleScripts(GOOGLE_AUTH_URL, 'googlejsAuth');
  }

  componentDidUpdate() {
    if (this.props.opened) {
      this.onSelect();
      this.props.onDropdownClose?.();
    }
  }

  injectGoogleScripts(authUrl, scriptId, onLoad) {
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = authUrl;
      script.onload = onLoad;
      script.async = true;
      script.defer = true;

      if (document.head) {
        document.head.appendChild(script);
      }
    }
  }

  onApiLoad() {
    if (!window.gapi) return;

    window.gapi.load('picker');
  }

  doAuth(callback) {
    if (!window.google.accounts) return;

    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: getAppConfig().constants.GOOGLE_ID,
      scope: SCOPE,
      callback: callback,
    });

    client.requestAccessToken();
  }

  onSelect = () => {
    if (!window.google.accounts && !window.gapi) return;

    this.doAuth((response) => {
      if (response.access_token) {
        this.createPicker(response.access_token);
        window.google.accessToken = response.access_token;
      } else {
        console.warn('[Google Picker] Auth failed');
      }
    });
  };

  createPicker(oauthToken) {
    const view = new window.google.picker.View(window.google.picker.ViewId.DOCS);

    if (!view) {
      console.warn("[Google Picker] Can't find view by viewId");
    }

    const picker = new window.google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(oauthToken)
      .setDeveloperKey(DEVELOPER_KEY)
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .setCallback(this.props.onSelect);

    picker.build().setVisible(true);
  }

  render() {
    return React.cloneElement(this.props.children, { onClick: this.onSelect });
  }
}
