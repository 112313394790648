import * as React from 'react';
import { AttachmentTrigger } from './components';
import { Box } from '../box';
import { DropboxChooser } from '../dropbox-chooser';
import { GooglePicker } from '../google-picker';
import { GoogleService } from 'old/services/GoogleService';
import { Icon } from '../icon';
import { StorageService } from 'old/services/StorageService';
import { Uploader } from '../uploader';
import { Dropdown, Button } from 'ui';

export class AttachmentMenu extends React.Component {
  state = {
    isUploading: false,
    openedKey: undefined,
  };

  uploaderRef = React.createRef();

  setUploading = (isUploading) => {
    if (!this.props.hiddenText) {
      this.setState({ isUploading });
    }
    this.props.onUpload(isUploading);
  };

  onSelectGoogle = async (data) => {
    if (data.action === 'picked' && window.gapi) {
      this.setUploading(true);

      let response;
      const pickerFile = data.docs[0];
      const accessToken = window.google.accessToken;

      if (pickerFile.type === 'document') {
        response = await GoogleService.exportDriveDoc({
          fileId: pickerFile.id,
          mimeType: 'application/pdf',
          accessToken: accessToken,
        });
      } else {
        response = await GoogleService.getDriveFile(pickerFile.id, accessToken);
      }
      const file = new File([response.data], pickerFile.name, { type: pickerFile.mimeType });

      if (this.props.disableUpload) {
        this.props.onChange([file]);
      } else {
        const responseData = await StorageService.send(file);
        this.props.onChange([responseData.data]);
      }

      this.setUploading(false);
    }
  };

  onSelectDropbox = async (data) => {
    this.setUploading(true);

    if (Array.isArray(data)) {
      if (this.props.disableUpload) {
        const promises = data.map(async (file) => {
          const response = await fetch(file.link);
          const blob = await response.blob();
          blob.name = file.name;
          return blob;
        });

        const files = await Promise.all(promises);

        this.props.onChange(files);
      } else {
        const files = data.map((file) => ({
          name: file.name,
          url: file.link,
        }));

        const promises = files.map((file) => StorageService.send(file));

        const responses = await Promise.all(promises);
        this.props.onChange(responses.map((r) => r.data));
      }
    }

    this.setUploading(false);
  };

  menu = () => [
    {
      label: 'Your Computer',
      key: 'image-upload',
      onClick: () => {
        if (this.uploaderRef.current) {
          this.uploaderRef.current.click();
        }
      },
      icon: <Icon size={16} name="image-upload" />,
    },
    {
      label: 'Google Drive',
      key: 'gdrive',
      icon: (
        <GooglePicker onSelect={this.onSelectGoogle} opened={this.state.openedKey === 'gdrive'} onDropdownClose={() => this.setState({ openedKey: undefined })} >
          <Icon size={16} className="attachment-menu__icon" name="gdrive" />
        </GooglePicker>
      ),
    },
    {
      label: 'Dropbox',
      key: 'dropbox',
      icon: (
        <DropboxChooser onSelect={this.onSelectDropbox} opened={this.state.openedKey === 'dropbox'} onDropdownClose={() => this.setState({ openedKey: undefined })} >
          <Icon size={16} className="attachment-menu__icon" name="dropbox" />
        </DropboxChooser>
      ),
    },
  ];

  onSelectKey = ({ key }) => {
    switch (key) {
      case ('image-upload'):
        this.setState({ openedKey: 'image-upload' });
        break;
      case ('gdrive'):
        this.setState({ openedKey: 'gdrive' });
        break;
      case ('dropbox'):
        this.setState({ openedKey: 'dropbox' });
        break;
    }
  };

  render() {
    const { AttachmentTriggerComponent, justifyContent } = this.props;

    return (
      <>
        <Dropdown
          menu={{
            items: this.menu(),
            onClick: this.onSelectKey
          }}
          trigger={['click']}
        >
         <Button style={{ border: 0, background: 'none', padding: 0 }}
           id={`attachment-icon-${this.props.suffixId}`}
           disabled={this.props.disabled} >
            {AttachmentTriggerComponent ? (
              <AttachmentTriggerComponent uploading={this.state.isUploading} active={this.props.active} />
            ) : (
              <AttachmentTrigger uploading={this.state.isUploading} active={this.props.active} />
            )}
          </Button>
        </Dropdown>
        <Box justifyContent={justifyContent ?? 'flex-end'} className="attachment-menu">
          <Uploader
            disableUpload={this.props.disableUpload}
            multiple
            onChange={this.props.onChange}
            setUploading={this.setUploading}
            TriggerComponent={() => <div ref={this.uploaderRef} />}
            id={`attachment-add-${this.props.suffixId}`}
          />
        </Box>
      </>
    );
  }
}
