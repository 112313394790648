import { PublicEventContentObject } from 'models';
import { useQuery } from 'query';
import { type PublicEventContentObjectDataObject } from 'models/service';

import { getEvent } from '../services';

import { getEventQueryKey } from '.';

export const useEventPublicQuery = (
  { eventId, invitationToken }: { eventId: string; invitationToken?: string },
  options?: { useErrorBoundary?: boolean },
) => {
  return useQuery<PublicEventContentObject>(
    getEventQueryKey({ eventId, view: 'public', invitationToken }),
    async () => {
      const { data: eventContenObject } = await getEvent<PublicEventContentObjectDataObject>(eventId, {
        invitation_token: invitationToken,
      });

      return new PublicEventContentObject(eventContenObject);
    },
    {
      useErrorBoundary: true,
      ...options,
    },
  );
};
