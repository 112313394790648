import { type QueryKey, QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      retry: 0,
    },
  },
});

export type { QueryFunctionContext, QueryKey } from '@tanstack/react-query';

export const generateQueryKey = <T extends QueryKey = QueryKey>(data: T) => JSON.parse(JSON.stringify(data)) as T;

export * from './useQuery';
export * from './useInfiniteQuery';
export * from './useMutation';
export * from './useResetQuery';
