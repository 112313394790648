import { type EventContentObject, type EventFeedback } from 'models';
import { Modal, Skeleton, message, Text } from 'ui';

import { useCreateEventFeedaback, useEventQuery } from '../../queries';
import { type EventFeedbackConfig, RateEventFeedbackForm } from './RateEventFeedbackForm';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  eventId: string;
};

const RateEventFeedbackModal = ({ onSuccess, onCancel, isOpened, eventId }: P) => {
  const { isLoading: isCreatingEventFeedback, mutate: createEventFeedback } = useCreateEventFeedaback();
  const { data, isLoading } = useEventQuery({ eventId });

  const createConfig = (data: EventContentObject): EventFeedbackConfig[] => {
    if (data.event.eventType === 'conference' || data.event.eventType === 'webinar') {
      return [
        {
          key: 'isHelpful',
          title: 'Rate the Event',
          starLabel: 'How useful was the event?',
          textAreaLabel: 'What would improve your event experience?',
          textKey: 'helpfulText',
        },
        {
          key: 'isAudioOk',
          title: 'Rate the Audio Quality',
          starLabel: 'How would you rate the audio quality?',
          textAreaLabel: 'What would improve your audio quality?',
          textKey: 'audioText',
        },
        {
          key: 'isVideoOk',
          title: 'Rate the Video Quality',
          starLabel: 'How would you rate the video quality?',
          textAreaLabel: 'What would improve your video quality?',
          textKey: 'videoText',
        },
      ];
    }

    return [
      {
        key: 'recommendationLikelihood',
        title: 'Rate the Service',
        starLabel: 'What is your likelihood of recommending this service to friends or family?',
        textAreaLabel: 'What would improve your experience with this service?',
        textKey: 'recommendationLikelihoodText',
      },
      {
        key: 'providerExperience',
        title: 'Rate the Provider',
        starLabel: 'How would you rate your experience with your provider today?',
        textAreaLabel: 'What would improve your experience with your provider?',
        textKey: 'providerExperienceText',
      },
      {
        key: 'isAudioOk',
        title: 'Rate the Audio Quality',
        starLabel: 'How would you rate the audio quality?',
        textAreaLabel: 'What would improve your audio quality?',
        textKey: 'audioText',
      },
      {
        key: 'isVideoOk',
        title: 'Rate the Video Quality',
        starLabel: 'How would you rate the video quality?',
        textAreaLabel: 'What would improve your video quality?',
        textKey: 'videoText',
      },
    ];
  };

  const onSubmit = async (data: EventFeedback) => {
    createEventFeedback(
      { id: eventId, data },
      {
        onSuccess() {
          message.success('Thank you for your feedback.');
          onSuccess();
        },
        onError(error) {
          message.error(error.response?.data.message ?? 'Failed to send feedback.');
        },
      },
    );
  };

  return (
    <Modal
      title="Rate Event Experience"
      isOpened={isOpened}
      onCancel={onCancel}
      maskClosable={false}
      width="small"
      disableBack
    >
      {isLoading || !data ? (
        <Skeleton active />
      ) : data.event.feedbacked ? (
        <Text>Event was already rated</Text>
      ) : (
        <RateEventFeedbackForm
          onSubmit={onSubmit}
          loading={isCreatingEventFeedback}
          onCancel={onCancel}
          config={createConfig(data)}
        />
      )}
    </Modal>
  );
};

export default RateEventFeedbackModal;
