import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import { GuestLayout } from '../../layouts/GuestLayout';
import { CommunityLayout } from '../../layouts/CommunityLayout';
import { MembersList } from '../../atoms/members-list';
import { Text } from '../../atoms/text';
import { Button } from '../../atoms/button';
import { ThemeConsumer } from '../../atoms/theme';
import { Card} from 'ui';
import { RichTextRenderer } from '../../atoms/rich-text';

class Renderer extends React.Component {
  createCommunityDescription(community) {
    let result = (
      <Text>
        <Trans>No Results</Trans>
      </Text>
    );
    if (community?.html_description) {
      result = <RichTextRenderer data={community.html_description} />;
    }
    return result;
  }

  createCommunityContact(community) {
    let result = (
      <Text>
        <Trans>No Results</Trans>
      </Text>
    );
    if (community?.administrators?.length) {
      result = <MembersList members={community.administrators} onDropdownSelect={() => {}} />;
    }
    return result;
  }

  joinCommunity = () => {
    this.props.history.push('/sign-up', {
      join_community_id: this.props.communityId,
      referrer: window.location.pathname,
    });
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <GuestLayout
            content={
              <CommunityLayout communityId={this.props.communityId} isPublicView>
                {(community) => (
                  <div className="row">
                    <div style={{ marginBottom: 15 }}>
                      <Button
                        size="lg"
                        textColor="white"
                        color={theme.color.brand}
                        onClick={this.joinCommunity}
                        title={
                          <React.Fragment>
                            <Trans>Join Community</Trans>
                          </React.Fragment>
                        }
                      />
                    </div>
                    <div className="col-xs-7">
                      <Card
                        title={
                          <div style={{ textTransform: 'uppercase' }}>
                            {community.privacy} <Trans>group</Trans>
                          </div>
                        }
                      >
                        {this.createCommunityDescription(community)}
                      </Card>
                    </div>

                    <div className="col-xs-5">
                      <Card
                        title={
                          <div style={{ textTransform: 'uppercase' }}>
                            <Trans>Contact</Trans>
                          </div>
                        }
                      >
                        {this.createCommunityContact(community)}
                      </Card>
                    </div>
                  </div>
                )}
              </CommunityLayout>
            }
          />
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => ({
  communityId: props.match.params.communityId,
});

export const PagePublicCommunityAbout = connect(mapState)(Renderer);
