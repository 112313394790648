// DEPRECATED - use instead API
import axios, { type AxiosResponse } from 'axios';
import qs from 'qs';
import { dispatch } from '../../old/store';
import { defaultHeadersInterceptor, defaultRailsInterceptor } from './interceptors';

export { appVersion, googleReCaptchaSiteKey } from './api-keys';

export interface ApiClientResponse<T> extends AxiosResponse<T> {
  ok: boolean;
  unauthorized?: boolean;
  errorMessage?: string;
  errorType?: string;
}

const apiClient = axios.create({
  responseType: 'json',
  baseURL: `${process.env.REACT_APP_API_URL}`,
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

apiClient.interceptors.request.use(async (config) => defaultHeadersInterceptor(config));

apiClient.interceptors.request.use(async (config) => defaultRailsInterceptor(config));

apiClient.interceptors.response.use(
  (response) => normalizeSuccessResponse(response),
  (error) => normalizeErrorResponse(error),
);

function normalizeSuccessResponse<T>(response: AxiosResponse<T>): ApiClientResponse<T> {
  return {
    ...response,
    ok: true,
  };
}

function normalizeErrorResponse(error: any) {
  if (
    ['invalid_grant', 'invalid_token', 'user_banned'].includes(error.response?.data?.error) ||
    error.response?.status === 401
  ) {
    dispatch.session.logOut();
  }

  return {
    ...error,
    ok: false,
    unauthorized: error.response?.status === 401,
    errorMessage: error.response?.data?.message,
    errorType: error.response?.data?.error,
  };
}

export default apiClient;
