import { AutocompleteUser } from 'models';
import { useQuery } from 'query';

import { getCommunitiesUsers } from '../services';
import { getCommunityUsersKey } from '.';

export const useEventCommunitiesUsers = (
  // TODO role should be type
  { communitiesIds, role }: { communitiesIds: string[]; role?: 'admin' | 'member' },
  options?: { useErrorBoundary?: boolean },
) => {
  return useQuery<AutocompleteUser[]>(
    getCommunityUsersKey({ communitiesIds, role }),
    async () => {
      const usersResponse = await getCommunitiesUsers({ community_ids: communitiesIds, role });

      return usersResponse.data.map((user: any) => new AutocompleteUser(user));
    },
    {
      // TODO this is set to `always` communities users can be changed and React Query is not notified about changes
      refetchOnMount: 'always',
      useErrorBoundary: true,
      ...options,
    },
  );
};
