import { Row, Col, Form } from 'antd';
import type React from 'react';
import { useTranslation } from 'react-i18next';

type PContent = {
  title?: string;
  tabs: TabP[];
  value?: any;
  onChange?: (value: any) => void;
  onSelect?: (value: any) => void;
};

type TabP = {
  key: any;
  content: any;
  id: string;
};

type P = {
  noStyle?: boolean;
  name?: string;
  title?: string;
  tabs: TabP[];
  onSelect?: (value: any) => void;
};

const CardTabsContent: React.FC<PContent> = ({ value, onChange, title, tabs, onSelect }) => {
  const { t } = useTranslation();
  const span = Math.floor(24 / tabs.length);

  const onClick = (value: any) => {
    return () => {
      if (onSelect) {
        onSelect(value);
      }

      if (onChange) {
        onChange(value);
      }
    };
  };

  return (
    <>
      {title ? (
        <Row>
          <Col className="ant-form-item-label">
            <label>{t(title)}</label>
          </Col>
        </Row>
      ) : null}
      <Row className="card-tabs">
        {tabs.map((tab: TabP) => (
          <Col
            key={`card-tab_content_${tab.key}`}
            span={span}
            className={value === tab.key ? 'selected' : ''}
            onClick={onClick(tab.key)}
            id={tab.id}
          >
            {tab.content}
          </Col>
        ))}
      </Row>
    </>
  );
};

const CardTabs: React.FC<P> = ({ name, title, tabs, onSelect, noStyle = true }) => {
  return (
    <Form.Item name={name} noStyle={noStyle}>
      <CardTabsContent tabs={tabs} title={title} onSelect={onSelect} />
    </Form.Item>
  );
};

export default CardTabs;
