export const copyToClipboard = async (generateClipboardString: () => Promise<string>) => {
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    const clipboardItem = new ClipboardItem({
      'text/plain': generateClipboardString().then(async (result: string) => {
        return new Promise((resolve) => {
          resolve(new Blob([result]));
        });
      }),
    });
    navigator.clipboard.write([clipboardItem]);
  } else {
    const result = await generateClipboardString();
    navigator.clipboard.writeText(result);
  }
};
