import { apiClient, type ApiClientResponse, routes } from 'common/services';

export const confirmTimezone = async (userId: string, timeZone: string) => {
  const updateUser = {
    user: {
      time_zone: timeZone,
    },
  };

  return apiClient.patch<undefined, ApiClientResponse<undefined>>(routes.user.patch(userId), updateUser);
};
