import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { timezones } from 'common/utils';
import {
  Section,
  SelectInput,
  Title,
  Row,
  Col,
  Form,
  TextInput,
  NumberInput,
  ModalFormActions,
  BirthDateInput,
} from 'ui';

import { type Gender, type Option, type PersonalData } from '../../types';
import { languages, genders, imperialUnits } from '../../utils';

export interface PersonalFormData extends Omit<PersonalData, 'heightInImperial'> {
  heightInImperial: { feet: number; inches: number };
}

type P = {
  user: { firstName?: string; lastName?: string; gender?: Gender };
  initialData?: PersonalFormData;
  onBack: () => void;
  onSubmit: (values: PersonalFormData) => void;
};

const generateInitialValues = (
  user: { firstName?: string; lastName?: string; gender?: Gender },
  initialData?: PersonalFormData,
) => {
  const initialValues: Partial<PersonalFormData> = {
    prefferedLanguage: 'en_US',
    prefferedTimezone: 'America/Chicago',
    firstName: user.firstName,
    lastName: user.lastName,
    gender: user.gender,
    ...initialData,
  };

  return initialValues;
};

const PersonalInfo = ({ user, onBack, onSubmit, initialData }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<PersonalFormData>();

  const initialValues: Partial<PersonalFormData> = generateInitialValues(user, initialData);

  const prefferedLanguages: Option[] = languages;
  const prefferedTimezones: Option[] = timezones;

  const handleFinish = (values: PersonalFormData) => {
    onSubmit(values);
  };

  const genderOptions: Option[] = useMemo(
    () => genders.map((gender) => ({ label: t(gender.label), value: gender.value })),
    [t],
  );

  return (
    <Form<PersonalFormData> form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Section>
        <fieldset>
          <legend>
            <Title level={4}>{t('Personal information')}</Title>
          </legend>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'firstName',
                  label: 'First name',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'given-name',
                }}
              />
            </Col>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <TextInput
                item={{
                  name: 'lastName',
                  label: 'Last name',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  autocomplete: 'family-name',
                }}
              />
            </Col>
          </Row>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <SelectInput
                item={{
                  label: 'Gender',
                  name: 'gender',
                  'data-test-id': 'select-gender',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  options: genderOptions,
                  getOptionLabel: (option) => option.label,
                  getOptionValue: (option) => option.value,
                }}
              />
            </Col>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <BirthDateInput item={{ label: 'Date of birth', name: 'birthDate' }} />
            </Col>
          </Row>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <NumberInput
                item={{
                  name: 'weightInImperial',
                  label: 'Weight',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  min: 1,
                  max: 1323,
                  controls: false,
                  addonAfter: imperialUnits.units.weight.label,
                }}
              />
            </Col>
            <Col item={{ xs: { span: 12 }, md: { span: 6 } }}>
              <SelectInput
                item={{
                  name: ['heightInImperial', 'feet'],
                  label: 'Height (ft)',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  options: [...Array.from({ length: 9 }).keys()],
                  getOptionLabel: (option) => option.toString(),
                  getOptionValue: (option) => option,
                }}
              />
            </Col>
            <Col item={{ xs: { span: 12 }, md: { span: 6 } }}>
              <SelectInput
                item={{
                  name: ['heightInImperial', 'inches'],
                  label: 'Height (in)',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  options: [...Array.from({ length: 12 }).keys()],
                  getOptionLabel: (option) => option.toString(),
                  getOptionValue: (option) => option,
                }}
              />
            </Col>
          </Row>
          <Row item={{ gutter: 24 }}>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <SelectInput
                item={{
                  name: 'prefferedLanguage',
                  label: 'My language',
                  'data-test-id': 'select-preffered-language',
                }}
                input={{
                  showSearch: true,
                  options: prefferedLanguages,
                  getOptionLabel: (option) => option.label,
                  getOptionValue: (option) => option.value,
                }}
              />
            </Col>
            <Col item={{ xs: { span: 24 }, md: { span: 12 } }}>
              <SelectInput
                item={{
                  name: 'prefferedTimezone',
                  label: 'My timezone',
                  'data-test-id': 'select-preffered-timezone',
                }}
                input={{
                  showSearch: true,
                  options: prefferedTimezones,
                  getOptionLabel: (option) => option.label,
                  getOptionValue: (option) => option.value,
                }}
              />
            </Col>
          </Row>
        </fieldset>
      </Section>
      <ModalFormActions left={{ onClick: onBack }} submit={{ children: 'Continue' }} />
    </Form>
  );
};

export default PersonalInfo;
