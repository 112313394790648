import { useQueryClient } from '@tanstack/react-query';
import { Entity, type Privacy } from 'models';
import { useMutation } from 'query';
import { changeRequest } from 'common/services';
import { getChannelsFeatureQueryKey } from 'features/channels/queries';
import { getCommunitiesInfiniteQueryKey, getCommunityQueryKey } from '..';
import { joinCommunity } from '../../services';

// TODO should not invalidate all communities, make invalidate correct ones (List type / Detail)
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const useJoinCommunity = (onRequestDone?: () => Promise<unknown> | unknown) => {
  const queryClient = useQueryClient();

  return useMutation<Entity, { id: string; privacy: Privacy; reason?: string }>(
    async ({ id, privacy, reason }) => {
      // TODO probably ideally in future these 2 seperated mutations
      const communityResponse =
        privacy === 'public'
          ? await joinCommunity({ id, reason })
          : await changeRequest({
              kind: 'community_request_to_join',
              subject_type: 'Community',
              subject_id: id,
              meta: {
                reason_to_join: reason,
              },
            });

      // TODO remove when user will be in react-query
      // invalidate user query instead in onSuccess/onError then
      // this is now because user contains communities and old logic do not work properly without this
      await onRequestDone?.();

      return new Entity(communityResponse.data);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(getCommunityQueryKey({}));
        // TODO this will be removed with useCommunityDataObjectQuery
        queryClient.invalidateQueries(['data-object', ...getCommunityQueryKey({})]);
        queryClient.invalidateQueries(getCommunitiesInfiniteQueryKey({}));
        queryClient.invalidateQueries(getChannelsFeatureQueryKey());
      },
      onError() {
        queryClient.invalidateQueries(getCommunityQueryKey({}));
        // TODO this will be removed with useCommunityDataObjectQuery
        queryClient.invalidateQueries(['data-object', ...getCommunityQueryKey({})]);
      },
    },
  );
};
