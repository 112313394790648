import { CommunityListItem, RegisteredEventInvitee, type ReduxUser } from 'models';
import moment from 'moment';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { AxiosErrorHandler } from 'common/error-handlers';
import { type EventContentObjectDataObject } from 'models/service';
import { Button, Modal, Result, Skeleton } from 'ui';
import { CreateEventFormModal } from './components';
import { useChangeRequestsQuery, useEventUserQuery } from './queries';

export const ChangeRequestTelehealthEvent = ({
  viewer,
  onCancel,
  onSuccess,
  changeRequest,
}: {
  viewer: ReduxUser;
  onCancel: (force?: boolean) => void;
  onSuccess: (eventData: EventContentObjectDataObject) => Promise<void> | void;
  changeRequest: string;
}) => {
  const { push } = useHistory();

  const communitiesData = useMemo<CommunityListItem[]>(
    () => viewer.joined_communities.map((community) => new CommunityListItem(community)),
    [viewer],
  );

  // TODO fix duplicity with onboarding request
  const {
    isLoading: isLoadingChangeRequest,
    isFetching: isFetchingChangeRequest,
    data: changeRequestData,
    error: changeRequestError,
  } = useChangeRequestsQuery({ changeRequestId: changeRequest });

  const {
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
    data: userData,
    error: userError,
  } = useEventUserQuery({ userId: changeRequestData?.subjectUser.id }, { useErrorBoundary: false });

  if (
    (isLoadingChangeRequest && isFetchingChangeRequest) ||
    !changeRequestData ||
    changeRequestData.status !== 'pending' ||
    (isLoadingUser && isFetchingUser) ||
    !userData
  ) {
    return (
      <Modal
        isOpened
        title={'Create Telehealth Visit'}
        disableBack
        onCancel={() => {
          onCancel(true);
        }}
      >
        <AxiosErrorHandler error={changeRequestError ?? userError} showError actions={false}>
          {changeRequestData?.status === 'approved' ? (
            <Result
              status="info"
              title="Telehealth visit has been created already"
              subTitle="The event you're trying to create has already been created"
              extra={
                // TODO fix redirect to be `<a` so also `useHistory` is not here
                <Button
                  onClick={() => {
                    push(routes.events.detail(changeRequestData.meta.eventId));
                  }}
                  size="large"
                >
                  Go to event
                </Button>
              }
            />
          ) : changeRequestData?.status === 'denied' ? (
            <Result
              status="error"
              title="Request for Telehealth Visit denied"
              subTitle="The request you're trying to access has been denied already. Please go back to Admin Console and select Denied requests."
            />
          ) : changeRequestData?.status === 'expired' ? (
            <Result
              status="error"
              title="Request for Telehealth Visit expired"
              subTitle="The request you're trying to access has expired already"
            />
          ) : (
            <Skeleton active paragraph={{ rows: 12 }} />
          )}
        </AxiosErrorHandler>
      </Modal>
    );
  }

  return (
    <CreateEventFormModal
      isOpened
      viewer={viewer}
      communities={communitiesData}
      initialData={{
        communityId: changeRequestData.subject.subjectId,
        patientType: changeRequestData.meta.patientType.name,
        visitType: changeRequestData.meta.visitType.name,
        visitTypeSubOption: changeRequestData.meta.visitTypeOption.name,
        visitTypeSubSubOption: changeRequestData.meta.visitTypeSubOption?.name,
        startTime: moment(changeRequestData.meta.visitDate),
        duration: changeRequestData.meta.visitDuration,
        invitees: [
          new RegisteredEventInvitee({
            id: userData.id,
            first_name: userData.firstName,
            last_name: userData.lastName,
            profile_photo: userData.profilePhoto,
            event_role: 'attendee',
          }),
        ],
        htmlContent: changeRequestData.meta.htmlContent,
        textContent: changeRequestData.meta.textContent,
        changeRequestId: changeRequestData.id,
      }}
      onCancel={onCancel}
      onSuccess={onSuccess}
    />
  );
};
