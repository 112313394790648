import * as React from 'react';

import { GoogleService } from '../../services/GoogleService';
import { Button, GoogleLogoIcon } from 'ui';

export class GoogleLogin extends React.Component {
  onLogin = async () => {
    const data = await GoogleService.login({ redirectUri: window.location.origin + '/sign-up' }); // This is hack, we don't care about redirect, and only some are allowed on google settings

    this.props.onSuccess(data);
  };

  render() {
    return (
      <Button
        onClick={() => this.onLogin()}
        className={this.props.className}
        icon={<GoogleLogoIcon />}
        data-test-id="btn_google-sign-in"
        block
        size="large"
      >
        Sign in with Google
      </Button>
    );
  }
}
