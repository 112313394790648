import { routes } from 'routes';
import { images } from 'common/utils';

import { Avatar, Link } from 'ui';

type P = {
  authorId: string;
  avatar: {
    url: string;
    alias?: string;
  };
};

const AvatarLink = ({ authorId, avatar }: P) => {
  return (
    <Link to={routes.members.detail(authorId)} className="avatar-link" effects={{ focus: true, hover: true }}>
      <Avatar size="large" photo={avatar.url ?? images.default.userProfilePhoto}>
        {avatar.alias}
      </Avatar>
    </Link>
  );
};

export default AvatarLink;
