import * as React from 'react';
import { withTranslation } from 'react-i18next';

import { RichTextEditor } from '../../atoms/rich-text';
import { FieldError } from '../../atoms/form/FieldError';
import { Field } from 'formik';
import { StorageService } from '../../services/StorageService';

export class Renderer extends React.Component {
  state = {
    values: {
      ...this.props.initialValues,
    },
    clicked: false,
  };

  handleFieldChange = (fieldProps) => (value) => {
    this.setState(
      (state) => ({
        values: {
          ...state.values,
          html_content: value.html,
          text_content: value.text,
        },
      }),
      () => {
        fieldProps.form.setFieldValue('html_content', value.html);
        fieldProps.form.setFieldValue('text_content', value.text);
      },
    );
  };

  componentWillUnmount() {
    this.setState({
      values: {
        html_content: '',
        text_content: '',
      },
    });
  }
  handleClicked = () => {
    this.setState({ clicked: true });
  };

  render() {
    return (
      <Field>
        {(fieldProps) => (
          <div onClick={this.handleClicked} data-test="field_description" style={{ margin: '20px 0' }}>
            <div
              style={{
                color: '#90a4ae',
                fontSize: 12,
                fontWeight: 100,
                lineHeight: '14px',
                padding: '0 5px',
                textAlign: 'left',
                opacity: 1,
              }}
            >
              {this.props.label}
            </div>
            <div style={{ padding: '0 5px' }}>
              <RichTextEditor
                onChange={this.handleFieldChange(fieldProps)}
                data={this.state.values.html_content}
                onRequestUpload={StorageService.send}
                placeholder={this.props.t('Description')}
                maxWidth={'622px'}
              />
            </div>
            <FieldError name="text_content" />
          </div>
        )}
      </Field>
    );
  }
}

export const CampaignDescription = withTranslation()(Renderer);
