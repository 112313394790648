import { dispatch } from 'old/store';
import { useMutation } from 'query';
import { type ContentObjectDataObject } from 'models/service';
import { getContentObject } from '../../services';

export const useReloadContentObjectMutation = () => {
  return useMutation<ContentObjectDataObject, { id: string }>(
    async ({ id }) => {
      const response = await getContentObject(id);
      return response.data;
    },
    {
      async onSuccess(data) {
        dispatch.feed.storeContentObjectData(data);
      },
    },
  );
};
