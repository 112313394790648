import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Form, TextInput, Section, Button } from 'ui';

type P = {
  onSubmit: (data: { email: string }) => void | void;
  loading: boolean;
  onNavigateToPrimaryLogin: () => void;
  email?: string;
};

const ForgotPasswordForm = ({ onSubmit, loading, onNavigateToPrimaryLogin, email }: P) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = (data: { email: string }) => {
    onSubmit(data);
  };

  return (
    <Form onFinish={onFinish} initialValues={{ email }} form={form} className="forgot-password-form">
      <TextInput
        item={{
          name: 'email',
          label: 'Email',
          rules: [
            {
              type: 'email',
            },
            {
              required: true,
              whitespace: true,
            },
          ],
        }}
        input={{
          autocomplete: 'email',
        }}
      />
      <Section paddingTop paddingBottom={false} lineTop={false} lineBottom={false}>
        <Button
          className="forgot-password-form__button"
          type="primary"
          htmlType="submit"
          loading={loading}
          icon={loading ? <LoadingOutlined /> : null}
          size="large"
        >
          {t('Send reset link')}
        </Button>
        <Button
          className="forgot-password-form__button"
          onClick={() => {
            onNavigateToPrimaryLogin();
          }}
          size="large"
        >
          {t(`Back to Log in`)}
        </Button>
      </Section>
    </Form>
  );
};

export default ForgotPasswordForm;
