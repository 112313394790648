import { type AxiosRequestConfig } from 'axios';
import { appVersion } from '../api-keys';

export const defaultHeadersInterceptor = (config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers['x-app-name'] = String(process.env.REACT_APP_CONFIG);
    config.headers['x-app-platform'] = 'fe';
    config.headers['x-app-version'] = appVersion;
  }

  return config;
};
