import cn from 'classnames';
import { type ReduxUser } from 'models';
import { GlobalSearch } from 'old/atoms/global-search';
import { type ReactNode, Suspense } from 'react';
import { AxiosErrorBoundary } from 'common/error-handlers';
import { type NodeUserDataObject } from 'models/service';
import { Loading } from '../../Loading';

import { AppLogo } from './AppLogo';
import { Navigation } from './Navigation';
import { UserMenu } from './UserMenu';

type P = {
  user: ReduxUser;
  // TODO we need to ensure this is preloaded always and should be internal object
  nodeUser?: NodeUserDataObject;
  unreadMessages?: number;
  // TODO create `react-query` and load it here instead
  badges: {
    total: number;
  };
  onLogOut: () => Promise<void>;
  children?: ReactNode;
  aside?: ReactNode;
};

const AppLayout = ({ user, nodeUser, badges, unreadMessages, onLogOut, children, aside }: P) => {
  const showMessagesNotifications = Boolean(user.badge_notifications_settings?.enable_notifications.value);

  return (
    <section className={cn('app-layout', { 'app-layout--with-sidebar': Boolean(aside) })}>
      <AxiosErrorBoundary actions handleUnauthorized>
        <header className="app-layout__header">
          <div className="app-layout__header__content">
            <div className="app-layout__header__content__logo">
              <AppLogo />
            </div>
            <div className="app-layout__header__content__search-bar">
              <GlobalSearch user={user} />
            </div>
            <div className="app-layout__header__content__user-menu">
              <UserMenu viewer={user} nodeViewer={nodeUser} badges={badges} onLogOut={onLogOut} />
            </div>
          </div>
        </header>

        <div className="app-layout__main">
          <nav className="app-layout__main__left-sidebar">
            <div className="app-layout__main__left-sidebar__content">
              <Navigation
                badges={{ '/messages': { count: showMessagesNotifications ? Number(unreadMessages) : 0 } }}
                viewer={user}
              />
            </div>
          </nav>
          <AxiosErrorBoundary actions>
            <main className="app-layout__main__center">
              <Suspense fallback={<Loading />}>
                <div className="app-layout__main__center__content">{children}</div>
              </Suspense>
            </main>
            {aside ? (
              <aside className="app-layout__main__right-sidebar">
                <div className="app-layout__main__right-sidebar__content">{aside}</div>
              </aside>
            ) : null}
          </AxiosErrorBoundary>
        </div>
      </AxiosErrorBoundary>
    </section>
  );
};

export default AppLayout;
