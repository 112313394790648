import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans, withTranslation } from 'react-i18next';

import { Entity } from 'models';
import { Avatar } from 'ui';
import { Box } from '../../atoms/box/Box';
import { Button } from '../../atoms/button';
import { Count } from '../../atoms/count';
import { Icon } from '../../atoms/icon';
import { Loader } from '../../atoms/loader/Loader';
import { CommunityService } from '../../services/CommunityService';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { SegmentedControl } from './SegmentedControl';
import { Community } from './Community';
import { RichTextRenderer } from '../../atoms/rich-text';
import { ConsentsModal } from 'features/consents';
import { images } from 'common/utils';

const buttonSettings = {
  type: 'button',
  size: 'lg',
  block: true,
};

function Section(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <React.Fragment>
          <Text
            size="11px"
            lineHeight="1.2"
            weight="600"
            color={theme.color.lightGray}
            component="p"
            style={{ padding: '5px 15px', borderBottom: '1px solid #EDEFF2' }}
          >
            {props.title.toUpperCase()}
          </Text>
          {props.children}
        </React.Fragment>
      )}
    </ThemeConsumer>
  );
}

function Contact(props) {
  return (
    <div className="organization-preview__contact">
      <Avatar size={42} name={props.user.first_name} photo={props.user.profile_photo ?? images.default.userProfilePhoto} />
      <Text size="11px" lineHeight="12px" component="p" className="organization-preview__contact__text">
        {props.user.first_name} <br />
        <strong>
          <Trans>Member</Trans>
        </strong>
      </Text>
    </div>
  );
}

export class Renderer extends React.Component {
  state = {
    selectedTab: 'about',
  };

  TABS = [
    { value: 'about', title: this.props.t('About'), scope: '' },
    { value: 'communities', title: this.props.t('Communities'), scope: '' },
  ];

  async componentDidMount() {
    await CommunityService.get(this.props.data.id);
  }

  onShowConsents = (show) => {
    this.setState({ isConsentsOpened: show });
  };

  renderAbout = (loading, theme, data, viewer) => {
    return !loading ? (
      <React.Fragment>
        <div className="organization-preview__about">
          <Count
            iconName="user"
            iconColor={theme.color.lightGray}
            color="#B0BEC5"
            style={{ marginBottom: 15, display: 'inline-block' }}
          >
            {data.users_count ?? 0}
          </Count>
          {data.joined === true ? (
            <React.Fragment>
              <div style={{ marginTop: 10 }}>
                <Button {...buttonSettings} color={theme.color.green} outline title={<Trans>You're a member</Trans>} disabled />
              </div>
            </React.Fragment>
          ) : data.privacy === 'public' ? (
            <div style={{ marginTop: 10 }}>
              {data.required_consents?.some((requiredConsent) => !viewer.users_consents?.some((consent) => requiredConsent.id === consent.id)) ? (
                <Button
                  {...buttonSettings}
                  color={theme.color.green}
                  textColor="white"
                  title={<Trans>Confirm consents to join</Trans>}
                  onClick={() => {
                    this.onShowConsents(true);
                  }}
                />
              ) : (
                <Button
                  {...buttonSettings}
                  color={theme.color.green}
                  textColor="white"
                  title={this.props.joining ? <Loader /> : <Trans>Join Now</Trans>}
                  onClick={() => this.props.onJoinNow(data)}
                />
              )}
            </div>
          ) : data.privacy === 'private' ? (
            data.joined === 'pending' ? (
              <React.Fragment>
                <div style={{ marginTop: 10 }}>
                  <Button
                    {...buttonSettings}
                    color={theme.color.green}
                    outline
                    disabled
                    title={(props) => (
                      <React.Fragment>
                        <Icon name="pba-arrow-left" size={28} color={props.textColor} />
                        <Text style={props.textStyle} color={props.textColor}>
                          <Trans>Request sent</Trans>
                        </Text>
                      </React.Fragment>
                    )}
                  />
                </div>
                <Text color={theme.color.gray} size="14px" lineHeight="1.3">
                  <Trans>
                    Requests to join communities are generally reviewed within <strong>24 hours</strong>. Feel free to
                    continue browsing other communities that interest you.
                  </Trans>
                </Text>
              </React.Fragment>
            ) : (
              <div style={{ marginTop: 10 }}>
                <Button
                  type="button"
                  color={theme.color.green}
                  textColor="white"
                  size="lg"
                  block
                  title={this.props.t('Request to Join')}
                  onClick={() => this.props.onJoinNow(data)}
                />
              </div>
            )
          ) : null}
        </div>

        <Section title="Contact">
          <div style={{ paddingBottom: 10 }}>
            <div style={{ overflow: 'auto', padding: '20px 0' }}>
              <div style={{ whiteSpace: 'nowrap', display: 'inline-block', padding: '0 10px' }}>
                {data.administrators.map((user) => (
                  <Contact key={user.id} user={user} />
                ))}
              </div>
            </div>
          </div>
        </Section>
        <Section title="About">
          <div style={{ padding: '22px 15px' }}>
            <RichTextRenderer data={data.html_description} />
            {data.url ? (
              <div>
                <Icon name="link" color={theme.color.lightGray} size={24} style={{ marginRight: 15, float: 'left' }} />
                <a
                  href={data.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.color.brand, fontSize: 14, textDecoration: 'none', lineHeight: '28px' }}
                >
                  {data.url}
                </a>
              </div>
            ) : null}
          </div>
        </Section>
      </React.Fragment>
    ) : (
      <Loader color={theme.color.lightGray} size={30} />
    );
  };

  onCommunityClick = (id) => {
    if (!this.props.onCommunityClick || !this.props.onCommunityClick(id)) {
      window.location.href = `/communities/${id}`;
    }
  };

  renderCommunities = (data) => {
    return data.communities.length ? (
      <div>
        {data.communities.map((community) => (
          <Box flexDirection="row" key={community.id} className="organization-preview__community">
            <Community onClick={() => this.onCommunityClick(community.id)} community={community} />
          </Box>
        ))}
      </div>
    ) : (
      <Text>
        <Trans>No communities</Trans>
      </Text>
    );
  };

  render() {
    const { data, loading, viewer, fetchUser } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="organization-preview">
            <Box
              justifyContent="flex-end"
              className="organization-preview__header"
              style={{ backgroundImage: `url(${data.cover_photo ? data.cover_photo.url : images.default.organizationCoverPhoto})` }}
            >
              <Box flexDirection="row" alignItems="center" style={{ position: 'relative' }}>
                {data.profile_photo ? (
                  <img className="organization-preview__image" src={data.profile_photo?.url ?? images.default.organizationProfilePhoto} alt="" />
                ) : null}
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                  {data.short_name ? (
                    <Text size="22px" lineHeight="1.2" color="white" weight="500" component="p">
                      {data.short_name}
                    </Text>
                  ) : null}
                  <Text size="16px" lineHeight="1.2" color="white" component="p">
                    {data.name}
                  </Text>
                </div>
              </Box>
            </Box>
            <div style={{ borderBottom: '2px solid #EDEFF2' }}>
              <SegmentedControl
                labels={this.TABS}
                selectedLabel={this.state.selectedTab}
                onChange={(label) => {
                  this.setState({ selectedTab: label });
                }}
                fontSize={13}
                buttonMargin="0"
                buttonPadding="15px"
              />
            </div>
            {this.state.selectedTab === 'about' ? this.renderAbout(loading, theme, data, viewer) : this.renderCommunities(data)}
            {this.state.isConsentsOpened ? (
              <ConsentsModal
                isOpened
                entity={new Entity(data.default_community ?? data)}
                requiredConsentIds={data.required_consents.map((consent) => consent.id)}
                onAccept={async () => {
                  await fetchUser();
                  this.onShowConsents(false);
                }}
                onReject={async () => {
                  await fetchUser();
                  this.onShowConsents(false);
                }}
                onClose={() => {
                  fetchUser();
                  this.onShowConsents(false);
                }}
                zIndex={100000}
              />
            ) : null}
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state) => {
  return {
    viewer: select.session.user(state),
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchUser: () => {
      return dispatch.session.fetchUser();
    },
  };
};

export const OrganizationPreview = connect(mapState, mapDispatch)(withTranslation()(Renderer));
