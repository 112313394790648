import { isEventLive, type UserRSVP } from 'models';

export const useIsLiveStatus = ({
  startTime,
  endTime,
  currentUserRsvp,
}: {
  startTime: Date;
  endTime: Date;
  currentUserRsvp: UserRSVP;
}) => {
  const isLive = isEventLive({ startTime, endTime });

  return currentUserRsvp === 'going' && isLive;
};
