import { useState, useEffect } from 'react';

import { type Social } from 'models/content-object';
import Post from 'models/post';
import type Program from 'models/program';
import { Modal, message } from 'ui';
import { PostForm } from './components';
import api from './services/api';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
  program: Program;
  onRequestReturn?: () => void;
};

const CreatePost = ({ onSuccess, onCancel, isOpened, program, onRequestReturn }: P) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [postData, setPostData] = useState<Post<Social>>(new Post({}, program));

  const fetchData = () => {
    (async () => {
      if (isOpened) {
        setPostData(new Post({}, program));
      }
    })();
  };

  const onSubmit = async (post: Post<Social>) => {
    setLoading(true);

    const postResponse = await api.createPost(post);

    if (postResponse.ok) {
      await onSuccess();
      message.success('Post was created.');
    } else {
      message.error('Failed to create post.');
    }

    setLoading(false);
  };

  useEffect(fetchData, [isOpened, program]);

  return (
    <Modal
      title={'Create Post'}
      isOpened={isOpened}
      onCancel={onCancel}
      onBackClick={onRequestReturn}
      disableBack={!onRequestReturn}
      destroyOnClose
    >
      <PostForm onSubmit={onSubmit} loading={loading} post={postData} program={program} />
    </Modal>
  );
};

export default CreatePost;
