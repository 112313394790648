import { getAppConfig } from 'old/atoms/app-config-gate';
import { dispatch } from 'old/store';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidationResponseErrorDataObject } from 'common/services';
import { message, Title } from 'ui';
import { Separator, SignUpForm, SocialButtons } from '../components';
import { useLoginQuery, useSignUpQuery, useUserQuery } from '../queries';

type P = {
  onSuccessLogin: () => void;
  onLoginError: () => void;
  publicCommunityId?: string;
  changeRequestId?: string;
  invitationToken?: string;
};

const SignUp = ({ publicCommunityId, onSuccessLogin, onLoginError, changeRequestId, invitationToken }: P) => {
  const { t } = useTranslation();
  const { mutateAsync: signUp, isLoading: isLoadingSignUp, error: signUpError, data: signUpData } = useSignUpQuery();
  const { mutate: getProfile, isLoading: isLoadingProfile, data: profileData } = useUserQuery();
  const { mutateAsync: login, isLoading: isLoadingLogin, isError: isLoginError, data: loginData } = useLoginQuery();
  const { constants } = getAppConfig();
  const hasAnySocialLogin = Boolean(constants.GOOGLE_ID);

  const onSignUpSuccess = useMemo(
    () => async (loginData: any) => {
      await dispatch.session.storeSuccessfulEmailResponse(loginData);
      getProfile();
    },
    [getProfile],
  );

  const onGetUser = useMemo(
    () => async (userData: any) => {
      await dispatch.session.setUserData(userData);
      onSuccessLogin();
    },
    [onSuccessLogin],
  );

  const onSubmit = async (submitData: { firstName: string; lastName: string; email: string; password: string }) => {
    await signUp({
      ...submitData,
      passwordConfirmation: submitData.password,
      publicCommunityId,
      invitationToken,
    });
    await login(submitData);
  };

  useEffect(() => {
    if (signUpData && loginData) {
      onSignUpSuccess(loginData);
    }
  }, [onSignUpSuccess, signUpData, loginData]);

  useEffect(() => {
    if (signUpError) {
      const errorData = signUpError.response?.data;
      message.error(
        isValidationResponseErrorDataObject(errorData)
          ? Object.entries(errorData.errors)
              .map(([key, value]) => value.map((error: string) => `${key} ${error}`).join(', '))
              .join(', ')
          : t('Something went wrong, please try again later.'),
      );
    }
  }, [signUpError, t]);

  useEffect(() => {
    if (isLoginError) {
      onLoginError();
    }
  }, [isLoginError, t, onLoginError]);

  useEffect(() => {
    if (profileData) {
      onGetUser(profileData);
    }
  }, [onGetUser, profileData]);

  return (
    <>
      <Title level={1}>{t('Sign Up')}</Title>
      <div className="onboarding-sign-up__form-wraper">
        <SignUpForm
          onSubmit={onSubmit}
          loading={isLoadingSignUp || isLoadingProfile || isLoadingLogin}
          changeRequestId={changeRequestId}
          invitationToken={invitationToken}
        />
      </div>
      {hasAnySocialLogin ? (
        <>
          <Separator text="or" />
          <SocialButtons onSuccessLogin={onSuccessLogin} />
        </>
      ) : null}
    </>
  );
};

export default SignUp;
