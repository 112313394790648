import { type NamePath } from 'antd/lib/form/interface';
import type React from 'react';
import { useEffect } from 'react';
import { Item } from './Item';

type PContent<T> = {
  value?: T;
  onChange?: (value: any) => void;
  onUpdate?: (value: T) => void;
  onSetOnChange?: (value: (value: T) => void) => void;
};

export type EmptyInputContentProperties<T> = {
  name: NamePath;
  onChange?: (value: T) => void;
  onSetOnChange?: (value: (value: T) => void) => void;
};

function EmptyInputContent<T>({ value, onChange, onUpdate, onSetOnChange }: PContent<T>): React.ReactElement {
  useEffect(() => {
    if (onUpdate && value) {
      onUpdate(value);
    }

    if (onSetOnChange && onChange) {
      onSetOnChange(onChange);
    }
  }, [value, onUpdate, onChange, onSetOnChange]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

function EmptyInput<T>({ name, onChange, onSetOnChange }: EmptyInputContentProperties<T>): React.ReactElement {
  return (
    <Item name={name} noStyle>
      <EmptyInputContent onUpdate={onChange} onSetOnChange={onSetOnChange} />
    </Item>
  );
}

export default EmptyInput;
