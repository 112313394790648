import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  isOnboardingTourStepOption,
  type UpdateOnboardingTourStepOptionHandler,
  type OnboardingTourStepOption,
  type UpdateOnboardingTourStepOptionHandlerOptions,
} from 'features/onboarding/models';

type P = {
  stepKey?: string;
};

const DEFAULT_PROPS: P = { stepKey: 'step' } as const;

export const useOnboardingTourStep = ({ stepKey = 'step' }: P = DEFAULT_PROPS): {
  step: OnboardingTourStepOption;
  onChangeStep: UpdateOnboardingTourStepOptionHandler;
} => {
  const { state, search } = useLocation();
  const { push, replace } = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const step = useMemo(() => {
    const step = searchParams.get(stepKey);

    return isOnboardingTourStepOption(step) ? step : undefined;
  }, [searchParams, stepKey]);

  const onChangeStep = useCallback(
    ({ step, replace: replaceInHistory = false }: UpdateOnboardingTourStepOptionHandlerOptions) => {
      const newSearchParams = new URLSearchParams(searchParams);

      if (step) {
        newSearchParams.set(stepKey, step);
      } else {
        newSearchParams.delete(stepKey);
      }

      (replaceInHistory ? replace : push)({ state, search: newSearchParams.toString() });
    },
    [push, replace, searchParams, state, stepKey],
  );

  return useMemo(
    () => ({
      step: step ?? 'intro',
      onChangeStep,
    }),
    [step, onChangeStep],
  );
};
