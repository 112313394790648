import * as React from 'react';
import update from 'immutability-helper';
import { Form } from 'formik';
import { Trans, withTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons';

import { WhiteBoard } from '../whiteboard';
import { withPermissions } from '../auth';
import { Button } from '../button';
import { Loader } from '../loader';
import { Kudo } from '../kudo';
import { ControlIcon, Files } from '../text-editor';
import { StorageService } from '../../services/StorageService';
import { AutocompleteService } from '../../services/AutocompleteService';
import { EMPTY_DRAFT } from './emptyDraftState';
import { PinnedStateToggle, ErrorBag, HeaderWithAccessoryView } from './components';
import { AttachmentMenu } from './AttachmentMenu';
import { validate } from './helpers';
import { RichTextEditor } from '../rich-text';
import { Text } from '../text';
import { Icon } from '../icon';
import CreatePoll from '../../../features/polls/CreatePoll';
import { Row, Col, Tooltip } from '../../../ui';
import variables from 'common/styles/variables.json';
import { CommunitySelect } from 'features/communities';

const allFields = [
  'post_in_communities',
  'html_content',
  'attachments',
  'poll',
  'pinned',
  'kudos',
  'emojis',
  'whiteboard',
];

class Renderer extends React.Component {
  static defaultProps = {
    draft: EMPTY_DRAFT,
    isCurriculum: false,
    fields: allFields,
    placeholder: 'Create post...',
  };

  static getContent = (draft) => {
    const content = draft[draft.type];

    return {
      html_content: content?.html_content ?? '',
      text_content: content?.text_content ?? '',
    };
  };

  state = {
    errors: {},
    isSubmitting: false,
    isUploading: false,
    isPollEditorActive: false,
    isWhiteboardContentAvailable: false,
    isKudoEditorActive: false,
    values: {
      ...this.props.draft,
      ...Renderer.getContent(this.props.draft),
    },
    createPoll: false,
    poll: null,
  };

  get isEdit() {
    return Boolean(this.props.draft?.id);
  }

  componentDidMount() {
    this.setState({
      isKudoEditorActive: this.state.values.type === 'kudo',
    });
  }

  onChange = (name, value) => this.props.onChange?.(name, value);

  handleRichTextUploading = (uploading) => this.setState({ richTextUploading: uploading });

  handleRichTextChange = (value) => {
    this.setState(
      update(this.state, { values: { html_content: { $set: value.html }, text_content: { $set: value.text } } }),
      () => {
        this.onChange('html_content', value.html);
        this.onChange('text_content', value.text);
        this.validateForm();
      },
    );
  };

  handleFieldChange = (name) => (value) => {
    this.setState(update(this.state, { values: { [name]: { $set: value } } }), () => {
      this.onChange(name, value);
      this.validateForm();
    });
  };

  handleAttachmentChange = (name) => (value) => {
    this.setState(update(this.state, { values: { [name]: { $push: value } } }), () => {
      this.onChange(name, this.state.values[name]);
      this.validateForm();
    });
  };

  onRemoveAttachment = (attachment) => () => {
    this.setState(({ values }) => {
      values.attachments = values.attachments.filter((att) => att.id !== attachment.id);
      this.onChange('attachments', values.attachments);

      return { values };
    });
  };

  toggleKudos = () => {
    this.handleFieldChange('kudos')(null);
    this.setState((prevState) => ({
      isKudoEditorActive: !prevState.isKudoEditorActive,
    }));
  };

  renderPinPostButton = (title) => {
    return withPermissions(
      [
        { scope: 'superadmin' },
        {
          scope: 'manage-community',
          subj: this.state.values.post_in_communities.map((community) => community.id),
        },
        {
          scope: 'super-user',
          type: 'crud_pinned_posts',
          subj: this.state.values.post_in_communities.map((community) => community.id),
        },
      ],
      <PinnedStateToggle title={title} active={this.state.values.pinned} onToggle={this.handleFieldChange('pinned')} />,
      null,
    );
  };

  renderCommonToolbar = (fields) => {
    const isDisabled = Boolean(this.state.poll);

    return (
      <React.Fragment>
        {fields.includes('kudos') && !this.isEdit && (
          <div style={{ marginRight: 30 }}>
            <ControlIcon
              name="endorsement"
              active={this.state.isKudoEditorActive}
              onClick={this.toggleKudos}
              id="icon_control-endorsement"
              disabled={isDisabled}
              tooltip={isDisabled ? 'You can’t Give Kudos now' : 'Kudos'}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  getMentions = async (value) => {
    if (!value) {
      return [];
    }

    if (this.state.values.post_in_communities?.length) {
      const response = await AutocompleteService.getCommunityUsers({
        scope: 'communities',
        search: value,
        community_ids: this.state.values.post_in_communities.map((c) => c.id),
        limit: 5,
      });

      if (response.ok) {
        return response.data.map((user) => ({
          id: `@${user.name}`,
          userId: user.id,
          link: `/members/${user.id}`,
        }));
      }
    }

    if (this.props.courseId) {
      const response = await AutocompleteService.getProgramUsers({
        scope: 'programs',
        search: value,
        program_ids: [this.props.courseId],
        limit: 5,
      });

      if (response.ok) {
        return response.data.map((user) => ({
          id: `@${user.name}`,
          userId: user.id,
          link: `/members/${user.id}`,
        }));
      }
    }

    return [];
  };

  createPoll = (values) => {
    this.setState({ poll: values, createPoll: false });
  };

  validateForm = (force) => {
    if (!this.state.validated && !force) {
      return [];
    }

    const { fields } = this.props;
    const { author, shared_content_object, post_in_communities } = this.state.values;

    const values = {
      ...this.state.values,
      post_in_communities: post_in_communities.map((community) => ({
        id: community.id,
        name: community.name,
      })),
      replies: undefined,
      author: author
        ? {
            id: author.id,
            first_name: author.first_name,
            last_name: author.last_name,
          }
        : undefined,
      shared_content_object: shared_content_object ? { id: shared_content_object.id } : undefined,
    };

    const errors = validate(values, fields);

    this.setState({ errors: errors, validated: true });

    return [values, errors];
  };

  renderEditor() {
    const { fields } = this.props;

    return (
      <Form
        onSubmit={async (evt) => {
          evt.preventDefault();

          this.setState({ isSubmitting: true });

          const [{ post_in_communities, ...values }, errors] = this.validateForm(true);

          if (Object.keys(errors).length === 0) {
            try {
              await this.props.onSubmit(
                this.state.poll
                  ? {
                      ...this.state.poll,
                      description: values.text_content,
                      html_description: values.html_content,
                      pinned: values.pinned,
                      community_id: post_in_communities?.[0]?.id,
                      attachments: values.attachments,
                    }
                  : {
                      ...values,
                      community_id: post_in_communities?.[0]?.id,
                      post: {
                        ...values.post,
                        html_content: values.html_content,
                        text_content: values.text_content,
                      },
                    },
              );
              this.setState({
                values: {
                  ...EMPTY_DRAFT,
                  post_in_communities: this.props.draft.post_in_communities,
                },
                validated: false,
                poll: null,
              });
            } catch (error) {
              console.log('Submition stopped');
            }
          }

          this.setState({ isSubmitting: false, isKudoEditorActive: false });
        }}
      >
        {fields.includes('post_in_communities') ? (
          <div style={{ borderBottom: '1px solid #ebeff1' }} data-test-id="post-editor_header">
            <HeaderWithAccessoryView viewer={this.props.viewer}>
              {/* TODO this is hacked now. Will be fixed properly with refactor of post editor */}
              <CommunitySelect
                value={
                  this.state.values.post_in_communities?.[0]
                    ? {
                        value: this.state.values.post_in_communities?.[0]?.id,
                        label: this.state.values.post_in_communities?.[0]?.name,
                      }
                    : undefined
                }
                onChange={(value) => {
                  this.handleFieldChange('post_in_communities')([{ id: value.value, name: value.label }]);
                }}
              />
            </HeaderWithAccessoryView>
          </div>
        ) : null}
        <div>
          {fields.includes('html_content') ? (
            <React.Fragment>
              {this.state.isWhiteboardContentAvailable ? (
                <WhiteBoard
                  onDrawingDone={(whiteboard_image) => {
                    this.handleAttachmentChange('attachments')(whiteboard_image);
                    this.setState({ isWhiteboardContentAvailable: false });
                  }}
                />
              ) : (
                <React.Fragment>
                  <div style={{ padding: '10px 20px' }} data-test-id="post-editor_content" id="post-editor_content">
                    <RichTextEditor
                      onUploading={this.handleRichTextUploading}
                      onChange={this.handleRichTextChange}
                      data={this.state.values.html_content}
                      onRequestUpload={StorageService.send}
                      placeholder={
                        this.state.isKudoEditorActive ? this.props.t('Express your Kudos...') : this.props.placeholder
                      }
                      mentions={this.getMentions}
                      maxWidth={this.props.maxWidth || '565px'}
                    />
                  </div>

                  {this.state.isKudoEditorActive && (
                    <Kudo
                      disabled={this.isEdit}
                      onSelect={this.handleFieldChange('kudos')}
                      value={this.state.values.kudo?.user}
                      communities={this.state.values.post_in_communities}
                    />
                  )}

                  {this.state.poll && (
                    <div className="post-editor__poll-item">
                      <Row item={{ gutter: variables.spaceMd.value }}>
                        <Col
                          item={{
                            span: 22,
                            className: 'post-editor__poll-body',
                            onClick: () => this.setState({ createPoll: true }),
                          }}
                        >
                          <Icon name="pol" size={18} />
                          <Text size={16} weight={400}>
                            <Trans>Poll</Trans>
                          </Text>
                        </Col>
                        <Col item={{ span: 2, className: 'post-editor__poll-delete' }}>
                          <CloseCircleOutlined
                            onClick={() => {
                              this.setState({ poll: null });
                            }}
                            data-test-id="btn_delete-poll"
                          />
                        </Col>
                      </Row>
                    </div>
                  )}

                  <Files attachments={this.state.values.attachments} onRemove={this.onRemoveAttachment} />
                </React.Fragment>
              )}
              <ErrorBag errors={this.state.errors} />
            </React.Fragment>
          ) : null}
          <div className="post-editor__control-panel" data-test-id="post-editor_control-panel">
            <div style={{ width: 10 }} />
            <React.Fragment>
              {fields.includes('poll') && (
                <div style={{ marginRight: 30 }}>
                  <ControlIcon
                    name="pol"
                    active={Boolean(this.state.poll)}
                    onClick={() => this.setState({ createPoll: true })}
                    id="icon_control-poll"
                    disabled={this.state.isKudoEditorActive}
                    tooltip={this.state.isKudoEditorActive ? 'You can’t create Poll now' : 'Poll'}
                  />
                  {this.state.createPoll && (
                    <CreatePoll
                      onSuccess={this.createPoll}
                      isOpened={this.state.createPoll}
                      onCancel={() => {
                        if (window.confirm(this.props.t('Do you wish to discard your Poll?'))) {
                          this.setState({ createPoll: false });
                        }
                      }}
                      poll={this.state.poll}
                    />
                  )}
                </div>
              )}
              {fields.includes('whiteboard') && (
                <div style={{ marginRight: 30 }}>
                  <ControlIcon
                    name="whiteboard"
                    active={this.state.isWhiteboardContentAvailable}
                    onClick={() =>
                      this.setState((prevState) => ({
                        isWhiteboardContentAvailable: !prevState.isWhiteboardContentAvailable,
                      }))
                    }
                    data-test-id="icon_control-whiteboard"
                    tooltip="White board"
                  />
                </div>
              )}
              {fields.includes('attachments') && (
                <div style={{ marginRight: 30 }}>
                  <Tooltip item={{ title: 'Attach file' }}>
                    <AttachmentMenu
                      onChange={this.handleAttachmentChange('attachments')}
                      onUpload={(isUploading) => {
                        this.setState({ isUploading });
                      }}
                      active={this.state.values.attachments.length > 0}
                      position="top"
                      suffixId={this.props.draft?.id}
                    />
                    <div style={{ width: 30 }} />
                  </Tooltip>
                </div>
              )}

              {this.renderCommonToolbar(fields)}

              {fields.includes('pinned') ? (
                <React.Fragment>
                  <div style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                  {this.renderPinPostButton(<Trans>Pin post</Trans>)}
                  <div style={{ width: 30 }} />
                </React.Fragment>
              ) : null}
            </React.Fragment>

            {this.props.onSubmit ? (
              <Button
                type="submit"
                title={this.props.t('Post')}
                size="sm"
                color="brand"
                textColor="white"
                disabled={
                  this.props.submitDisabled ||
                  this.state.isSubmitting ||
                  this.state.isUploading ||
                  this.state.richTextUploading
                }
                id="btn_submit-post"
                style={{ marginRight: 0, marginLeft: fields.includes('pinned') ? 0 : 'auto' }}
              />
            ) : null}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <div className="post-editor" data-test-id="post-editor_wrapper">
        {this.renderEditor()}
        {this.state.isSubmitting ? (
          <div className="post-editor__submitting">
            <Loader color="white" />
          </div>
        ) : null}
      </div>
    );
  }
}

export const PostEditor = withTranslation()(Renderer);
