import { useTranslation } from 'react-i18next';
import { Form, Modal, Section, TextAreaInput, ModalFormActions } from 'ui';

type FormValues = {
  reason?: string;
};

type P = {
  isOpened: boolean;
  onFinish: (message?: string) => Promise<void> | void;
  onCancel?: () => void;
};

const RequestJoinModal = ({ onFinish, ...rest }: P) => {
  const { t } = useTranslation();

  const handleFormFinish = (values: FormValues) => onFinish(values.reason);

  return (
    <Modal title={'Request to Join'} disableBack {...rest} destroyOnClose>
      <Form<FormValues> onFinish={handleFormFinish}>
        <Section paddingBottom={false} paddingTop={false}>
          <TextAreaInput
            item={{
              name: 'reason',
              label: t('Message'),
            }}
          />
        </Section>
        <ModalFormActions right={{ onClick: rest.onCancel, children: 'Cancel' }} submit={{ children: 'Send' }} />
      </Form>
    </Modal>
  );
};

export default RequestJoinModal;
