// TODO fix imports(ui component should not be dependent on anything from outside ui)
import { type FileAsset, type FolderAsset, FileType, FolderType } from 'models';
import { DefaultFileIcon, DocumentIcon, FolderIcon, ImgIcon, PdfIcon, XlsIcon } from 'ui/Icons';

type P = {
  asset: FileAsset | FolderAsset;
};

const FileIcon = ({ asset }: P) => {
  if (asset.type === FolderType) {
    return <FolderIcon />;
  }

  if (asset.type === FileType) {
    if (asset.contentType.includes('image')) {
      return <ImgIcon />;
    }

    if (asset.contentType.includes('pdf')) {
      return <PdfIcon />;
    }

    if (asset.contentType.includes('spreadsheetml')) {
      return <XlsIcon />;
    }

    if (['wordprocessingml', 'msword'].some((word) => asset.contentType.includes(word))) {
      return <DocumentIcon />;
    }
  }

  return <DefaultFileIcon />;
};

export default FileIcon;
