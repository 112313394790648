import { type ReduxUser } from 'models';
import { TABS } from 'old/pages/page-settings/SettingsSideNav';
import { useTranslation } from 'react-i18next';
import { useAutoFocus } from 'common/hooks';
import variables from 'common/styles/variables.json';
import { Button, Col, Form, RadioGroupInput, Row, Section, SwitchInput, Title, message } from 'ui';
import { useUpdateNotificationsSettings } from '../../queries';
import { NotificationCheckbox } from './NotificationCheckbox';

export type FormValues = {
  badgeNotificationsSettings: {
    enableNotifications: boolean;
  };
  emailNotificationsSettings: {
    enableNotifications: boolean;
    newsfeed: boolean;
    events: boolean;
    donations: boolean;
    program: boolean;
  };
  pushNotificationsSettings: {
    enableNotifications: boolean;
    newsfeed: boolean;
    events: boolean;
    messaging: boolean;
    donations: boolean;
    program: boolean;
  };
};

type P = {
  isLoadingRedux?: boolean;
  user: ReduxUser;
  onSuccess: () => Promise<void> | void;
};

const SettingsNotifications = ({ isLoadingRedux, user, onSuccess }: P) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const firstInput = useAutoFocus();
  const { mutate: updateNotificationSettings, isLoading } = useUpdateNotificationsSettings();

  const onFinish = async (data: FormValues) => {
    updateNotificationSettings(
      {
        userId: user.id,
        notifications: data,
      },
      {
        async onSuccess() {
          await onSuccess();
          message.success('Notification settings was successfully updated.');
        },
        onError() {
          message.error('Something went wrong.');
        },
      },
    );
  };

  const initialValues: FormValues = {
    badgeNotificationsSettings: {
      enableNotifications: user.badge_notifications_settings?.enable_notifications.value ?? false,
    },
    emailNotificationsSettings: {
      enableNotifications: user.email_notifications_settings?.enable_notifications.value ?? false,
      newsfeed: user.email_notifications_settings?.newsfeed.value ?? false,
      events: user.email_notifications_settings?.events.value ?? false,
      donations: user.email_notifications_settings?.donations.value ?? false,
      program: user.email_notifications_settings?.program.value ?? false,
    },
    pushNotificationsSettings: {
      enableNotifications: user.push_notifications_settings?.enable_notifications.value ?? false,
      newsfeed: user.push_notifications_settings?.newsfeed.value ?? false,
      events: user.push_notifications_settings?.events.value ?? false,
      messaging: user.push_notifications_settings?.messaging.value ?? false,
      donations: user.push_notifications_settings?.donations.value ?? false,
      program: user.push_notifications_settings?.program.value ?? false,
    },
  };

  return (
    <div id={TABS.notifications}>
      <Title level={2}>Notifications</Title>
      <Form<FormValues> onFinish={onFinish} form={form} initialValues={initialValues}>
        <Section paddingTop paddingBottom lineBottom={false}>
          <Row
            item={{
              gutter: [0, variables.spaceXs.value],
            }}
          >
            <Col item={{ span: 8 }}>
              <Title level={5}>Source</Title>
            </Col>
            <Col item={{ span: 8, flex: 1, className: 'settings_notifications__center' }}>
              <Title level={5}>Email</Title>
            </Col>
            <Col item={{ span: 8, className: 'settings_notifications__center' }}>
              <Title level={5}>Push</Title>
            </Col>

            <Col item={{ span: 8 }}>All notifications</Col>
            <Col item={{ span: 8, className: 'settings_notifications__center' }}>
              <Form.Item noStyle dependencies={[['emailNotificationsSettings', 'enableNotifications', 'value']]}>
                {() => (
                  <SwitchInput
                    item={{
                      name: ['emailNotificationsSettings', 'enableNotifications'],
                    }}
                    input={{
                      ref: firstInput,
                      'aria-label': t('Enable/disable all email notifications'),
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col item={{ span: 8, className: 'settings_notifications__center' }}>
              <Form.Item noStyle dependencies={[['pushNotificationsSettings', 'enableNotifications', 'value']]}>
                {() => (
                  <SwitchInput
                    item={{
                      name: ['pushNotificationsSettings', 'enableNotifications'],
                    }}
                    input={{
                      'aria-label': t('Enable/disable all push notifications'),
                    }}
                  />
                )}
              </Form.Item>
            </Col>

            <Col item={{ span: 24 }}>
              <fieldset>
                <Row>
                  <Col item={{ span: 8 }}>Newsfeed</Col>
                  <Col item={{ span: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="email" area="newsfeed" heading="Newsfeed" user={user} />
                  </Col>
                  <Col item={{ span: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="push" area="newsfeed" heading="Newsfeed" user={user} />
                  </Col>
                </Row>
              </fieldset>
            </Col>

            <Col item={{ span: 24 }}>
              <fieldset>
                <Row>
                  <Col item={{ span: 8 }}>Conferences and Events</Col>
                  <Col item={{ span: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="email" area="events" heading="Newsfeed" user={user} />
                  </Col>
                  <Col item={{ span: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="push" area="events" heading="Newsfeed" user={user} />
                  </Col>
                </Row>
              </fieldset>
            </Col>

            <Col item={{ span: 24 }}>
              <fieldset>
                <Row>
                  <Col item={{ span: 8 }}>Messaging</Col>
                  <Col item={{ span: 8, offset: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="push" area="messaging" heading="Newsfeed" user={user} />
                  </Col>
                </Row>
              </fieldset>
            </Col>

            <Col item={{ span: 24 }}>
              <fieldset>
                <Row>
                  <Col item={{ span: 8 }}>Program</Col>
                  <Col item={{ span: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="email" area="program" heading="Newsfeed" user={user} />
                  </Col>
                  <Col item={{ span: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="push" area="program" heading="Newsfeed" user={user} />
                  </Col>
                </Row>
              </fieldset>
            </Col>

            <Col item={{ span: 24 }}>
              <fieldset>
                <Row>
                  <Col item={{ span: 8 }}>Donations</Col>
                  <Col item={{ span: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="email" area="donations" heading="Newsfeed" user={user} />
                  </Col>
                  <Col item={{ span: 8, className: 'settings_notifications__center' }}>
                    <NotificationCheckbox type="push" area="donations" heading="Newsfeed" user={user} />
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>
        </Section>

        <Section paddingTop={false} paddingBottom lineBottom={false}>
          <Row
            item={{
              gutter: [0, variables.spaceXs.value],
            }}
          >
            <Col item={{ span: 24 }}>
              <Title level={5}>Badge Notifications</Title>
            </Col>

            <Col item={{ span: 24, className: 'settings_notifications__badge' }}>
              <RadioGroupInput
                item={{
                  name: ['badgeNotificationsSettings', 'enableNotifications'],
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  options: [
                    {
                      label: t('On'),
                      value: true,
                    },
                    {
                      label: t('Off'),
                      value: false,
                    },
                  ],
                }}
              />
            </Col>
          </Row>
        </Section>

        <Button type="primary" htmlType="submit" loading={(isLoadingRedux ?? false) || isLoading} size="large">
          Update
        </Button>
      </Form>
    </div>
  );
};

export default SettingsNotifications;
