import { CategoryListItem } from './category';
import { Entity } from './entity';
import { Image } from './image';
import { type Privacy } from './privacy';
import { type OrganizationBaseDataObject, type OrganizationDataObject } from './service';
import { type Tag } from './tag';

abstract class OrganizationBase extends Entity {
  // TODO what is optional and what isn't ?
  state?: string;
  url?: string;
  ein?: string;
  usersCount?: number;
  privacy?: Privacy;
  profilePhoto?: Image;
  whiteProfilePhoto?: Image;
  coverPhoto?: Image;
  categories: CategoryListItem[];
  requiredConsents: Array<{ id: string }>;
  // TODO this tag should be always available - check and fix type
  tags?: Tag[];
  customChannels?: boolean;

  constructor(data: OrganizationBaseDataObject) {
    super(data);

    this.state = data.state;
    this.url = data.url;
    this.ein = data.ein;
    this.usersCount = data.users_count;
    this.privacy = data.privacy;
    this.profilePhoto = data.profile_photo ? new Image(data.profile_photo) : undefined;
    this.whiteProfilePhoto = data.white_profile_photo ? new Image(data.white_profile_photo) : undefined;
    this.coverPhoto = data.cover_photo ? new Image(data.cover_photo) : undefined;
    this.categories = data.categories.map((category) => new CategoryListItem(category));
    this.requiredConsents = data.required_consents;
    this.tags = data.tags;
    this.customChannels = data.custom_channels;
  }
}

export class OrganizationListItem extends OrganizationBase {
  // TODO add all other fields when needed
}

export class Organization extends OrganizationBase {
  settings: {
    allowMedicalCommunity: boolean;
    allowedIntegrations: string[];
  };

  constructor(data: OrganizationDataObject) {
    super(data);

    this.settings = {
      allowMedicalCommunity: data.settings.allow_medical_community,
      allowedIntegrations: data.settings.allowed_integrations,
    };
  }
  // TODO add all other fields when needed
}
