import { type ContentObjectRedux } from 'models';
import { dispatch } from 'old/store';
import { useMutation } from 'query';
import { repostContentObject } from '../../services';

export const useRepostContentObjectMutation = () => {
  return useMutation<
    ContentObjectRedux,
    { communityId: string; objectId: string; htmlContent?: string; textContent?: string }
  >(
    async ({ communityId, objectId, htmlContent, textContent }) => {
      const response = await repostContentObject({
        post: {
          post_in_communities: [{ id: communityId }],
          text_content: textContent,
          html_content: htmlContent,
          shared_content_object_id: objectId,
        },
      });

      return response.data; // TODO do not return directly response
    },
    {
      onSuccess: async (data) => dispatch.feed.reloadContentObjects({ responseData: data }), // TODO remove this from redux
    },
  );
};
