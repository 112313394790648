import { type ReduxUser } from 'models';
import { useScreenSizeObserver } from 'common/hooks';
import variables from 'common/styles/variables.json';

import { Card } from 'ui';
import { Greetings } from './Greetings';
import { type BadgeOptions, NavigationMenu } from './NavigationMenu';

type P = {
  badges?: BadgeOptions;
  viewer: ReduxUser;
};

const Navigation = ({ badges, viewer }: P) => {
  const { screenWidth } = useScreenSizeObserver();
  const isCollapsed = screenWidth < variables.screenXl.value;

  return (
    <div className="app-navigation">
      <Card className="app-navigation__greetings" shrinked>
        <Greetings isCollapsed={isCollapsed} viewer={viewer} />
      </Card>
      <Card className="app-navigation__menu" shrinkedLeft shrinkedRight marginBottom={false}>
        <NavigationMenu isCollapsed={isCollapsed} badges={badges} />
      </Card>
    </div>
  );
};

export default Navigation;
