import { useTranslation } from 'react-i18next';

import {
  Text,
  Button,
  BookmarkIconLine,
  BookmarkIconFill,
  Dropdown,
  DotsIcon,
  type MenuProps,
  NavigationPill,
} from 'ui';
import { AvatarLink } from './AvatarLink';
import { HeaderRow } from './HeaderRow';
import { UserNameLink } from './UserNameLink';

export type Pilltype = {
  photoUrl?: string;
  name: string;
  link: string;
};

type P = {
  // TODO to remove complexity from and a lot of passing arguments through all compoents
  // we should type correctly `ContentObject` and pass it here
  // so every logic part about `ContentObject` and how should be rendered is here
  // or this component otherwise is not `ContentObjectHeader`, but it is instead some UI component only
  // we should instead make hooks here for generate `actions` / `pills` / etc. so it is reausable and also this component becomes real `ContentObjectHeader`
  // also we need add `react-query` for actions or we can't handle loading / error states
  subtitle?: Date | string;
  title?: string;
  avatar?: {
    url: string;
    alias?: string;
  };
  authorId: string;
  bookmark?: {
    isBookmarked: boolean;
    onBookmarkClick: () => void;
  };
  actions?: MenuProps['items'];
  pill?: Pilltype;
};

const ContentObjectHeader = ({ title, subtitle, avatar, authorId, actions, pill, bookmark }: P) => {
  const { t } = useTranslation();

  return (
    <div className="feed-header">
      <div className="feed-header__content">
        <HeaderRow fullWidth={!avatar} aside={avatar ? <AvatarLink authorId={authorId} avatar={avatar} /> : null}>
          {title ? <UserNameLink authorId={authorId} title={title} /> : null}
          <Text type="secondary">
            {subtitle instanceof Date ? t('dateFormatShortMonthDayYearTime', { date: subtitle }) : subtitle}
          </Text>
        </HeaderRow>
        <div className="feed-header__actions">
          {bookmark ? (
            <Button
              className="feed-header__button"
              icon={bookmark.isBookmarked ? <BookmarkIconFill /> : <BookmarkIconLine />}
              type="link"
              // eslint-disable-next-line react/jsx-handler-names
              onClick={bookmark.onBookmarkClick}
              size="large"
            />
          ) : null}
          {actions && actions.length > 0 ? (
            <Dropdown
              className="feed-header__button"
              menu={{
                items: actions,
              }}
              trigger={['click']}
            >
              <Button className="feed-header__button" icon={<DotsIcon />} type="link" size="large" />
            </Dropdown>
          ) : null}
        </div>
      </div>
      {pill ? (
        <HeaderRow fullWidth={!avatar} className="feed-header__content__pill">
          <NavigationPill name={pill.name} link={pill.link} photoUrl={pill.photoUrl} />
        </HeaderRow>
      ) : null}
    </div>
  );
};

export default ContentObjectHeader;
