import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes';

type P = {
  referrer?: string;
};

const DEFAULT_PROPS: P = {} as const;

export const useOnSuccessLogin = <T extends { referrer?: string; originalState?: unknown }>({
  referrer,
}: P = DEFAULT_PROPS) => {
  const history = useHistory();
  const { state } = useLocation<T | undefined>();

  const getFinalReferrer = useCallback(() => {
    const finalReferrer = state?.referrer ?? referrer ?? '/';

    // TODO fix these constants
    if (
      ['/login', '/sign-up', '/new-password', '/forgot-password', routes.onboardingTour.index].some((path) =>
        finalReferrer.startsWith(path),
      )
    ) {
      return '/';
    }

    return finalReferrer;
  }, [referrer, state]);

  return useMemo(
    () => ({
      onSuccessLogin() {
        const finalReferrer = getFinalReferrer();
        history.push(finalReferrer);
      },
      onSuccessSignUp() {
        const finalReferrer = getFinalReferrer();
        history.push(routes.onboardingTour.index, { referrer: finalReferrer });
      },
      onSuccessOnboardingTour() {
        const finalReferrer = getFinalReferrer();
        history.push(finalReferrer, state?.originalState);
      },
    }),
    [getFinalReferrer, history, state],
  );
};
