import { type FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Header, ContentLeft, Footer, SignUp, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation, useOnSuccessLogin } from '../hooks';
import { type OnboardingLocationState } from '../types';

type P = Record<string, unknown>;

export type SignUpLocationState = OnboardingLocationState;

const PageSignUp: FC<P> = () => {
  const { state } = useLocation<SignUpLocationState | undefined>();
  const { onNavigateToPrimaryLogin } = useOnboardingNavigation<SignUpLocationState>();
  const { onSuccessSignUp } = useOnSuccessLogin<SignUpLocationState>();

  const search = state?.referrer?.split('?')[1];
  const invitationToken = new URLSearchParams(search).get('invitation_token') ?? undefined;
  const changeRequestId = new URLSearchParams(search).get('chr') ?? undefined;

  const headerButtons: HeaderItem[] = [
    {
      label: 'Log in',
      type: 'primary',
      onClick() {
        onNavigateToPrimaryLogin();
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={
        <SignUp
          publicCommunityId={state?.join_community_id}
          onSuccessLogin={onSuccessSignUp}
          onLoginError={onNavigateToPrimaryLogin}
          changeRequestId={changeRequestId}
          invitationToken={invitationToken}
        />
      }
      footer={<Footer />}
    />
  );
};

export default PageSignUp;
