import { type CampaignSlim, getCampaignSlimData } from 'models';
import { useInfiniteQuery, type QueryFunctionContext, type QueryKey } from 'query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { getCampaigns } from '../services';
import { getCampaignsInfiniteQueryKey } from '.';

type P = {
  pageSize: number;
  organizationId?: string;
  featured?: boolean;
  scope: 'channels';
};

export const useCampaignsInfiniteQuery = ({ pageSize, organizationId, featured, scope }: P) =>
  useInfiniteQuery<CampaignSlim[], ApiClientResponseError<DefaultResponseErrorDataObject>>(
    getCampaignsInfiniteQueryKey({ pageSize, organizationId, featured }),
    async ({ pageParam: cursor = undefined }: QueryFunctionContext<QueryKey, string>) => {
      const response = await getCampaigns({
        cursor,
        limit: pageSize,
        organization_id: organizationId,
        featured,
        scope,
      });

      return response.data.map((campaign) => getCampaignSlimData(campaign));
    },
    {
      getNextPageParam: (lastPage) => (lastPage.length < pageSize ? undefined : lastPage[lastPage.length - 1]?.id),
    },
  );
