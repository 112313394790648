export * from './events';
export * from './navigation';
export * from './password';
export * from './file-icons';

import { ReactComponent as AddLineImport } from './add_line.svg';
import { ReactComponent as AppleIconImport } from './apple.svg';
import { ReactComponent as ArrowLeftLineImport } from './arrow_left_line.svg';
import { ReactComponent as BankIconImport } from './bank_line.svg';
import { ReactComponent as BellCircleIconImport } from './bell-circle.svg';
import { ReactComponent as BillLineImport } from './bill_line.svg';
import { ReactComponent as BookmarkIconFillImport } from './bookmark_fill.svg';
import { ReactComponent as BookmarkIconLineImport } from './bookmark_line.svg';
import { ReactComponent as CalendarIconImport } from './calendar.svg';
import { ReactComponent as CamcorderLineIconImport } from './camcorder_line.svg';
import { ReactComponent as CommentIconImport } from './chat_3_line.svg';
import { ReactComponent as CheckCircleFillIconImport } from './check_circle_fill.svg';
import { ReactComponent as CheckCircleLineWithBackgroundImport } from './check_circle_line_with_background.svg';
import { ReactComponent as CheckImport } from './check_line.svg';
import { ReactComponent as CommunityIconImport } from './classify_2_line.svg';
import { ReactComponent as CloseCircleFillIconImport } from './close_circle_fill.svg';
import { ReactComponent as CloseCircleLineWithBackgroundImport } from './close_circle_line_with_background.svg';
import { ReactComponent as CloseLineIconImport } from './close_line.svg';
import { ReactComponent as CompassFilledIconImport } from './compass_fill.svg';
import { ReactComponent as CompassIconImport } from './compass_line.svg';
import { ReactComponent as DeleteIconImport } from './delete_2_line.svg';
import { ReactComponent as DotGridLineIconImport } from './dot_grid_line.svg';
import { ReactComponent as DownLineImport } from './down_line.svg';
import { ReactComponent as DuplicateIconImport } from './duplicate.svg';
import { ReactComponent as EventIconImport } from './event.svg';
import { ReactComponent as LogOutIconImport } from './exit_line.svg';
import { ReactComponent as EyeLineIconImport } from './eye_line.svg';
import { ReactComponent as FacebookIconImport } from './fb.svg';
import { ReactComponent as ReportIconImport } from './flag_1_line.svg';
import { ReactComponent as GameIconImport } from './game.svg';
import { ReactComponent as GoogleIconImport } from './google.svg';
import { ReactComponent as CampaignIconImport } from './hand_heart_line.svg';
import { ReactComponent as HeartFilledIconImport } from './heart_fill.svg';
import { ReactComponent as HeartIconImport } from './heart_line.svg';
import { ReactComponent as HeartbeatIconImport } from './heartbeat.svg';
import { ReactComponent as EHRIconImport } from './heartbeat_line.svg';
import { ReactComponent as HornLineIconImport } from './horn_line.svg';
import withAntIconWrapper from './icon-wrapper/withAntIconWrapper';
import { ReactComponent as InboxLineIconImport } from './inbox_line.svg';
import { ReactComponent as InformationFillImport } from './information_fill.svg';
import { ReactComponent as HelpIconImport } from './information_line.svg';
import { ReactComponent as InviteIconImport } from './invite.svg';
import { ReactComponent as LeftLineImport } from './left_line.svg';
import { ReactComponent as LightLineIconImport } from './light_line.svg';
import { ReactComponent as LinkLineImport } from './link_line.svg';
import { ReactComponent as LinkedInIconImport } from './linkedin.svg';
import { ReactComponent as ListCheckIconImport } from './list-check.svg';
import { ReactComponent as ListCircleIconImport } from './list-circle.svg';
import { ReactComponent as MailIconImport } from './mail_send_line.svg';
import { ReactComponent as MessageIconImport } from './message.svg';
import { ReactComponent as MessagesCircleIconImport } from './messages-circle.svg';
import { ReactComponent as DotsIconImport } from './more_2_line.svg';
import { ReactComponent as ProgramIconImport } from './mortarboard_line.svg';
import { ReactComponent as NotificationIconImport } from './notification_line.svg';
import { ReactComponent as NotificationOffLine } from './notification_off_line.svg';
import { ReactComponent as OutlookIconImport } from './outlook.svg';
import { ReactComponent as EditIconImport } from './pencil_line.svg';
import { ReactComponent as PicLineIconImport } from './pic_line.svg';
import { ReactComponent as PlayCircleFilledIconImport } from './play_circle_fill.svg';
import { ReactComponent as QuestionLineIconImport } from './question_line.svg';
import { ReactComponent as RadarLineIconImport } from './radar_line.svg';
import { ReactComponent as RepeatLineImport } from './repeat_line.svg';
import { ReactComponent as RightLineImport } from './right_line.svg';
import { ReactComponent as SadFaceIconImport } from './sad-face.svg';
import { ReactComponent as SettingsIconImport } from './settings_5_line.svg';
import { ReactComponent as ShareIconImport } from './share_forward_line.svg';
import { ReactComponent as GoogleLogoImport } from './social-icons/google.svg';
import { ReactComponent as StarIconImport } from './star_line.svg';
import { ReactComponent as WhiteLabelIconImport } from './tag_2_line.svg';
import { ReactComponent as TagLineIconImport } from './tag_line.svg';
import { ReactComponent as TimeFilledIconImport } from './time_fill.svg';
import { ReactComponent as TimelineIconImport } from './time_line.svg';
import { ReactComponent as ProfileIconImport } from './user_3_line.svg';
import { ReactComponent as AdminConsoleIconImport } from './user_security_line.svg';
import { ReactComponent as XIconImport } from './x.svg';

export const EventIcon = withAntIconWrapper(EventIconImport);
export const MessageIcon = withAntIconWrapper(MessageIconImport);

export const NotificationIcon = withAntIconWrapper(NotificationIconImport);

export const DotsIcon = withAntIconWrapper(DotsIconImport);
export const DuplicateIcon = withAntIconWrapper(DuplicateIconImport);
export const SadFaceIcon = withAntIconWrapper(SadFaceIconImport);
export const HeartbeatIcon = withAntIconWrapper(HeartbeatIconImport);
export const BellCircleIcon = withAntIconWrapper(BellCircleIconImport);
export const ListCheckIcon = withAntIconWrapper(ListCheckIconImport);
export const CalendarIcon = withAntIconWrapper(CalendarIconImport);
export const ListCircleIcon = withAntIconWrapper(ListCircleIconImport);
export const InviteIcon = withAntIconWrapper(InviteIconImport);
export const MessagesCircleIcon = withAntIconWrapper(MessagesCircleIconImport);
export const GameIcon = withAntIconWrapper(GameIconImport);
export const DownLine = withAntIconWrapper(DownLineImport);
export const HeartIcon = withAntIconWrapper(HeartIconImport);
export const HeartFilledIcon = withAntIconWrapper(HeartFilledIconImport);
export const CommentIcon = withAntIconWrapper(CommentIconImport);
export const BookmarkIconLine = withAntIconWrapper(BookmarkIconLineImport);
export const BookmarkIconFill = withAntIconWrapper(BookmarkIconFillImport);
export const Check = withAntIconWrapper(CheckImport);
export const ShareIcon = withAntIconWrapper(ShareIconImport);
export const LinkLine = withAntIconWrapper(LinkLineImport);
export const EditIcon = withAntIconWrapper(EditIconImport);
export const ReportIcon = withAntIconWrapper(ReportIconImport);
export const DeleteIcon = withAntIconWrapper(DeleteIconImport);
export const CloseCircleLineWithBackground = withAntIconWrapper(CloseCircleLineWithBackgroundImport);
export const CheckCircleLineWithBackground = withAntIconWrapper(CheckCircleLineWithBackgroundImport);
export const XIcon = withAntIconWrapper(XIconImport);
export const RepeatLine = withAntIconWrapper(RepeatLineImport);
export const FacebookIcon = withAntIconWrapper(FacebookIconImport);
export const LinkedInIcon = withAntIconWrapper(LinkedInIconImport);
export const MailIcon = withAntIconWrapper(MailIconImport);
export const LeftLine = withAntIconWrapper(LeftLineImport);
export const RightLine = withAntIconWrapper(RightLineImport);
export const CompassIcon = withAntIconWrapper(CompassIconImport);
export const CompassFilledIcon = withAntIconWrapper(CompassFilledIconImport);
export const EyeLineIcon = withAntIconWrapper(EyeLineIconImport);
export const BankIcon = withAntIconWrapper(BankIconImport);
export const StarIcon = withAntIconWrapper(StarIconImport);
export const AddLine = withAntIconWrapper(AddLineImport);
export const HornLineIcon = withAntIconWrapper(HornLineIconImport);
export const QuestionLineIcon = withAntIconWrapper(QuestionLineIconImport);
export const CloseLineIcon = withAntIconWrapper(CloseLineIconImport);
export const ProgramIcon = withAntIconWrapper(ProgramIconImport);
export const NotificationOffLineIcon = withAntIconWrapper(NotificationOffLine);
export const TimeFilledIcon = withAntIconWrapper(TimeFilledIconImport);
export const PlayCircleFilledIcon = withAntIconWrapper(PlayCircleFilledIconImport);
export const CampaignIcon = withAntIconWrapper(CampaignIconImport);
export const DotGridLineIcon = withAntIconWrapper(DotGridLineIconImport);
export const InformationFillIcon = withAntIconWrapper(InformationFillImport);
export const BillLineIcon = withAntIconWrapper(BillLineImport);
export const CamcorderLineIcon = withAntIconWrapper(CamcorderLineIconImport);
export const GoogleLogoIcon = withAntIconWrapper(GoogleLogoImport);
export const CheckCircleFillIcon = withAntIconWrapper(CheckCircleFillIconImport);
export const CloseCircleFillIcon = withAntIconWrapper(CloseCircleFillIconImport);
export const TagLineIcon = withAntIconWrapper(TagLineIconImport);
export const InboxLineIcon = withAntIconWrapper(InboxLineIconImport);
export const TimeLineIcon = withAntIconWrapper(TimelineIconImport);
export const RadarLineIcon = withAntIconWrapper(RadarLineIconImport);
export const ArrowLeftLine = withAntIconWrapper(ArrowLeftLineImport);
export const PicLineIcon = withAntIconWrapper(PicLineIconImport);
export const LightLineIcon = withAntIconWrapper(LightLineIconImport);
export const AppleIcon = withAntIconWrapper(AppleIconImport);
export const GoogleIcon = withAntIconWrapper(GoogleIconImport);
export const OutlookIcon = withAntIconWrapper(OutlookIconImport);

// Menu icons
export const ProfileIcon = withAntIconWrapper(ProfileIconImport);
export const CommunityIcon = withAntIconWrapper(CommunityIconImport);
export const WhiteLabelIcon = withAntIconWrapper(WhiteLabelIconImport);
export const AdminConsoleIcon = withAntIconWrapper(AdminConsoleIconImport);
export const EHRIcon = withAntIconWrapper(EHRIconImport);
export const SettingsIcon = withAntIconWrapper(SettingsIconImport);
export const HelpIcon = withAntIconWrapper(HelpIconImport);
export const LogOutIcon = withAntIconWrapper(LogOutIconImport);
