import { type ContentObjectRedux } from 'models';
import { apiClient, type ApiClientResponse, routes } from 'common/services';

type RepostObjectRequestData = {
  post: {
    post_in_communities: [{ id: string }];
    text_content?: string;
    html_content?: string;
    shared_content_object_id: string;
  };
};

export const repostContentObject = async (data: RepostObjectRequestData) =>
  apiClient.post<ContentObjectRedux, ApiClientResponse<ContentObjectRedux>>(routes.contentObjects.create, data);
