import type RichTextEditorBase from 'rich-text-editor/build';

const maxIndent = (maxIndentLength: number) => {
  return function (editor: RichTextEditorBase) {
    editor.model.document.on('change', () => {
      const selectedElement = editor.model.document.selection.getFirstPosition()?.parent;
      const indentListCommand = editor.commands.get('indentList');
      const id = 'max-indent';

      if (
        selectedElement?.name === 'listItem' &&
        (selectedElement.getAttribute('listIndent') as number) >= maxIndentLength
      ) {
        indentListCommand?.forceDisabled(id);
      } else {
        indentListCommand?.clearForceDisabled(id);
      }
    });
  };
};

export default maxIndent;
