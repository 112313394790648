import { type Program } from 'models';
import { dispatch } from 'old/store';
import { useEffect } from 'react';
import { Modal, message } from 'ui';
import { useCreateRequestToJoinQuery } from '../queries';
import { RequestToJoinForm } from './components';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  program: Program;
};

const CreateRequestToJoin = ({ onSuccess, onCancel, isOpened, program }: P) => {
  const {
    mutateAsync: createRequestToJoin,
    isLoading: isCreatingRequestToJoin,
    error: createRequestToJoinError,
    isSuccess: isCreateRequestToJoinSuccess,
  } = useCreateRequestToJoinQuery();

  const onSubmit = async (data: { reason: string }) => {
    await createRequestToJoin({ programId: program.id, data });
  };

  useEffect(() => {
    if (createRequestToJoinError) {
      message.error('Something went wrong, please try again later.');
    }
  }, [createRequestToJoinError]);

  useEffect(() => {
    if (isCreateRequestToJoinSuccess) {
      dispatch.entities.updateEntity({
        programs: {
          [program.id]: {
            requested_to_join: () => true,
          },
        },
      });
      onSuccess();
    }
    // There is no need to update store programs on onSuccess function, only on isCreateRequestToJoinSuccess change. User is not updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateRequestToJoinSuccess]);

  return (
    <Modal title={'Why do you want to subscribe?'} isOpened={isOpened} onCancel={onCancel} disableBack destroyOnClose>
      <RequestToJoinForm onSubmit={onSubmit} loading={isCreatingRequestToJoin} />
    </Modal>
  );
};

export default CreateRequestToJoin;
