import { useEffect, useMemo } from 'react';

import { AxiosErrorHandler, handleAxiosError } from 'common/error-handlers';
import {
  Form,
  Modal,
  Section,
  Row,
  Col,
  ModalFormActions,
  Skeleton,
  message,
  Title,
  Empty,
  NotificationOffLineIcon,
} from 'ui';

import { useUpdateNotificationSettings, useNotificationSettingsQuery } from '../../queries';
import { NotificationCheckbox, isChangeAllowed } from './NotificationCheckbox';

export type CommunityNotificationSettingsFormValues = {
  emailNotificationsSettings?: {
    newsfeed?: boolean;
    events?: boolean;
    program?: boolean;
    donations?: boolean;
  };
  pushNotificationsSettings?: {
    newsfeed?: boolean;
    events?: boolean;
    program?: boolean;
    donations?: boolean;
  };
};

type P = {
  communityId: string;
  isOpened: boolean;
  onClose: () => void;
};

const NotificationSettingsModal = ({ communityId, onClose, ...rest }: P) => {
  const {
    isLoading: isLoadingNotifications,
    isRefetching: isRefetchingNotifications,
    data: notificationSettings,
    error: queryError,
  } = useNotificationSettingsQuery({ id: communityId });

  const {
    isLoading: isUpdating,
    mutate: updateNotificationSettings,
    isSuccess: isUpdated,
    error: updateError,
  } = useUpdateNotificationSettings();

  useEffect(() => {
    if (isUpdated) {
      message.success('Notification settings was successfully updated.');
      onClose();
    }
  }, [onClose, isUpdated]);

  useEffect(() => {
    handleAxiosError(updateError);
  }, [updateError]);

  const initialValues: Partial<CommunityNotificationSettingsFormValues> | undefined = useMemo(() => {
    if (notificationSettings) {
      return {
        emailNotificationsSettings: {
          newsfeed: notificationSettings.emailNotificationsSettings.newsfeed.value,
          events: notificationSettings.emailNotificationsSettings.events.value,
          program: notificationSettings.emailNotificationsSettings.program.value,
          donations: notificationSettings.emailNotificationsSettings.donations.value,
        },
        pushNotificationsSettings: {
          newsfeed: notificationSettings.pushNotificationsSettings.newsfeed.value,
          events: notificationSettings.pushNotificationsSettings.events.value,
          program: notificationSettings.pushNotificationsSettings.program.value,
          donations: notificationSettings.pushNotificationsSettings.donations.value,
        },
      };
    }

    return {};
  }, [notificationSettings]);

  const isPossibleToEditSomeSettings = useMemo(() => {
    if (notificationSettings) {
      const notificationTypes = ['emailNotificationsSettings', 'pushNotificationsSettings'] as const;
      const notificationEntities = ['donations', 'events', 'newsfeed', 'program'] as const;

      return notificationTypes.some((notificationType) =>
        notificationEntities.some((notificationEntity) =>
          isChangeAllowed({
            notificationSettings,
            notificationType,
            notificationEntity,
          }),
        ),
      );
    }

    return false;
  }, [notificationSettings]);

  const handleSubmit = async (values: CommunityNotificationSettingsFormValues) => {
    updateNotificationSettings({ id: communityId, values });
  };

  return (
    <Modal
      title="Community Notification Settings"
      disableBack
      onCancel={onClose}
      closable={!isUpdating}
      {...rest}
      destroyOnClose
    >
      <AxiosErrorHandler error={queryError} actions={false}>
        {isLoadingNotifications || isRefetchingNotifications || !notificationSettings ? (
          <Skeleton active paragraph={{ rows: 5 }} />
        ) : (
          <Form<CommunityNotificationSettingsFormValues>
            initialValues={initialValues}
            onFinish={handleSubmit}
            className="community-notification-settings"
          >
            {isPossibleToEditSomeSettings ? (
              <>
                <Section paddingBottom paddingTop={false}>
                  <Row>
                    <Col item={{ span: 12 }}>
                      <Title level={5}>Email Notifications</Title>
                      <NotificationCheckbox
                        notificationSettings={notificationSettings}
                        notificationType="emailNotificationsSettings"
                        notificationEntity="newsfeed"
                        label="Newsfeed"
                      />
                      <NotificationCheckbox
                        notificationSettings={notificationSettings}
                        notificationType="emailNotificationsSettings"
                        notificationEntity="events"
                        label="Events"
                      />
                      <NotificationCheckbox
                        notificationSettings={notificationSettings}
                        notificationType="emailNotificationsSettings"
                        notificationEntity="program"
                        label="Programs"
                      />
                      <NotificationCheckbox
                        notificationSettings={notificationSettings}
                        notificationType="emailNotificationsSettings"
                        notificationEntity="donations"
                        label="Donations"
                      />
                    </Col>
                    <Col item={{ span: 12 }}>
                      <Title level={5}>Push Notifications</Title>
                      <NotificationCheckbox
                        notificationSettings={notificationSettings}
                        notificationType="pushNotificationsSettings"
                        notificationEntity="newsfeed"
                        label="Newsfeed"
                      />
                      <NotificationCheckbox
                        notificationSettings={notificationSettings}
                        notificationType="pushNotificationsSettings"
                        notificationEntity="events"
                        label="Events"
                      />
                      <NotificationCheckbox
                        notificationSettings={notificationSettings}
                        notificationType="pushNotificationsSettings"
                        notificationEntity="program"
                        label="Programs"
                      />
                      <NotificationCheckbox
                        notificationSettings={notificationSettings}
                        notificationType="pushNotificationsSettings"
                        notificationEntity="donations"
                        label="Donations"
                      />
                    </Col>
                  </Row>
                </Section>
                <ModalFormActions
                  right={{ onClick: onClose, children: 'Cancel', disabled: isUpdating }}
                  submit={{ children: 'Save', loading: isUpdating }}
                />
              </>
            ) : (
              <Row>
                <Col item={{ span: 24 }}>
                  <Empty
                    image={<NotificationOffLineIcon />}
                    description={{
                      title: 'Notifications disabled',
                      subtitle:
                        'Either notifications are disabled by default in this community, or your global notifications are turned off',
                    }}
                  />
                </Col>
              </Row>
            )}
          </Form>
        )}
      </AxiosErrorHandler>
    </Modal>
  );
};

export default NotificationSettingsModal;
