import * as React from 'react';
import { Trans } from 'react-i18next';

import { ThemeConsumer } from '../theme';
import { RichTextRenderer } from '../rich-text';
import { Box } from '../box';
import { Text } from '../text';
import { Button } from '../button';
import { Avatar, Link } from 'ui';
import { images } from 'common/utils';

const PADDING = 20;

export class CampaignRendererCore extends React.Component {
  renderCoverPhoto() {
    return this.props.item.campaign.cover_photo ? (
      <div className="renderer-core__cover-photo-wrapper">
        <img
          src={this.props.item.campaign.cover_photo?.url ?? images.default.campaignCoverPhoto}
          alt={this.props.item.name}
        />
      </div>
    ) : null;
  }

  renderPanel() {
    const { item } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <React.Fragment>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <div>
                <Avatar
                  size={45}
                  radius={5}
                  photo={item.campaign.organization.profile_photo?.url ?? images.default.organizationProfilePhoto}
                />
              </div>
              <div className="renderer-core__campaign-name">
                <Text color="#455A64" size="14px" component="p" weight="600" style={{ marginBottom: 5 }}>
                  {item.campaign.name}
                </Text>
              </div>
              {this.props.isBookmark ? null : (
                <Button
                  size="md"
                  title={item.campaign.finished ? <Trans>Finished</Trans> : <Trans>Donate</Trans>}
                  textColor="white"
                  color={theme.color.brand}
                />
              )}
            </Box>

            {item.campaign.html_content && (
              <div style={{ paddingTop: 15 }}>
                <RichTextRenderer data={item.campaign.html_content} />
              </div>
            )}
          </React.Fragment>
        )}
      </ThemeConsumer>
    );
  }

  renderContentPanel() {
    const { item } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <Link
            to={`/campaigns/listing/${item.campaign.organization.id}/${item.campaign.id}`}
            style={{ textDecoration: 'inherit' }}
          >
            {this.renderPanel()}
          </Link>
        )}
      </ThemeConsumer>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderCoverPhoto()}
        <div
          className="renderer-core"
          style={{
            paddingLeft: PADDING,
            paddingRight: PADDING,
            paddingTop: 15,
            paddingBottom: this.props.isBookmark ? 15 : 0,
          }}
        >
          {this.renderContentPanel()}
        </div>
      </React.Fragment>
    );
  }
}
