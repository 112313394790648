import { type Pagination, type CampaignSlim, getCampaignSlimData } from 'models';
import { useQuery } from 'query';
import { getCampaigns } from '../services';
import { getCampaignsQueryKey } from '.';

type P = {
  scope: 'channels';
  organizationId?: string;
  featured?: boolean;
  pagination?: Pagination;
};

export const useCampaignsQuery = ({ pagination, organizationId, featured, scope }: P) => {
  return useQuery<CampaignSlim[]>(getCampaignsQueryKey({ pagination, organizationId, featured }), async () => {
    const { pageSize, position } = pagination ?? {};

    const response = await getCampaigns({
      limit: pageSize,
      offset: position !== undefined && pageSize !== undefined ? position * pageSize : undefined,
      organization_id: organizationId,
      featured,
      scope,
    });
    return response.data.map((campaign) => getCampaignSlimData(campaign));
  });
};
