import { message as messageAD } from 'antd';
import { type TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

type Content = JSX.Element | string;
type Duration = number;
type OnClose = () => void;

type LabelProperties = {
  label: Content;
  translateOptions?: TOptions;
};

messageAD.config({ top: 60 });

const Text = ({ label, translateOptions }: LabelProperties) => {
  const { t } = useTranslation();

  return typeof label === 'string' ? t(label, translateOptions) : label;
};

const message = {
  success(content: Content, duration?: Duration, onClose?: OnClose, translateOptions?: TOptions) {
    messageAD.success(<Text label={content} translateOptions={translateOptions} />, duration, onClose);
  },
  error(content: Content, duration?: Duration, onClose?: OnClose, translateOptions?: TOptions) {
    messageAD.error(<Text label={content} translateOptions={translateOptions} />, duration, onClose);
  },
  warn(content: Content, duration?: Duration, onClose?: OnClose, translateOptions?: TOptions) {
    messageAD.warning(<Text label={content} translateOptions={translateOptions} />, duration, onClose);
  },
  info(content: Content, duration?: Duration, onClose?: OnClose, translateOptions?: TOptions) {
    messageAD.info(<Text label={content} translateOptions={translateOptions} />, duration, onClose);
  },
};

export default message;
