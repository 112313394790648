import { type Pagination } from 'models';
import { generateQueryKey } from 'query';

export * from './mutations';
export * from './useEventCommunitiesUsers';
export * from './useEventProgramUsers';
export * from './useEventQuery';
export * from './useEventPublicQuery';
export * from './useEventPublicQueryDeprecated';
export * from './useEventUserQuery';
export * from './useInvitationTokenQuery';
export * from './useChangeRequestsQuery';
export * from './useEnterBigMarker';
export * from './useEventsInfiniteQuery';
export * from './useEventsQuery';

export const getEventQueryKey = ({
  eventId,
  ...params
}: {
  eventId?: string;
  invitationToken?: string;
  view?: 'internal' | 'public-deprecated' | 'public';
}) => generateQueryKey(['events', eventId, params]);

// TODO these should belong somewhere else
export const getProgramUsersKey = (params: { programId?: string; programAdminsIds?: string[] }) =>
  generateQueryKey(['events', 'program', 'users', params]);
export const getCommunityUsersKey = (params: { communitiesIds?: string[]; role?: string }) =>
  generateQueryKey(['events', 'communities', 'users', params]);
export const getUserKey = (params: { userId?: string }) => generateQueryKey(['events', 'users', params]);
export const getInvitationTokenKey = (params: { invitationToken?: string }) =>
  generateQueryKey(['events', 'invitation-token', params]);
export const getCreateEventChangeRequest = (params?: { changeRequestId?: string }) =>
  generateQueryKey(['events', 'change-request', params]);

export const getEventsInfiniteQueryKey = (params: {
  pageSize?: number;
  scope?: 'channels';
  organizationId?: string;
  featured?: boolean;
}) => generateQueryKey(['events', 'list', 'infinite', params]);

export const getEventsQueryKey = (params: {
  scope?: 'channels';
  organizationId?: string;
  featured?: boolean;
  pagination?: Pagination;
}) => generateQueryKey(['events', 'list', params]);
