import { BaseUser } from 'models';
import { useQuery } from 'query';
import { useTranslation } from 'react-i18next';
import { message } from 'ui';
import { getUsers } from '../services';
import { getCommunityUsersKey } from './';

type P = {
  search?: string;
  excludeCommunityId?: string;
};

export const useUsersQuery = ({ search, excludeCommunityId }: P) => {
  const { t } = useTranslation();

  return useQuery<BaseUser[]>(
    getCommunityUsersKey(search ?? ''),
    async () => {
      const { data } = await getUsers({ search: search ?? '', exclude_community_id: excludeCommunityId });
      return data.map((user) => new BaseUser(user));
    },
    {
      onError() {
        message.error(t('Failed to load data.'));
      },
    },
  );
};
