import { type ReduxUser, type ContentObjectRedux, isProgramContentObject } from 'models';

export const canCommentContentObject = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject?: ContentObjectRedux;
}) => {
  if (!contentObject) {
    return false;
  }

  if (contentObject.type === 'campaign') {
    return false;
  }

  if (viewer.superadmin) {
    return true;
  }

  if (isProgramContentObject(contentObject)) {
    return (
      (contentObject.program?.settings?.allow_comments ?? false) ||
      (contentObject.program?.id && viewer.admin_programs.includes(contentObject.program.id))
    );
  }

  const communities = contentObject.post_in_communities;

  return (
    communities &&
    !communities.some(
      (community) =>
        !viewer.admin_communities.includes(community.id) &&
        community.organization &&
        !viewer.admin_organizations.includes(community.organization.id) &&
        (community.settings ? !community.settings.allow_comments : false),
    )
  );
};
