import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ChannelsOrganization, type ChannelsOrganizationDataObject } from '../types';

export const getOrganizations = async () =>
  apiClient.get<ChannelsOrganization[], ApiClientResponse<ChannelsOrganizationDataObject[]>>(
    routes.organizations.find,
    {
      params: { scope: 'joined', order: 'channels' },
    },
  );
