import * as React from 'react';

import { Box } from '../box/Box';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { Avatar } from 'ui';
import { images } from 'common/utils';

export function AvatarGroup(props) {
  const { users, total, size } = props;
  const leftUsersCount = total - users.length;

  return (
    <ThemeConsumer>
      {(theme) => (
        <Box flexDirection="row">
          {users.map((user, index) => (
            <div
              key={index}
              style={{
                border: '2px solid #ffffff',
                borderRadius: '50%',
                marginLeft: index > 0 ? '-9px' : 0,
              }}
            >
              <Avatar name={`${user.first_name} ${user.last_name}`} photo={user.profile_photo ?? images.default.userProfilePhoto} size={size} />
            </div>
          ))}
          {leftUsersCount > 0 ? (
            <Text
              lineHeight={`${size}px`}
              color={theme.color.lightGray}
              size="14px"
              weight="600"
              style={{ marginLeft: 4 }}
            >
              +{leftUsersCount.toString()}
            </Text>
          ) : null}
        </Box>
      )}
    </ThemeConsumer>
  );
}

AvatarGroup.defaultProps = {
  size: 20,
};
