import { type TFunction } from 'i18next';
import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { isDateOfBirthValid } from 'common/validators';
import Form from '../Form';
import BirthDatePicker, { type BirthDateType } from './BirthDatePicker';

type P = {
  item?: ComponentProps<typeof Form.Item>;
  input?: ComponentProps<typeof BirthDatePicker>;
};

export const defaultBirthDateValidator = (t: TFunction) => ({
  async validator(_: any, value: Partial<BirthDateType>) {
    if (value !== undefined && !isDateOfBirthValid(value)) {
      if (value.month === undefined) {
        throw new Error(t('Please select month'));
      }

      if (value.day === undefined) {
        throw new Error(t('Please select day'));
      }

      if (value.year === undefined) {
        throw new Error(t('Please select year'));
      }

      if (!value?.isNumberOfDaysValid) {
        throw new Error(t('Number of days in month is not valid'));
      }

      throw new Error(t('Date of birth is not valid'));
    }
  },
  validateTrigger: 'onSubmit',
});

const BirthDateInput = <Values = any,>({ item, input }: P) => {
  const { t } = useTranslation();

  return (
    <Form.Item<Values> rules={[defaultBirthDateValidator(t), { required: true }]} {...item}>
      <BirthDatePicker {...input} />
    </Form.Item>
  );
};

export default BirthDateInput;
