import moment, { type Moment } from 'moment';
import { type TaskKindOption } from 'features/programs/form/features/ActivityTypes';

export type Option = {
  id: string;
  name: string;
  logs?: Log[];
};

export type Log = {
  activity_task_log: LogData;
  updated_at?: string;
};

export type LogData = {
  activity_task_id: string;
  activity_task_option_id?: string;
  value?: number | string;
};

export type Task = {
  id?: string;
  input: string;
  name: string;
  options?: Option[];
  logs?: Log[];
  kind?: TaskKindOption;
};

export type Time = {
  id?: string;
  time: string;
};

export type Reminder = {
  id?: string;
  minutes: number;
  type?: number;
};

type LastActivitySubmission = {
  id: string;
  created_at: Moment;
  updated_at: Moment;
  user_id: string;
  activity_id: string;
};

export type PeriodType = {
  weekly: moment.DurationInputArg2;
  monthly: moment.DurationInputArg2;
  quarterly: moment.DurationInputArg2;
  annually: moment.DurationInputArg2;
  daily: moment.DurationInputArg2;
};

type PeriodicityType = keyof PeriodType | 'one_time';
export class Activity {
  id = '';
  programs_topic_id = '';
  name = '';
  description = '';
  html_description = '';
  periodicity_type?: PeriodicityType;
  times: Time[] = [];
  start_time?: Moment;
  end_time?: Moment;
  tasks: Task[] = [];
  next_period_date?: Moment | null;
  reminders?: Reminder[];
  is_withings?: boolean = false;
  is_dexcom?: boolean = false;
  multiple_submissions = false;
  last_activity_submission?: LastActivitySubmission;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
      this.is_withings = Boolean(data.is_withings);
      this.is_dexcom = Boolean(data.is_dexcom);

      if (data.start_time) {
        this.start_time = moment(data.start_time);
      }

      if (data.end_time) {
        this.end_time = moment(data.end_time);
      }

      this.next_period_date = this.getNextPeriodDate(data.start_time, data.end_time, data.periodicity_type, data.times);
      this.programs_topic_id = data.programs_topic?.id;
    }

    if (!this.start_time) {
      this.start_time = moment().add(10, 'minutes');
    }

    if (!this.end_time) {
      this.end_time = this.start_time.clone().add(60, 'minutes');
    }
  }

  private readonly getNextPeriodDate = (
    start: string,
    end: string,
    periodicityType: PeriodicityType,
    times: Time[],
  ) => {
    const now = moment();
    const startTime = moment(start);
    const endTime = moment(end);
    const nextPeriodDate = moment(start);

    if (now.isAfter(endTime)) {
      return null;
    }

    if (startTime.format('MMM D, YYYY') === now.format('MMM D, YYYY')) {
      return nextPeriodDate;
    }

    switch (periodicityType) {
      case 'one_time':
      case 'daily':
        return now;
      case 'weekly':
        while (nextPeriodDate.isBefore(now, 'day')) {
          nextPeriodDate.add(1, 'w');
        }

        break;
      case 'monthly':
        while (nextPeriodDate.isBefore(now, 'day')) {
          nextPeriodDate.add(1, 'M');
        }

        break;
      case 'quarterly':
        while (nextPeriodDate.isBefore(now, 'day')) {
          nextPeriodDate.add(1, 'Q');
        }

        break;
      case 'annually':
        while (nextPeriodDate.isBefore(now, 'day')) {
          nextPeriodDate.add(1, 'y');
        }

        break;
    }

    if (nextPeriodDate.isAfter(endTime)) {
      return null;
    }

    return nextPeriodDate;
  };

  get isBloodPressureType(): boolean {
    return this.tasks.some((task) => task.kind === 'withings_blood_pressure_diastolic');
  }

  get isWithingsWeightType(): boolean {
    return this.tasks.some((task) => task.kind === 'withings_weight');
  }

  get isDexcom(): boolean {
    return this.tasks.some((task) => task.kind === 'dexcom_glucose');
  }
}
