import { useTranslation } from 'react-i18next';
import { Form, Modal, ModalFormActions, Section, TextInput } from 'ui';

type FormValues = {
  name: string;
};

type P = {
  isOpened: boolean;
  onFinish: (values: FormValues) => void;
  onCancel: () => void;
  loading: boolean;
  title: string;
  inputLabel: string;
  initialValues?: FormValues;
};

const AssetEditor = ({ onFinish, loading, title, inputLabel, initialValues, ...rest }: P) => {
  const { t } = useTranslation();

  const handleFormFinish = (values: FormValues) => {
    onFinish(values);
  };

  return (
    <Modal title={title} disableBack {...rest} destroyOnClose>
      <Form<FormValues> onFinish={handleFormFinish} initialValues={initialValues}>
        <Section paddingBottom={false} paddingTop={false}>
          <TextInput
            item={{
              name: 'name',
              label: t(inputLabel),
            }}
          />
        </Section>
        <ModalFormActions
          right={{ onClick: rest.onCancel, children: 'Cancel' }}
          submit={{ children: t('Save'), loading }}
        />
      </Form>
    </Modal>
  );
};

export default AssetEditor;
