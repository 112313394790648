import { type ContentObjectRedux } from 'models';
import { useCallback } from 'react';
import { SOCIAL_NETWORKS, openPopup, type SocialName } from 'common/utils';

export const useOnShareSocial = () => {
  return useCallback((action: SocialName, item: ContentObjectRedux) => {
    const { type, text_content } = item;
    const { id } = item[type];
    const socialNetwork = SOCIAL_NETWORKS[action];
    const url = socialNetwork.generateLink({
      text: text_content ?? '',
      sharedContent: `${window.location.origin}/${type}s/${id}${type === 'event' ? '/about' : ''}`,
    });

    if (action === 'email') {
      if (socialNetwork.alert) {
        setTimeout(() => {
          socialNetwork.alert?.();
        }, 500);
      }

      window.location.href = url;
    } else {
      openPopup(url, socialNetwork.domain);
    }
  }, []);
};
