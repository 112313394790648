import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { default as PageJoinedPrograms } from './PageJoinedPrograms';

const PageJoinedProgramsReduxConnect = ({ viewer }) => <PageJoinedPrograms viewer={viewer} />;

const mapState = (state) => ({
  viewer: select.session.user(state),
});

export default connect(mapState)(PageJoinedProgramsReduxConnect);
