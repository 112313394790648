import { useQueryClient } from '@tanstack/react-query';
import { FolderAsset, FolderType } from 'models';
import { useMutation } from 'query';
import { getAssetsQueryKey } from '..';
import { createFolder } from '../../services';

export const useCreateFolder = () => {
  const queryClient = useQueryClient();

  return useMutation<FolderAsset, { name: string; params: { communityId: string; parentId?: string } }>(
    async ({ name, params }) => {
      const response = await createFolder({
        community_id: params.communityId,
        parent_id: params.parentId,
        type: FolderType,
        name,
      });

      return new FolderAsset(response.data);
    },
    {
      onSuccess: async (data, { params: { communityId, parentId } }) =>
        queryClient.invalidateQueries(getAssetsQueryKey({ id: communityId, params: { parentId } })),
    },
  );
};
