import { type AxiosError } from 'axios';
import { message } from 'ui';

// TODO replace with update of ANTD
const isAxiosError = (error: AxiosError | Error): error is AxiosError => 'request' in error;

export const handleAxiosError = <T = unknown>(error?: AxiosError<T> | Error | null, customMessage?: string) => {
  if (error) {
    if (customMessage) {
      message.error(customMessage);
      return;
    }

    if (isAxiosError(error)) {
      if (!error.response) {
        message.error('Please check your internet connection.');
        return;
      }

      if (error.response.status === 403) {
        message.error('Sorry, you are not authorized to perform this action.');
        return;
      }

      if (error.response.status === 404) {
        message.error('Sorry, resource or target does not exist.');
        return;
      }
    }

    message.error('Sorry, something went wrong.');
  }
};
