import { type ReduxUser } from 'models';
import { CircleIcon } from 'old/atoms/icon';
import variables from 'common/styles/variables.json';
import { DexcomRow } from './DexcomRow';
import { useDexcomDesync, useDexcomSync } from './hooks';

const clientId = 'cDVvnes7gOhl87yuKaf6tgRja95LbqfB';

export type StatusType = 'abort' | 'error' | 'success';

type P = {
  onFinished: ({ status, messageText }: { status: StatusType; messageText: string }) => void;
  automaticSync?: boolean;
  user: ReduxUser;
};

const DexcomSync = ({ automaticSync, onFinished, user }: P) => {
  const { syncDexcom, isSyncing } = useDexcomSync(clientId, onFinished);
  const { desyncDexcom, isDesyncing } = useDexcomDesync(onFinished);

  if (automaticSync && !isSyncing && !user.dexcom.synced) {
    syncDexcom();
  }

  return (
    <>
      {user.dexcom.synced ? (
        <DexcomRow
          title="Dexcom account synced"
          buttonText="Unlink"
          loading={isDesyncing}
          onClick={async () => desyncDexcom()}
          danger
          icon={<CircleIcon size={16} name="check" color="white" background={variables.greenColor.value} />}
        />
      ) : (
        <DexcomRow
          title="Dexcom account not synced"
          description="If you want to use blood glucomer, you need to sync your Dexcom account."
          buttonText="Synchronize account"
          loading={isSyncing}
          onClick={() => {
            syncDexcom();
          }}
          icon={<CircleIcon size={16} name="question-mark" color="white" background={variables.gray.value} />}
        />
      )}
    </>
  );
};

export default DexcomSync;
