import { type ReactNode, useMemo } from 'react';
import { type SelectProperties, Select, InfinityLoading } from '..';

type P<ValueType extends { label: ReactNode; value: string }, OptionType extends ValueType> = Omit<
  SelectProperties<ValueType, OptionType>,
  'labelInValue' | 'onChange'
> & {
  onChange?: (value?: ValueType | ValueType[]) => void;
  handleRequestMoreData: () => void;
  hasMoreData?: boolean;
};

const InfinityScrollSelect = <
  ValueType extends { label: ReactNode; value: string },
  OptionType extends ValueType = ValueType,
>({
  onChange,
  handleRequestMoreData,
  hasMoreData,
  ...rest
}: P<ValueType, OptionType>) => {
  const options = useMemo(() => {
    const options = rest.options ? [...rest.options] : [];

    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (rest.loading || hasMoreData) {
      options.push({
        value: '',
        label: (
          <InfinityLoading
            isLoading={rest.loading ?? false}
            onEnter={handleRequestMoreData}
            hasMoreData={hasMoreData}
            spinnerSize="default"
          />
        ),
        disabled: true,
      } as unknown as OptionType); // This is just for TS check, this option will never be selected it is just UI loading representation
    }

    return options;
  }, [handleRequestMoreData, hasMoreData, rest.loading, rest.options]);

  // TODO maybe try fix that scroll is remembered inside of Select and not restored with new search
  return (
    <Select<ValueType, OptionType>
      filterOption={false}
      onChange={(_, b) => {
        onChange?.(b);
      }}
      {...rest}
      options={options}
      labelInValue
    />
  );
};

export default InfinityScrollSelect;
