import { useMutation } from '@tanstack/react-query';

import { AutocompleteUser } from '../../../../models/user';
import { currentUser } from '../../services';

export const useUserQuery = () =>
  useMutation({
    async mutationFn() {
      const userResponse = await currentUser();

      return new AutocompleteUser(userResponse.data);
    },
  });
