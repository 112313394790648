import { type CategoryDeprecated, type ReduxUser } from 'models';
import { type FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Program from 'models/program';
import { message } from 'ui';
import { type OrganizationDataObject } from '../../models/service';
import { ProgramForm } from './form';
import { useInvalidatePrograms } from './queries/hooks';
import api from './services/api';

type P = {
  onSuccess: (any: Program) => Promise<void> | void;
  program: string;
  currentUser: ReduxUser;
};

const EditProgram: FC<P> = ({ program, onSuccess, currentUser, ...rest }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [programData, setProgramData] = useState<Program>(new Program());
  const [categoriesData, setCategoriesData] = useState<CategoryDeprecated[]>([]);
  const [error, setError] = useState<Error>();
  const organizationsData = useMemo<OrganizationDataObject[]>(
    () => currentUser.joined_organizations.filter((org) => currentUser.admin_organizations.includes(org.id)),
    [currentUser],
  );

  const { t } = useTranslation();
  const invalidatePrograms = useInvalidatePrograms();

  const fetchData = () => {
    (async () => {
      setLoading(true);
      const programResponse = await api.getProgram(program);
      const categoriesResponse = await api.getCategories({ type: 'program' });

      if (programResponse.ok && categoriesResponse.ok) {
        setProgramData(programResponse.data);
        setCategoriesData(categoriesResponse.data);

        setError(undefined);
      } else {
        setErrorMessages(programResponse.errorMessage ?? categoriesResponse.errorMessage);
      }

      setLoading(false);
    })();
  };

  const setErrorMessages = (responseError: string | undefined) => {
    setError(new Error(responseError ?? 'Something went wrong.'));
    message.error('Failed to load data.');
  };

  const getUsers = async (scope: string, value: string, limit = 10) => {
    const response = await api.getMembers({
      scope,
      search: value,
      limit,
      exclude_program_id: program,
    });

    if (response.ok) {
      return response.data;
    }

    setError(new Error(response.errorMessage ?? 'Something went wrong.'));
    message.error('Failed to load data.');
  };

  // TODO fix type
  const onSubmit = async (programData: any) => {
    setSubmitLoading(true);
    const programResponse = await api.editProgram(program, programData);

    if (programResponse.ok) {
      await onSuccess(programResponse.data);
      message.success('Program was updated.');
      invalidatePrograms();
    } else {
      message.error('Failed to edit program.');
    }

    setSubmitLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, []);

  return (
    <ProgramForm
      onSubmit={onSubmit}
      getUsers={getUsers}
      error={error}
      loading={submitLoading || loading}
      program={programData}
      categories={categoriesData}
      currentUser={currentUser}
      organizations={organizationsData}
      formTitle={t('Edit program')}
      {...rest}
    />
  );
};

export default EditProgram;
