import { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Text } from '../text';
import { Translation } from '../translation';
import { RichTextRenderer } from '../rich-text';
import { Button, ReadMore, Title, Row } from 'ui';
import { Subscribe } from 'features/programs';

const PADDING = 20;

export class Renderer extends Component {
  state = {
    isSubscribeOpened: false,
  };

  openWithingsOrder = (isBookmark) => {
    if (!isBookmark) {
      this.setState({ isSubscribeOpened: true });
    }
  };

  createWithingsOrderHref = (item, isBookmark) => {
    if (isBookmark) {
      return `withings_order_posts/${item.withings_order_post?.id}`;
    }
  };

  renderWithingsOrder = (item, isBookmark) => {
    if (item.filled_withings_order) {
      return (
        <div className="renderer-core__order-completed">
          <Text>
            <Trans>Order was successful!</Trans>
          </Text>
        </div>
      );
    }

    return (
      <Row item={{ align: 'middle', justify: 'center' }}>
        <Button
          type="primary"
          className="renderer-core__order"
          onClick={() => this.openWithingsOrder(isBookmark)}
          href={this.createWithingsOrderHref(item, isBookmark)}
          size="large"
        >
          Create order
        </Button>
      </Row>
    );
  };

  render() {
    const { item, isBookmark, isOnFeed } = this.props;
    const html_content = item.withings_order_post?.html_content;
    const text_content = item.withings_order_post?.text_content;

    const withingsOrderPostBody = (
      <div style={{ paddingLeft: PADDING, paddingRight: PADDING }}>
        {html_content ? <RichTextRenderer data={html_content} /> : <div className="dont-break-out">{text_content}</div>}
        {this.renderWithingsOrder(item, isBookmark)}
        {text_content ? <Translation id={item.withings_order_post?.id} type={item.type} /> : null}
      </div>
    );

    return (
      <>
        <div className="withings-order-post-core__title">
          <Title level={5}>{item.withings_order_post.title}</Title>
        </div>
        <div className="renderer-core">
          {isOnFeed ? <ReadMore>{withingsOrderPostBody}</ReadMore> : withingsOrderPostBody}
        </div>

        <Subscribe
          user={this.props.viewer}
          isOpened={this.state.isSubscribeOpened}
          programId={item.program.id}
          onCancel={() => {
            this.setState({ isSubscribeOpened: false });
            this.props.onReloadObject(item);
          }}
          onSuccess={() => {
            this.setState({ isSubscribeOpened: false });
            this.props.onReloadObject(item);
          }}
        />
      </>
    );
  }
}

export const WithingsOrderPostRendererCore = withTranslation()(Renderer);
