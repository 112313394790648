import { type EventSlim, type CampaignSlim, type CommunitySlim, type EntityOptions } from 'models';
import { CampaignCard } from 'features/campaigns';
import { CommunityCard } from 'features/communities';
import { EventCard } from 'features/events';
import { ProgramCard } from 'features/programs';
import { type ProgramSlim } from 'features/programs/types';

type EntityType = CampaignSlim | CommunitySlim | EventSlim | ProgramSlim;

type P = {
  getPathToEntityDetail: (id: string, options: EntityOptions) => string;
  entity: EntityType;
  showPrice?: boolean;
  showTag?: boolean;
};

// TODO these functions belong somewhere in channel types, but probably they won't be even needed if channel entities will have entity type or something
const isProgramEntity = (entity: EntityType): entity is ProgramSlim => {
  return 'sponsors' in entity && typeof entity.sponsors === 'object';
};

const isCommunityEntity = (entity: EntityType): entity is CommunitySlim => {
  return 'name' in entity && typeof entity.name === 'string';
};

const isCampaignEntity = (entity: EntityType): entity is CampaignSlim => {
  return 'amountGoal' in entity && typeof entity.amountGoal === 'string';
};

const isEventEntity = (entity: EntityType): entity is EventSlim => {
  return 'place' in entity && (typeof entity.place === 'string' || entity.place === null);
};

const EntityCard = ({ getPathToEntityDetail, entity, showTag, showPrice }: P) => {
  const renderCard = () => {
    if (isProgramEntity(entity)) {
      return (
        <ProgramCard
          getPathToProgramDetail={(id) => getPathToEntityDetail(id, { entityType: 'program' })}
          program={entity}
          showTag={showTag}
          showPrice={showPrice}
        />
      );
    }

    if (isCampaignEntity(entity)) {
      return (
        <CampaignCard
          getPathToCampaignDetail={(id, organizationId) =>
            getPathToEntityDetail(id, { entityType: 'campaign', organizationId })
          }
          campaign={entity}
          showTag={showTag}
        />
      );
    }

    if (isEventEntity(entity)) {
      return (
        <EventCard
          getPathToEventDetail={(id) => getPathToEntityDetail(id, { entityType: 'event' })}
          event={entity}
          showTag={showTag}
        />
      );
    }

    if (isCommunityEntity(entity)) {
      return (
        <CommunityCard
          getPathToCommunityDetail={(id) => getPathToEntityDetail(id, { entityType: 'community' })}
          community={entity}
          showTag={showTag}
        />
      );
    }

    console.error('Invalid entity type', entity);

    return null;
  };

  return renderCard();
};

export default EntityCard;
