import cn from 'classnames';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { type TimeTagMode } from 'features/todos/types';
import { Paragraph, Text } from 'ui';
import { TodoWidgetItemTimeTag } from './TodoWidgetItemTimeTag';

type P = {
  type?: 'default' | 'featured';
  path: string;
  icon: ReactNode;
  title: string;
  description?: ReactNode;
  additionalDescription?: ReactNode;
  className?: string;
  item?: ReactNode;
  mode: TimeTagMode;
  time: Date;
  additionalDescriptionRows?: number;
};

const BaseTodoWidgetItem = ({
  type = 'default',
  path,
  icon,
  title,
  description,
  additionalDescription,
  className,
  item,
  mode,
  time,
  additionalDescriptionRows = 2,
}: P) => {
  const { t } = useTranslation();
  const descriptionRows = type === 'featured' ? 1 : 2;

  return (
    // TODO maybe not class on `NavLink` making this to `display: flex` and styling depends on `a`
    <NavLink
      to={path}
      className={cn('base-todo-widget-item', { 'base-todo-widget-item--featured': type === 'featured' }, className)}
    >
      <div className="base-todo-widget-item__info">
        <Text size="small" ellipsis>
          {icon}
          {t(title)}
        </Text>
        <TodoWidgetItemTimeTag time={time} mode={mode} />
      </div>

      {description ? (
        <Paragraph weight="bold" type="title" ellipsis={{ rows: descriptionRows }}>
          {description}
        </Paragraph>
      ) : null}

      {type === 'featured' && additionalDescription ? (
        <Paragraph size="small" ellipsis={{ rows: additionalDescriptionRows }}>
          {additionalDescription}
        </Paragraph>
      ) : null}

      {item ? <div>{item}</div> : null}
    </NavLink>
  );
};

export default BaseTodoWidgetItem;
