import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { Formik } from 'formik';
import { Trans, withTranslation } from 'react-i18next';

import { CategoriesField } from '../form/smart-fields';
import { validator } from '../../utils/validator';
import { Box } from '../box';
import { Button } from '../button';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { TextField, SelectField, CheckboxGroup, AdminsField, CropperEditorField, RadioGroup } from '../form';
import { withPermissions } from '../auth';
import { RequestError } from '../error';
import {
  buildEmailNotificationsValues,
  buildPushNotificationsValues,
  mapperToArray,
  mapperToObject,
  notifications,
} from '../../utils/notifications';
import { RichTextEditor } from '../rich-text';
import { StorageService } from '../../services/StorageService';
import { FieldError } from '../form';
import { states } from 'common/utils';

export class Renderer extends React.Component {
  state = {
    response: null,
  };

  deleteOrganization = async () => {
    if (
      await window.confirm(
        this.props.t(
          `You are about to delete this organization. By deleting this organization, you will also delete all of the organization's associated communities. Are you sure you want to delete this organization?`,
        ),
      )
    ) {
      if (
        await window.confirm(
          `${this.props.t(
            'Are you sure you want to delete this organization? If you continue, all communities related to the organization will be permanently deleted. No content or communities related to the organization can be recovered.',
          )}`,
        )
      ) {
        this.props.onDelete();
      }
    }
  };

  renderButtons = (formik, theme) => (
    <Box flex="1" flexDirection="row" justifyContent="space-between" style={{ margin: '30px 0' }}>
      <Button
        size="lg"
        title={this.props.organization ? <Trans>Update</Trans> : <Trans>Submit</Trans>}
        type="submit"
        color={theme.color.brand}
        onClick={() => {
          if (Object.keys(formik.errors).length > 0) {
            window.scrollTo(0, 0);
          }
          formik.submitForm();
        }}
        textColor="white"
        style={{ padding: '0 40px' }}
        data-test-id="btn_submit"
      />
      {this.props.onDelete &&
        withPermissions(
          [{ scope: 'superadmin' }],
          <Button
            size="lg"
            title={this.props.t('Delete')}
            type="submit"
            color={theme.color.red}
            onClick={this.deleteOrganization}
            textColor="white"
            style={{ padding: '0 40px' }}
          />,
          null,
        )}
    </Box>
  );

  handleDescriptionChange = (formik) => (value) => {
    this.setState(
      {
        html_description: value.html,
        description: value.text,
      },
      () => {
        formik.setFieldValue('html_description', value.html);
        formik.setFieldValue('description', value.text);
      },
    );
  };

  render() {
    const organization = this.props.organization || {};
    let push_notifications_settings = {};
    let email_notifications_settings = {};

    if (this.props.organization) {
      email_notifications_settings = organization.email_notifications_settings
        ? organization.email_notifications_settings
        : {};
      push_notifications_settings = organization.push_notifications_settings
        ? organization.push_notifications_settings
        : {};
    }

    return (
      <Formik
        enableReinitialize
        validate={(values) => {
          return validator.validateAll(
            values,
            {
              profile_photo: 'required',
              white_profile_photo: 'required',
              cover_photo: 'required',
              name: 'required|min:3',
              categories: 'required|min:1',
              url: 'required|url',
              ein: 'required|numeric|min:3',
              state: 'required',
              privacy: 'required',
              html_description: 'required|min:3',
              allow_medical_community: 'required',
            },
            {
              'url.url': this.props.t('The field is required in the following URL format: https://www.example.com'),
            },
          );
        }}
        initialValues={{
          profile_photo: organization.profile_photo,
          privacy: organization.privacy,
          white_profile_photo: organization.white_profile_photo,
          cover_photo: organization.cover_photo,
          name: organization.name,
          categories: organization.categories,
          url: organization.url,
          administrators: organization.administrators ? organization.administrators : [this.props.currentUser],
          html_description: organization.html_description || '',
          description: organization.description || '',
          ein: organization.ein ? organization.ein : '',
          state: organization.state ? organization.state : states[0].code,
          push_notifications_settings:
            mapperToArray(push_notifications_settings).length !== 0
              ? mapperToArray(push_notifications_settings)
              : notifications.map((setting) => setting.value),
          email_notifications_settings:
            mapperToArray(email_notifications_settings).length !== 0
              ? mapperToArray(email_notifications_settings)
              : notifications.map((setting) => setting.value),
          allow_medical_community: organization?.settings?.allow_medical_community ?? false,
        }}
        onSubmit={async (values, formik) => {
          formik.setSubmitting(true);
          if (values.ein.length < 3) {
            formik.setFieldError('ein', this.props.t('This field must be at least 9.'));
            return;
          }
          this.setState({ response: null });
          const category_ids = values.categories.map((category) => category.id);
          const push_notifications_settings = mapperToObject(notifications, values.push_notifications_settings);
          const email_notifications_settings = mapperToObject(notifications, values.email_notifications_settings);

          const result = {
            ...values,
            cover_photo: Array.isArray(values.cover_photo) ? values.cover_photo[0] : values.cover_photo,
            profile_photo: Array.isArray(values.profile_photo) ? values.profile_photo[0] : values.profile_photo,
            white_profile_photo: Array.isArray(values.white_profile_photo)
              ? values.white_profile_photo[0]
              : values.white_profile_photo,
            category_ids: category_ids,
            push_notifications_settings: push_notifications_settings,
            email_notifications_settings: email_notifications_settings,
            admin_ids: values.administrators.map((admin) => admin.id),
            administrators: values.administrators.map((administrator) => ({
              id: administrator.id,
              first_name: administrator.first_name,
              last_name: administrator.last_name,
            })),
            categories: values.categories.map((category) => ({
              id: category.id,
              name: category.name,
            })),
            settings: {
              allow_medical_community: values.allow_medical_community,
              allowed_integrations: this.props.organization
                ? organization.settings.allowed_integrations
                : values.allow_medical_community
                ? ['withings', 'dexcom', 'onetouch']
                : [],
            },
          };

          delete result.allow_medical_community;

          const response = await this.props.onSubmit(result);

          this.setState({ response: response });
          if (response?.ok) {
            this.props.onSuccess(response);
            formik.resetForm(values);
          } else {
            this.props.onError();
          }
          formik.setSubmitting(false);
        }}
      >
        {(formik) => (
          <ThemeConsumer>
            {(theme) => (
              <React.Fragment>
                <Prompt
                  when={Object.keys(formik.touched).length > 0}
                  message={() => this.props.t('Do you wish to discard changes?')}
                />
                <Box flexDirection="row" justifyContent="space-around" style={{ marginTop: 20 }}>
                  <CropperEditorField
                    name="profile_photo"
                    labelAlign="center"
                    label={<Trans>Logo</Trans>}
                    id="profile-photo"
                    iconId={'attachment-profile_photo'}
                  />
                  <CropperEditorField
                    name="white_profile_photo"
                    labelAlign="center"
                    label={<Trans>Inverse Logo</Trans>}
                    id="white-profile-photo"
                    iconId={'attachment-white_profile_photo'}
                  />
                </Box>
                <div style={{ marginTop: 20 }}>
                  <CropperEditorField
                    name="cover_photo"
                    label={<Trans>Hero Image</Trans>}
                    id="cover-photo"
                    iconId={'attachment-cover_photo'}
                  />
                </div>

                <TextField
                  name="name"
                  placeholder={this.props.t('Organization name')}
                  label={<Trans>Organization name</Trans>}
                />
                <CategoriesField
                  name="categories"
                  placeholder={this.props.t('Choose...')}
                  label={<Trans>Category</Trans>}
                  type="organization"
                />
                <TextField
                  name="url"
                  placeholder={this.props.t('Organization URL')}
                  label={<Trans>Organization URL</Trans>}
                />
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    borderBottom: '1px solid rgb(200, 199, 204)',
                    paddingBottom: 10,
                  }}
                >
                  <Text
                    weight="100"
                    size="12px"
                    component="p"
                    color={theme.color.lightGray}
                    style={{ padding: '0 5px' }}
                  >
                    <Trans>Description</Trans>
                  </Text>
                  <RichTextEditor
                    placeholder={this.props.t('Description')}
                    data={organization.html_description || ''}
                    onRequestUpload={StorageService.send}
                    onChange={this.handleDescriptionChange(formik)}
                    maxWidth={'645px'}
                  />
                  <FieldError name="html_description" />
                </div>
                <TextField name="ein" placeholder={this.props.t('EIN Number')} label={<Trans>EIN Number</Trans>} />
                <SelectField
                  name="state"
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  label={<Trans>State</Trans>}
                  options={states.map((option) => ({
                    label: option.name,
                    value: option.code,
                  }))}
                  placeholder={this.props.t('Please select a state')}
                />
                <div>
                  <AdminsField
                    name="administrators"
                    currentUser={this.props.currentUser}
                    removeAdmin={(admin) => {
                      const admins = formik.values.administrators.filter((item) => admin.id !== item.id);
                      formik.setFieldValue('administrators', admins);
                    }}
                    placeholder={this.props.t('Search User')}
                  />
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-xs-6 organization-form__email-settings">
                    <Text
                      weight="600"
                      size="14px"
                      style={{ marginTop: 20, paddingBottom: 20 }}
                      component="p"
                      color={theme.color.lightGray}
                    >
                      <Trans>Email Notifications</Trans>
                    </Text>
                    <CheckboxGroup
                      name="email_notifications_settings"
                      values={buildEmailNotificationsValues(
                        email_notifications_settings,
                        formik.values.email_notifications_settings,
                      )}
                    />
                  </div>
                  <div className="col-xs-6 organization-form__push-settings">
                    <Text
                      weight="600"
                      size="14px"
                      style={{ marginTop: 20, paddingBottom: 20 }}
                      component="p"
                      color={theme.color.lightGray}
                    >
                      <Trans>Push Notifications</Trans>
                    </Text>
                    <CheckboxGroup
                      name="push_notifications_settings"
                      values={buildPushNotificationsValues(
                        push_notifications_settings,
                        formik.values.push_notifications_settings,
                      )}
                    />
                  </div>
                </div>

                <Text
                  weight="600"
                  size={14}
                  style={{ marginTop: 30, paddingBottom: 10 }}
                  component="p"
                  color={theme.color.lightGray}
                >
                  <Trans>Privacy</Trans>
                </Text>
                <RadioGroup
                  inline
                  name="privacy"
                  values={[
                    {
                      value: 'public',
                      label: <Trans>Public</Trans>,
                      disabled: organization.settings?.allow_medical_community,
                    },
                    {
                      value: 'secret',
                      label: <Trans>Secret</Trans>,
                      disabled: organization.settings?.allow_medical_community,
                    },
                  ]}
                />

                <Text
                  weight="600"
                  size={14}
                  style={{ marginTop: 30, paddingBottom: 10 }}
                  component="p"
                  color={theme.color.lightGray}
                >
                  <Trans>Organization type</Trans>
                </Text>
                <RadioGroup
                  inline
                  name="allow_medical_community"
                  values={[
                    {
                      value: true,
                      label: <Trans>Medical</Trans>,
                      disabled: this.props.organization,
                    },
                    {
                      value: false,
                      label: <Trans>Non-medical</Trans>,
                      disabled: this.props.organization,
                    },
                  ]}
                />

                {this.state.response ? <RequestError response={this.state.response} /> : null}
                {this.renderButtons(formik, theme)}
              </React.Fragment>
            )}
          </ThemeConsumer>
        )}
      </Formik>
    );
  }
}

export const OrganizationForm = withTranslation()(Renderer);
