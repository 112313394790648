import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FrownOutlined } from '@ant-design/icons';

import { PostEditorTrigger } from '../../atoms/post-editor';
import { RegularFeed, PinnedFeed } from '../../atoms/feed';
import { getFeedKey } from '../../models/feed';
import EditPoll from '../../../features/polls/EditPoll';
import { can } from '../../utils/permissions';

import { CommunityProvider } from 'features/communities';
import { Col, Row, Text, Card } from 'ui';
import { withRouter } from 'react-router';

class Feed extends React.Component {
  state = {
    draft: undefined,
    reloadNumber: 0,
  };

  componentDidMount() {
    this.props.loadCollection();
  }

  initiatePostEditing = async (object) => {
    switch (object.type) {
      case 'post':
      case 'poll':
      case 'kudo':
        this.setState({ draft: object });
        break;
      case 'event':
        this.navigateToEventEdit(object.event.id);
        break;
      default:
        console.warn(this.props.t(`Cannot initiated editing object of type '${object.type}`));
    }
  };

  navigateToEvents = () => {
    this.props.history.push(`/events`);
  };

  navigateToEvent = (eventId) => {
    this.props.history.push(`/events/${eventId}/about`);
  };

  navigateToEventEdit = (eventId) => {
    this.props.history.push(`/events/${eventId}/about/edit`);
  };

  render() {
    const { community, viewer, reloadContentObject, hasPinnedPosts, hasContent, loadingFeed } = this.props;

    return (
      <RegularFeed
        community={community}
        limit={7}
        viewer={viewer}
        onInitiatePostEditing={this.initiatePostEditing}
        onRequestEventDetail={this.navigateToEvent}
        render={(feedNode, actions) => (
          <React.Fragment>
            <Card
              shrinked
              className={classNames({
                'community-post-trigger__disabled': !can(community, 'ContentObject', 'post'),
              })}
            >
              <PostEditorTrigger
                initialPostInCommunities={[community]}
                draft={this.state.draft}
                viewer={viewer}
                onRequestClose={() => {
                  this.setState({ draft: undefined });
                }}
                onSubmit={async (object) => {
                  if (object.id !== undefined) {
                    await actions.updateObject(object);
                    this.setState({ draft: undefined });
                  } else {
                    await actions.createObject(object);
                  }

                  await this.props.loadCollection();
                  this.setState((state) => ({
                    reloadNumber: state.reloadNumber + 1,
                  }));
                }}
                maxWidth={598}
                isDisabled={!can(community, 'ContentObject', 'post')}
              />
            </Card>
            {this.state.draft && this.state.draft.type === 'poll' && (
              <EditPoll
                poll={{
                  ...this.state.draft?.poll,
                  post_in_communities: this.state.draft?.post_in_communities,
                  attachments: this.state.draft?.attachments,
                }}
                isOpened={true}
                onCancel={() => this.setState({ draft: undefined })}
                onSuccess={async () => {
                  await reloadContentObject(this.state.draft);
                  this.setState({ draft: undefined });
                }}
              />
            )}
            {hasPinnedPosts && (
              <div style={{ margin: '15px 0' }}>
                <PinnedFeed
                  community={community}
                  actions={actions}
                  limit={3}
                  pinned={true}
                  viewer={viewer}
                  reloadNumber={this.state.reloadNumber}
                  onInitiatePostEditing={this.initiatePostEditing}
                />
              </div>
            )}
            <div>{feedNode}</div>
            {!hasPinnedPosts && !hasContent && !loadingFeed && (
              <Row
                item={{
                  gutter: 15,
                  align: 'middle',
                  justify: 'center',
                  className: 'community-feed-empty-state',
                }}
              >
                <Col item={{ span: 24, className: 'community-feed-empty-state__icon' }}>
                  <FrownOutlined />
                </Col>
                <Col item={{ span: 24, className: 'community-feed-empty-state__text' }}>
                  <Text>This community has no content.</Text>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      />
    );
  }
}

export class Renderer extends React.Component {
  render() {
    return <CommunityProvider communityId={this.props.communityId} render={Feed} renderProps={this.props} />;
  }
}

const mapDispatch = (dispatch, props) => {
  const feedName = getFeedKey({ community: { id: props.communityId }, pinned: true });
  return {
    loadCollection: () =>
      dispatch.feed.getAsync({
        feed: feedName,
        params: {
          community_id: props.communityId,
          limit: 3,
          reset: true,
          pinned: true,
        },
      }),
    reloadContentObject: (object) => {
      dispatch.feed.reloadAsync({ feed: feedName, postId: object.id });
    },
  };
};

const mapState = (state, props) => {
  const pinnedFeedName = getFeedKey({ community: { id: props.communityId }, pinned: true });
  const feedName = getFeedKey({ community: { id: props.communityId }, pinned: false });
  const pinned = select.feed.get(state, pinnedFeedName);
  const feed = select.feed.get(state, feedName);
  return {
    hasPinnedPosts: pinned && pinned.length > 0,
    hasContent: feed && feed.length > 0,
    communityId: props.communityId,
    viewer: select.session.user(state),
    loadingFeed: state.loading.effects.feed.getAsync,
  };
};

export const CommunityFeed = withTranslation()(connect(mapState, mapDispatch)(withRouter(Renderer)));
