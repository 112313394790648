import { useEffect, useState } from 'react';
import { Button } from 'ui';

export const useTextCollapseExpand = ({ text, rows = 2 }: { text?: string; rows?: number }) => {
  const [isDescriptionShrunk, setIsDescriptionShrunk] = useState<boolean>(true);
  const [isEllipsisApplied, setIsEllipsisApplied] = useState<boolean>(false);

  useEffect(() => {
    setIsEllipsisApplied(false);
    setIsDescriptionShrunk(true);
  }, [text]);

  const button = isEllipsisApplied ? (
    <Button
      className="channels-organization-select__description__button"
      type="link"
      onClick={() => {
        setIsDescriptionShrunk((isDescriptionShrunk) => !isDescriptionShrunk);
      }}
    >
      {isDescriptionShrunk ? 'Show more' : 'Show less'}
    </Button>
  ) : null;

  return {
    ellipsis: isDescriptionShrunk
      ? {
          rows,
          onEllipsis() {
            setIsEllipsisApplied(true);
          },
        }
      : false,
    collapseExpandButton: button,
  };
};
