import { type ClipboardInputTransformationData } from '@ckeditor/ckeditor5-clipboard';
import type RichTextEditorBase from 'rich-text-editor/build';
import { message } from 'ui/Message';

const findRecursiveWrongImage = (parent: any) => {
  if (parent.getChildren) {
    for (const element of parent.getChildren()) {
      if (element.name === 'img' && parent.name !== 'figure') {
        return true;
      }

      if (findRecursiveWrongImage(element)) {
        return true;
      }
    }
  }

  return false;
};

function ClipboardImageValidator(editor: RichTextEditorBase) {
  editor.plugins
    .get('ClipboardPipeline')
    .on('inputTransformation', (event_, data: ClipboardInputTransformationData) => {
      const { content } = data;

      if (findRecursiveWrongImage(content)) {
        event_.stop();
        message.error(
          'We cannot allow images from unknown sources for security reasons. Please use the regular upload method to submit your images.',
        );
      }
    });
}

export default ClipboardImageValidator;
