import * as React from 'react';
import { Formik, Field } from 'formik';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { isAfter, format, parseISO, isDate, isValid } from 'date-fns';

import { Box } from 'old/atoms/box';
import { H4, Text } from 'old/atoms/text';
import { Button } from 'old/atoms/button';
import { validator } from 'old/utils/validator';
import { ThemeConsumer } from 'old/atoms/theme';
import { TextField, CheckboxGroup, DateField, ScrollToError, CropperEditorField, TagsField } from 'old/atoms/form';
import { CommunitiesField } from 'old/atoms/form/smart-fields';
import { Icon } from 'old/atoms/icon';
import { CampaignService } from 'old/services/CampaignService';
import { CampaignDescription } from './CampaignDescription';
import { showSuccessErrorAlert } from 'old/atoms/alert';
import { Loader } from '../../atoms/loader';
import { ToggleWithLabel } from '../../atoms/form/base-atoms/ToggleWithLabel';
import { canManageShowInChannels } from 'permissions';
import { CheckboxWithLabel } from 'old/atoms/form/base-atoms/CheckboxWithLabel';
import { Tooltip, HelpIcon, Alert, Section, Text as TextAD } from 'ui';

const FORMAT_SERIALIZE = 'yyyy-MM-dd';

const STRIPE_GATEWAY = 'stripe';
const BRAINTREE_GATEWAY = 'braintree';
const APPLEPAY_GATEWAY = 'applepay';

const STRIPE_DEFAULT = [STRIPE_GATEWAY];
const BRAINTREE_DEFAULT = [BRAINTREE_GATEWAY];

class Renderer extends React.Component {
  state = {
    allowStripe: this.props.campaign ? this.props.campaign.payment_methods.includes(STRIPE_GATEWAY) : false,
  };

  initialValues() {
    let currentCampaign = null;
    if (this.props.campaign) {
      currentCampaign = this.props.campaign;
    }

    const formatDate = (value) => {
      const parsedDate = parseISO(value);
      const isValidDate = isDate(parsedDate) && isValid(parsedDate);

      return isValidDate ? parsedDate : '';
    };

    return {
      cover_photo: currentCampaign ? currentCampaign.cover_photo : null,
      name: currentCampaign ? currentCampaign.name : '',
      html_content: currentCampaign ? currentCampaign.html_content : '',
      text_content: currentCampaign ? currentCampaign.text_content : '',
      community: currentCampaign ? [currentCampaign.community] : '',
      amount1: Number(currentCampaign?.amounts[0] ?? 20).toFixed(2),
      amount2: Number(currentCampaign?.amounts[1] ?? 40).toFixed(2),
      amount3: Number(currentCampaign?.amounts[2] ?? 60).toFixed(2),
      amount4: Number(currentCampaign?.amounts[3] ?? 80).toFixed(2),
      amount_goal: Number(currentCampaign?.amount_goal ?? 0).toFixed(2),
      intervals: currentCampaign ? currentCampaign.intervals : [],
      payment_methods: currentCampaign ? currentCampaign.payment_methods : BRAINTREE_DEFAULT,
      start_time: currentCampaign ? formatDate(currentCampaign.start_time) : '',
      end_time: currentCampaign ? formatDate(currentCampaign.end_time) : '',
      allow_comment: currentCampaign ? [currentCampaign.allow_comment] : [false],
      allow_share: currentCampaign ? [currentCampaign.allow_share] : [false],
      allow_custom_amount: currentCampaign ? [currentCampaign.allow_custom_amount] : [true],
      pinned: currentCampaign ? currentCampaign.pinned : false,
      state: currentCampaign ? currentCampaign.state : '',
      country: currentCampaign ? currentCampaign.country : '',
      city: currentCampaign ? currentCampaign.city : '',
      zip: currentCampaign ? currentCampaign.zip : '',
      street: currentCampaign ? currentCampaign.street : '',
      apt: currentCampaign ? currentCampaign.apt : '',
      tag_ids: currentCampaign ? currentCampaign.tags?.map(tag => tag.id) : [],
      show_in_channels: currentCampaign?.show_in_channels ?? false,
      featured_in_channels: currentCampaign?.featured_in_channels ?? false,
    };
  }

  updateGatewayOptions = (form, allowStripe) => {
    this.setState((prevState) => {
      if (allowStripe !== prevState.allowStripe) {
        form.setFieldValue('payment_methods', allowStripe ? STRIPE_DEFAULT : BRAINTREE_DEFAULT, true);
      }

      if (allowStripe && !form.values.intervals.includes('one-time')) {
        form.setFieldValue('allow_custom_amount', [false], true);
      }

      return { allowStripe };
    });
  };

  resetTagsValueIfNeeded = (form, prevCommunityArray, newCommunityArray) => {
    const [prevCommunity] = prevCommunityArray ?? [];
    const [newCommunity] = newCommunityArray ?? [];

    if (prevCommunity?.organization_id !== newCommunity?.organization_id) {
      form.setFieldValue('tag_ids', []);
    }
  };

  resetChannelsVisibilityValueIfNeeded = (form, prevCommunityArray, newCommunityArray) => {
    const [prevCommunity] = prevCommunityArray ?? [];
    const [newCommunity] = newCommunityArray ?? [];

    if (prevCommunity?.organization_id !== newCommunity?.organization_id) {
      form.setFieldValue('show_in_channels', false);
      form.setFieldValue('featured_in_channels', false);
    }
  };

  onChangeCommunity = (fieldProps, name, value) => {
    const communityArray = value.length > 1 ? [value[value.length - 1]] : value;
    const previousArray = fieldProps.form.values[name];

    fieldProps.form.setFieldValue(name, communityArray, true);
    fieldProps.form.setFieldTouched(name, true, true);
    fieldProps.field.onBlur(name);

    this.resetTagsValueIfNeeded(fieldProps.form, previousArray, communityArray);
    this.updateGatewayOptions(fieldProps.form, Boolean(communityArray[0]?.organization?.meta?.stripe));
    this.resetChannelsVisibilityValueIfNeeded(fieldProps.form, previousArray, communityArray);
  };

  onChangeInterval = (form, allowCustomAmount) => {
    if (form.values.payment_methods.includes('stripe')) {
      form.setFieldValue('allow_custom_amount', [allowCustomAmount], true);
    }
  };

  mapTagsForPicker = (tags) => tags.map((tagItem) => ({ value: tagItem.id, label: tagItem.name }));

  render() {
    let currentCampaign = null;
    if (this.props.campaign) {
      currentCampaign = this.props.campaign;
    }

    return (
      <React.Fragment>
        <ThemeConsumer>
          {(theme) => (
            <Box className="manage-campaign">
              <Formik
                validateOnChange
                onSubmit={async (values) => {
                  const {
                    allow_comment,
                    allow_share,
                    allow_custom_amount,
                    pinned,
                    amount1,
                    amount2,
                    amount3,
                    amount4,
                    start_time,
                    end_time,
                    community,
                    ...final
                  } = values;
                  const result = {
                    ...final,
                    cover_photo: [values.cover_photo],
                    community_id: community[0].id,
                    allow_comment: allow_comment.length > 1 ? allow_comment[1] : allow_comment[0],
                    allow_share: allow_share.length > 1 ? allow_share[1] : allow_share[0],
                    allow_custom_amount:
                      allow_custom_amount.length > 0 ? allow_custom_amount[allow_custom_amount.length - 1] : false,
                    pinned: pinned,
                    amounts: [amount1, amount2, amount3, amount4],
                    start_time: format(start_time, FORMAT_SERIALIZE),
                    end_time: format(end_time, FORMAT_SERIALIZE),
                  };
                  let response;

                  if (currentCampaign) {
                    response = await CampaignService.update(currentCampaign.id, result);
                  } else {
                    response = await CampaignService.create(result);
                  }

                  showSuccessErrorAlert({
                    isOk: response.ok,
                    successMessage: <Trans>Successfully saved!</Trans>,
                    errorMessage: response?.data?.message ? (
                      response.data.message
                    ) : (
                      <Trans>Something went wrong.</Trans>
                    ),
                  });

                  this.props.confirm();
                }}
                validate={(values) => {
                  return validator.validateAll(values, {
                    cover_photo: 'required',
                    name: 'required|min:3',
                    text_content: 'required|min:3',
                    community: 'required',
                    amount1: 'required|numeric|min:1',
                    amount2: `required|numeric|min:${values.amount1 ? Number(values.amount1) + 0.01 : 1}`,
                    amount3: `required|numeric|min:${values.amount2 ? Number(values.amount2) + 0.01 : 1}`,
                    amount4: `required|numeric|min:${values.amount3 ? Number(values.amount3) + 0.01 : 1}`,
                    amount_goal: 'required|numeric|max:9999999999',
                    intervals: 'required',
                    payment_methods: 'required',
                    start_time: 'required',
                    end_time: 'required',
                  });
                }}
                initialValues={this.initialValues()}
              >
                {(formik) => (
                  <React.Fragment>
                    <ScrollToError {...formik} />
                    <CropperEditorField name="cover_photo" />
                    <Box className="manage-campaign__fields-group">
                      <TextField
                        name="name"
                        placeholder={this.props.t('Title')}
                        label={<Trans>Title</Trans>}
                        id={'name'}
                      />
                      <CampaignDescription initialValues={this.initialValues()} label={this.props.t('Description')} />
                      <H4>
                        <Trans>POST IN COMMUNITY</Trans>
                      </H4>
                      <CommunitiesField
                        disabled={Boolean(this.props.campaign)}
                        placeholder={this.props.t('Search community')}
                        name="community"
                        scope="admin"
                        currentSelectedData={formik.values.community}
                        onChange={this.onChangeCommunity}
                        id={'search-community'}
                      />
                    </Box>
                    <Box className="manage-campaign__fields-group">
                      <H4>
                        <Trans>CHECK ADDRESS</Trans>
                      </H4>
                      <TextField
                        name="state"
                        placeholder={this.props.t('State')}
                        label={<Trans>State</Trans>}
                        id={'state'}
                      />
                      <TextField
                        name="country"
                        placeholder={this.props.t('Country')}
                        label={<Trans>Country</Trans>}
                        id={'country'}
                      />
                      <Box flexDirection="row" className="row">
                        <div className="col-sm-8">
                          <TextField
                            name="city"
                            placeholder={this.props.t('City')}
                            label={<Trans>City</Trans>}
                            id={'city'}
                          />
                        </div>
                        <div className="col-sm-4">
                          <TextField
                            name="zip"
                            placeholder={this.props.t('ZIP')}
                            label={<Trans>ZIP</Trans>}
                            id={'zip'}
                          />
                        </div>
                      </Box>
                      <Box flexDirection="row" className="row">
                        <div className="col-sm-8">
                          <TextField
                            name="street"
                            placeholder={this.props.t('Street')}
                            label={<Trans>Street</Trans>}
                            id={'street'}
                          />
                        </div>
                        <div className="col-sm-4">
                          <TextField
                            name="apt"
                            placeholder={this.props.t('Apt')}
                            label={<Trans>Apt</Trans>}
                            id={'apt'}
                          />
                        </div>
                      </Box>
                    </Box>
                    <Box className="manage-campaign__fields-group">
                      <H4>
                        <Trans>SET YOUR AMOUNTS</Trans>
                      </H4>
                      <div className="manage-campaign__fields-group__amount">
                        <TextField name="amount1" type="number" id={'amount1'} placeholder={'Enter amount'} />
                      </div>
                      <div className="manage-campaign__fields-group__amount">
                        <TextField name="amount2" type="number" id={'amount2'} placeholder={'Enter amount'} />
                      </div>
                      <div className="manage-campaign__fields-group__amount">
                        <TextField name="amount3" type="number" id={'amount3'} placeholder={'Enter amount'} />
                      </div>
                      <div className="manage-campaign__fields-group__amount">
                        <TextField name="amount4" type="number" id={'amount4'} placeholder={'Enter amount'} />
                      </div>
                    </Box>
                    <Box className="manage-campaign__fields-group">
                      <H4>
                        <Trans>SET SUBSCRIPTION PLANS</Trans>
                      </H4>
                      <CheckboxGroup
                        name="intervals"
                        onChange={(values) => {
                          this.onChangeInterval(formik, values.includes('one-time'));
                        }}
                        values={[
                          { value: 'one-time', label: <Trans>One Time</Trans>, id: 'one-time' },
                          { value: 'monthly', label: <Trans>Monthly</Trans>, id: 'monthly' },
                          { value: 'quarterly', label: <Trans>Quarterly</Trans>, id: 'quarterly' },
                          { value: 'annually', label: <Trans>Annually</Trans>, id: 'annually' },
                        ]}
                      />
                    </Box>
                    <Box className="manage-campaign__fields-group">
                      <H4>
                        <Trans>SET CAMPAIGN GOAL</Trans>
                      </H4>
                      <div className="manage-campaign__fields-group__label-amount">
                        <TextField
                          name="amount_goal"
                          type="number"
                          min={0}
                          placeholder="5000"
                          label={<Trans>Campaign Goal</Trans>}
                          id={'amount_goal'}
                        />
                      </div>
                    </Box>
                    <Box className="manage-campaign__fields-group">
                      <H4>
                        <Trans>SET PAYMENT METHOD</Trans>
                      </H4>
                      <CheckboxGroup
                        name="payment_methods"
                        values={[
                          ...(this.state.allowStripe
                            ? [
                                {
                                  value: STRIPE_GATEWAY,
                                  disabled: true,
                                  label: (
                                    <Trans>
                                      <Trans>Payments by</Trans> <Text style={{ fontWeight: 'bold' }}>Stripe</Text>
                                    </Trans>
                                  ),
                                },
                              ]
                            : [
                                {
                                  value: BRAINTREE_GATEWAY,
                                  disabled: true,
                                  label: (
                                    <Trans>
                                      <Trans>Payments by</Trans> <Text style={{ fontWeight: 'bold' }}>Braintree</Text>
                                    </Trans>
                                  ),
                                },
                                {
                                  value: APPLEPAY_GATEWAY,
                                  label: (
                                    <div className="manage-campaign__apple-pay">
                                      <Icon size={25} color={'#000'} name="apple-pay" />
                                    </div>
                                  ),
                                },
                              ]),
                        ]}
                      />
                    </Box>
                    <Box className="manage-campaign__fields-group">
                      <Field name="pinned">
                        {(insideFormik) => (
                          <ToggleWithLabel
                            label={<Trans>Pin Campaign as a Post</Trans>}
                            name="pinned"
                            inputProps={{
                              ...insideFormik.field,
                              checked: insideFormik.field.value === true,
                              onChange: (event) => {
                                insideFormik.field.onChange({
                                  target: {
                                    name: event.target.name,
                                    value: event.target.checked,
                                  },
                                });
                              },
                            }}
                            id={'pin-campaign'}
                          />
                        )}
                      </Field>
                    </Box>
                    {canManageShowInChannels({
                      viewer: this.props.user,
                      organizationId: formik.values.community[0]?.organization_id,
                    }) && formik.values.community?.[0] && formik.values.community[0].privacy !== 'secret' ? (
                      <>
                        <Box>
                          <H4>
                            <Trans>CHANNELS</Trans>
                          </H4>
                        </Box>
                        <Section lineBottom={false}>
                          <Alert
                            message={
                              <>
                                <TextAD>{this.props.t('The selected community is part of an organization that is using')}</TextAD>{' '}
                                <TextAD strong>{this.props.t(formik.values.community[0]?.organization?.custom_channels ? 'custom' : 'default')}</TextAD>{' '}
                                <TextAD>{this.props.t('channels')}</TextAD>
                              </>
                            }
                            type="info"
                            showIcon
                          />
                        </Section>
                        <TagsField
                          options={this.mapTagsForPicker(
                            formik.values.community[0]?.organization?.tags ?? [],
                          )}
                          name="tag_ids"
                          label={<Trans>Tags</Trans>}
                        />
                        <CheckboxWithLabel
                          label={
                            <>
                              <Trans>Show campaign in Channels</Trans>
                              <Tooltip
                                item={{
                                  title:
                                    'Campaign will display in your organization’s channels. Everyone in your organization will be able to donate to the campaign.',
                                }}
                              >
                                <span style={{ paddingLeft: 8 }} >
                                  <HelpIcon />
                                </span>
                              </Tooltip>
                            </>
                          }
                          name="show_in_channels"
                          inputProps={{
                            onChange: () => {
                              formik.setFieldValue('show_in_channels', !formik.values.show_in_channels);
                              if (!formik.values.show_in_channels) {
                                formik.setFieldValue('featured_in_channels', false);
                              }
                            },
                            value: formik.values.show_in_channels,
                            checked: formik.values.show_in_channels,
                          }}
                        />
                        {formik.values.show_in_channels && (
                          <>
                            <CheckboxWithLabel
                              label={
                                <>
                                  <Trans>Pin Campaign as featured in Channels</Trans>
                                  <Tooltip
                                    item={{
                                      title:
                                        'Campaign will appear as featured in the Channels',
                                    }}
                                  >
                                    <span style={{ paddingLeft: 8 }} >
                                      <HelpIcon  />
                                    </span>
                                  </Tooltip>
                                </>
                              }
                              name="featured_in_channels"
                              inputProps={{
                                onChange: () => formik.setFieldValue('featured_in_channels', !formik.values.featured_in_channels),
                                value: formik.values.featured_in_channels,
                                checked: formik.values.featured_in_channels,
                              }}
                            />
                          </>
                        )}
                      </>
                    ) : null}
                    <Box className="manage-campaign__fields-group">
                      <H4>
                        <Trans>TIME RANGE</Trans>
                      </H4>
                      <Box className="manage-campaign__fields-group__line-fields">
                        <DateField
                          label={`${this.props.t('From')}:`}
                          range={{ before: [100, 'year'], after: [10, 'year'] }}
                          name="start_time"
                          defaultValue={formik.values.start_time}
                          placeholder={`${this.props.t('From')}:`}
                          disabledDays={
                            currentCampaign
                            ? { before: new Date(), after: new Date(formik.values.end_time) }
                            : formik.values.end_time
                            ? { before: new Date(), after: new Date(formik.values.end_time) }
                            : { before: new Date() }
                          }
                          id={'start_time'}
                          onChange={(value) => {
                            const newStartTime = new Date(value);

                            if (isAfter(newStartTime, new Date(formik.values.end_time))) {
                              formik.setFieldValue('end_time', newStartTime);
                            }
                          }}
                        />
                        <DateField
                          name="end_time"
                          placeholder={`${this.props.t('To')}:`}
                          label={`${this.props.t('To')}:`}
                          defaultValue={formik.values.end_time}
                          range={{ before: [100, 'year'], after: [10, 'year'] }}
                          disabledDays={{
                            before: new Date(formik.values.start_time || new Date()),
                          }}
                          id={'end_time'}
                        />
                      </Box>
                    </Box>
                    <CheckboxGroup
                      name="allow_custom_amount"
                      values={[{ value: true, label: this.props.t('Allow custom amount') }]}
                      disabled={
                        !formik.values.intervals.includes('one-time') &&
                        formik.values.payment_methods.includes('stripe')
                      }
                    />
                    <Box>
                      <div className="manage-campaign__button-wrapper">
                        <Button
                          type="submit"
                          size="lg"
                          onClick={formik.submitForm}
                          color={theme.color.brand}
                          disabled={formik.isSubmitting}
                          textColor={theme.color.white}
                          title={
                            !formik.isSubmitting ? (
                              `${currentCampaign ? this.props.t('Update campaign') : this.props.t('Start campaign')}`
                            ) : (
                              <Loader color={theme.color.lightGray} size={21} />
                            )
                          }
                          id="btn_campaign-start"
                        />
                      </div>
                    </Box>
                  </React.Fragment>
                )}
              </Formik>
            </Box>
          )}
        </ThemeConsumer>
      </React.Fragment>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
  organization: select.admin.getOrganization(state),
});

export const ManageCampaign = withTranslation()(connect(mapState)(Renderer));
