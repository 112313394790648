import { type PriceInterval } from 'models/service';
import { Button } from 'ui';

type P = {
  onSelect: (donationType: PriceInterval) => void;
  types: PriceInterval[];
};

const StripeDonationTypeButtons = ({ onSelect, types }: P) => {
  const renderButtonTitle = (type: PriceInterval) => {
    switch (type) {
      case 'one_time':
        return 'One time';
      case 'monthly':
        return 'Monthly';
      case 'annually':
        return 'Annually';
      case 'quarterly':
        return 'Quarterly';
      default:
        return null;
    }
  };

  return (
    <div className="stripe-donation-type-buttons">
      {types.map((donationType: PriceInterval) => (
        <Button
          key={donationType}
          onClick={() => {
            onSelect(donationType);
          }}
          className="stripe-donation-type-buttons__button"
          size="large"
        >
          {renderButtonTitle(donationType)}
        </Button>
      ))}
    </div>
  );
};

export default StripeDonationTypeButtons;
