import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Form, TextInput, Section, Col, Row, CheckboxInput, Button } from 'ui';

type P = {
  onSubmit: (data: { email: string }) => void;
  onNavigateToPrimaryLogin: () => void;
  loading: boolean;
};

const PasswordLessLoginForm = ({ onSubmit, loading, onNavigateToPrimaryLogin }: P) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = (data: { email: string }) => {
    onSubmit(data);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      initialValues={{ remember: true }}
      className="onboarding-password-login-form__form"
    >
      <Row item={{ align: 'middle' }}>
        <Col item={{ span: 24 }}>
          <TextInput
            item={{
              name: 'email',
              label: 'Email',
              rules: [
                {
                  type: 'email',
                },
                {
                  required: true,
                  whitespace: true,
                },
              ],
            }}
            input={{
              autocomplete: 'email',
            }}
          />
        </Col>
        <Col item={{ span: 24 }}>
          <CheckboxInput
            item={{
              label: t('Remember me'),
              name: 'remember',
              valuePropName: 'checked',
            }}
          />
        </Col>
      </Row>
      <Section paddingTop paddingBottom={false} lineTop={false} lineBottom={false}>
        <div className="onboarding-paswordless-login-form__login-buttons-section">
          <Button
            className="onboarding-paswordless-login-form__button"
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={loading ? <LoadingOutlined /> : null}
            size="large"
          >
            {t('E-mail me a log in link')}
          </Button>
          <Button
            className="onboarding-paswordless-login-form__button"
            onClick={() => {
              onNavigateToPrimaryLogin();
            }}
            icon={<ArrowLeftOutlined />}
            size="large"
          >
            {t('All log in options')}
          </Button>
          {/* TODO fix this cant be in `Form.Item` it is bugged layout then, maybe it is good how is it done now */}
          {/* <FormButton
            button={{
              title: 'E-mail me a log in link',
              className: 'onboarding-paswordless-login-form__button',
              loading,
              icon: loading && <LoadingOutlined />,
            }}
            item={{ className: 'onboarding-paswordless-login-form__button' }}
          /> */}
        </div>
      </Section>
    </Form>
  );
};

export default PasswordLessLoginForm;
