import cs from 'classnames';
import { Form, Section, Button, type ButtonProps, type SectionProperties } from 'ui';

type P = {
  section?: SectionProperties;
  left?: ButtonProps;
  right?: ButtonProps;
  submit?: ButtonProps;
};

// TODO use this on mostly every Modal footer ideally
const ModalFormActions = ({ section, left, right, submit }: P) => (
  <Section paddingBottom={false} lineBottom={false} {...section}>
    <Form.Item className="modal-form-actions">
      {left ? (
        <Button size="middle" {...left} className={cs('modal-form-actions__left', left.className)}>
          {left.children ?? 'Back'}{' '}
        </Button>
      ) : null}

      {submit ? (
        <Button
          size="middle"
          type="primary"
          {...submit}
          htmlType={submit.onClick ? 'button' : 'submit'}
          className={cs('modal-form-actions__right', submit.className)}
        >
          {submit.children ?? 'Submit'}
        </Button>
      ) : null}

      {right ? (
        <Button size="middle" {...right} className={cs('modal-form-actions__right', right.className)}>
          {right.children ?? 'Back'}
        </Button>
      ) : null}
    </Form.Item>
  </Section>
);

export default ModalFormActions;
