import { useTranslation } from 'react-i18next';
import { ModalFormActions, Section, Text, Title } from 'ui';

import { ReactComponent as SuccessIcon } from './success.svg';

type P = {
  onSubmit: () => void;
};

const SubscribeResult = ({ onSubmit }: P) => {
  const { t } = useTranslation();

  return (
    <>
      <Section className="subscribe-result">
        <SuccessIcon className="subscribe-result__icon" />
        <Title level={3}>{t('Order was successful!')}</Title>
        <Text>
          {t(
            'Thank you! You have successfully completed your order form. You will receive an email once your Withings devices have been shipped.',
          )}
        </Text>
      </Section>
      <ModalFormActions submit={{ onClick: onSubmit, children: 'Continue to program' }} />
    </>
  );
};

export default SubscribeResult;
