import React from 'react';
import RichTextEditor from './RichTextEditor';

type P = {
  data: string;
};

const RichTextRenderer = ({ data }: P) => {
  return <RichTextEditor toolbar={[]} data={data} editable={false} />;
};

export default React.memo(RichTextRenderer);
