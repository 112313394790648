import type RichTextEditorBase from 'rich-text-editor/build';

// Source https://apps.timwhitlock.info/emoji/tables/unicode
function Emojis(editor: RichTextEditorBase) {
  editor.plugins.get('SpecialCharacters').addItems('Emoticons', [
    { character: '😁', title: 'grinning face with smiling eyes' },
    { character: '😂', title: 'face with tears of joy' },
    { character: '😃', title: 'smiling face with open mouth' },
    { character: '😄', title: 'smiling face with open mouth and smiling eyes' },
    { character: '😅', title: 'smiling face with open mouth and cold sweat' },
    { character: '😆', title: 'smiling face with open mouth and tightly-closed eyes' },
    { character: '😉', title: 'winking face' },
    { character: '😊', title: 'smiling face with smiling eyes' },
    { character: '😋', title: 'face savouring delicious food' },
    { character: '😌', title: 'relieved face' },
    { character: '😍', title: 'smiling face with heart-shaped eyes' },
    { character: '😏', title: 'smirking face' },
    { character: '😒', title: 'unamused face' },
    { character: '😓', title: 'face with cold sweat' },
    { character: '😔', title: 'pensive face' },
    { character: '😖', title: 'confounded face' },
    { character: '😘', title: 'face throwing a kiss' },
    { character: '😚', title: 'kissing face with closed eyes' },
    { character: '😜', title: 'face with stuck-out tongue and winking eye' },
    { character: '😝', title: 'face with stuck-out tongue and tightly-closed eyes' },
    { character: '😞', title: 'disappointed face' },
    { character: '😠', title: 'angry face' },
    { character: '😡', title: 'pouting face' },
    { character: '😢', title: 'crying face' },
    { character: '😣', title: 'persevering face' },
    { character: '😤', title: 'face with look of triumph' },
    { character: '😥', title: 'disappointed but relieved face' },
    { character: '😨', title: 'fearful face' },
    { character: '😩', title: 'weary face' },
    { character: '😪', title: 'sleepy face' },
    { character: '😫', title: 'tired face' },
    { character: '😭', title: 'loudly crying face' },
    { character: '😰', title: 'face with open mouth and cold sweat' },
    { character: '😱', title: 'face screaming in fear' },
    { character: '😲', title: 'astonished face' },
    { character: '😳', title: 'flushed face' },
    { character: '😵', title: 'dizzy face' },
    { character: '😷', title: 'face with medical mask' },
    { character: '😸', title: 'grinning cat face with smiling eyes' },
    { character: '😹', title: 'cat face with tears of joy' },
    { character: '😺', title: 'smiling cat face with open mouth' },
    { character: '😻', title: 'smiling cat face with heart-shaped eyes' },
    { character: '😼', title: 'cat face with wry smile' },
    { character: '😽', title: 'kissing cat face with closed eyes' },
    { character: '😾', title: 'pouting cat face' },
    { character: '😿', title: 'crying cat face' },
    { character: '🙀', title: 'weary cat face' },
    { character: '🙅', title: 'face with no good gesture' },
    { character: '🙆', title: 'face with ok gesture' },
    { character: '🙇', title: 'person bowing deeply' },
    { character: '🙈', title: 'see-no-evil monkey' },
    { character: '🙉', title: 'hear-no-evil monkey' },
    { character: '🙊', title: 'speak-no-evil monkey' },
    { character: '🙋', title: 'happy person raising one hand' },
    { character: '🙌', title: 'person raising both hands in celebration' },
    { character: '🙍', title: 'person frowning' },
    { character: '🙎', title: 'person with pouting face' },
    { character: '🙏', title: 'person with folded hands' },
  ]);
}

export default Emojis;
