import type RichTextEditorBase from 'rich-text-editor/build';

class UploadAdapter {
  loader: any;
  onRequestUpload: (uri: string) => any;
  onStart: (id: string) => void;
  onSuccess: (id: string) => void;
  onError: (id: string, message: string) => void;
  metaData: Record<string, { width: number; height: number }>;

  constructor({
    loader,
    onRequestUpload,
    onHandleStart,
    onHandleSuccess,
    onHandleError,
    metaData,
  }: {
    loader: any;
    onRequestUpload: (uri: string) => any;
    onHandleStart: (id: string) => void;
    onHandleSuccess: (id: string) => void;
    onHandleError: (id: string, message: string) => void;
    metaData: Record<string, { width: number; height: number }>;
  }) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.onRequestUpload = onRequestUpload;
    this.onStart = onHandleStart;
    this.onSuccess = onHandleSuccess;
    this.onError = onHandleError;
    this.metaData = metaData;
  }

  // Starts the upload process.
  upload() {
    const { loader, onRequestUpload, onStart, onSuccess, onError, metaData } = this;
    // Return a promise that will be resolved when the file is uploaded.
    return loader.file.then(async (file: any) => {
      onStart(loader.id);

      return new Promise((resolve, reject) => {
        const img = new Image();

        img.addEventListener('load', async function () {
          const response = await onRequestUpload(file);

          if (response.ok) {
            metaData[response.data.url] = {
              width: img.width,
              height: img.height,
            };

            onSuccess(loader.id);

            resolve({
              default: response.data.url,
            });
            return;
          }

          onError(loader.id, 'Error during file upload');
          reject();
        });

        img.addEventListener('error', function () {
          onError(loader.id, 'Error during accesing file');
          reject();
        });

        img.src = URL.createObjectURL(file);
      });
    });
  }

  // Aborts the upload process.
  abort() {
    this.onError(this.loader.id, 'Abort file upload');
    return false;
  }
}

function ImageUpload(
  onRequestUpload: (uri: string) => any,
  onChangeUploading: (uploading: boolean) => void,
  onUpdateData: (editor: any) => undefined | void,
  onError: (message: string) => void,
  uploadData: Record<string, boolean>,
  metaData: Record<string, { width: number; height: number }>,
) {
  const onHandleChangeUploading = () => {
    // eslint-disable-next-line unicorn/no-array-reduce
    const uploading = Object.values(uploadData).reduce(
      (uploading, uploadingImage) => uploading || uploadingImage,
      false,
    );
    onChangeUploading(uploading);
  };

  const onHandleStart = (id: string) => {
    uploadData[id] = true;
    onHandleChangeUploading();
  };

  const onHandleSuccess = (id: string) => {
    uploadData[id] = false;
    onHandleChangeUploading();
  };

  const onHandleError = (id: string, message: string) => {
    uploadData[id] = false;
    onHandleChangeUploading();
    onError(message);
  };

  return function (editor: RichTextEditorBase) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return new UploadAdapter({
        loader,
        onRequestUpload,
        onHandleStart,
        onHandleSuccess,
        onHandleError,
        metaData,
      });
    };

    editor.conversion.for('downcast').add((dispatcher: any) => {
      dispatcher.on('attribute:src:imageBlock', (_: any, data: any, conversionApi: any) => {
        const viewWriter = conversionApi.writer;

        const url = data.attributeNewValue;
        const dimensions = metaData[url];

        if (dimensions) {
          viewWriter.setAttribute('imagewidth', dimensions.width, conversionApi.mapper.toViewElement(data.item));
          viewWriter.setAttribute('imageheight', dimensions.height, conversionApi.mapper.toViewElement(data.item));
          // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (!uploadData[url]) {
          uploadData[url] = true;
          onHandleChangeUploading();

          const img = new Image();

          img.addEventListener('load', function () {
            metaData[url] = {
              width: img.width,
              height: img.height,
            };

            uploadData[url] = false;
            onHandleChangeUploading();
            onUpdateData(editor);
          });

          img.addEventListener('error', function () {
            uploadData[url] = false;
            onHandleChangeUploading();
          });

          img.src = url;
        }
      });
    });

    editor.data.upcastDispatcher.on('element:figure', (_: any, data: any) => {
      if (data.viewItem.childCount) {
        for (let index = 0; index < data.viewItem.childCount; index++) {
          const children = data.viewItem.getChild(index);

          if (children.name === 'img' && children.getAttribute('src')) {
            metaData[children.getAttribute('src')] = {
              width: data.viewItem.getAttribute('imagewidth'),
              height: data.viewItem.getAttribute('imageheight'),
            };
          }
        }
      }
    });
  };
}

export default ImageUpload;
