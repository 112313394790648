import { InvitationToken, type InvitationTokenKind } from 'models';
import { useMutation } from 'query';
import { getInviteToken } from '../../services';

type Variables = {
  kind: InvitationTokenKind;
  subjectId: string;
};

export const useGenerateInviteToken = () =>
  useMutation<InvitationToken, Variables>(async ({ kind, subjectId }: Variables) => {
    const resposne = await getInviteToken({
      kind,
      subject_type: 'Event',
      subject_id: subjectId,
    });

    return new InvitationToken(resposne.data);
  });
