import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { Entity } from 'models';
import { Loader } from '../loader';
import { ThemeConsumer } from '../theme';
import { Icon } from '../icon';
import { Box } from '../box';
import { Text } from '../text';
import { Button } from '../button';
import { Count } from '../count';
import { JoinCommunityQuestionForm } from './JoinCommunityQuestionForm';
import { RichTextRenderer } from '../../atoms/rich-text';
import { Col, Avatar } from 'ui';
import { can } from '../../utils/permissions';
import { ConsentsModal } from 'features/consents';
import { images } from 'common/utils';
import { hasMembershipInSubject, hasChangeRequestStatus, Community } from 'models';

const buttonSettings = {
  type: 'button',
  size: 'lg',
  block: true,
};

function Section(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <React.Fragment>
          <Text
            size="11px"
            lineHeight="1.2"
            weight="600"
            color={theme.color.lightGray}
            component="p"
            style={{ padding: '5px 15px', borderBottom: '1px solid #EDEFF2' }}
          >
            {props.title.toUpperCase()}
          </Text>
          {props.children}
        </React.Fragment>
      )}
    </ThemeConsumer>
  );
}

function Contact(props) {
  return (
    <div className="community-preview__contact">
      <Avatar
        size={42}
        name={props.user.first_name}
        photo={props.user.profile_photo ?? images.default.userProfilePhoto}
      />
      <Text size="11px" lineHeight="12px" component="p" className="community-preview__contact__text">
        {props.user.first_name} <br />
        <strong>
          <Trans>Member</Trans>
        </strong>
      </Text>
    </div>
  );
}

class Renderer extends React.Component {
  state = {
    visibleQuestion: false,
  };

  onShowConsents = (show) => {
    this.setState({ isConsentsOpened: show });
  };

  render() {
    const { data, loading, viewer, fetchUser } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="community-preview">
            <Box
              justifyContent="flex-end"
              className="community-preview__header"
              style={{
                backgroundImage: `url(${data.cover_photo ? data.cover_photo.url : images.default.communityCoverPhoto}`,
              }}
            >
              <Box flexDirection="row" alignItems="center" style={{ position: 'relative' }}>
                {data.organization?.profile_photo ? (
                  <img src={data.organization.profile_photo?.url ?? images.default.organizationProfilePhoto} alt="" />
                ) : null}
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                  {data.short_name ? (
                    <Text size="22px" lineHeight="1.2" color="white" weight="500" component="p">
                      {data.short_name}
                    </Text>
                  ) : null}
                  <Text size="16px" lineHeight="1.2" color="white" component="p">
                    {data.name}
                  </Text>
                </div>
              </Box>
            </Box>
            {!loading ? (
              <React.Fragment>
                <div className="community-preview__count-wrapper">
                  <div className="community-preview__count">
                    <Count iconName="user" iconColor={theme.color.lightGray} color="#B0BEC5">
                      {data.members_count ?? 0}
                    </Count>
                  </div>
                  {hasMembershipInSubject({ viewer, subjectType: 'Community', subjectId: data.id }) ? (
                    <React.Fragment>
                      <div className="community-preview__cta">
                        <Button
                          {...buttonSettings}
                          color={theme.color.green}
                          outline
                          title={<Trans>Joined!</Trans>}
                          disabled
                        />
                      </div>
                      <div className="community-preview__cta">
                        <Button
                          {...buttonSettings}
                          color={theme.color.brand}
                          textColor="white"
                          title={<Trans>Start Exploring!</Trans>}
                          onClick={() => this.props.onExploring(data)}
                        />
                      </div>
                    </React.Fragment>
                  ) : data.privacy === 'public' || can(data, 'Community', 'joinWithoutPermission') ? (
                    <div className="community-preview__cta">
                      {data.organization?.required_consents?.some(
                        (requiredConsent) =>
                          !viewer.users_consents?.some((consent) => requiredConsent.id === consent.id),
                      ) ? (
                        <Button
                          {...buttonSettings}
                          color={theme.color.green}
                          textColor="white"
                          title={<Trans>Confirm consents to join</Trans>}
                          onClick={() => {
                            this.onShowConsents(true);
                          }}
                        />
                      ) : (
                        <Button
                          {...buttonSettings}
                          color={theme.color.green}
                          textColor="white"
                          title={<Trans>Join Now!</Trans>}
                          onClick={() => this.props.onJoinNow(data)}
                        />
                      )}
                    </div>
                  ) : data.privacy === 'private' ? (
                    hasChangeRequestStatus({ community: new Community(data), status: 'pending' }) ? (
                      <React.Fragment>
                        <div style={{ margin: '10px 10px 0' }}>
                          <Button
                            {...buttonSettings}
                            color={theme.color.green}
                            outline
                            disabled
                            title={(props) => (
                              <React.Fragment>
                                <Icon name="pba-arrow-left" size={28} color={props.textColor} />
                                <Text style={props.textStyle} color={props.textColor}>
                                  <Trans>Request sent</Trans>
                                </Text>
                              </React.Fragment>
                            )}
                          />
                        </div>
                        <Box style={{ padding: '15px 0' }}>
                          <Text color={theme.color.gray} size="14px" lineHeight="1.5">
                            <Trans>
                              Requests to join communities are generally reviewed within <strong>24 hours</strong>. Feel
                              free to continue browsing other communities that interest you.
                            </Trans>
                          </Text>
                        </Box>
                      </React.Fragment>
                    ) : this.state.visibleQuestion ? (
                      <Col item={{ span: 24, className: 'community-preview__join-question' }}>
                        <JoinCommunityQuestionForm
                          onSubmit={(values) => {
                            this.props.onJoinNow(data, values, true);
                          }}
                        />
                      </Col>
                    ) : (
                      <Box justifyContent="center" alignItems="center">
                        <Button
                          {...buttonSettings}
                          color={theme.color.green}
                          textColor="white"
                          title={<Trans>Request to Join</Trans>}
                          style={{ width: 300 }}
                          onClick={() => this.setState({ visibleQuestion: true })}
                        />
                      </Box>
                    )
                  ) : null}
                </div>

                {data.administrators ? (
                  <Section title="Contact">
                    <div style={{ paddingBottom: 10 }}>
                      <div style={{ overflow: 'auto', padding: '20px 0' }}>
                        <div style={{ whiteSpace: 'nowrap', display: 'inline-block', padding: '0 10px' }}>
                          {data.administrators.map((user) => (
                            <Contact key={user.id} user={user} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </Section>
                ) : null}

                <Section title="About">
                  <div style={{ padding: '22px 15px' }}>
                    <RichTextRenderer data={data.html_description} />
                  </div>
                </Section>
              </React.Fragment>
            ) : (
              <Loader color={theme.color.lightGray} size={30} />
            )}
            {this.state.isConsentsOpened ? (
              <ConsentsModal
                isOpened
                entity={new Entity(data)}
                requiredConsentIds={data.organization.required_consents.map((consent) => consent.id)}
                onAccept={async () => {
                  await fetchUser();
                  this.onShowConsents(false);
                }}
                onReject={async () => {
                  await fetchUser();
                  this.onShowConsents(false);
                }}
                onClose={() => {
                  fetchUser();
                  this.onShowConsents(false);
                }}
                zIndex={100000}
              />
            ) : null}
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state) => {
  return {
    viewer: select.session.user(state),
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchUser: () => {
      return dispatch.session.fetchUser();
    },
  };
};

export const CommunityPreview = connect(mapState, mapDispatch)(Renderer);
