import { apiClient, type ApiClientResponse, routes } from 'common/services';

type CommunitiesUsersRequest = {
  community_ids: string[];
  // TODO this should be type
  role?: 'admin' | 'member';
};

// TODO Type and should not be this feature
export const getCommunitiesUsers = async (params: CommunitiesUsersRequest) =>
  apiClient.get<any, ApiClientResponse<any>>(routes.community.autoComplete, {
    params: {
      scope: 'communities',
      ...params,
    },
  });
