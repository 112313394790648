import { type ReduxUser } from 'models';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { canCreateProgram, canViewArchivedPrograms } from 'permissions';
import { Title, SelectInput, Button, Form, AddLine, Row, Col, Segmented, TextInput } from 'ui';
import { type ProgramsScope, type ProgramStatus } from '../types';
import { JoinedProgramsList } from './JoinedProgramsList';

type FormValues = {
  search?: string;
  scope?: ProgramsScope;
  status?: ProgramStatus | 'all';
};

type P = {
  getPathToProgramDetail: (id: string) => string;
  navigateToProgramCreateForm: () => void;
  viewer: ReduxUser;
};

const getInitialValues = (viewer: ReduxUser): Partial<FormValues> => {
  return {
    status: canCreateProgram({ viewer }) ? 'published' : 'all',
    scope: 'joined',
  };
};

const JoinedPrograms = ({ getPathToProgramDetail, navigateToProgramCreateForm, viewer }: P) => {
  const { t } = useTranslation();

  return (
    <Form<FormValues> initialValues={getInitialValues(viewer)} className="joined-programs" noOptional>
      <Title level={1}>Joined programs</Title>
      <div className="joined-programs-header">
        <Row item={{ gutter: variables.spaceMd.value }}>
          <Col>
            <TextInput
              item={{
                name: 'search',
                label: 'Search',
              }}
            />
          </Col>
          {canCreateProgram({ viewer }) ? (
            <Col>
              <Form.Item
                name="scope"
                label="Membership"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Segmented
                  options={[
                    { value: 'joined', label: t('Joined') },
                    { value: 'not_joined', label: t('Not Joined') },
                  ]}
                />
              </Form.Item>
            </Col>
          ) : null}
          {canViewArchivedPrograms({ viewer }) ? (
            <Col>
              <SelectInput
                item={{
                  className: 'joined-programs-header__select',
                  name: 'status',
                  label: 'Status',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  options: [
                    {
                      value: 'all',
                      label: t('All'),
                    },
                    {
                      value: 'draft',
                      label: t('Draft'),
                    },
                    {
                      value: 'published',
                      label: t('Published'),
                    },
                    {
                      value: 'finished',
                      label: t('Archived'),
                    },
                  ],
                  getOptionLabel: (option) => option.label,
                  getOptionValue: (option) => option.value,
                }}
              />
            </Col>
          ) : null}
        </Row>
        {canCreateProgram({ viewer }) ? (
          <Button
            onClick={() => {
              navigateToProgramCreateForm();
            }}
            type="link"
            className="joined-programs-header__btn"
            icon={<AddLine className="joined-programs-header__btn__icon" />}
          >
            Create program
          </Button>
        ) : null}
      </div>
      <Form.Item<FormValues> noStyle dependencies={['search', 'scope', 'status']}>
        {({ getFieldsValue }) => {
          const { scope = 'joined', search = '', status } = getFieldsValue();

          return (
            <JoinedProgramsList
              getPathToProgramDetail={getPathToProgramDetail}
              search={search}
              scope={scope}
              status={status === 'all' ? undefined : status}
            />
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default JoinedPrograms;
