import { apiClient, type ApiClientResponse, routes } from 'common/services';

type RequestData = {
  pathname: string;
};

export const createStripeDonationPayment = async (id: string, data: RequestData) =>
  apiClient.post<{ checkout_url: string }, ApiClientResponse<{ checkout_url: string }>>(
    routes.campaigns.stripeDonation.payment.create(id),
    {
      redirect_path: data.pathname,
    },
  );
