import { type PaginationData } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { getPrograms } from '../services';
import {
  type ProgramListItem,
  getProgramListData,
  type ProgramListDataObject,
  type ProgramsScope,
  type ProgramStatus,
} from '../types';
import { getProgramsInfiniteQueryKey } from './';

type P = {
  scope: ProgramsScope;
  pageSize: number;
  search?: string;
  organizationId?: string;
  featured?: boolean;
  status?: ProgramStatus;
};

export const useProgramsInfiniteQuery = ({ scope, pageSize, search, organizationId, featured, status }: P) =>
  useInfiniteQuery<PaginationData<ProgramListItem>>(
    getProgramsInfiniteQueryKey({ scope, pageSize, organizationId, featured, status, search }),
    async ({ pageParam: cursor = undefined }: QueryFunctionContext<QueryKey, string>) => {
      const response = await getPrograms({
        scope,
        limit: pageSize,
        cursor,
        organization_id: organizationId,
        featured,
        status,
        view: 'table',
        search,
      });
      return {
        data: response.data.data.map((programDataObject: ProgramListDataObject) =>
          getProgramListData(programDataObject),
        ),
        total: response.data.total,
      };
    },
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * pageSize >= lastPage.total) {
          return undefined;
        }

        return lastPage.data[lastPage.data.length - 1]?.id;
      },
    },
  );
