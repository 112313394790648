import * as React from 'react';
import { Trans } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { AttachmentMenu } from '../post-editor';
import { Box } from '../box';
import { ThemeConsumer } from '../theme';
import { validator } from '../../utils/validator';
import { Files, ControlIcon } from '../text-editor';
import { RichTextEditor } from '../rich-text';
import { StorageService } from '../../services/StorageService';
import { Avatar } from 'ui';
import { images } from 'common/utils';

export class Renderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...(props.comment || {}),
    };
  }

  initFormValues = (comment) => {
    return {
      html_content: comment ? comment.html_content : '',
      text_content: comment ? comment.text_content : '',
      attachments: comment ? comment.attachments : [],
    };
  };
  handleCommentChange = (formik) => (value) => {
    this.setState(
      {
        html_content: value.html,
        text_content: value.text,
      },
      () => {
        formik.setFieldValue('html_content', value.html);
        formik.setFieldValue('text_content', value.text);
      },
    );
  };

  onRequestMentions = async (value) => {
    if (value && this.props.onRequestMentions) {
      const data = await this.props.onRequestMentions(value);

      return (data || []).map((user) => ({
        id: `@${user.name}`,
        userId: user.id,
        link: `/members/${user.id}`,
      }));
    }

    return [];
  };

  handleRichTextUploading = (uploading) => this.setState({ richTextUploading: uploading });

  render() {
    return (
      <ThemeConsumer>
        {() => (
          <Formik
            initialValues={this.initFormValues(this.props.comment)}
            validate={(values) =>
              validator.validateAll(values, {
                html_content: 'required_if:attachments[0]',
              })
            }
            onSubmit={async (values, formik) => {
              const result = {
                ...values,
                id: this.props.comment ? this.props.comment.id : null,
              };
              const response = await this.props.onSubmit(result);

              if (response?.ok) {
                await formik.resetForm(this.initFormValues(this.props.comment));
                this.setState(
                  {
                    ...this.initFormValues(this.props.comment),
                  },
                );
              }

              formik.setSubmitting(false);
            }}
          >
            {(formik) => (
              <Form className="comment-form">
                {formik.values.attachments.length ? (
                  <div style={{ margin: '0 26px 0 30px', paddingTop: 10 }}>
                    <Files
                      attachments={formik.values.attachments}
                      onRemove={(attachment) => {
                        return () => {
                          formik.setFieldValue(
                            'attachments',
                            formik.values.attachments.filter((file) => file.id !== attachment.id),
                          );
                        };
                      }}
                    />
                  </div>
                ) : null}
                <Field name="html_content">
                  {() => {
                    return (
                      <Box flexDirection="row" alignItems="end" flex="1">
                        <div className="comment-form__avatar">
                          <Avatar size={25} photo={this.props.viewer.profile_photo ?? images.default.userProfilePhoto} />
                        </div>
                        <Box
                          style={{ width: 'calc(100% - 88px)' }}
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          flexGrow={1}
                        >
                          <div
                            className="comment-form__rich-text-wrapper"
                            onClick={() => this.setState({ focused: true })}
                          >
                            {this.props.focused || this.state.focused ? (
                              <div style={{ flex: 1, width: 'calc(100% - 33px)' }}>
                                <RichTextEditor
                                  collapsible={true}
                                  isFocusedInitially={true}
                                  focused={this.props.focused || this.state.focused}
                                  onChange={this.handleCommentChange(formik)}
                                  data={this.state.html_content}
                                  onRequestUpload={StorageService.send}
                                  placeholder="Comment..."
                                  mentions={this.onRequestMentions}
                                  maxWidth="100%"
                                  onUploading={this.handleRichTextUploading}
                                  disabled={this.props.loading}
                                />
                              </div>
                            ) : (
                              <div className="comment-form__placeholder" id={'start-comment'}>
                                <Trans>Comment...</Trans>
                              </div>
                            )}

                            <AttachmentMenu
                              onChange={(attachments) => {
                                formik.setFieldValue('attachments', [...formik.values.attachments, ...attachments]);
                              }}
                              onUpload={(isUploading) => {
                                formik.setSubmitting(isUploading);
                              }}
                              active={formik.values.attachments.length > 0}
                              suffixId={this.props.suffixId}
                              disabled={this.props.loading}
                            />
                          </div>
                        </Box>
                        <div style={{ padding: '4px 0 0 10px' }}>
                          <ControlIcon
                            size="23px"
                            disabled={
                              (!formik.values.html_content && formik.values.attachments.length === 0) ||
                              this.state.richTextUploading ||
                              formik.isSubmitting
                              || this.props.loading
                            }
                            active={
                              !!formik.values.html_content ||
                              formik.values.attachments.length > 0 ||
                              !formik.isSubmitting
                            }
                            name="arrow-open-right-full"
                            onClick={formik.submitForm}
                          />
                        </div>
                      </Box>
                    );
                  }}
                </Field>
              </Form>
            )}
          </Formik>
        )}
      </ThemeConsumer>
    );
  }
}

export const CommentForm = withTranslation()(Renderer);
