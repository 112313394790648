import { useMutation } from '@tanstack/react-query';
import { changeRequest } from 'common/services';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';

export const useCreateRequestToJoinQuery = () =>
  useMutation<
    undefined,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    {
      programId: string;
      data: { reason: string };
    }
  >({
    async mutationFn({ programId, data }) {
      await changeRequest({
        kind: 'program_request_to_join',
        subject_type: 'Program',
        subject_id: programId,
        meta: {
          reason_to_join: data.reason,
        },
      });

      return undefined;
    },
  });
