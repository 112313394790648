export class OauthToken {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiresIn: number;
  createdAt: number;

  constructor(data: {
    accessToken: string;
    tokenType: string;
    refreshToken: string;
    expiresIn: number;
    createdAt: number;
  }) {
    this.accessToken = data.accessToken;
    this.tokenType = data.tokenType;
    this.refreshToken = data.refreshToken;
    this.expiresIn = data.expiresIn;
    this.createdAt = data.createdAt;
  }
}
