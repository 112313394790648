import { message } from 'ui';

export type SocialName = 'email' | 'facebook' | 'linkedin' | 'x';
export type SocialType = {
  domain: '' | 'facebook.com' | 'linkedin.com' | 'twitter.com';
  generateLink: ({ sharedContent, text }: { sharedContent: string; text?: string }) => string;
  alert?: () => void;
};

export const SOCIAL_NETWORKS: Record<SocialName, SocialType> = {
  facebook: {
    domain: 'facebook.com',
    generateLink({ sharedContent }: { sharedContent: string }) {
      return `https://www.facebook.com/sharer/sharer.php?u=${window.encodeURIComponent(sharedContent)}&src=sdkpreparse`;
    },
  },
  linkedin: {
    domain: 'linkedin.com',
    generateLink({ sharedContent }: { sharedContent: string }) {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${window.encodeURIComponent(sharedContent)}`;
    },
  },
  x: {
    domain: 'twitter.com',
    generateLink({ text, sharedContent }: { text?: string; sharedContent: string }) {
      return `https://twitter.com/intent/tweet?${
        text ? `text=${window.encodeURIComponent(text)}&` : ''
      }url=${window.encodeURIComponent(sharedContent)}`;
    },
  },
  email: {
    domain: '',
    generateLink({ text, sharedContent }: { text?: string; sharedContent: string }) {
      return `mailto:?subject=Share from ${process.env.REACT_APP_TITLE ?? ''}&body=${text} ${sharedContent}`;
    },
    alert() {
      message.info('shareEmailInfoMessage');
    },
  },
};

export function openPopup(url: string, name: string) {
  const width = 640;
  const height = 480;
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  return window.open(
    url,
    name,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
  );
}
